// REST connection to the API
import { RestLink } from 'apollo-link-rest'
import { throwServerError } from '@apollo/client/core'
import { CodexDebug, CodexHooks } from '@/@core'

export default function createRestLink() {
  return new RestLink({
    uri: 'https://api.codex.gjirafa.dev/v2/',
    headers: {
      'Content-Type': 'application/json'
    },

    async responseTransformer(response, typeName) {
      try {
        response = await response.json()

        response = CodexHooks.applyFilters('apollo/responseTransformer', response, null, typeName)
        response = CodexHooks.applyFilters(
          `apollo/responseTransformer?type=${typeName}`,
          response,
          null,
          typeName
        )
      } catch (e) {
        // Do nothing
      }
      return response
    },

    customFetch: (uri, options) => {
      // Convert headers from Headers to object
      const headers: any = {}
      if (options?.headers?.entries) {
        // @ts-ignore
        // eslint-disable-next-line no-unsafe-optional-chaining
        for (const pair of options?.headers?.entries()) {
          headers[pair[0]] = pair[1]
        }
      }

      CodexDebug.apolloInfo(`RestLink customFetch uri: "${uri}"`, {
        options: { ...options, headersObject: headers }
      })

      // [Apollo Hook] Apply filters to uri
      uri = CodexHooks.applyFilters('apollo/restFetchUri', uri, null, options, headers)

      let body = {}
      try {
        if (options?.body) {
          body = JSON.parse(options.body)
        }
      } catch {
        // Do nothing
      }

      // [Apollo Hook] Apply filters to options
      options = CodexHooks.applyFilters(
        `apollo/restFetchOptions?type=${body.__typename}`,
        options,
        null,
        uri,
        headers
      )

      return fetch(uri, options).then(async (response) => {
        // Check if response.status is error code (4xx or 5xx)
        if (
          response.status.toString().startsWith('4') ||
          response.status.toString().startsWith('5')
        ) {
          throwServerError(
            response,
            // Codex - Wrapped in rest because if response has errors key, apollo will treat it as a graphql error
            { __rest: await response.json() },
            `Response not successful: Received status code ${response.status}`
          )
        }

        // [Apollo Hook] Apply filters to response
        response = CodexHooks.applyFilters('apollo/restFetchResponse', response, null, uri, options)

        return response
      })
    }
  } as RestLink.Options)
}
