import { CodexHooks } from '@/@core'

CodexHooks.addFilter('layouts/types', (items) => {
  return [
    ...items,
    {
      pluginName: 'codex',
      displayName: 'NotRegistered',
      name: 'not-registered',
      render: () => import('./NotRegistered.vue'),
      renderEditor: () => import('./NotRegistered.vue'),
      settings: {
        delete: true,
        duplicate: false,
        drag: false,
        appearanceTab: false
      },
      group: null,
      attrs: {}
    }
  ]
})
