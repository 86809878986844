import gql from 'graphql-tag'

export const PUBLISHABLE_LABEL_INPUT = gql`
  fragment PublishableLabelInput on CodexLabel {
    color
    createdAt
    createdBy
    description
    externalId
    entities
    restrictions
    id
    name
    normalizedName
    status
    type
    updatedAt
    updatedBy
  }
`

export const READ_LABEL = gql`
  ${PUBLISHABLE_LABEL_INPUT}

  query ReadLabel($id: String!, $input: PublishableLabelInput!) {
    label(id: $id, input: $input)
      @rest(type: "CodexLabel", path: "organizationId/labels/{args.id}", method: "GET") {
      ...PublishableLabelInput
    }
  }
`

export const CREATE_LABEL = gql`
  ${PUBLISHABLE_LABEL_INPUT}

  mutation CreateLabel($input: PublishableLabelInput!) {
    createLabel(input: $input)
      @rest(type: "CodexLabel", path: "organizationId/labels", method: "POST") {
      ...PublishableLabelInput
    }
  }
`

export const UPDATE_LABEL = gql`
  ${PUBLISHABLE_LABEL_INPUT}

  mutation UpdateLabel($input: PublishableLabelInput!) {
    updateLabel(input: $input)
      @rest(type: "CodexLabel", path: "organizationId/labels/{args.input.id}", method: "PUT") {
      ...PublishableLabelInput
    }
  }
`

export const DELETE_LABEL = gql`
  ${PUBLISHABLE_LABEL_INPUT}

  mutation DeleteLabel($id: String!, $input: PublishableLabelInput!) {
    deleteLabel(id: $id, input: $input)
      @rest(type: "CodexLabel", path: "organizationId/labels/{args.id}", method: "DELETE") {
      NoResponse
    }
  }
`
