<script setup lang="ts">
import { computed, inject, ref, toRefs, watch } from 'vue'
import ToggleButton from 'primevue/togglebutton'
import FolderGridItem from './FolderGridItem.vue'
import { useI18nStore } from '@/@core/i18n'
import { useDragAndDrop } from '../../composables/useDragAndDrop'
import useFolders from '../../composables/useFolders'

const ct = useI18nStore().createI18nContext('ct.assets.pages')

const { rootFolders: folders, activeFolderId, activeFolder, updateActiveFolderId } = useFolders()
const showAll = ref(false)

const resultFolders = computed(() => {
  return activeFolder?.value?.children?.items
})

const foldersComputed = computed(() => {
  return activeFolderId?.value
    ? showAll.value
      ? resultFolders.value || []
      : resultFolders.value?.slice(0, 6) || []
    : showAll.value
    ? folders.value || []
    : folders.value?.slice(0, 6) || []
})

const showShowAll = computed(() => {
  return resultFolders.value?.length ? resultFolders.value?.length > 6 : folders.value?.length > 6
})

const { handleAssetDragstart, handleDragEnter, handleFolderDrop, scrollWhenHover } =
  useDragAndDrop()
</script>

<template>
  <div class="f-grid-full" style="overflow-x: hidden">
    <Row>
      <template v-for="folder in foldersComputed" :key="folder.id">
        <Col :col="{ sm: 12, md: 4, lg: 3, xl: 2 }">
          <FolderGridItem
            :show-path="true"
            :folder="folder"
            :draggable="true"
            @click="updateActiveFolderId(folder.id)"
            @update:folder-id="updateActiveFolderId"
            @dragstart="handleAssetDragstart($event, folder, false, [])"
            @dragenter.prevent="handleDragEnter"
            @dragover.prevent="scrollWhenHover"
            @drop.prevent="handleFolderDrop"
          />
        </Col>
      </template>
    </Row>
    <Row>
      <ToggleButton
        v-if="showShowAll"
        v-model="showAll"
        class="toggle-button"
        :on-label="ct.$t('listing.folder.show-less')"
        :off-label="ct.$t('listing.folder.show-all')"
        icon-pos="left"
        unstyled
      >
        <template #icon>
          <GjIcon
            class="toggle-button--icon"
            :class="{ expanded: showAll }"
            name="ArrowDown"
            size="18px"
          />
        </template>
      </ToggleButton>
    </Row>
  </div>
</template>

<style lang="scss" scoped>
.toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;
  margin: 10px auto 0;
  cursor: pointer;

  &--icon {
    transition: all 0.3s;

    &.expanded {
      transform: rotate(180deg);
    }
  }
}
</style>
