import { enumMapping } from '@/modules/@global/utils/EnumMapping'
import { CodexAutomationEventType } from '@/gql/graphql'
import { CodexAutomationStatus } from '@/gql/graphql'
import { merge } from 'lodash'

/**
 * Workflow Name
 */
export const WORKFLOWS_NAME = 'workflows-listing'

/**
 * Workflow Event Enums
 */
export const EVENT_MAPPING = enumMapping({
  [CodexAutomationEventType.AssetUploaded]: 1,
  [CodexAutomationEventType.CommentAdded]: 2,
  [CodexAutomationEventType.CommentReplyAdded]: 3,
  [CodexAutomationEventType.CommentResolved]: 4,
  [CodexAutomationEventType.EntryCreated]: 5,
  [CodexAutomationEventType.EntryDeleted]: 6,
  [CodexAutomationEventType.EntryUpdated]: 7,
  [CodexAutomationEventType.UserMentioned]: 8
})

/**
 * Workflow Status Enums
 */
export const STATUS_MAPPING = enumMapping({
  [CodexAutomationStatus.Active]: 1,
  [CodexAutomationStatus.Deleted]: 2,
  [CodexAutomationStatus.Draft]: 3,
  [CodexAutomationStatus.Inactive]: 4
})

/**
 * Workflow Defaults
 */
export function workflowDefaults(workflow = {}) {
  return merge(
    {
      title: null,
      description: null,
      createdBy: {
        firstName: '',
        lastName: '',
        imageUrl: ''
      },
      status: CodexAutomationStatus.Active,
      eventType: CodexAutomationEventType.CommentAdded,
      sites: []
    },
    workflow
  )
}
