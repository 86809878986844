import {
  FIELD_FILTER_OPERATORS,
  FIELD_TYPES,
  FIELD_VALUE_TYPES,
  RANGE_OPERATORS,
  TEXT_FIELD_APPEARANCES
} from '@/modules/models/editor-ts/fields/constants'
import { CodexHooks } from '@/@core'
import {
  rangeValidation,
  type RangeValidationType
} from '@/modules/@global/models/field-validations/general/helpers'
import type {
  FieldAttrsType,
  FieldConditionalValidationType
} from '@/modules/@global/models/FieldTypes'

const countCharacters = (str: string) => {
  if (!str?.length) return 0
  return str.trim().length
}

const required = {
  name: 'required',
  type: FIELD_TYPES.TEXT,
  valueType: [FIELD_VALUE_TYPES.SINGLE, FIELD_VALUE_TYPES.LIST],
  component: () => import('@/modules/@global/models/field-validations/general/Required.vue'),
  attr: {
    value: false,
    errorMessage: 'Text is required.',
    conditionsEnabled: false,
    conditions: [
      {
        isSystem: false,
        field: '',
        operator: FIELD_FILTER_OPERATORS.EXISTS,
        value: ''
      }
    ]
  },
  validation: (value, attrs: FieldConditionalValidationType, field: FieldAttrsType) => {
    if (attrs.value) {
      if (field.valueType === FIELD_VALUE_TYPES.SINGLE) {
        const textLength = countCharacters(value)
        if (attrs.value && textLength < 1) {
          return { isValid: false, message: attrs.errorMessage }
        }
      } else {
        const listLength = value?.length || 0
        if (listLength < 1) {
          return { isValid: false, message: attrs.errorMessage }
        }
      }
    }

    return { isValid: true }
  }
}

const readOnly = {
  name: 'readOnly',
  type: FIELD_TYPES.TEXT,
  valueType: [FIELD_VALUE_TYPES.SINGLE, FIELD_VALUE_TYPES.LIST],
  component: () => import('@/modules/@global/models/field-validations/general/ReadOnly.vue'),
  attr: {
    value: false,
    errorMessage: 'Text is read only.',
    conditionsEnabled: false,
    conditions: [
      {
        isSystem: false,
        field: '',
        operator: FIELD_FILTER_OPERATORS.EXISTS,
        value: ''
      }
    ]
  },
  validation: () => ({ isValid: true })
}

const uniqueInCollection = {
  name: 'uniqueInCollection',
  type: FIELD_TYPES.TEXT,
  valueType: [FIELD_VALUE_TYPES.SINGLE],
  component: () => import('@/modules/@global/models/field-validations/general/Unique.vue'),
  attr: {
    value: false,
    errorMessage: 'Text should be unique'
  },
  validation: () => ({ isValid: true })
}

const textLengthRange = {
  name: 'textLengthRange',
  type: FIELD_TYPES.TEXT,
  valueType: [FIELD_VALUE_TYPES.SINGLE],
  component: () => import('./TextLengthRange.vue'),
  attr: {
    isEnabled: false,
    rangeOperator: RANGE_OPERATORS.BETWEEN,
    min: 10,
    max: 50,
    exactly: 0,
    errorTemplate: 'Text length should be {min}-{max}',
    errorMessage: 'Text length should be 10-50'
  },
  validation: (value: any, config: RangeValidationType, attrs: FieldAttrsType) => {
    const rangeValidationForTypes = [
      TEXT_FIELD_APPEARANCES.SINGLE_LINE,
      TEXT_FIELD_APPEARANCES.MULTIPLE_LINES,
      TEXT_FIELD_APPEARANCES.SLUG
    ]

    if (rangeValidationForTypes.includes(attrs.appearance)) {
      if (attrs.valueType === FIELD_VALUE_TYPES.SINGLE) {
        const textLength = countCharacters(value)
        return rangeValidation(textLength, config)
      } else {
        // TODO: Discuss with team: IF Api Validation character limit for list
      }
    }

    return { isValid: true }
  }
}

const listLengthRange = {
  name: 'listLengthRange',
  type: FIELD_TYPES.TEXT,
  valueType: [FIELD_VALUE_TYPES.LIST],
  component: () =>
    import('@/modules/@global/models/field-validations/general/ArrayLengthRange.vue'),
  attr: {
    isEnabled: false,
    rangeOperator: RANGE_OPERATORS.BETWEEN,
    min: 2,
    max: 5,
    exactly: 0,
    errorTemplate: 'List length should be between: {min}-{max}',
    errorMessage: 'List length should be between: 2-5'
  },
  validation: (value: any, config: RangeValidationType, attrs: FieldAttrsType) => {
    return rangeValidation(value?.length || 0, config)
  }
}

CodexHooks.addFilter('fields/validations', (items) => {
  return [...items, required, readOnly, uniqueInCollection, textLengthRange, listLengthRange]
})
