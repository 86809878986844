<script setup lang="ts">
import { ref } from 'vue'
import { LIST_DROPDOWN_TAGS, LIST_SELECTED_DROPDOWN_TAGS } from './queries/index.graphql'

const modelValue = defineModel<{
  operator: string
}>()
</script>

<template>
  <div class="">
    <DynamicSelectMultiple
      v-model="modelValue.operator"
      :query="LIST_DROPDOWN_TAGS"
      :selected-query="LIST_SELECTED_DROPDOWN_TAGS"
      option-label="tagAlias"
      option-value="id"
    />
  </div>
</template>
<style scoped></style>
