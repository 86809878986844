import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=null', (items) => {
  return [
    ...items,
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('@/modules/@core/pages/AboutView.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('@/modules/@core/pages/NotFound.vue')
    }
  ]
})
