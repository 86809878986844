import * as yup from 'yup'
import type { FormField } from '@/modules/@global/components/DynamicForm/DynamicForm'
import { useI18nStore } from '@/@core/i18n'
import { CodexLabelEntity } from '@/gql/graphql'

/**
 * Label forms fields definition
 */
interface LabelFormsFields {
  name: FormField
  entities: FormField
  restrictions: FormField
  description: FormField
  color: FormField
}

/**
 * Label forms fields
 */
export const LabelFormsFields = (): LabelFormsFields => {
  const ct = useI18nStore().createI18nContext('ct.labels.forms.fields')

  return {
    name: {
      type: 'Text',
      name: 'name',
      label: ct.$t('name.label'),
      rules: yup.string().required('Name is required')
    },
    description: {
      type: 'Text',
      name: 'description',
      label: ct.$t('description.label')
    },
    entities: {
      type: 'label-entities-select',
      name: 'entities',
      label: ct.$t('entities.label')
    },
    restrictions: {
      type: 'label-restrictions',
      name: 'restrictions',
      label: ct.$t('restrictions.label'),
      show: (values) =>
        values?.entities?.some((e) => e === CodexLabelEntity.All || e === CodexLabelEntity.Entry) ||
        false
    },
    color: {
      type: 'label-color',
      name: 'color',
      label: ct.$t('color.label'),
      rules: yup.string().required('Color is required')
    }
  }
}

/**
 * Create Label Form
 */
const CreateLabelForm = (): FormField[] => {
  const fields = LabelFormsFields()

  return [
    {
      type: 'Row',
      children: [
        // Name
        {
          ...fields.name,
          layout: { col: 12 }
        },
        // Description
        {
          ...fields.description,
          layout: { col: 12 }
        },
        // Entities
        {
          ...fields.entities,
          layout: { col: 12 }
        },
        // Restrictions
        {
          ...fields.restrictions,
          layout: { col: 12 }
        },
        // Color
        {
          ...fields.color,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

/**
 * Update Label Form
 */
const UpdateLabelForm = (): FormField[] => {
  const fields = LabelFormsFields()

  return [
    {
      type: 'Row',
      children: [
        // Name
        {
          ...fields.name,
          layout: { col: 12 }
        },
        // Description
        {
          ...fields.description,
          layout: { col: 12 }
        },
        // Entities
        {
          ...fields.entities,
          layout: { col: 12 }
        },
        // Restrictions
        {
          ...fields.restrictions,
          layout: { col: 12 }
        },
        // Color
        {
          ...fields.color,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

export { CreateLabelForm, UpdateLabelForm }
