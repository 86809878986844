<script setup lang="ts"></script>

<template>
  <div class="input-group">
    <div class="input-group-prepend" v-if="$slots.prepend">
      <slot name="prepend" />
    </div>
    <div
      :class="{
        'slot-left-border': $slots.prepend,
        'slot-right-border': $slots.append
      }"
      style="flex-grow: 1"
    >
      <slot />
    </div>

    <div class="input-group-append" v-if="$slots.append">
      <slot name="append" />
    </div>
  </div>
</template>

<style lang="scss">
.input-group {
  display: flex;
  align-items: center;
  width: 100%;

  .slot-left-border .p-component {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .slot-right-border .p-component {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .input-group-prepend {
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 0.5rem;
    border-top: 1px solid var(--color-border-neutral);
    border-left: 1px solid var(--color-border-neutral);
    border-bottom: 1px solid var(--color-border-neutral);
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
  }
  .input-group-append {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    border-top: 1px solid var(--color-border-neutral);
    border-right: 1px solid var(--color-border-neutral);
    border-bottom: 1px solid var(--color-border-neutral);
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }
}
</style>
