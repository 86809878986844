<script setup lang="ts">
import { useForm } from 'vee-validate'
import DynamicFormList from './DynamicFormList.vue'
import { computed, provide, ref } from 'vue'
import { CodexHooks } from '@/@core'
import { cloneDeep, isString } from 'lodash'
import { useI18nStore } from '@/@core/i18n'

/**
 * Props for the DynamicForm component
 * @typedef {Object} DynamicFormProps
 * @property {String} name - The name of the form
 * @property {Array} schema - The schema of the form
 * @property {Object} data - The initial data for the form
 * @property {Boolean} debug - Whether or not to show debug information
 * @property {Object} mutation - The mutation to use for the form
 */

/**
 * The DynamicForm component
 * @component DynamicForm
 * @param {DynamicFormProps} props - The component props
 */
const props = defineProps({
	name: {
		type: String,
		required: true
	},
	showSubmit: {
		type: Boolean,
		default: true
	},
	schema: {
		type: Array,
		required: true
	},
	data: {
		type: Object,
		default: () => ({})
	},
	debug: {
		type: Boolean,
		default: false
	},
	mutation: {
		type: Object || null,
		default: null
	},
	labels: {
		type: Object,
		default: () => ({})
	},
	class: {
		type: String,
		default: ''
	}
})

const ct = useI18nStore().createI18nContext('ct.global.components.dynamic-form')

// Define emits
let emit = defineEmits(['submit'])

// Initialize the form using the vee-validate library
const form = useForm({
  initialValues: props.data,
})

// Destructure the form object to get the handleSubmit, isSubmitting, and values properties
const { handleSubmit, isSubmitting, isValidating, values, errors, setFieldError, setErrors } = form

// Filter the schema using a CodexHook
let schemaFiltered = computed(() => {
  return CodexHooks.applyFilters(`dynamic-form/schema?name=${props.name}`, cloneDeep(props.schema))
})

// Provide the form object to child components
provide('dynamicForm', form)

// Define a function to resolve errors
function resolveError(error: any) {
  let restErrors = error.networkError?.result?.__rest

  if (restErrors) {
    if (restErrors?.errors) {
      // Field Errors
      Object.keys(restErrors?.errors).forEach((key) => {
        setFieldError(
          key.toLowerCase(),
          restErrors?.errors[key]
            .map((err: any) => {
              if (isString(err)) return err
              return err.message
            })
            .join(', ')
        )
      })
    } else if (restErrors?.message) {
      // Global Errors
      setErrors({ _global: restErrors?.message })
    } else {
      // Unknown Error
      setErrors({ _global: 'Unknown error' })
    }
  }
}

// Clear errors when the form is submitted
function clearErrors() {
  Object.keys(errors.value).forEach((key) => {
    setFieldError(key, '')
  })
}

// Define the onSubmit function to handle form submission
const onSubmit = handleSubmit(async (values) => {
  let results = {}
  if (props.mutation) {
    try {
      results = await props.mutation.mutate({ input: values })
    } catch (error) {
      clearErrors()
      resolveError(error)
      return
    }
  }

  emit('submit', values, results)
})

defineExpose({
  props: {
    submit: ref({
      disabled: isSubmitting || isValidating,
      loading: isSubmitting || isValidating,
      label: ct.d$t(props.labels.submit, 'submit'),
      onClick: onSubmit
    })
  },
  onSubmit,
  values,
  errors,
  isSubmitting,
  isValidating
})
</script>

<template>
  <form @submit="onSubmit" class="dynamic-form" :class="props.class">
    <div class="dynamic-form__fields">
      <DynamicFormList :fields="schemaFiltered" />
    </div>

    <InlineMessage
      class="dynamic-form__errors"
      v-if="errors._global"
      severity="error"
      :text="errors._global"
    >
    </InlineMessage>

		<div class="dynamic-form__actions" v-show="showSubmit">
			<Button class="dynamic-form__submit" type="submit" :disabled="isSubmitting || isValidating" :loading="isSubmitting || isValidating">
				{{ ct.d$t(labels.submit, 'submit') }}
			</Button>
		</div>

    <pre v-if="debug">Errors: {{ errors }}</pre>
    <pre v-if="debug">Values: {{ values }}</pre>
  </form>
</template>

<style scoped></style>
