// Middleware to log errors
import { onError } from '@apollo/client/link/error'
import { CodexDebug, CodexHooks } from '@/@core'

export default function createErrorLink() {
  return onError(({ graphQLErrors, networkError, response, operation }) => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
      cache,
      clientAwareness,
      forceFetch,
      getCacheKey,
      headers,
      response: r,
      optimisticResponse,
      ...requestContextDest
    } = operation.getContext()

    function isType(operation: any, type: string): boolean {
      return operation.query.definitions.some((definition: any) => definition.operation === type)
    }

    const isRest = (networkError as any)?.result?.__rest !== undefined
    const isMutation = isType(operation, 'mutation')
    const isQuery = isType(operation, 'query')
    const isSubscription = isType(operation, 'subscription')
    const catchError = requestContextDest.catchError === true

    // [Apollo Hook] Show error
    const showError = CodexHooks.applyFilters(
      'apollo/showError',
      requestContextDest.showError !== undefined
        ? requestContextDest.showError
        : isMutation && isRest,
      null,
      {
        isRest,
        isMutation,
        isQuery,
        isSubscription
      },
      operation,
      response,
      networkError,
      graphQLErrors
    )

    const requestContext = {
      ...requestContextDest,
      showError,
      catchError,
      query: operation.query.loc?.source.body,
      variables: operation.variables,
      operationName: operation.operationName,
      isMutation,
      isQuery,
      isSubscription,
      isRest
    }

    // [Apollo Hook] Apply actions to errors
    CodexHooks.applyActions(
      'apollo/errorLink',
      null,
      graphQLErrors,
      networkError,
      requestContext,
      response,
      operation
    )

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        CodexDebug.apolloError(`[GraphQL error]: Message: ${message}, Path: ${path}`, {
          locations,
          response,
          operation,
          requestContext
        })
      )
    }

    if (networkError) {
      CodexDebug.apolloError(`[Network error]: ${networkError}`, {
        networkError,
        response,
        operation,
        requestContext
      })
    }
  })
}
