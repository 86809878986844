<script setup lang="ts">
import { useMutation, useQuery } from '@vue/apollo-composable'
import { READ_VIEW, UPDATE_VIEW } from '@/modules/entries/queries/index.rest'
import { computed, inject } from 'vue'
import { cloneDeep } from 'lodash'
import { UpdateViewForm } from '@/modules/entries/forms/View'
import FormSkeleton from '@/modules/@global/components/Skeletons/FormSkeleton.vue'
import { useI18nStore } from '@/@core/i18n'
import type { Context } from '@/modules/@global/composables/useContext'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.views.popups.update-view')

/**
 * Dialog
 */
let dialog = inject(DialogRefKey)

/**
 * Read view
 */
const { loading, result, error } = useQuery(
  READ_VIEW,
  () => ({
    id: dialog?.value?.data?.id
  }),
  {
    fetchPolicy: 'network-only'
  }
)

/**
 * Update view
 */
const updateView = useMutation(UPDATE_VIEW)

const data = computed(() => {
  const apiResponse = cloneDeep(result?.value?.view)
  if (apiResponse) {
    apiResponse.customizations = {
      columns: dialog?.value?.data?.columns,
      order: cloneDeep(dialog?.value?.data?.order)
    }
    apiResponse.searchTerm = dialog?.value?.data?.searchTerm
    apiResponse.filters = dialog?.value?.data?.filters
  }
  return apiResponse
})

async function updateViewHandler(values) {
  dialog?.value?.close(values)
}
</script>

<template>
  <SidebarContent :title="ct.$t('title')">
    <template v-if="loading">
      <FormSkeleton />
    </template>

    <template v-else-if="error">
      <InlineMessage severity="error" :text="error"></InlineMessage>
    </template>

    <template v-else>
      <DynamicForm
        name="update-view"
        :ref="(r: any) => context?.setRef('form', r)"
        :show-submit="false"
        :schema="UpdateViewForm()"
        :data="data"
        :mutation="updateView"
        :labels="{
          submit: ct.$t('submit')
        }"
        @submit="updateViewHandler"
      ></DynamicForm>
    </template>

    <template #footer>
      <Button
        v-if="!loading && !error"
        v-bind="context.getRef('form')?.props?.submit.value"
      ></Button>
    </template>
  </SidebarContent>
</template>
