<script setup lang="ts">
import { computed } from 'vue'
import { useI18nStore } from '@/@core/i18n/index'
import { CodexPresetResize, CodexUrlEntityType } from '@/gql/graphql'
import Checkboxes from '@/modules/@global/components/Checkboxes/Checkboxes.vue'

const ct = useI18nStore().createI18nContext('ct.urls.general.entity-types')

// eslint-disable-next-line no-undef
const modelValue = defineModel<{
  operator: string
  value: CodexPresetResize[]
}>()

const entityTypeOptions = computed(() => {
  return [
    {
      value: CodexUrlEntityType.Section,
      label: ct.$t(CodexUrlEntityType.Section)
    },
    {
      value: CodexUrlEntityType.Entry,
      label: ct.$t(CodexUrlEntityType.Entry)
    },
    {
      value: CodexUrlEntityType.Redirect,
      label: ct.$t(CodexUrlEntityType.Redirect)
    }
  ]
})
</script>

<template>
  <div class="new-filters-dropdown__filter-body new-filters-dropdown__filter-body--scroll">
    <Row align-items="center">
      <Col>
        <Checkboxes v-model="modelValue.value" :options="entityTypeOptions" />
      </Col>
    </Row>
  </div>
</template>

<style scoped></style>
