import {
  FIELD_TYPES,
  FIELD_VALUE_TYPES,
  WIDGET_GROUPS,
  FIELD_FILTER_OPERATORS,
  RANGE_OPERATORS,
  PATTERN_TYPE
} from '@/modules/models/editor-ts/fields/constants'
import { CodexHooks } from '@/@core'

CodexHooks.addFilter('fields/types', (items) => {
  return [
    ...items,
    {
      pluginName: 'codex',
      displayName: 'URL',
      name: 'field_url',
      render: () => import('./Render.vue'),
      renderEditor: () => import('./Render.vue'),
      renderEditorPanel: [
        {
          icon: 'Settings',
          label: 'General',
          component: () => import('./General.vue'),
          isGeneral: true
        },
        {
          icon: 'Brush',
          label: 'Appearance',
          component: () => import('./Appearance.vue')
        }
      ],
      renderEditorInitialPanel: [
        {
          icon: 'Settings',
          label: 'Initial',
          component: () => import('./Initial.vue')
        }
      ],
      group: WIDGET_GROUPS.CODEX,
      icon: () => import('./icon.svg'),

      type: FIELD_TYPES.URL,
      valueType: FIELD_VALUE_TYPES.SINGLE,

      attrs: {
        editableIn: {
          default: [3]
        },
        pattern: {
          default: '/{system.modelAlias}/{id}'
        },
        generateOnly: {
          default: false
        },
        appearance: {
          default: 1
        },
        helpText: {
          default: ''
        },
        helpTextDisplay: {
          default: 'tooltip'
        },
        validation: {
          default: {
            required: {
              value: false,
              errorMessage: 'URL is required',
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: false,
                  field: '',
                  operator: FIELD_FILTER_OPERATORS.EXISTS,
                  value: ''
                }
              ]
            },
            readOnly: {
              value: false,
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: false,
                  field: '',
                  operator: FIELD_FILTER_OPERATORS.EXISTS,
                  value: ''
                }
              ]
            },
            matchingRegex: {
              isEnabled: false,
              type: PATTERN_TYPE.CUSTOM,
              patterns: [],
              flags: [],
              errorMessage: 'Pattern does not match'
            }
          }
        },
        configuration: {
          default: {
            searchable: true,
            filterable: true
          }
        },
        hidden: {
          default: {
            value: false,
            conditionsEnabled: false,
            conditions: [
              {
                isSystem: false,
                field: '',
                operator: FIELD_FILTER_OPERATORS.EXISTS,
                value: ''
              }
            ]
          }
        }
      }
    }
  ]
})

export function getPossibleParams(name, type, valueType) {
  const configs = Object.freeze({
    [TYPES.SECTION]: {
      [VALUE_TYPES.SINGLE]: [`content.${name}.id`, `content.${name}.url`],
      [VALUE_TYPES.LIST]: [`getMain(content.${name}).id`, `getMain(content.${name}).url`]
    },
    [TYPES.AUTHOR]: {
      [VALUE_TYPES.SINGLE]: [`content.${name}.id`, `content.${name}.url`],
      [VALUE_TYPES.LIST]: [`getFirst(content.${name}).id`, `getFirst(content.${name}).url`]
    }
  })
  return configs[type]?.[valueType]
}
