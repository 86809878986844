<script setup lang="ts">
import {
  LIST_DROPDOWN_SITES,
  LIST_SELECTED_DROPDOWN_SITES
} from '@/modules/sites/queries/index.graphql'
import { ref, nextTick, computed } from 'vue'
import { useCodexQuery } from '@/modules/@global/composables/useCodexQuery'
import { isArray } from 'lodash'

// ToDo: Translations

const props = withDefaults(
  defineProps<{
    query?: Object
    selectedQuery?: Object
    optionLabel?: string | ((data: any) => string)
    optionValue?: string
    display?: 'chip' | 'comma'
  }>(),
  {
    query: LIST_DROPDOWN_SITES,
    selectedQuery: LIST_SELECTED_DROPDOWN_SITES,
    optionLabel: 'name',
    optionValue: 'id',
    display: 'chip'
  }
)

/**
 * Model
 */
const modelValue = defineModel<Array<string>>({ default: () => [] })

/**
 * Pagination / Filter
 */
const page = ref(0)
const limit = ref(20)
const query = ref('')

/**
 * Load Selected Items
 */
// prettier-ignore
const { firstLoading: existingFirstLoading, result: existingResult, error: existingError } = useCodexQuery(
  props.selectedQuery,
  () => {
    return {
      // prettier-ignore
      ids: modelValue?.value ? (isArray(modelValue?.value) ? modelValue?.value : [modelValue?.value]) : ['n/a']
    }
  }
)

/**
 * Load Dropdown Items
 */
const { loading, result, error, fetchMore, variables } = useCodexQuery(
  props.query,
  () => {
    return {
      offset: 0,
      limit: limit.value,
      where: {
        query: query.value
      }
    }
  },
  {
    debounce: 100
  }
)

/**
 * Dropdown Items
 */
const records = computed(() => {
  return result.value?.data?.items || []
})

/**
 * Existing Items label
 */
const current = computed(() => {
  if (!modelValue.value || modelValue?.value?.length === 0) return null

  if (props.display === 'chip') {
    return modelValue.value.map((item: any) => {
      return {
        label: itemLabel(item),
        value: item.value
      }
    })
  }

  if (props.display === 'comma') {
    return modelValue.value.map((item: any) => itemLabel(item)).join(', ')
  }

  return null
})

/**
 * Return item label
 */
const itemLabel = (modelItem: any) => {
  let value = modelItem.value || modelItem

  // Find in props.path
  let found = result.value?.data?.items.find((item: any) => item.id === value)
  if (found)
    return typeof props.optionLabel === 'function'
      ? props.optionLabel(found)
      : found?.[props.optionLabel]

  if (existingFirstLoading.value) return 'Loading...'

  // Find in existing
  found = existingResult.value?.existing?.items.find((item: any) => item.id === value)
  if (found)
    return typeof props.optionLabel === 'function'
      ? props.optionLabel(found)
      : found?.[props.optionLabel]

  return `Item '${modelItem.value}' not found`
}

/**
 * On filter reset page and set query
 */
function onFilter(event: any) {
  page.value = 0
  query.value = event.value
}

/**
 * On change reset page
 */
function onChange() {
  page.value = 0
}

/**
 * On lazy load fetch more
 */
function onLazyLoad(event: any) {
  if (
    event.last !== result.value?.data?.items?.length ||
    event.last === result.value?.data?.total ||
    loading.value
  ) {
    return
  }

  loading.value = true
  page.value += 1

  fetchMore({
    variables: {
      offset: page.value * limit.value,
      limit: limit.value
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      nextTick(() => {
        loading.value = false
      })

      if (!fetchMoreResult) return prev
      return Object.assign({}, prev, {
        data: {
          ...prev?.data,
          ...fetchMoreResult?.data,
          items: [...(prev?.data?.items || []), ...(fetchMoreResult?.data?.items || [])]
        }
      })
    }
  })
}
</script>

<template>
  <MultiSelect
    v-model="modelValue"
    :loading="loading"
    :options="records"
    :filter="true"
    :filter-match-mode="'true'"
    :auto-option-focus="true"
    :option-label="props.optionLabel"
    :option-value="props.optionValue"
    :data-key="props.optionValue"
    :show-clear="true"
    :display="display"
    :show-toggle-all="false"
    :virtual-scroller-options="{
      lazy: true,
      itemSize: 36,
      onLazyLoad: onLazyLoad,
      numToleratedItems: 10
    }"
    @filter="onFilter"
    @change="onChange"
    placeholder="Select"
  >
    <!-- If display is comma, we take over the whole value slot -->
    <template #value="props" v-if="display === 'comma'">
      <div>{{ current ? current : props.placeholder }}</div>
    </template>

    <!-- If display is chip, there is a chip slot for each value separately -->
    <template #chip="item">
      <span class="p-multiselect-token-label" data-pc-section="tokenlabel">
        {{ itemLabel(item) }}
      </span>
    </template>

    <template #option="{ option }">
      <slot name="option" :option="option" />
    </template>
  </MultiSelect>
</template>
