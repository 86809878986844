import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=organization', (items) => {
  return [
    ...items,
    {
      path: 'jobs',
      name: 'jobs',
      component: () => import('@/modules/jobs/pages/JobsListing.vue')
    }
  ]
})
