import { CodexHooks } from '@/@core'
import { useI18nStore } from '@/@core/i18n'
import { generateNavigationItem } from '@/@core/navigation'
import { useOrganization } from '@/modules/organizations/store/organization'

CodexHooks.addFilter('navigation/items?parent-static=builder-module', (items) => {
  const ct = useI18nStore().createI18nContext('ct.workflows.navigation')

  return [
    ...items,
    generateNavigationItem({
      name: 'workflows',
      title: ct.$t('listing'), //check in graphql
      route: { name: 'workflows', params: { organizationId: useOrganization().organizationId } }
    })
  ]
})