<script setup lang="ts">
import DatePicker from 'primevue/calendar'
import Chips from '@/modules/primevue/components/Chips/Chips.vue'
const props = withDefaults(defineProps<{ dateFormat: string }>(), {
  dateFormat: 'dd/mm/yy'
})
</script>

<template>
  <DatePicker v-bind="$attrs" :date-format="props.dateFormat">
    <!-- Implement GjIcon -->
    <template #dropdownicon="scope" v-if="$attrs.icon">
      <GjIcon v-bind="scope" :name="$attrs.icon" :class="$attrs.iconClass" size="24" />
    </template>
    <template #previousicon="scope" v-if="$attrs.previousIcon">
      <GjIcon v-bind="scope" :name="$attrs.previousIcon" size="24" />
    </template>
    <template #nexticon="scope" v-if="$attrs.nextIcon">
      <GjIcon v-bind="scope" :name="$attrs.nextIcon" size="24" />
    </template>
    <template #incrementicon="scope" v-if="$attrs.incrementIcon">
      <GjIcon v-bind="scope" :name="$attrs.incrementIcon" size="24" />
    </template>
    <template #decrementicon="scope" v-if="$attrs.decrementIcon">
      <GjIcon v-bind="scope" :name="$attrs.decrementIcon" size="24" />
    </template>
  </DatePicker>
</template>

<style scoped></style>
