import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'

import Component from './InteractiveBlock.vue'

export default Node.create({
  name: 'interactiveBlock',

  group: 'block',

  atom: true,

  draggable: true,

  addAttributes() {
    return {
      test: {
        default: 1
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-node=${this.name}]`
      }
    ]
  },

  addCommands() {
    return {
      // prettier-ignore
      setInteractiveBlock: () => ({ commands }) => {
        return commands.insertContent({ type: this.name })
      }
    }
  },

  renderHTML({ HTMLAttributes }) {
    return [`div`, mergeAttributes(HTMLAttributes, { 'data-node': this.name })]
  },

  addNodeView() {
    return VueNodeViewRenderer(Component)
  }
})
