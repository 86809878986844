import { merge } from 'lodash'

/**
 * ApiKey Name
 */
export const APIKEYS_NAME = 'apikeys-listing'

/**
 * ApiKey Defaults
 */
export function apikeyDefaults(apikey = {}) {
  return merge(
    {
      name: '',
      teams: []
    },
    apikey
  )
}
