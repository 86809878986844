import { CodexHooks } from '@/@core'
import { defineStore } from 'pinia'
import { ref, type Ref, computed } from 'vue'

export const useDynamicForm = defineStore('dynamicForm', () => {
  const refreshKey: Ref<number> = ref(0)

  /**
   * This is a hack to force form fields to refresh when a new filter is added or removed.
   */
  CodexHooks.addAction('hooks/filter-added?name=dynamic-form/fields', () => {
    refreshKey.value++
  })

  CodexHooks.addAction('hooks/filter-removed?name=dynamic-form/fields', () => {
    refreshKey.value++
  })

  /**
   * Gather fields from hooks
   */
  const fields = computed(() => {
    // Use a ref to force the computed to refresh when the refreshKey changes
    refreshKey.value

    // Get the fields from the hooks
    const items: any[] = CodexHooks.applyFilters('dynamic-form/fields', [])

    return items
  })

  /**
   * Resolve Field
   */
  const resolveField = (field: any) => {
    for (const item of fields.value) {
      if (item.type === field.type) {
        return item
      }
    }
  }

  return {
    fields,
    resolveField
  }
})
