import {
  FIELD_TYPES,
  FIELD_VALUE_TYPES,
  WIDGET_GROUPS,
  FIELD_FILTER_OPERATORS,
  RANGE_OPERATORS
} from '@/modules/models/editor-ts/fields/constants'
import { CodexHooks } from '@/@core'

CodexHooks.addFilter('fields/types', (items) => {
  return [
    ...items,
    {
      pluginName: 'codex',
      displayName: 'Reference',
      name: 'field_reference',
      render: () => import('./Render.vue'),
      renderEditor: () => import('./Render.vue'),
      renderEditorPanel: [
        {
          icon: 'Settings',
          label: 'General',
          component: () => import('./General.vue'),
          isGeneral: true
        }
      ],
      renderEditorInitialPanel: [
        {
          icon: 'Settings',
          label: 'Initial',
          component: () => import('./Initial.vue')
        }
      ],
      group: WIDGET_GROUPS.CODEX,
      icon: () => import('./icon.svg'),

      type: FIELD_TYPES.REFERENCE,
      valueType: FIELD_VALUE_TYPES.SINGLE,

      attrs: {
        appearance: {
          default: 1
        },
        helpText: {
          default: ''
        },
        helpTextDisplay: {
          default: 'tooltip'
        },
        validation: {
          default: {
            allowDuplicates: false,
            required: {
              value: false,
              errorMessage: 'Reference is required',
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: true,
                  field: 'SiteId',
                  operator: FIELD_FILTER_OPERATORS.EQUALS,
                  value: ''
                }
              ]
            },
            readOnly: {
              value: false,
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: false,
                  field: '',
                  operator: FIELD_FILTER_OPERATORS.EXISTS,
                  value: ''
                }
              ]
            },
            numberOfReferences: {
              isEnabled: false,
              rangeOperator: RANGE_OPERATORS.BETWEEN,
              min: 2,
              max: 5,
              exactly: 1,
              errorMessage: 'Number of references: 2-5.',
              errorTemplate: 'Number of references: {min}-{max}'
            },
            modelTypes: {
              isEnabled: false,
              acceptedModels: [],
              errorMessage: 'You can select entries only from specified models.'
            }
          }
        },
        disableCreateButton: {
          default: false
        },
        disableBrowseButton: {
          default: false
        },
        configuration: {
          default: {
            searchable: true,
            filterable: true
          }
        },
        hidden: {
          default: {
            value: false,
            conditionsEnabled: false,
            conditions: [
              {
                isSystem: false,
                field: '',
                operator: FIELD_FILTER_OPERATORS.EXISTS,
                value: ''
              }
            ]
          }
        }
      }
    }
  ]
})
