import {
  FIELD_FILTER_OPERATORS,
  FIELD_TYPES,
  FIELD_VALUE_TYPES,
  RANGE_OPERATORS
} from '@/modules/models/editor-ts/fields/constants'
import { CodexHooks } from '@/@core'
import type {
  FieldAttrsType,
  FieldConditionalValidationType
} from '@/modules/@global/models/FieldTypes'
import { isNil } from 'lodash'
import { rangeValidation, type RangeValidationType } from '../../general/helpers'

const required = {
  name: 'required',
  type: FIELD_TYPES.AUTHOR,
  valueType: [FIELD_VALUE_TYPES.SINGLE, FIELD_VALUE_TYPES.LIST],
  component: () => import('@/modules/@global/models/field-validations/general/Required.vue'),
  attr: {
    value: false,
    errorMessage: 'Author is required.',
    conditionsEnabled: false,
    conditions: [
      {
        isSystem: false,
        field: '',
        operator: FIELD_FILTER_OPERATORS.EXISTS,
        value: ''
      }
    ]
  },
  validation: (
    value: boolean | null,
    attr: FieldConditionalValidationType,
    field: FieldAttrsType
  ) => {
    return attr.value && isNil(value)
      ? { isValid: false, message: attr.errorMessage }
      : { isValid: true }
  }
}

const readOnly = {
  name: 'readOnly',
  type: FIELD_TYPES.AUTHOR,
  valueType: [FIELD_VALUE_TYPES.SINGLE, FIELD_VALUE_TYPES.LIST],
  component: () => import('@/modules/@global/models/field-validations/general/ReadOnly.vue'),
  attr: {
    value: false,
    conditionsEnabled: false,
    conditions: [
      {
        isSystem: false,
        field: '',
        operator: FIELD_FILTER_OPERATORS.EXISTS,
        value: ''
      }
    ]
  },
  validation: () => ({ isValid: true })
}
const numberOfPropertiesGenerator = {
  name: 'numberOfProperties',
  type: FIELD_TYPES.AUTHOR,
  valueType: [FIELD_VALUE_TYPES.LIST],
  component: () =>
    import('@/modules/@global/models/field-validations/fields/Author/LimitAuthorsNr.vue'),
  attr: {
    isEnabled: false,
    rangeOperator: RANGE_OPERATORS.BETWEEN,
    min: 1,
    max: 2,
    errorMessage: 'Number of Authors should contains 1-2',
    errorTemplate: 'Number of Authors should contains {min}-{max}'
  },
  validation: (value: any, config: RangeValidationType, attrs: FieldAttrsType) => {
    return rangeValidation(value?.length || 0, config)
  }
}

CodexHooks.addFilter('fields/validations', (items) => {
  return [...items, required, readOnly, numberOfPropertiesGenerator]
})
