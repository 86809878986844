import { merge } from 'lodash'

/**
 * Team Name
 */
export const TEAMS_NAME = 'teams-listing'

/**
 * Team Defaults
 */
export function teamDefaults(team = {}) {
  return merge(
    {
      name: '',
      description: '',
      members: []
    },
    team
  )
}
