import { useI18nStore } from '@/@core/i18n'
import type { FormField } from '@/modules/@global/components/DynamicForm/DynamicForm'
import * as yup from 'yup'

/**
 * User forms fields definition
 */
interface UserFormsFields {
  email: FormField
  teams: FormField
}

/**
 * User forms fields
 */
export const UserFormsFields = (): UserFormsFields => {
  const ct = useI18nStore().createI18nContext('ct.users.forms.fields')

  return {
    email: {
      type: 'Chips',
      name: 'emails',
      label: ct.$t('email.label')
    },
    teams: {
      type: 'TeamsSelect',
      name: 'teams',
      label: ct.$t('teams.label')
    }
  }
}

/**
 * User invite form
 */
const CreateUserInvite = (): FormField[] => {
  const fields = UserFormsFields()

  return [
    {
      type: 'Row',
      children: [
        // Users
        {
          name: 'users',
          type: 'Repeater',
          layout: { col: 12 },
          children: [
            // Email
            {
              ...fields.email,
              layout: { col: 12 }
            },
            // Team
            {
              ...fields.teams,
              layout: { col: 12 }
            }
          ]
        }
      ]
    }
  ]
}

export { CreateUserInvite }
