import UrlEntityTypeFilterComponent from './UrlEntityType.vue'
import { useI18nStore } from '@/@core/i18n'
import { CodexUrlEntityType } from '@/gql/graphql'
// import { mapUrlEntityType } from '../../constants'

export default class UrlEntityTypeFilter {
  static COMPONENT = UrlEntityTypeFilterComponent
  static OPERATORS = {
    includeOneOf: 'includeOneOf',
    includeAllOf: 'includeAllOf',
    exclude: 'exclude'
  }

  static type: string = 'urlEntityType'

  operator: string | null = null

  value: Array<CodexUrlEntityType> | null = null

  constructor(
    {
      operator = UrlEntityTypeFilter.OPERATORS.includeOneOf,
      value = []
    }: { operator: string; value?: Array<CodexUrlEntityType> } = {
      operator: UrlEntityTypeFilter.OPERATORS.includeOneOf
    }
  ) {
    this.operator = operator
    this.value = value
  }
  set(
    {
      operator = UrlEntityTypeFilter.OPERATORS.includeOneOf,
      value = []
    }: { operator: string; value?: Array<CodexUrlEntityType> } = {
      operator: UrlEntityTypeFilter.OPERATORS.includeOneOf
    }
  ) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value?.length
  }

  getValueLabel(): string | null {
    const ct = useI18nStore().createI18nContext('ct.urls')
    return this.value ? this.value.map((v) => ct.$t(v?.toLowerCase())).join(', ') : null
  }

  toLoad() {
    return {
      sections: this.value
    }
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value
    }
  }

  asGraphQL() {
    return {
      in: this.value
    }
  }

  static validate({ value }: { value?: Array<CodexUrlEntityType> }) {
    if (value?.length === 0) {
      return 'Value is required'
    }
    return true
  }

  static fromQueryParam({
    operator,
    value
  }: {
    operator: string
    value?: Array<CodexUrlEntityType>
  }) {
    return new UrlEntityTypeFilter({
      operator,
      value
    })
  }
}
