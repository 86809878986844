<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable'
import { CREATE_AUTHOR } from '@/modules/authors/queries/index.rest'
import { inject } from 'vue'
import { CreateAuthorForm } from '@/modules/authors/forms/Authors'
import { useI18nStore } from '@/@core/i18n'
import { authorDefaults } from '../constants'
import type { Context } from '@/modules/@global/composables/useContext'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.authors.popups.create-author')

/**
 * Dialog
 */
let dialog = inject(DialogRefKey)

/**
 * Create author
 */
const createAuthor = useMutation(CREATE_AUTHOR, {
  refetchQueries: ['ListAuthors']
})

async function createAuthorHandler(values) {
  dialog?.value?.close(values)
}

/**
 * Default values
 */
const defaultValues = authorDefaults()
</script>

<template>
  <SidebarContent :title="ct.$t('title')">
    <DynamicForm
      name="create-author"
      :ref="(r:any) => context?.setRef('form', r)"
      :show-submit="false"
      :schema="CreateAuthorForm()"
      :mutation="createAuthor"
      :data="defaultValues"
      :labels="{
        submit: ct.$t('submit')
      }"
      @submit="createAuthorHandler"
    />

    <template #footer>
      <Button v-bind="context.getRef('form')?.props?.submit.value"></Button>
    </template>
  </SidebarContent>
</template>
