import { CodexHooks } from '@/@core'
import ConfirmDelete from '@/modules/@global/popups/ConfirmDelete.vue'
import { useI18nStore } from '@/@core/i18n'

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  const ct = useI18nStore().createI18nContext('ct.workflows.popups.confirm-delete-workflows')

  items = [
    ...items,
    /* Delete Workflow */
    {
      type: 'Dialog',
      name: 'confirm-delete-workflows',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('cancel'),
          confirm: ct.$p('confirm'),
          title: ct.$p('title'),
          message: ct.$p('message')
        }
      }
    }
  ]

  return items
})
