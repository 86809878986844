import type { Context } from '@/modules/@global/composables/useContext'

export type Action = (...args: any[]) => void
export type Filter = (...args: any[]) => {}

export enum SourceType {
  Plugin = 'plugin',
  Module = 'module',
  Core = 'core'
}

export interface Source {
  type: SourceType
  module?: string
  plugin?: string
  version?: string
}

export interface PriorityHandler<T> {
  handler: T
  context?: Context | null
  priority: number
  source: Source
}