// @ts-nocheck
function createWatchers() {
  const props = Object.keys(this.$options.props || {})
  for (const prop of props) {
    this.$watch(prop, (val, old) => {
      console.log('[WhyDidYouRender:watcher]', this.$options.name, prop, { val, old })

      if (window.WDYU_DEBUG) {
        // eslint-disable-next-line no-debugger
        debugger
      }
    })
  }

  this.$parent && createWatchers.call(this.$parent)
}

export default {
  created() {
    if (!this.$options.debug) return
    createWatchers.call(this)
  },
  updated() {
    if (!this.$options.debug) return
    console.log('[WhyDidYouRender:UPDATED]', this.$options.name)
  }
}
