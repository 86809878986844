<script setup lang="ts">
import { CreateLinkForm } from '@/modules/@global/components/RichContent/bubble-menus/link/LinkForms'
import { computed } from 'vue'

/**
 * Props ( editor )
 */
const props = defineProps({
  editor: {
    type: Object,
    required: true
  }
})

/**
 * Link handler
 */
function linkHandler(data: any) {
  // empty
  if (data.href === '') {
    props.editor.chain().focus().extendMarkRange('link').unsetLink().run()
    return
  }

  // update link
  props.editor.chain().focus().extendMarkRange('link').setLink(data).run()
}

/**
 * Default values
 */
const defaultValues = computed(() => ({
  href: props.editor.getAttributes('link').href
}))
</script>

<template>
  <DynamicForm
    :key="props.editor.getAttributes('link').href"
    name="link-form"
    :show-submit="true"
    :schema="CreateLinkForm()"
    :data="defaultValues"
    @submit="linkHandler"
  ></DynamicForm>
</template>
