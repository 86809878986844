import type { FormField } from '@/modules/@global/components/DynamicForm/DynamicForm'
import * as yup from 'yup'
import { useI18nStore } from '@/@core/i18n'

/**
 * Site forms fields definition
 */
interface SiteFormsFields {
  id: FormField
  name: FormField
  alias: FormField
  domain: FormField
  description: FormField
  logo: FormField
}

/**
 * Site forms fields
 */
export const SiteFormsFields = (): SiteFormsFields => {
  const ct = useI18nStore().createI18nContext('ct.sites.forms.fields')
  return {
    id: {
      type: 'Text',
      name: 'id',
      label: ct.$t('id.label')
    },
    name: {
      type: 'Text',
      name: 'name',
      label: ct.$t('name.label'),
      rules: yup.string().required('Name is required')
    },
    alias: {
      type: 'Text',
      name: 'alias',
      label: ct.$t('alias.label'),
      rules: yup.string().required('Alias is required')
    },
    domain: {
      type: 'Text',
      name: 'domain',
      label: ct.$t('domain.label')
    },
    description: {
      type: 'TextArea',
      name: 'description',
      label: ct.$t('description.label')
    },
    logo: {
      type: 'Text',
      name: 'logo',
      label: ct.$t('logo.label'),
      rules: yup.string().required('Logo is required')
    }
  }
}

/**
 * Create Site Form
 */
const CreateSiteForm = (): FormField[] => {
  const fields = SiteFormsFields()

  return [
    {
      type: 'Row',
      children: [
        // Name
        {
          ...fields.name,
          layout: { col: 12 }
        },
        // Alias
        {
          ...fields.alias,
          layout: { col: 12 }
        },
        // Domain
        {
          ...fields.domain,
          layout: { col: 12 }
        },
        // Description
        {
          ...fields.description,
          layout: { col: 12 }
        },
        // Logo
        {
          ...fields.logo,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

/**
 * Update Site Form
 */
const UpdateSiteForm = (): FormField[] => {
  const fields = SiteFormsFields()

  return [
    {
      type: 'Row',
      children: [
        // ID
        {
          ...fields.id,
          layout: { col: 12 },
          props: {
            disabled: true
          }
        },
        // Name
        {
          ...fields.name,
          layout: { col: 12 }
        },
        // Alias
        {
          ...fields.alias,
          layout: { col: 12 },
          props: {
            disabled: true
          }
        },
        // Domain
        {
          ...fields.domain,
          layout: { col: 12 }
        },
        // Description
        {
          ...fields.description,
          layout: { col: 12 }
        },
        // Logo
        {
          ...fields.logo,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

export { CreateSiteForm, UpdateSiteForm }
