import { CodexHooks } from '@/@core'
import { useOrganization } from '@/modules/organizations/store/organization'
import { useSite } from '@/modules/sites/store/site'

CodexHooks.addFilter('apollo/restFetchOptions', setBodyForDeleteRequests)
CodexHooks.addFilter('apollo/restFetchUri', setDefaultSiteIdInUri)
CodexHooks.addFilter('apollo/restFetchUri', setOrganizationId)

// Codex - Our API requires sending a body in DELETE requests...
function setBodyForDeleteRequests(options: any) {
  if (options.method === 'DELETE') {
    options.body = options.body ? JSON.stringify(options.body) : JSON.stringify({})
  }
  return options
}

// Codex - If no siteId in url, add default siteId
function setDefaultSiteIdInUri(uri: string) {
  const url = new URL(uri)

  if (!url.searchParams.has('siteId')) {
    url.searchParams.append('siteId', useSite().siteId)
  }

  return url.toString()
}

// Codex - Set organizationId
function setOrganizationId(uri: string) {
  if (uri.includes('/organizationId/')) {
    uri = uri.replace('/organizationId/', `/${useOrganization().organizationId}/`)
  }
  const url = new URL(uri)
  return url.toString()
}
