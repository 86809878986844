import { merge } from 'lodash'
import { CodexModelAccessStrategy, CodexModelStatus } from '@/gql/graphql'
import { enumMapping } from '@/modules/@global/utils/EnumMapping'

/**
 * Model Name
 */
export const MODELS_NAME = 'models-listing'

/**
 * Model Enums
 */
export const MODEL_STATUS_MAPPING = enumMapping({
  [CodexModelStatus.Published]: 1,
  [CodexModelStatus.Deleted]: 2,
  [CodexModelStatus.Draft]: 3
})

export const ACCESS_STRATEGY_MAPPING = enumMapping({
  [CodexModelAccessStrategy.Unrestricted]: 1,
  [CodexModelAccessStrategy.Restricted]: 2
})

/**
 * Model defaults
 */
export function modelDefaults(model = {}) {
  return merge(
    {
      status: CodexModelStatus.Draft,
      name: '',
      alias: '',
      iconId: '',
      description: '',
      blocks: [],
      titleAlias: null,
      slugAlias: null,
      featuredMediaAlias: null,
      deletedBlocks: [],
      externalId: null,
      restrictions: {
        sites: {
          allowAll: true,
          allowed: []
        }
      },
      configurations: {
        sidebar: {
          general: {
            isEnabled: true,
            items: {
              preview: { isEnabled: true, order: 0, pattern: '' },
              status: { isEnabled: true, order: 1 },
              labels: { isEnabled: true, order: 2 },
              publishedAt: { isEnabled: true, order: 3 },
              metrics: { isEnabled: true, order: 4 },
              created: { isEnabled: true, order: 5 },
              updated: { isEnabled: true, order: 6 },
              prolexis: { isEnabled: true, order: 7 },
              id: { isEnabled: true, order: 8 },
              externalId: { isEnabled: true, order: 9 },
              addToList: { isEnabled: true, order: 10 },
              firstPublishedAt: { isEnabled: true, order: 11 }
            }
          },
          history: { isEnabled: true },
          referencedBy: { isEnabled: true }
        },
        accessStrategy: CodexModelAccessStrategy.Unrestricted,
        lockDurationInSeconds: 900
      }
    },
    model
  )
}
