<script setup lang="ts">
defineOptions({
  name: 'DialogContent'
})

const props = defineProps<{
  title?: string
  subtitle?: string
}>()
</script>

<template>
  <div class="p-dialog-content-header">
    <slot name="header">
      <div class="flex flex-column">
        <Text type="title-medium-base">{{ props.title }}</Text>
        <Text type="body-regular-base">{{ props.subtitle }}</Text>
      </div>
    </slot>
  </div>

  <div class="p-dialog-content-body">
    <slot />
  </div>

  <div class="p-dialog-content-footer">
    <slot name="footer" />
  </div>
</template>
<style scoped></style>
