import { Extension } from '@tiptap/core'
import { filterSelectedNodes } from '@/modules/@global/components/RichContent/utils'

function updateSelections(editor, storage) {
  /**
   * Find selected text nodes
   */
  storage.selectedTextNodes = filterSelectedNodes({ editor }, ({ node }) => {
    return node.type.isTextblock
  })

  /**
   * Find selected text align nodes
   */
  storage.selectedTextAlignNodes = filterSelectedNodes({ editor }, ({ node }) => {
    return node.type.attrs?.textAlign
  })

  /**
   * Find selected nodes with all parents
   */
  storage.selectedNodesDeep = filterSelectedNodes({ editor }, ({ node }) => {
    return node.type.name !== 'text'
  })

  /**
   * Find selected nodes
   */
  storage.selectedNodes = filterSelectedNodes(
    { keepLast: true, editor },
    ({ node, nodeFrom, nodeTo, selection }) => {
      return (
        node.type.name !== 'text' &&
        ((nodeTo >= selection.from && nodeTo <= selection.to) ||
          (nodeFrom >= selection.from && nodeFrom <= selection.to))
      )
    }
  )
}

export default Extension.create({
  name: 'selections',

  addStorage() {
    return {
      selectedTextAlignNodes: { nodes: [], range: { from: 0, to: 0 } },
      selectedTextNodes: { nodes: [], range: { from: 0, to: 0 } },
      selectedNodesDeep: { nodes: [], range: { from: 0, to: 0 } },
      selectedNodes: { nodes: [], range: { from: 0, to: 0 } }
    }
  },

  onCreate() {
    updateSelections(this.editor, this.storage)
  },

  onSelectionUpdate() {
    updateSelections(this.editor, this.storage)
  }
})
