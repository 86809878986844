import { graphql } from '@/gql/gql'

export const LIST_URLS = graphql(/* GraphQL */ `
  query ListUrls($limit: Int!, $offset: Int!, $where: CodexUrlFilter, $order: CodexUrlOrder) {
    data: urlCollection(limit: $limit, offset: $offset, where: $where, order: $order) {
      total
      items {
        id
        url
        entity {
          ... on CodexEntry {
            id
            system {
              title
              modelId
              modelAlias
              featuredMedia {
                url(transformation: { format: THUMBNAIL, width: 50 })
              }
              model {
                iconId
              }
            }
          }
          ... on CodexSection {
            id
            title
          }
        }
        entityType
        fieldAlias
        level
        isCustom
        statusCode
        redirectUrl
        createdBy {
          id
        }
      }
    }
  }
`)

export const LIST_URLS_ONLY = graphql(/* GraphQL */ `
  query ListUrlsOnly($where: CodexUrlFilter) {
    data: urlCollection(where: $where) {
      total
      items {
        url
      }
    }
  }
`)
