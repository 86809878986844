<script setup lang="ts">
import { useMutation, useQuery } from '@vue/apollo-composable'
import { READ_URL, UPDATE_URL } from '@/modules/urls/queries/index.rest'
import { inject } from 'vue'
import { cloneDeep } from 'lodash'
import { UpdateUrlForm } from '@/modules/urls/forms/Url'
import FormSkeleton from '@/modules/@global/components/Skeletons/FormSkeleton.vue'
import { useI18nStore } from '@/@core/i18n'
import type { Context } from '@/modules/@global/composables/useContext'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.urls.popups.update-url')

/**
 * Dialog
 */
let dialog = inject(DialogRefKey)

/**
 * Read url
 */
const { loading, result, error } = useQuery(
	READ_URL,
	() => ({
		id: dialog?.value?.data?.id
	}),
	{
		fetchPolicy: 'network-only'
	}
)

/**
 * Update url
 */
const updateUrl = useMutation(UPDATE_URL)

async function updateUrlHandler(values) {
	dialog?.value?.close(values)
}
</script>

<template>
	<div class="update-url-popup">

		<DialogContent :title="ct.$t('title')">
			<template v-if="loading">
				<FormSkeleton />
			</template>

			<template v-else-if="error">
				<InlineMessage severity="error" :text="error"></InlineMessage>
			</template>

			<template v-else>
				<DynamicForm name="update-url" :ref="(r: any) => context?.setRef('form', r)" :show-submit="false"
					:schema="UpdateUrlForm()" :data="cloneDeep(result?.url)" :mutation="updateUrl" :labels="{
						submit: ct.$t('submit')
					}" @submit="updateUrlHandler"></DynamicForm>
			</template>

			<template #footer>
				<Button v-if="!loading && !error" v-bind="context.getRef('form')?.props?.submit.value"></Button>
			</template>
		</DialogContent>
	</div>
</template>

<style lang="scss">
.update-url-popup {
	width: 800px;
}
</style>
