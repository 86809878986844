import { CodexHooks } from '@/@core'

CodexHooks.addFilter('rich-content/slash-commands', (commands) => {
  commands = [
    ...commands,
    {
      label: 'Options',
      items: [
        {
          label: 'H1',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setNode('heading', { level: 1 }).run()
          }
        },
        {
          label: 'H2',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setNode('heading', { level: 2 }).run()
          }
        }
      ]
    },
    {
      label: 'Other',
      items: [
        {
          label: 'Interacive Block',
          command: ({ editor, range }) => {
            console.log(editor, range)
            editor.chain().focus().deleteRange(range).setInteractiveBlock().run()
          }
        },
        {
          label: 'bold',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('bold').run()
          }
        },
        {
          label: 'italic',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('italic').run()
          }
        }
      ]
    },
    {
      label: 'Other',
      items: [
        {
          label: 'Interacive Block',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setInteractiveBlock().run()
          }
        },
        {
          label: 'bold',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('bold').run()
          }
        },
        {
          label: 'italic',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('italic').run()
          }
        },
        {
          label: 'Interacive Block',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setInteractiveBlock().run()
          }
        },
        {
          label: 'bold',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('bold').run()
          }
        },
        {
          label: 'italic',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('italic').run()
          }
        },
        {
          label: 'Interacive Block',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setInteractiveBlock().run()
          }
        },
        {
          label: 'bold',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('bold').run()
          }
        },
        {
          label: 'italic',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('italic').run()
          }
        },
        {
          label: 'Interacive Block',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setInteractiveBlock().run()
          }
        },
        {
          label: 'bold',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('bold').run()
          }
        },
        {
          label: 'italic',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('italic').run()
          }
        },
        {
          label: 'Interacive Block',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setInteractiveBlock().run()
          }
        },
        {
          label: 'bold',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('bold').run()
          }
        },
        {
          label: 'italic',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('italic').run()
          }
        },
        {
          label: 'Interacive Block',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setInteractiveBlock().run()
          }
        },
        {
          label: 'bold',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('bold').run()
          }
        },
        {
          label: 'italic',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('italic').run()
          }
        },
        {
          label: 'Interacive Block',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setInteractiveBlock().run()
          }
        },
        {
          label: 'bold',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('bold').run()
          }
        },
        {
          label: 'italic',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('italic').run()
          }
        },
        {
          label: 'Interacive Block',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setInteractiveBlock().run()
          }
        },
        {
          label: 'bold',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('bold').run()
          }
        },
        {
          label: 'italic',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('italic').run()
          }
        },
        {
          label: 'Interacive Block',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setInteractiveBlock().run()
          }
        },
        {
          label: 'bold',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('bold').run()
          }
        },
        {
          label: 'italic',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('italic').run()
          }
        },
        {
          label: 'Interacive Block',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setInteractiveBlock().run()
          }
        },
        {
          label: 'bold',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('bold').run()
          }
        },
        {
          label: 'italic',
          command: ({ editor, range }) => {
            editor.chain().focus().deleteRange(range).setMark('italic').run()
          }
        }
      ]
    }
  ]

  return commands
})
