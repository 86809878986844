// Middleware to add authorization header to requests
import { ApolloLink } from '@apollo/client/core'
import { CodexDebug, useOidcStore } from '@/@core'

export default function createAuthLink() {
  return new ApolloLink((operation, forward) => {
    const token = useOidcStore().oidcAccessToken

    CodexDebug.apolloInfo(
      `AuthLink adding authorization header to "${operation.operationName}" request`,
      {
        token,
        operation
      }
    )

    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    })

    return forward(operation)
  })
}
