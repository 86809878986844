import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=site', (items) => {
  return [
    ...items,
    {
      path: 'entries/:viewId',
      name: 'entries',
      component: () => import('@/modules/entries/pages/EntriesListing.vue')
    },
    {
      // TODO: Add View Id
      path: 'entries/:viewId/:modelId/:entryId',
      name: 'entries-edit',
      component: () => import('@/modules/entries/pages/EntriesEdit.vue'),
      meta: {
        activeNavItem: 'entries'
      }
    }
  ]
})
