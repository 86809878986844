<script setup lang="ts">
import { computed, inject, ref } from 'vue'
import { useI18nStore } from '@/@core/i18n'
import usePopups from '@/modules/@global/composables/usePopups'
import { CodexEntityType } from '@/gql/graphql'
import type { Context } from '@popperjs/core'

const modelValue = defineModel<string>(null)
const ct = useI18nStore().createI18nContext('ct.urls.popups.create-url')
const context = inject<Context | null>('context', null)
const urlObj = computed(() => context?.value?.getRef('form')?.values ?? {})
const { showPopup } = usePopups(context?.value || {})
const title = ref<string | null>(null)
const url = ref<string | null>(null)

const placeholder = computed(() => {
  const obj = urlObj.value || {}
  if (!obj.redirectState && obj.entityType === CodexEntityType.Section)
    return ct.$t('select-section')
  else if (!obj.redirectState && obj.entityType === CodexEntityType.Entry)
    return ct.$t('select-entry')
  else if (obj.redirectState) return ct.$t('select-url')
  else return ''
})

async function openRedirectPopup() {
  const obj = urlObj.value || {}
  if (!obj.redirectState && obj.entityType === CodexEntityType.Section) {
    const result = await showPopup(
      'add-sections',
      {},
      {
        selectionMode: 'single'
      }
    )
    if (result && result.data?.[0]) {
      title.value = result.data[0].title
      modelValue.value = result.data[0].id
    }
  } else if (!obj.redirectState && obj.entityType === CodexEntityType.Entry) {
    await showPopup('add-entry')
  } else {
    const result = await showPopup(
      'add-url',
      {
        filterName: obj.entityType || ''
      },
      {
        size: 'large'
      }
    )
    if (result) {
      url.value = result.data?.url || null
      modelValue.value = result.data?.id || null
    }
  }
}
</script>

<template>
  <div class="url-redirect-select" @click="openRedirectPopup">
    <InputText :value="title || url" readonly :placeholder="placeholder" />
  </div>
</template>

<style scoped lang="scss">
.url-redirect-select {
  input {
    cursor: pointer;
  }
}
</style>
