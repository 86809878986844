<script setup lang="ts">
import ContextMenu from 'primevue/contextmenu'
import { ref } from 'vue'

defineOptions({
  name: 'ContextMenu'
})

const menu = ref<ContextMenu | null>(null)

const props = defineProps<{
  model?: Array<{
    label: string
    icon?: string
    disabled?: boolean
    command?: (params: any) => void
  }>
}>()

function showMenu(originalEvent: Event) {
  if (menu.value) {
    menu.value.show(originalEvent)
  }
}

defineExpose({
  showMenu
})
</script>

<template>
  <ContextMenu ref="menu" :model="props.model" class="items">
    <template #item="{ item }">
      <a v-ripple class="flex items-center item">
        <GjIcon v-if="item.icon" :name="item.icon" class="icon" size="20" />
        <Text type="body-medium-base">{{ item.label }}</Text>
      </a>
    </template>
  </ContextMenu>
</template>

<style>
.icon {
  margin-right: 5px;
  margin-left: 5px;
}

.item {
  cursor: pointer;
  height: 30px;
  padding: 0;
  margin: 0;
  justify-content: left;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
}
</style>
