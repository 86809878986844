import { CodexHooks } from '@/@core'

import SitesSelect from './SitesSelect.vue'

CodexHooks.addFilter('dynamic-form/fields', (items) => {
  return [
    ...items,
    {
      type: 'SitesSelect',
      component: SitesSelect
    }
  ]
})
