<script setup lang="ts">
import NumberFilter from './NumberFilter'
import { computed } from 'vue'
import { useI18nStore } from '@/@core/i18n'

const ct = useI18nStore().createI18nContext('ct.global.components.filters')

// eslint-disable-next-line no-undef
const modelValue = defineModel<{
  operator: string
  value1: number
  value2?: number
}>()

const showInput = computed(() => {
  return (
    modelValue.value?.operator !== NumberFilter.OPERATORS.exists &&
    modelValue.value?.operator !== NumberFilter.OPERATORS.notExists
  )
})
const isBetween = computed(() => modelValue.value?.operator === NumberFilter.OPERATORS.between)
const options = computed(() => [
  {
    label: ct.$t('operators.is'),
    value: NumberFilter.OPERATORS.equals
  },
  {
    label: ct.$t('operators.not'),
    value: NumberFilter.OPERATORS.notEquals
  },
  {
    label: ct.$t('operators.greater-than'),
    value: NumberFilter.OPERATORS.greaterThan
  },
  {
    label: ct.$t('operators.lower-than'),
    value: NumberFilter.OPERATORS.lowerThan
  },
  {
    label: ct.$t('operators.between'),
    value: NumberFilter.OPERATORS.between
  },
  {
    label: ct.$t('operators.exists'),
    value: NumberFilter.OPERATORS.exists
  },
  {
    label: ct.$t('operators.not-exists'),
    value: NumberFilter.OPERATORS.notExists
  }
])
</script>

<template>
  <div class="new-filters-dropdown__filter-body">
    <Row>
      <Col>
        <Dropdown v-model="modelValue.operator" :options="options" size="small" />
      </Col>
    </Row>

    <Row v-if="showInput">
      <Col>
        <InputNumber size="small" v-model="modelValue.value1" />
      </Col>
      <Col v-if="isBetween">
        <InputNumber size="small" v-model="modelValue.value2" />
      </Col>
    </Row>
  </div>
</template>

<style scoped></style>
