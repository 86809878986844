<script setup lang="ts">
import { provide } from 'vue'
import DynamicFormList from '@/modules/@global/components/DynamicForm/DynamicFormList.vue'

const props = defineProps<{
  field: any
  repeaterIndex: number
}>()

provide('fieldNameAppend', `${props.field.name}.${props.repeaterIndex}.`)
</script>

<template>
  <div class="grid">
    <DynamicFormList :fields="field.children" />
  </div>
</template>
