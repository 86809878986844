import {
  FIELD_TYPES,
  FIELD_VALUE_TYPES,
  WIDGET_GROUPS,
  FIELD_FILTER_OPERATORS
} from '@/modules/models/editor-ts/fields/constants'
import { CodexHooks } from '@/@core'

CodexHooks.addFilter('fields/types', (items) => {
  return [
    ...items,
    {
      pluginName: 'codex',
      displayName: 'Rich Content',
      name: 'field_rich_content',
      render: () => import('./Render.vue'),
      renderEditor: () => import('./Render.vue'),
      renderEditorPanel: [
        {
          icon: 'Settings',
          label: 'General',
          component: () => import('./General.vue'),
          isGeneral: true
        }
      ],
      renderEditorInitialPanel: [
        {
          icon: 'Settings',
          label: 'Initial',
          component: () => import('./Initial.vue')
        }
      ],
      group: WIDGET_GROUPS.BASIC,
      icon: () => import('./icon.svg'),

      type: FIELD_TYPES.RICH_CONTENT,
      valueType: FIELD_VALUE_TYPES.SINGLE,

      attrs: {
        version: {
          default: null
        },
        calculateMetrics: {
          default: false
        },
        appearance: {
          default: 1
        },
        helpText: {
          default: ''
        },
        helpTextDisplay: {
          default: 'tooltip'
        },
        warningStatus: {
          default: true
        },
        showCharacterCount: {
          default: false
        },
        validation: {
          default: {
            readOnly: {
              value: false,
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: false,
                  field: '',
                  operator: FIELD_FILTER_OPERATORS.EXISTS,
                  value: ''
                }
              ]
            },
            blocks: {
              isEnabled: true,
              values: ['paragraph'],
              errorMessage: ''
            },
            models: {
              isEnabled: true,
              values: [],
              errorMessage: ''
            },
            toolbarOptions: {
              fontStyle: {
                isEnabled: true,
                bold: true,
                italic: true,
                underline: true,
                strikethrough: true,
                colorStyle: true
              },
              dropcap: {
                isEnabled: true
              },
              textAlignment: {
                isEnabled: true,
                alignLeft: true,
                alignCenter: true,
                alignRight: true
              },
              links: {
                isEnabled: true,
                external: false,
                codex: false,
                phone: false,
                email: false
              },
              scripts: {
                isEnabled: true
              },
              comments: {
                isEnabled: true
              }
            }
          }
        },
        configuration: {
          default: {
            searchable: true,
            filterable: true,
            aiFeatures: false
          }
        },
        hidden: {
          default: {
            value: false,
            conditionsEnabled: false,
            conditions: [
              {
                isSystem: false,
                field: '',
                operator: FIELD_FILTER_OPERATORS.EXISTS,
                value: ''
              }
            ]
          }
        }
      }
    }
  ]
})
