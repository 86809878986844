import { CodexHooks } from '@/@core'
import { generateNavigationItem } from '@/@core/navigation'
import { useI18nStore } from '@/@core/i18n'
import { useOrganization } from '@/modules/organizations/store/organization'

CodexHooks.addFilter('navigation/items?parent-static=builder-module', (items) => {
  const ct = useI18nStore().createI18nContext('ct.models.navigation')

  return [
    ...items,
    generateNavigationItem({
      name: 'models',
      title: ct.$t('listing'),
      route: { name: 'models', params: { organizationId: useOrganization().organizationId } }
    })
  ]
})
