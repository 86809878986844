import { useQuery } from '@vue/apollo-composable'
import { computed, ref, watch } from 'vue'
import { cloneDeep } from 'lodash'

export function useCodexQuery(...args: any): ReturnOf<useQuery> {
  const lastResults = ref([])
  const lastErrors = ref(null)
  const fatalErrors = ref(null)
  const variables = ref({})
  const firstLoading = ref(true)

  let query: any

  try {
    query = useQuery(...args)
  } catch (e: any) {
    fatalErrors.value = e?.message
    firstLoading.value = false
    console.log(e)
  }

  // Set variables, needs fixing to show the variables used in the current query
  query?.onResult((_result: any) => {
    if (_result) {
      variables.value = query?.variables.value || {}
    }
  })

  watch(
    () => query?.variables,
    (newValue) => {
      variables.value = newValue.value
    },
    { deep: true }
  )

  watch(
    query?.loading,
    (loading: any) => {
      if (loading === false && firstLoading.value === true) {
        firstLoading.value = false
      }
    },
    { immediate: true }
  )

  watch(query?.error, (error: any) => {
    lastErrors.value = error
  })

  watch(
    query?.result,
    (_result: any) => {
      if (_result) {
        lastResults.value = cloneDeep(_result)
      }
    },
    { immediate: true }
  )

  const error = computed(() => {
    if (fatalErrors.value) return fatalErrors.value
    return query.error?.value || lastErrors.value
  })

  const result = computed(() => {
    return query.result?.value || lastResults.value
  })

  return {
    ...query,
    error,
    result,
    variables,
    firstLoading
  }
}
