<script setup lang="ts">
import { computed } from 'vue'
import { uniqueId } from 'lodash'

const props = withDefaults(
	defineProps<{
		options: any[]
		horizontal?: boolean
		labelKey?: string
		valueKey?: string
		size?: 'small' | 'large' | null
	}>(),
	{
		options: () => [],
		horizontal: false,
		labelKey: 'label',
		valueKey: 'value',
		size: null
	}
)

const modelValue = defineModel<Array<String>>()

const id = uniqueId('checkboxes_')

const checkboxesClasses = computed(() => {
	return {
		'checkboxes--horizontal': props.horizontal,
		'checkboxes--small': props.size === 'small',
		'checkboxes--large': props.size === 'large'
	}
})

const textSize = (size: 'small' | 'large' | null) => {
	const sizeMappings: Record<'small' | 'large' | null, string> = {
		small: 'body-regular-small',
		large: 'body-regular-headline'
	}
	return sizeMappings[size] || 'body-regular-base'
}
</script>

<template>
	<div class="checkboxes__wrapper" :class="checkboxesClasses">
		<div class="checkboxes" v-for="option in options" :key="option[valueKey]">
			<Checkbox type="checkbox" :disabled="option.disabled" :input-id="`${id}-${option[valueKey]}`" v-model="modelValue"
				:size="size" :value="option[valueKey]" />
			<Text :for="`${id}-${option[valueKey]}`" :type="textSize(size)" is="label">
				{{ option[labelKey] }}
			</Text>
		</div>
	</div>
</template>

<style scoped lang="scss"></style>
