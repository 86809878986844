import { CodexHooks } from '@/@core'
import CreateUrl from './CreateUrl.vue'
import UpdateUrl from './UpdateUrl.vue'
import AddUrl from './AddUrl.vue'
import AddSectionPopup from '../../sections/popups/AddSectionPopup.vue'
import AddEntryPopup from './AddEntryPopup.vue'
import ConfirmDelete from '@/modules/@global/popups/ConfirmDelete.vue'
import { useI18nStore } from '@/@core/i18n'

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  const ct = useI18nStore().createI18nContext('ct.urls.popups.confirm-delete-urls')
  items = [
    ...items,

    /* Create Url */
    {
      type: 'Sidebar',
      name: 'create-url',
      component: CreateUrl
    },
    {
      type: 'Dialog',
      name: 'confirm-delete-urls',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('cancel'),
          confirm: ct.$p('confirm'),
          title: ct.$p('title'),
          message: ct.$p('message')
        }
      }
    },

    /* Update Url */
    {
      type: 'Dialog',
      name: 'update-url',
      component: UpdateUrl
    },

    /* Select Redirect to */
    {
      type: 'Sidebar',
      name: 'add-url',
      component: AddUrl
    },
    {
      type: 'Sidebar',
      name: 'add-entry',
      component: AddEntryPopup
    }
  ]

  return items
})
