import gql from 'graphql-tag'

export const PUBLISHABLE_TEAM_INPUT = gql`
  fragment PublishableTeamInput on CodexTeam {
    createdAt
    createdBy
    description
    id
    members
    name
    updateAt
    updatedBy
  }
`

export const READ_TEAM = gql`
  ${PUBLISHABLE_TEAM_INPUT}

  query ReadTeam($id: String!, $input: PublishableTeamInput!) {
    team(id: $id, input: $input)
      @rest(type: "CodexTeam", path: "organizationId/teams/{args.id}", method: "GET") {
      ...PublishableTeamInput
    }
  }
`

export const CREATE_TEAM = gql`
  ${PUBLISHABLE_TEAM_INPUT}

  mutation CreateTeam($input: PublishableTeamInput!) {
    createTeam(input: $input)
      @rest(type: "CodexTeam", path: "organizationId/teams", method: "POST") {
      ...PublishableTeamInput
    }
  }
`

export const UPDATE_TEAM = gql`
  ${PUBLISHABLE_TEAM_INPUT}

  mutation UpdateTeam($input: PublishableTeamInput!) {
    updateTeam(input: $input)
      @rest(type: "CodexTeam", path: "organizationId/teams/{args.input.id}", method: "PUT") {
      ...PublishableTeamInput
    }
  }
`

export const DELETE_TEAM = gql`
  ${PUBLISHABLE_TEAM_INPUT}

  mutation DeleteTeam($id: String!, $input: PublishableTeamInput!) {
    deleteTeam(id: $id, input: $input)
      @rest(type: "CodexTeam", path: "organizationId/teams/{args.id}", method: "DELETE") {
      NoResponse
    }
  }
`
