import { CodexHooks } from '@/@core'
import GravityFilter from './gravity/GravityFilter'
import ResizeFilter from './resize/ResizeFilter'

CodexHooks.addFilter('filters/types', (items) => {
  return [
    ...items,
		{
			type: 'preset-resize',
			component: ResizeFilter
		},
    {
      type: 'preset-gravity',
      component: GravityFilter
    }
  ]
})