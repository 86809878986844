// pinia store
import { useQuery } from '@vue/apollo-composable'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useCodexQuery } from '@/modules/@global/composables/useCodexQuery'

export default function useNestedItems({
  name,
  query,
  nestedQuery
}: {
  name: String
  query: any
  nestedQuery: any
}): any {
  const route = useRoute()

  // Data
  const page = ref(0)
  const limit = ref(10)
  const expandedKeys = ref([]) as any
  const queries = ref({}) as any

  // Methods
  function onExpand(node: any) {
    doQuery(node.key)
  }
  function setPage(_page: number) {
    page.value = _page
  }
  function setLimit(_limit: number) {
    limit.value = _limit
  }
  function setExpandedKeys(_expandedKeys: string[]) {
    expandedKeys.value = _expandedKeys
  }
  function doQuery(id: string | null) {
    queries.value[id || 'main'] = useCodexQuery(
      id && nestedQuery ? nestedQuery : query,
      () => ({
        limit: id ? 10000 : limit.value,
        offset: id ? 0 : page.value * limit.value,
        siteId: (route?.params?.siteId as string) || 'Test',
        parentId: id
      }),
      {
        debounce: 10,
        context: {
          showError: false
        }
      }
    )

    return queries.value[id || 'main']
  }
  function getQuery(id: string | null) {
    return queries.value[id || 'main']
  }
  function getResultsRecursive(id: string | null) {
    const query = getQuery(id)
    if (!query) return []
    const results = query.result?.data?.items?.map((item: any) => {
      return {
        key: item.id,
        data: {
          ...item,
          _query: {
            loading: computed(() => {
              const _query = getQuery(item.id)
              return _query ? _query.loading : false
            }),
            error: computed(() => {
              const _query = getQuery(item.id)
              return _query ? _query.error : null
            }),
            totalRecords: computed(() => {
              const _query = getQuery(item.id)
              return _query ? _query.result?.data?.total || 0 : 0
            })
          }
        },
        leaf: item.children.total === 0,
        children: getResultsRecursive(item.id)
      }
    })
    return results
  }

  // Query
  const theQuery = doQuery(null)

  // Computed
  const records = computed(() => {
    return getResultsRecursive(null) || []
  })
  const totalRecords = computed(() => theQuery.result?.data?.total || 0)
  const loading = computed(() => theQuery.loading)
  const error = computed(() => theQuery.error)

  // Return
  return {
    queries,
    theQuery,
    expandedKeys,
    records,
    totalRecords,
    loading,
    error,
    page,
    limit,
    onExpand,
    setPage,
    setLimit,
    setExpandedKeys
  }
}
