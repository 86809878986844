<script setup lang="ts">
import FilterItems from './FilterItems.vue'
import ActiveFilters from './ActiveFilters.vue'
import {
  ref,
  reactive,
  computed,
  onMounted,
  onBeforeUnmount,
  nextTick,
  provide,
  watch,
  inject
} from 'vue'
import { createPopper } from '@popperjs/core'
import { someParentHasClass } from './helpers'
import { cloneDeep } from 'lodash'
import Filters, { ActiveFilter, Filter } from './Filters'
import { CodexHooks } from '@/@core'
import { useI18nStore } from '@/@core/i18n'

import { useRoute, useRouter } from 'vue-router'

const ct = useI18nStore().createI18nContext('ct.global.components.filters')
const allFiltersButtonRef = ref()
const allFiltersDropdownRef = ref()
const activeFilterRef = ref()
const activeFiltersRef = ref()

defineOptions({
  name: 'Filters'
})

const props = withDefaults(
  defineProps<{
    filters: Filter[]
    name: string
    queryParams?: boolean
    skipFilters?: string[]
  }>(),
  {
    name: '',
    filters: () => [],
    queryParams: false,
    skipFilters: () => ['s']
  }
)

const context = inject('context')

const filters = computed<Filter[]>(() => props.filters)

const filtersObject = reactive(new Filters([], props.skipFilters))
provide('filtersObject', filtersObject)

const refreshKey = ref(0)
CodexHooks.addAction(`hooks/filter-added?name=filters/filters?name=${props.name}`, () => {
  refreshKey.value++
})

CodexHooks.addAction(`hooks/filter-added?name=filters/filters?name=${props.name}`, () => {
  refreshKey.value++
})

CodexHooks.addAction(`i18n/setLocale`, () => {
  refreshKey.value++
})

const computedFilters = computed(() => {
  refreshKey.value
  return CodexHooks.applyFilters(
    `filters/filters?name=${props.name}`,
    cloneDeep(filters.value),
    context?.value,
    filtersObject
  )
})

watch(
  computedFilters,
  (newValue) => {
    filtersObject.setFilters(newValue)
  },
  {
    deep: true,
    immediate: true
  }
)

const allFiltersPopper = ref(null)
const activeFilterPopper = ref(null)
const showAllFiltersPopper = ref(false)
const activeFilter = reactive<ActiveFilter>({
  filter: null,
  value: null,
  location: null
})
provide('activeFilter', activeFilter)

const isMobile = computed(() => window.innerWidth <= 768)
if (props.queryParams) {
  const route = useRoute()
  const router = useRouter()
  filtersObject.loadFromQuery(route.query)
  watch(
    filtersObject,
    (v) => {
      const skippedFilters: any = {}
      props.skipFilters.forEach((filter) => {
        skippedFilters[filter] = route.query[filter]
      })

      router.replace({
        query: {
          ...skippedFilters,
          ...v.getQueryParamsObject()
        }
      })
    },
    {
      deep: true
    }
  )
}

const activeFilterObject = computed(() => {
  if (!activeFilter.filter) return null

  try {
    return filtersObject.getFilterObject(activeFilter.filter.name!)
  } catch (e) {
    return null
  }
})

watch(
  activeFilter,
  (newValue) => {
    if (newValue) {
      if (!newValue?.filter?.name) return
      if (!newValue?.value) return
      try {
        filtersObject.setActiveFilter(newValue.filter.name, newValue.value)
      } catch (e) {
        filtersObject.clearActiveFilter(newValue.filter.name)
        // console.error(e)
      }
    }
  },
  {
    deep: true
  }
)

const documentClick = (e: any) => {
  const filtersDropdownElement = someParentHasClass(allFiltersDropdownRef, 'p-dropdown-panel')
  const targetDropdownElement = someParentHasClass(e.target, 'p-dropdown-panel')
  if (
    !someParentHasClass(e.target, 'new-filters-dropdown') &&
    !someParentHasClass(e.target, 'new-filters-dropdown__filter') &&
    !someParentHasClass(e.target, 'p-datepicker') &&
    someParentHasClass(e.target, 'p-multiselect') &&
    (!targetDropdownElement || targetDropdownElement === filtersDropdownElement)
  ) {
    if (showAllFiltersPopper.value) {
      toggleAllFiltersPopper()
    } else {
      destroyActiveFilterDropdown()
    }
  }
}

/**
 * Clear all filters
 */
const clearAllFilters = () => {
  filtersObject.clearActiveFilters()
  resetActiveFilterValue()
}

/**
 * Clear a filter
 */
const clearFilter = (filter: Filter) => {
  if (!showAllFiltersPopper.value) {
    destroyActiveFilterDropdown()
  }

  filtersObject.clearActiveFilter(filter.name!)
  resetActiveFilterValue()
}

/**
 * Reset active filter value
 */
const resetActiveFilterValue = () => {
  if (activeFilter.filter) {
    activeFilter.value = filtersObject.getFilterDefaultValue(activeFilter.filter.name!)
  }
}

/**
 * Toggle All Filters Popper
 */
const toggleAllFiltersPopper = () => {
  try {
    showAllFiltersPopper.value = !showAllFiltersPopper.value

    if (allFiltersPopper.value) {
      allFiltersPopper.value.update()
    }

    // Destroy active filter dropdown
    if (!showAllFiltersPopper.value) {
      destroyActiveFilterDropdown()
    }
  } catch (e) {
    console.log(e)
  }
}

/**
 * Destroy active filter dropdown
 */
const destroyActiveFilterDropdown = () => {
  if (activeFilterPopper.value) {
    activeFilterPopper.value.destroy()
    activeFilterPopper.value = null
    activeFilter.filter = null
  }
}

const backToFilters = () => {
  if (activeFilterPopper.value?.state?.options?.location === 'active-filters-dropdown') {
    activeFiltersRef.value.toggleNotFittingFiltersPopper()
  } else {
    showAllFiltersPopper.value = true
    allFiltersPopper.value.update()
  }

  destroyActiveFilterDropdown()
}

/**
 * Called when a filter is clicked
 */
const editFilter = (
  filterItemEl: any,
  filter: any,
  popperOptions: { modifiers: {}[]; placement: string } = {
    modifiers: [],
    placement: 'right-start'
  },
  location: string
) => {
  // Destroy active filter dropdown
  if (activeFilterPopper.value) {
    activeFilterPopper.value.destroy()
  }

  // Set the location where the filter is being edited from
  activeFilter.location = location

  // Set the filter value
  activeFilter.value = cloneDeep(filtersObject.getActiveFilter(filter.name))

  // If its not yet set, use defaults
  if (!activeFilter.value) {
    activeFilter.value = filtersObject.getFilterDefaultValue(filter.name)
  }

  // Set the active filter
  activeFilter.filter = filter

  //
  let itemEl
  let positionEl
  if (isMobile.value && location !== 'active-filters-dropdown') {
    itemEl = allFiltersButtonRef
    positionEl = 'bottom-end'
    toggleAllFiltersPopper()
    popperOptions.modifiers = [
      {
        name: 'offset',
        options: {
          offset: [0, 4]
        }
      }
    ]
  } else if (isMobile.value && location === 'active-filters-dropdown') {
    itemEl = allFiltersButtonRef
    positionEl = 'bottom-end'
    popperOptions.placement = 'bottom-end'
    activeFiltersRef.value.toggleNotFittingFiltersPopper()
    popperOptions.modifiers = [
      {
        name: 'offset',
        options: {
          offset: [0, 4]
        }
      }
    ]
  } else {
    itemEl = filterItemEl
    positionEl = 'right-start'
  }

  // Create the new active filter dropdown
  activeFilterPopper.value = createPopper(itemEl?.value || itemEl, activeFilterRef.value, {
    placement: positionEl,
    location,
    ...popperOptions
  })

  nextTick(() => {
    activeFilterPopper.value.update()
  })
}
provide('editFilter', editFilter)

onMounted(() => {
  allFiltersPopper.value = createPopper(allFiltersButtonRef.value, allFiltersDropdownRef.value, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4]
        }
      }
    ]
  })
  document.addEventListener('mousedown', documentClick)
})

onBeforeUnmount(() => {
  document.removeEventListener('mousedown', documentClick)
})

defineExpose({
  filters: filtersObject
})
</script>

<template>
  <div class="new-filters-dropdown__container">
    <!-- filters-dropdown  >  new-filters-dropdown -->
    <!-- York  >  New York -->

    <div class="new-filters-dropdown__active-filters-wrapper">
      <!-- Active Filters -->
      <ActiveFilters ref="activeFiltersRef" :filters="filters" />
      <!-- / Active Filters -->
    </div>

    <div class="new-filters-dropdown__actions-wrapper">
      <!-- Toggle Button -->
      <div
        ref="allFiltersButtonRef"
        class="new-filters-dropdown__actions-toggle"
        @click="toggleAllFiltersPopper"
      >
        <GjIcon name="FiltersAlt" />
      </div>
      <!-- / Toggle Button -->
    </div>

    <!-- ------- -->
    <!-- Poppers -->
    <!-- ------- -->

    <!-- All Filters Dropdown  -->
    <div v-show="showAllFiltersPopper" ref="allFiltersDropdownRef" class="new-filters-dropdown">
      <div class="new-filters-dropdown__body">
        <FilterItems :filters="filtersObject.filters" />
      </div>

      <!-- Clear All Button -->
      <div
        v-show="filtersObject.hasActiveFilters()"
        class="new-filters-dropdown__clear-all"
        @click="clearAllFilters"
      >
        <GjIcon name="Close" size="18" class="new-filters-dropdown__clear-all-icon" />
        {{ ct.$t('clear-filters') }}
      </div>
      <!-- / Clear All Button -->
    </div>
    <!-- / All Filters Dropdown -->

    <!-- Active Filter -->
    <div
      v-show="activeFilterPopper"
      ref="activeFilterRef"
      class="new-filters-dropdown__filter"
      :class="`location-${activeFilter.location}`"
    >
      <template v-if="activeFilterObject">
        <a class="new-filters-dropdown__filter-back" href="#" @click.prevent="backToFilters">
          <GjIcon name="ArrowLeft" />

          {{ ct.$t('go-back') }}
        </a>
        <component
          :key="activeFilter.filter?.name"
          :is="activeFilterObject.COMPONENT"
          :filter="activeFilter.filter"
          :filter-object="activeFilterObject"
          v-model="activeFilter.value"
          :set-cache="filtersObject.setCache"
          :cache="filtersObject.cache"
        />

        <!-- Clear Button -->
        <div
          v-show="filtersObject.isFilterActive(activeFilter.filter.name)"
          class="new-filters-dropdown__filter-clear"
          @click="clearFilter(activeFilter.filter)"
        >
          <GjIcon name="Close" size="18" class="new-filters-dropdown__filter-clear-icon" />
          {{ ct.$t('clear-filter') }}
        </div>
        <!-- / Clear Button -->
      </template>
    </div>
    <!-- / Active Filter -->
  </div>
</template>

<style lang="scss">
.new-filters-dropdown__filter-back {
  display: flex;
  align-items: center;
  padding: 10px 10px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #052d61;

  svg {
    position: relative;
    top: 1px;
  }
}

// .new-filters-dropdown__filter.location-active-filters-dropdown {
//   .new-filters-dropdown__filter-back {
//     display: none;
//   }
// }

@media (min-width: 769px) {
  .new-filters-dropdown__filter-back {
    display: none;
  }
}

.new-filters-dropdown__container {
  display: flex;
}

.new-filters-dropdown__active-filters-wrapper {
  flex-grow: 1;
  min-width: 0;
}

.new-filters-dropdown__actions-wrapper {
  margin-left: 10px;
}

.new-filters-dropdown__actions-toggle {
  cursor: pointer;
  display: flex;
}

.new-filters-dropdown {
  z-index: 100;
  min-width: 220px;

  background: #ffffff;
  border: 1px solid #e0e5eb;
  box-shadow: 0 4px 25px rgba(17, 21, 56, 0.08);
  border-radius: 4px;
}

.new-filters-dropdown__body {
  padding: 10px 0;
  overflow-y: auto;
  max-height: 400px;
}

.new-filters-dropdown__filter {
  z-index: 110;
  min-width: 220px;
  max-width: 350px;

  background: #ffffff;
  border: 1px solid #e0e5eb;
  box-shadow: 0 4px 25px rgba(17, 21, 56, 0.08);
  border-radius: 4px;
}

.new-filters-dropdown__filter {
  .v-select.vs--single {
    min-height: 30px;

    .vs__dropdown-toggle {
      border: 1px solid #e0e5eb;
      background: #f4f6fa;
      border-radius: 4px;
      min-height: 30px;
    }

    .vs__selected {
      font-style: normal;
      font-weight: 500 !important;
      font-size: 14px;
      color: #1d417b;

      line-height: 1em;
      margin-top: 4px;
    }

    .vs__search,
    .vs__search:focus {
      line-height: 1em;
    }
  }
}

.new-filters-dropdown__filter-body {
  padding: 16px 16px;
  max-height: 440px;
  display: flex;
  flex-direction: column;

  .row {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.new-filters-dropdown__filter-body--scroll {
  overflow-y: auto;
}

.new-filters-dropdown__clear-all,
.new-filters-dropdown__filter-clear {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #052d61;

  padding: 12px 16px;
  border-top: 1px solid #e0e5eb;

  cursor: pointer;
}

.new-filters-dropdown__clear-all-icon,
.new-filters-dropdown__filter-clear-icon {
  vertical-align: bottom;
}

.new-filters-dropdown__range-divider {
  width: 10px;
  height: 1px;
  background: #e0e5eb;
  margin: 0 6px;
}

.new-filters-dropdown__checkbox-group {
  word-break: break-all;

  .custom-checkbox:not(:last-child) {
    margin-bottom: 8px;
  }
}

.new-filters-dropdown__checkbox-group-no-result {
  text-align: center;
  font-size: 14px;
  opacity: 0.5;
}

.new-filters-dropdown__date-picker {
  display: none;

  & + .flatpickr-calendar {
    box-shadow: none;
  }
}
</style>
