import { CodexHooks } from '@/@core'
import SchedulePopup from '@/modules/entries/components/EntrySchedule/SchedulePopup.vue'

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  return [
    ...items,
    {
      type: 'Dialog',
      name: 'entry-schedule',
      component: SchedulePopup
    }
  ]
})
