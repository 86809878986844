<script setup lang="ts">
import DialogContent from '@/modules/@global/components/DialogContent/DialogContent.vue'
import { computed, inject, ref } from 'vue'
import DatePicker from '@/modules/primevue/components/DatePicker/DatePicker.vue'
import EntriesDisplay from '@/modules/entries/components/EntriesDisplay.vue'
import { CodexEntryStatus } from '@/gql/graphql'
import { CMI_ORGANIZATIONS } from '@/modules/@global/utils/constants'
import { useOrganization } from '@/modules/organizations/store/organization'
import dayjs from 'dayjs'
import { ScheduleType } from '@/modules/entries/components/EntrySchedule/consts'
import { useUnpublishedReferences } from '@/modules/entries/components/EntrySchedule/unpublishedReferences'
import { useI18nStore } from '@/@core/i18n'

const dialog = inject('dialogRef')

const ct = useI18nStore().createI18nContext('ct.entries.popups.entry-schedule')

const isEntryPublished = computed(
  () => dialog.value.data.entry.system.status === CodexEntryStatus.Published
)

const { referencedEntries, loading } = useUnpublishedReferences({
  model: dialog.value.data.model,
  entry: dialog.value.data.entry
})

const organization = useOrganization()
const showScheduleUnpublish = !CMI_ORGANIZATIONS.includes(organization.organizationId)

const scheduleType = ref(isEntryPublished.value ? ScheduleType.Unpublish : ScheduleType.Publish)

const publishDate = ref(dayjs().add(1, 'minute').second(0).millisecond(0).toDate())
const unpublishDate = ref(dayjs().add(1, 'minute').second(0).millisecond(0).toDate())

const schedule = (redirect = false, publishReferences = false) => {
  const max =
    dayjs(dialog.value.data.entry.system.publishedAt).unix() > dayjs().unix()
      ? dayjs(dialog.value.data.entry.system.publishedAt)
      : dayjs()

  const isSameOrBefore = (value1, value2) => dayjs(value1).unix() <= dayjs(value2).unix()

  if (scheduleType.value === ScheduleType.Publish) {
    if (
      isEntryPublished.value ||
      !publishDate.value ||
      isSameOrBefore(publishDate.value, dayjs())
    ) {
      return
    }
  } else if (!unpublishDate.value || isSameOrBefore(unpublishDate.value, max)) {
    return
  }

  dialog.value?.close({
    scheduleType: scheduleType.value,
    publishDate:
      scheduleType.value === ScheduleType.Publish ? publishDate.value.toISOString() : null,
    unpublishDate:
      scheduleType.value === ScheduleType.Unpublish ? unpublishDate.value.toISOString() : null,
    redirect,
    publishReferences
  })
}

const cancel = () => {
  dialog.value?.close(false)
}
</script>

<template>
  <DialogContent :title="ct.$t('title')">
    <div style="display: flex; flex-direction: column; gap: 24px">
      <div style="display: flex; flex-direction: column; gap: 12px">
        <InputBase direction="horizontal" :label="ct.$t('schedule-types.publish')">
          <RadioButton
            v-model="scheduleType"
            :value="ScheduleType.Publish"
            :disabled="isEntryPublished"
          />
        </InputBase>
        <InputBase :label="ct.$t('date')" v-if="scheduleType === ScheduleType.Publish">
          <DatePicker v-model="publishDate" show-time />
        </InputBase>
        <EntriesDisplay
          v-if="scheduleType === ScheduleType.Publish && referencedEntries.length"
          :entries="referencedEntries"
        >
          <Text
            type="body-regular-base"
            class="text--main"
            :text="ct.$t('table-header', { count: referencedEntries.length })"
          />
        </EntriesDisplay>
      </div>

      <div style="display: flex; flex-direction: column; gap: 12px" v-if="showScheduleUnpublish">
        <InputBase direction="horizontal" :label="ct.$t('schedule-types.unpublish')">
          <RadioButton v-model="scheduleType" :value="ScheduleType.Unpublish" />
        </InputBase>
        <InputBase :label="ct.$t('date')" v-if="scheduleType === ScheduleType.Unpublish">
          <DatePicker v-model="unpublishDate" show-time />
        </InputBase>
      </div>
    </div>
    <template #footer>
      <Button severity="secondary" @click="cancel" :label="ct.$t('actions.cancel')" />
      <SplitButton
        :disabled="loading"
        :model="[
          {
            label: ct.$t('actions.schedule-current.continue'),
            command: () => schedule(false, false)
          }
        ]"
        severity="primary"
        :label="ct.$t('actions.schedule-current.redirect')"
        @click="schedule(true, false)"
      />
      <SplitButton
        v-if="referencedEntries.length && scheduleType === ScheduleType.Publish"
        :disabled="loading"
        :model="[
          { label: ct.$t('actions.schedule-all.continue'), command: () => schedule(false, true) }
        ]"
        severity="primary"
        :label="ct.$t('actions.schedule-all.redirect')"
        @click="schedule(true, true)"
      />
    </template>
  </DialogContent>
</template>

<style lang="scss">
@at-root div.p-dialog {
  width: 500px !important;
}
</style>
