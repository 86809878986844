import { merge } from 'lodash'
import { enumMapping } from '@/modules/@global/utils/EnumMapping'
import { CodexLabelEntity } from '@/gql/graphql'

/**
 * Label Name
 */
export const LABELS_NAME = 'labels-listing'

export const LABEL_ENTITY_MAPPING = enumMapping({
  [CodexLabelEntity.All]: 1,
  [CodexLabelEntity.Entry]: 2,
  [CodexLabelEntity.Asset]: 3
})

/**
 * Label defaults
 */
export function labelDefaults(label = {}) {
  return merge(
    {
      __typename: 'CodexLabel',
      name: null,
      description: null,
      color: null,
      entities: [CodexLabelEntity.All],
      restrictions: {
        sites: {
          allowAll: true,
          allowed: []
        }
      },
      type: 2 // legacy property,
    },
    label
  )
}
