import { CodexHooks } from '@/@core'
import ConfirmDelete from '@/modules/@global/popups/ConfirmDelete.vue'
import { useI18nStore } from '@/@core/i18n'

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  const ct = useI18nStore().createI18nContext('ct.plugins.popups.confirm-plugins')

  items = [
    ...items,

    {
      type: 'Dialog',
      name: 'confirm-activate-plugins',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('cancel'),
          confirm: ct.$p('activate-confirm'),
          title: ct.$p('activate-title'),
          message: ct.$p('activate-message')
        }
      }
    },
    {
      type: 'Dialog',
      name: 'confirm-activate-latest-plugins',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('cancel'),
          confirm: ct.$p('activate-latest-confirm'),
          title: ct.$p('activate-latest-title'),
          message: ct.$p('activate-latest-message')
        }
      }
    },
    {
      type: 'Dialog',
      name: 'confirm-deactivate-plugins',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('cancel'),
          confirm: ct.$p('deactivate-confirm'),
          title: ct.$p('deactivate-title'),
          message: ct.$p('deactivate-message')
        }
      }
    },
    {
      type: 'Dialog',
      name: 'confirm-delete-plugins',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('cancel'),
          confirm: ct.$p('delete-confirm'),
          title: ct.$p('delete-title'),
          message: ct.$p('delete-message')
        }
      }
    }
  ]

  return items
})
