<script setup lang="ts">
import { inject } from 'vue'
import { CreateModelForm } from '@/modules/models/forms/Model'
import { CREATE_MODEL } from '@/modules/models/queries/index.rest'
import { useMutation } from '@vue/apollo-composable'
import { GenerateUUID } from '@/modules/@global/utils/GenerateUUID'
import { modelDefaults } from '@/modules/models/constants'
import { useI18nStore } from '@/@core/i18n'
import type { Context } from '@/modules/@global/composables/useContext'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.models.popups.create-model')

/**
 * Dialog
 */
const dialog = inject(DialogRefKey)

/**
 * Create model
 */
const createModel = useMutation(CREATE_MODEL, {
	refetchQueries: ['ListModels']
})

async function createModelHandler(values) {
	dialog?.value?.close(values)
}

/**
 * Default values
 */
const defaultValues = modelDefaults({
	blocks: [
		{
			id: GenerateUUID.BLOCK(),
			type: 'codex_row',
			content: [
				{
					id: GenerateUUID.BLOCK(),
					type: 'codex_column',
					content: [],
					isField: false,
					attrs: {
						_id: '',
						_class: '',
						_alias: '',
						_responsive: {
							desktop: {
								display: true,
								columns: 6,
								order: 1,
								orientation: 'vertical',
								align: 'left'
							},
							tablet: {
								display: true,
								columns: 6,
								order: 1,
								orientation: 'vertical',
								align: 'left'
							},
							mobile: {
								display: true,
								columns: 6,
								order: 1,
								orientation: 'vertical',
								align: 'left'
							}
						},
						cols: 6
					}
				},
				{
					id: GenerateUUID.BLOCK(),
					type: 'codex_column',
					content: [],
					isField: false,
					attrs: {
						_id: '',
						_class: '',
						_alias: '',
						_responsive: {
							desktop: {
								display: true,
								columns: 6,
								order: 1,
								orientation: 'vertical',
								align: 'left'
							},
							tablet: {
								display: true,
								columns: 6,
								order: 1,
								orientation: 'vertical',
								align: 'left'
							},
							mobile: {
								display: true,
								columns: 6,
								order: 1,
								orientation: 'vertical',
								align: 'left'
							}
						},
						cols: 6
					}
				}
			],
			isField: false,
			attrs: {
				_id: '',
				_class: '',
				_alias: '',
				_responsive: {
					desktop: {
						display: true,
						columns: 12
					},
					tablet: {
						display: true,
						columns: 12
					},
					mobile: {
						display: true,
						columns: 12
					}
				},
				layout: '6-6'
			}
		}
	]
})
</script>

<template>
	<SidebarContent :title="ct.$t('title')">
		<DynamicForm name="create-model" :ref="(r: any) => context?.setRef('form', r)" :show-submit="false"
			:schema="CreateModelForm()" :mutation="createModel" :data="defaultValues" :labels="{
				submit: ct.$t('submit')
			}" @submit="createModelHandler"></DynamicForm>

		<template #footer>
			<Button v-bind="context.getRef('form')?.props?.submit.value"></Button>
		</template>
	</SidebarContent>
</template>
