<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import debounce from 'lodash/debounce'

const props = withDefaults(
	defineProps<{
		name: string
		queryParams?: boolean
		placeholder?: string
		block?: boolean
	}>(),
	{
		name: '',
		placeholder: '',
		queryParams: false,
		block: false
	}
)

const search = ref('')

if (props.queryParams) {
	const route = useRoute()
	const router = useRouter()

	if (route?.query?.s) {
		search.value = route.query.s as string
	}

	watch(
		search,
		(value) => {
			if (value) {
				router.replace({
					query: {
						...route.query,
						s: value
					}
				})
			} else {
				const { s, ...others } = route.query
				router.replace({
					query: {
						...others
					}
				})
			}
		},
		{
			deep: true
		}
	)
}

const debounceSearch = debounce((value: string) => {
	search.value = value
}, 300)

defineExpose({
	search
})
</script>

<template>
	<InputText :block="block" :value="search" @input="(event) => debounceSearch(event?.target?.value)" type="text"
		:placeholder="props.placeholder" />
</template>
