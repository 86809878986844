<script setup lang="ts">
import DialogContent from '@/modules/@global/components/DialogContent/DialogContent.vue'
import { inject, type Ref, ref } from 'vue'
import DatePicker from '@/modules/primevue/components/DatePicker/DatePicker.vue'
import EntriesDisplay from '@/modules/entries/components/EntriesDisplay.vue'
import { CMI_ORGANIZATIONS } from '@/modules/@global/utils/constants'
import { useOrganization } from '@/modules/organizations/store/organization'
import dayjs from 'dayjs'
import { PublishType } from '@/modules/entries/components/helper'
import { useUnpublishedReferences } from '@/modules/entries/components/EntrySchedule/unpublishedReferences'
import { CodexEntryStatus } from '@/gql/graphql'
import { useI18nStore } from '@/@core/i18n'

const dialog = inject('dialogRef')
const organization = useOrganization()

const ct = useI18nStore().createI18nContext('ct.entries.popups.entry-publish')

const { referencedEntries, loading } = useUnpublishedReferences({
  model: dialog.value.data.model,
  entry: dialog.value.data.entry
})

const hasPublishedVersion =
  dialog.value.data.entry?.system?.status === CodexEntryStatus.Published ||
  dialog.value.data.entry?.system?.status === CodexEntryStatus.Edited

const showCustomDate = !CMI_ORGANIZATIONS.includes(organization.organizationId)
const publishType: Ref<PublishType> = ref(
  hasPublishedVersion ? PublishType.Current : PublishType.Now
)
const publishDate = ref(dayjs().add(1, 'minute').second(0).millisecond(0).toDate())

const publish = (redirect = false, publishReferences = false) => {
  const obj: { publishAt: string | null; redirect: boolean; publishReferences: boolean } = {
    publishAt: dialog.value.data.entry.system.publishedAt,
    redirect,
    publishReferences
  }
  if (publishType.value === PublishType.Now) {
    obj.publishAt = dayjs().add(1, 'minute').second(0).millisecond(0).toISOString()
  } else if (publishType.value === PublishType.Custom) {
    obj.publishAt = publishDate.value.toISOString()
  }

  dialog.value?.close(obj)
}

const cancel = () => {
  dialog.value.close(false)
}
</script>

<template>
  <DialogContent :title="ct.$t('title')">
    <div style="display: flex; flex-direction: column; gap: 24px">
      <div style="display: flex; flex-direction: column; gap: 12px">
        <InputBase
          direction="horizontal"
          :label="ct.$t('publish-types.current')"
          v-if="hasPublishedVersion"
        >
          <RadioButton v-model="publishType" :value="PublishType.Current" />
        </InputBase>
        <InputBase direction="horizontal" :label="ct.$t('publish-types.now')">
          <RadioButton v-model="publishType" :value="PublishType.Now" />
        </InputBase>
        <template v-if="showCustomDate">
          <InputBase direction="horizontal" :label="ct.$t('publish-types.custom')">
            <RadioButton v-model="publishType" :value="PublishType.Custom" />
          </InputBase>
          <InputBase label="Date" v-if="publishType === PublishType.Custom">
            <DatePicker v-model="publishDate" show-time />
          </InputBase>
        </template>
        <EntriesDisplay v-if="referencedEntries.length" :entries="referencedEntries">
          <Text
            type="body-regular-base"
            class="text--main"
            :text="ct.$t('table-header', { count: referencedEntries.length })"
          />
        </EntriesDisplay>
      </div>
    </div>
    <template #footer>
      <Button severity="secondary" @click="cancel" :label="ct.$t('actions.cancel')" />
      <SplitButton
        :disabled="loading"
        :model="[
          { label: ct.$t('actions.publish-current.continue'), command: () => publish(false, false) }
        ]"
        severity="primary"
        :label="ct.$t('actions.publish-current.redirect')"
        @click="publish(true, false)"
      />
      <SplitButton
        v-if="referencedEntries.length"
        :disabled="loading"
        :model="[
          { label: ct.$t('actions.publish-all.continue'), command: () => publish(false, true) }
        ]"
        severity="primary"
        :label="ct.$t('actions.publish-all.redirect')"
        @click="publish(true, true)"
      />
    </template>
  </DialogContent>
</template>
