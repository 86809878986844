import { computed, ref, nextTick } from 'vue'
import { defineStore } from 'pinia'
import { LIST_MODELS, ORGANIZATION } from '../queries/index.graphql'
import { useQuery } from '@vue/apollo-composable'
import { CodexHooks } from '@/@core'
import { GET_PERMISSIONS } from '../queries/index.rest'
import { useSite } from '@/modules/sites/store/site'
import { useRoute } from '@/@core/router'
import { cloneDeep } from 'lodash'
import { toLowerFirstCharObjectKeys } from '@/modules/@global/utils/helpers'

export const useOrganization = defineStore('organization', () => {
  const route = useRoute()

  const organizationId = ref()
  const query = ref()
  const queryAllModels = ref()
  const permissionsQuery = ref()

  CodexHooks.addAction('organization/organizationIdChanged', organizationIdChanged)

  function organizationIdChanged(context: any, newId?: string) {
    organizationId.value = newId

    if (newId) {
      CodexHooks.applyActions('organization/organizationUnloaded', null, query?.value?.result)

      query.value = useQuery(ORGANIZATION)
      queryAllModels.value = useQuery(LIST_MODELS)
      permissionsQuery.value = useQuery(GET_PERMISSIONS, () => ({ id: newId as string }))

      query?.value?.onResult((result: any) => {
        if (result.loading !== true && !result.error && result?.data) {
          CodexHooks.applyActions('organization/organizationLoaded', null, result)
        }
      })
    } else {
      CodexHooks.applyActions('organization/organizationUnloaded', null, query?.value?.result)

      query.value = null
      queryAllModels.value = null
      permissionsQuery.value = null
    }
  }

  // Replaced this initialization with useOrganization() call below.. lets try
  // nextTick(() => {
  //   organizationIdChanged(route?.value?.params?.organizationId as string)
  // })
  const allModels = computed(() => {
    const models = cloneDeep(queryAllModels?.value?.result?.data?.items || [])
    models.forEach((model) => {
      model.fields.forEach((field) => {
        // field.type = FIELD_TYPES[field.type]
        // field.valueType = FIELD_VALUE_TYPES[field.valueType]
        field.validation = toLowerFirstCharObjectKeys(field.validation)
      })
    })
    return models
  })
  useSite()
  return {
    organizationId,
    query,
    result: computed(() => query?.value?.result),
    loading: computed(() => query?.value?.loading),
    error: computed(() => query?.value?.error),
    sites: computed(() => query?.value?.result?.organization?.sites?.items || []),
    models: computed(() => {
      const ids = query?.value?.result?.organization?.models?.items?.map((m) => m.id) || []
      return allModels.value.filter((m: { id: string }) => ids.includes(m.id)) || []
    }),
    allModels,
    getSiteByID(id: string) {
      return query?.value?.result?.organization?.sites?.items.find(
        (site: { id: string }) => site.id === id
      )
    },
    getSitesByIDs(ids: string[]) {
      return query?.value?.result?.organization?.sites?.items.filter((site: { id: string }) =>
        ids.includes(site.id)
      )
    },
    getModelById(id: string) {
      return cloneDeep(allModels.value?.find((m) => m.id === id || m.alias === id))
    },

    permissionsQuery,
    permissions: computed(() => {
      let permissions = permissionsQuery?.value?.result?.getPermissions?.permissions || []

      if (permissions.length === 0) {
        permissions = []
      }

      const permissionsObject: Record<string, boolean> = {}
      Object.keys(permissions).forEach((key) => {
        if (key === 'plugins') {
          return
        }
        permissions[key].forEach((permission: { id: string; effect: string }) => {
          permissionsObject[permission.id] = permission.effect.toLowerCase() === 'allow'
        })
      })

      return permissionsObject
    }),
    views: computed(() => query?.value?.result?.organization?.views?.items || [])
  }
})

// Initialize the store
useOrganization()