import { CodexHooks } from '@/@core'
import SourceFilter from './source/SourceFilter'
import ReferencesListFilter from './reference-list/ReferencesListFilter'
import TagParentFilter from './parent-tag/TagParentFilter'
CodexHooks.addFilter('filters/types', (items) => {
  return [
    ...items,
    {
      type: 'tag-parent',
      component: TagParentFilter
    },
    {
      type: 'tag-references-list',
      component: ReferencesListFilter
    },
    {
      type: 'tag-source',
      component: SourceFilter
    }
  ]
})
