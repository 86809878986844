import { CodexHooks } from '@/@core'
import CreateLabel from '@/modules/labels/popups/CreateLabel.vue'
import UpdateLabel from '@/modules/labels/popups/UpdateLabel.vue'
import ConfirmDelete from '@/modules/@global/popups/ConfirmDelete.vue'
import { useI18nStore } from '@/@core/i18n'

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  const ct = useI18nStore().createI18nContext('ct.labels.popups.confirm-delete-labels')

  items = [
    ...items,

    /* Create Label */
    {
      type: 'Sidebar',
      name: 'create-label',
      component: CreateLabel
    },

    /* Update Label */
    {
      type: 'Sidebar',
      name: 'update-label',
      component: UpdateLabel
    },
    {
      type: 'Dialog',
      name: 'confirm-delete-labels',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('cancel'),
          confirm: ct.$p('confirm'),
          title: ct.$p('title'),
          message: ct.$p('message')
        }
      }
    }
  ]

  return items
})
