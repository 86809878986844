<script setup lang="ts">
import { CreateSiteForm } from '@/modules/sites/forms/Site'
import { useMutation } from '@vue/apollo-composable'
import { CREATE_SITE } from '@/modules/sites/queries/index.rest'
import { inject } from 'vue'
import { useI18nStore } from '@/@core/i18n'
import { siteDefaults } from '@/modules/sites/constants'
import type { Context } from '@/modules/@global/composables/useContext'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.sites.popups.create-site')

/**
 * Dialog
 */
const dialog = inject(DialogRefKey)

/**
 * Create site
 */
const createSite = useMutation(CREATE_SITE, {
  refetchQueries: ['ListSites']
})

async function createSiteHandler(values) {
  dialog?.value?.close(values)
}

/**
 * Default values
 */
const defaultValues = siteDefaults()
</script>

<template>
  <SidebarContent :title="ct.$t('title')">
    <DynamicForm
      name="create-site"
      :ref="(r:any) => context?.setRef('form', r)"
      :show-submit="false"
      :schema="CreateSiteForm()"
      :mutation="createSite"
      :data="defaultValues"
      :labels="{
        submit: ct.$t('submit')
      }"
      @submit="createSiteHandler"
    />
    <template #footer>
      <Button v-bind="context.getRef('form')?.props?.submit.value"></Button>
    </template>
  </SidebarContent>
</template>
