import gql from 'graphql-tag'

export const PUBLISHABLE_SITE_INPUT = gql`
  fragment PublishableSiteInput on CodexSite {
    alias
    createdAt
    createdBy
    description
    domain
    id
    logo
    name
    organizationId
    status
    updatedAt
    updatedBy
  }
`

export const READ_SITE = gql`
  ${PUBLISHABLE_SITE_INPUT}

  query ReadSite($id: String!, $input: PublishableSiteInput!) {
    site(id: $id, input: $input)
      @rest(type: "CodexSite", path: "organizationId/sites/{args.id}", method: "GET") {
      ...PublishableSiteInput
    }
  }
`

export const CREATE_SITE = gql`
  ${PUBLISHABLE_SITE_INPUT}

  mutation CreateSite($input: SiteInput!) {
    createSite(input: $input)
      @rest(type: "CodexSite", path: "organizationId/sites", method: "POST") {
      ...PublishableSiteInput
    }
  }
`

export const UPDATE_SITE = gql`
  ${PUBLISHABLE_SITE_INPUT}

  mutation UpdateSite($input: SiteInput!) {
    updateSite(input: $input)
      @rest(type: "CodexSite", path: "organizationId/sites/{args.input.id}", method: "PUT") {
      ...PublishableSiteInput
    }
  }
`

export const DELETE_SITE = gql`
  ${PUBLISHABLE_SITE_INPUT}

  mutation DeleteSite($id: String!, $input: PublishableSiteInput!) {
    deleteSite(id: $id, input: $input)
      @rest(type: "CodexSite", path: "organizationId/sites/{args.id}", method: "DELETE") {
      NoResponse
    }
  }
`
