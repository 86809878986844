<script setup lang="ts">
import DynamicSelectMultiple from '@/modules/@global/components/DynamicSelectMultiple/DynamicSelectMultiple.vue'
import { watch } from 'vue'

const restrictions = defineModel('modelValue')

watch(
  () => restrictions?.value?.sites?.allowed,
  (v) => {
    restrictions.value.sites.allowAll = v.length == 0
  },
  {
    deep: true
  }
)
</script>

<template>
  <DynamicSelectMultiple v-model="restrictions.sites.allowed" />
</template>

<style scoped lang="scss"></style>
