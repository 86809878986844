<script setup lang="ts">
import { mergeWith } from 'lodash'
import { useDynamicForm } from '../../store/dynamic-form'
import DynamicFormField from './DynamicFormField.vue'
import generateColumnClasses from '../../utils/generateColumnClasses'
import { inject, provide } from 'vue'
import type { useForm } from 'vee-validate'

const props = defineProps<{
  fields: Array<any>
}>()

let dynamicForm = useDynamicForm()

let form = inject('dynamicForm') as ReturnType<typeof useForm>

function isField(field: any) {
  let component = dynamicForm.resolveField(field)

  return !!field?.name && component?.field !== false
}

function mergeProps(field: any) {
  let component = dynamicForm.resolveField(field)

  return mergeWith({}, component?.props, field?.props, (objValue, srcValue) => {
    if (Array.isArray(objValue)) {
      return objValue.concat(srcValue)
    }
  })
}

function fieldClasses(field: any, isField: boolean) {
  let classes: Record<string, boolean> = {}

  if (isField) {
    classes['dynamic-form__field'] = true
  } else {
    classes['dynamic-form__layout'] = true
  }

  if (!showField(field)) {
    classes['hidden'] = true
  }

  if (field?.layout) {
    classes = { ...classes, ...generateColumnClasses(field.layout) }
  }

  return classes
}

function showField(field: any) {
  if (field?.show) {
    return field.show(form.values)
  }

  return true
}
</script>

<template>
  <template v-for="(field, idx) in props.fields" :key="field?.name || `layout-${idx}`">
    <!-- If its a field, render it -->
    <DynamicFormField
      v-if="isField(field)"
      :field="field"
      :component="dynamicForm.resolveField(field)"
      :merged-props="mergeProps(field)"
      :class="fieldClasses(field, true)"
    />

    <!-- Else its probably a layout component.. render it with children -->
    <component
      v-else
      :field="field"
      :component="dynamicForm.resolveField(field)"
      :merged-props="mergeProps(field)"
      :is="dynamicForm.resolveField(field)?.component"
      :class="fieldClasses(field, false)"
      v-bind="mergeProps(field)"
    >
      <DynamicFormList v-if="field?.children && field?.children?.length" :fields="field.children" />
    </component>
  </template>
</template>
