import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=organization', (items) => {
	return [
		...items,
		{
			path: 'teams',
			name: 'teams',
			component: () => import('@/modules/teams/pages/TeamsListing.vue')
		}
	]
})