import DateTimeFilterComponent from './DateTime.vue'
import { useI18nStore } from '@/@core/i18n'
import { formatDate } from '@/modules/@global/utils/formatDate'

export default class DateTimeFilter {
  static COMPONENT = DateTimeFilterComponent
  static OPERATORS: {
    equals: string
    notEquals: string
    greaterThan: string
    lowerThan: string
    between: string
    exists: string
    notExists: string
  } = {
    equals: 'eq',
    notEquals: 'ne',
    greaterThan: 'gt',
    lowerThan: 'lt',
    between: 'bt',
    exists: 'exists',
    notExists: 'notExists'
  }

  static type: string = 'datetime'

  operator: string | null = null

  value1?: Date | null = null

  value2?: Date | null = null

  constructor(
    {
      operator = DateTimeFilter.OPERATORS.equals,
      value1,
      value2
    }: { operator: string; value1?: string; value2?: string } = {
      operator: DateTimeFilter.OPERATORS.equals
    }
  ) {
    this.operator = operator
    this.value1 = value1 && new Date(value1)
    this.value2 = value2 && new Date(value2)
  }
  set(
    {
      operator = DateTimeFilter.OPERATORS.equals,
      value1,
      value2
    }: { operator: string; value1?: string; value2?: string } = {
      operator: DateTimeFilter.OPERATORS.equals
    }
  ) {
    this.operator = operator
    this.value1 = value1 && new Date(value1)
    this.value2 = value2 && new Date(value2)
  }

  getCount() {
    return 1
  }

  getValueLabel(): string | null {
    const ct = useI18nStore().createI18nContext('ct.global.filters.datetime')
    const formattedValue1 = formatDate(this.value1, 'MonthDayYear', false)
    const formattedValue2 = formatDate(this.value2, 'MonthDayYear', false)
    switch (this.operator) {
      case DateTimeFilter.OPERATORS.equals:
        return ct.$t('active-label.is', { value: formattedValue1 })
      case DateTimeFilter.OPERATORS.notEquals:
        return ct.$t('active-label.is-not', { value: formattedValue1 })
      case DateTimeFilter.OPERATORS.greaterThan:
        return ct.$t('active-label.is-greater-than', { value: formattedValue1 })
      case DateTimeFilter.OPERATORS.lowerThan:
        return ct.$t('active-label.is-lower-than', { value: formattedValue1 })
      case DateTimeFilter.OPERATORS.between:
        return ct.$t('active-label.is-between', {
          value1: formattedValue1,
          value2: formattedValue2
        })
      case DateTimeFilter.OPERATORS.exists:
        return ct.$t('active-label.exists', {})
      case DateTimeFilter.OPERATORS.notExists:
        return ct.$t('active-label.not-exists', {})
      default:
        return null
    }
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value1: this.value1,
      value2: this.value2
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case DateTimeFilter.OPERATORS.equals:
        return {
          gte: this.value1,
          lt: new Date(new Date(this.value1).setDate(this.value1.getDate() + 1))
        }
      case DateTimeFilter.OPERATORS.notEquals:
        return {
          lte: this.value1,
          gt: new Date(new Date(this.value1).setDate(this.value1.getDate() + 1))
        }
      case DateTimeFilter.OPERATORS.greaterThan:
        return {
          gt: this.value1
        }
      case DateTimeFilter.OPERATORS.lowerThan:
        return {
          lt: this.value1
        }
      case DateTimeFilter.OPERATORS.between:
        return {
          gte: this.value1,
          lte: this.value2
        }
      case DateTimeFilter.OPERATORS.exists:
        return {
          exists: true
        }
      case DateTimeFilter.OPERATORS.notExists:
        return {
          exists: false
        }
      default:
        return null
    }
  }

  static validate({
    operator,
    value1,
    value2
  }: {
    operator: string
    value1?: string
    value2?: string
  }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (
      operator !== DateTimeFilter.OPERATORS.exists &&
      operator !== DateTimeFilter.OPERATORS.notExists
    )
      if (!value1) {
        return 'Value 1 is required'
      }

    if (operator === DateTimeFilter.OPERATORS.between && !value2) {
      return 'Value 2 is required because operator is "between"'
    }

    return true
  }
  static fromQueryParam({
    operator,
    value1,
    value2
  }: {
    operator: string
    value1?: string
    value2?: string
  }) {
    return new DateTimeFilter({
      operator,
      value1,
      value2
    })
  }
}
