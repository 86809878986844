import { CodexHooks } from '@/@core'
import RedirectDropdown from '../dropdowns/RedirectDropdown.vue'

CodexHooks.addFilter('dynamic-form/fields', (items) => {
  return [
    ...items,
    {
      type: 'RedirectDropdown',
      component: RedirectDropdown
    }
  ]
})
