import { CodexHooks } from '@/@core'
import CreateApiKey from './CreateApiKey.vue'
import ApiKeyResponse from './ApiKeyResponse.vue'
import ConfirmDelete from '@/modules/@global/popups/ConfirmDelete.vue'
import { useI18nStore } from '@/@core/i18n'

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  const ct = useI18nStore().createI18nContext('ct.apikeys.popups')

  items = [
    ...items,

    /* Create ApiKey */
    {
      type: 'Sidebar',
      name: 'create-apikey',
      component: CreateApiKey
    },
    /* ApiKey Response */
    {
      type: 'Dialog',
      name: 'apikey-response',
      component: ApiKeyResponse
    },
    {
      type: 'Dialog',
      name: 'confirm-delete-apikeys',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('confirm-delete-apikeys.cancel'),
          confirm: ct.$p('confirm-delete-apikeys.confirm'),
          title: ct.$p('confirm-delete-apikeys.title'),
          message: ct.$p('confirm-delete-apikeys.message')
        }
      }
    },
    {
      type: 'Dialog',
      name: 'confirm-revoke-apikeys',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('confirm-revoke-apikeys.cancel'),
          confirm: ct.$p('confirm-revoke-apikeys.confirm'),
          title: ct.$p('confirm-revoke-apikeys.title'),
          message: ct.$p('confirm-revoke-apikeys.message')
        }
      }
    },
    {
      type: 'Dialog',
      name: 'confirm-regenerate-apikeys',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('confirm-regenerate-apikeys.cancel'),
          confirm: ct.$p('confirm-regenerate-apikeys.confirm'),
          title: ct.$p('confirm-regenerate-apikeys.title'),
          message: ct.$p('confirm-regenerate-apikeys.message')
        }
      }
    }
  ]

  return items
})
