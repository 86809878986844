import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=organization', (items) => {
  return [
    ...items,
    {
      path: 'plugins',
      name: 'plugins',
      component: () => import('@/modules/plugins/pages/PluginListing.vue')
    },
  ]
})
