import * as yup from 'yup'
import { isEmpty } from 'lodash'
import { enumMapping } from '@/modules/@global/utils/EnumMapping'

export function generateComputedPropsFromAttrs(attrs) {
  /* eslint-disable func-names */
  const computedProps = {}

  // eslint-disable-next-line no-restricted-syntax
  for (const attrName of attrs) {
    computedProps[attrName] = {
      get() {
        return this.widget.attrs && this.widget.attrs[attrName]
      },
      set(value) {
        return this.updateAttrs({ [attrName]: value })
      }
    }
  }

  return computedProps
}

export enum FIELD_TYPES {
  TEXT = 1,
  INTEGER = 2,
  DECIMAL = 3,
  DATE_TIME = 4,
  BOOLEAN = 5,
  JSON = 6,
  REFERENCE = 7,
  MEDIA = 8,
  LOCATION = 9,
  RICH_TEXT = 10,
  RICH_CONTENT = 11,
  AUTHOR = 12,
  TAG = 13,
  SECTION = 14,
  URL = 15,

  // Legacy code
  DATETIME = 4
}

export const mapFieldTypeLabel = (type) => {
  const keys = {
    [FIELD_TYPES.TEXT]: 'Text',
    [FIELD_TYPES.INTEGER]: 'Integer',
    [FIELD_TYPES.DECIMAL]: 'Decimal',
    [FIELD_TYPES.DATE_TIME]: 'DateTime',
    [FIELD_TYPES.BOOLEAN]: 'Boolean',
    [FIELD_TYPES.JSON]: 'JSON',
    [FIELD_TYPES.REFERENCE]: 'Reference',
    [FIELD_TYPES.MEDIA]: 'Media',
    [FIELD_TYPES.LOCATION]: 'Location',
    [FIELD_TYPES.RICH_TEXT]: 'Rich Text',
    [FIELD_TYPES.RICH_CONTENT]: 'Rich Content',
    [FIELD_TYPES.AUTHOR]: 'Author',
    [FIELD_TYPES.TAG]: 'Tag',
    [FIELD_TYPES.SECTION]: 'Section',
    [FIELD_TYPES.URL]: 'URL'
  }
  return keys[type]
}

export const FIELD_TYPE_MAPPING = enumMapping(FIELD_TYPES)

export enum FIELD_VALUE_TYPES {
  SINGLE = 1,
  LIST = 2
}

export const mapFieldValueTypeLabel = (type) => {
  const keys = {
    [FIELD_VALUE_TYPES.SINGLE]: 'Single',
    [FIELD_VALUE_TYPES.LIST]: 'List'
  }
  return keys[type]
}

export const FIELD_VALUE_TYPE_MAPPING = enumMapping(FIELD_VALUE_TYPES)

export const PATTERN_TYPE = Object.freeze({
  CUSTOM: 1,
  EMAIL: 2,
  URL: 3
})

export const FLAGS = Object.freeze({
  IGNORE_CASE: 'i',
  MULTI_LINE: 'm',
  SINGLE_LINE: 's',
  PATTERN_WHITESPACE: 'x',
  EXPLICIT_CAPTURE: 'n',
  CULTURE_INVARIANT: 'c',
  RIGHT_TO_LEFT: 'r',
  ECMA_SCRIPT: 'e'
})

export enum RANGE_OPERATORS {
  GTE = 1,
  LTE = 2,
  BETWEEN = 3,
  EXACTLY = 4
}

export enum FIELD_FILTER_OPERATORS {
  CONTAINS = 1,
  NOT_CONTAINS = 2,
  EQUALS = 3,
  NOT_EQUALS = 4,
  EXISTS = 5,
  NOT_EXISTS = 6
}

export const LAYOUT_BLOCKS = [
  'codex_row',
  'codex_column',
  'codex_container',
  'codex_tabs',
  'codex_tab'
]

export const MODEL_RESERVED_ALIASES = Object.freeze([
  'models',
  'model',
  'modelversions',
  'entries',
  'entry',
  'entrycollection',
  'entriescollection',
  'articles',
  'article',
  'articlelist',
  'articleslist',
  'sections',
  'section',
  'assets',
  'asset',
  'media',
  'medias',
  'system',
  'systems',
  'query',
  'queries',
  'string',
  'strings',
  'float',
  'int',
  'boolean',
  'datetime',
  'json',
  'contentblock',
  'reference',
  'references',
  'location',
  'locations',
  'gallery',
  'galleries',
  'gallerylist',
  'gallerieslist',
  'layout',
  'layouts',
  'codelet',
  'codelets',
  'theme',
  'themes',
  'webhooks',
  'webhook',
  'domain',
  'domains',
  'urls',
  'url',
  'adplacement',
  'adplacements',
  'metrics',
  'contentblockmark',
  'label',
  'id',
  'byte',
  'bytearray',
  'short',
  'long',
  'decimal',
  'date',
  'timespan',
  'uuid',
  'any',
  'mutation',
  'list',
  'tag',
  'subscription'
])

export const FIELD_RESERVED_ALIASES = Object.freeze(['id', 'system', 'attrs', 'content'])

export enum TEXT_FIELD_APPEARANCES {
  SINGLE_LINE = 1,
  MULTIPLE_LINES = 2,
  URL = 3,
  DROPDOWN = 4,
  RADIO = 5,
  SLUG = 6,
  TAG = 9,
  LIST = 10,
  CHECKBOX = 11
}

export enum WIDGET_GROUPS {
  PLUGIN = 'PLUGIN',
  BASIC = 'BASIC',
  GENERAL = 'GENERAL',
  CODEX = 'CODEX'
}

export enum BOOLEAN_FIELD_APPEARANCES {
  RADIO = 1,
  CHECKBOX = 2,
  SWITCH = 3
}

export const NUMBER_FIELD_APPEARANCES = Object.freeze({
  INPUT: 1,
  DROPDOWN: 2,
  RADIO: 3,
  RATING: 4,
  CHECKBOX: 5
})

export enum JSON_FIELD_APPEARANCES {
  JSON = 1
}

// export const assetsCountProperties = {
//   [ASSET_TYPES.IMAGE]: 'imageCount',
//   [ASSET_TYPES.VIDEO]: 'videoCount',
//   [ASSET_TYPES.FILE]: 'filesCount',
//   [ASSET_TYPES.VIDEO_PLAYLIST]: 'playlistsCount',
//   [ASSET_TYPES.AUDIO]: 'audioCount',
//   [ASSET_TYPES.PODCAST]: 'podcastCount'
// }

function getBlocks_(content, condition, blocks) {
  if (!content) return
  content.forEach((block) => {
    if (condition(block)) {
      blocks.push(block)
    }
    getBlocks_(block.content, condition, blocks)
  })
}

export function getBlocks(content, condition = () => true) {
  const blocks = []
  getBlocks_(content, condition, blocks)
  return blocks
}

export function getFieldParams(alias, type, valueType) {
  const configs = Object.freeze({
    [FIELD_TYPES.INTEGER]: {
      [FIELD_VALUE_TYPES.SINGLE]: '',
      [FIELD_VALUE_TYPES.LIST]: ''
    },
    [FIELD_TYPES.DECIMAL]: {
      [FIELD_VALUE_TYPES.SINGLE]: ' ',
      [FIELD_VALUE_TYPES.LIST]: ' '
    },
    [FIELD_TYPES.TEXT]: {
      [FIELD_VALUE_TYPES.SINGLE]: '',
      [FIELD_VALUE_TYPES.LIST]: ''
    },
    [FIELD_TYPES.MEDIA]: {
      [FIELD_VALUE_TYPES.SINGLE]: '{ id type media { ... on CodexAsset { url alt } } }',
      [FIELD_VALUE_TYPES.LIST]: '{ id type media { ... on CodexAsset { url alt } } }'
    },
    [FIELD_TYPES.RICH_TEXT]: {
      [FIELD_VALUE_TYPES.SINGLE]: ''
    },
    [FIELD_TYPES.JSON]: {
      [FIELD_VALUE_TYPES.SINGLE]: ''
    },
    [FIELD_TYPES.BOOLEAN]: {
      [FIELD_VALUE_TYPES.SINGLE]: ''
    },
    [FIELD_TYPES.LOCATION]: {
      [FIELD_VALUE_TYPES.SINGLE]: '{ address latitude longitude }'
    },
    [FIELD_TYPES.REFERENCE]: {
      [FIELD_VALUE_TYPES.SINGLE]: '{ id system { title slug model { iconId } } } ',
      [FIELD_VALUE_TYPES.LIST]: '{ items { id system { title slug model { iconId } } } } '
    },
    [FIELD_TYPES.DATE_TIME]: {
      [FIELD_VALUE_TYPES.SINGLE]: ''
    },
    [FIELD_TYPES.RICH_CONTENT]: {
      [FIELD_VALUE_TYPES.SINGLE]: '{ contentHTML text }',
      [FIELD_VALUE_TYPES.LIST]: '{ contentHTML text }'
    },
    [FIELD_TYPES.AUTHOR]: {
      [FIELD_VALUE_TYPES.SINGLE]: '{ id firstName lastName image { id alt url }  }',
      [FIELD_VALUE_TYPES.LIST]: '{ id firstName lastName image { id alt url } }'
    },
    [FIELD_TYPES.TAG]: {
      [FIELD_VALUE_TYPES.LIST]: '{ id tagAlias tagValue }'
    },
    [FIELD_TYPES.SECTION]: {
      [FIELD_VALUE_TYPES.SINGLE]: '{ id title }',
      [FIELD_VALUE_TYPES.LIST]: '{ id title }'
    },
    [FIELD_TYPES.URL]: {
      [FIELD_VALUE_TYPES.SINGLE]: ''
    }
  })
  return `${alias}  ${configs[type][valueType]}`
}

export function validateNumber(e, number, negativeNumbers = false) {
  const isBackspaceOrArrows =
    e.key === 'Backspace' ||
    e.code.includes('Arrow') ||
    e.code.includes('Tab') ||
    e.code.includes('Delete')
  if (number?.toString().length > 14 && !isBackspaceOrArrows) {
    e.preventDefault()
  }
  if (
    !/^[0-9]+$/.test(e.key) &&
    !isBackspaceOrArrows &&
    !(negativeNumbers && e.key === '-' && number >= 0)
  ) {
    e.preventDefault()
  }
}

export function errorTemplateParser(validation) {
  let text = validation.errorTemplate.replaceAll('{min}', validation.min)
  text = text.replaceAll('{max}', validation.max)
  text = text.replaceAll('{exactly}', validation.exactly)
  validation.errorMessage = text
}

export function getContentText(blocks, entry) {
  function addText(block, array) {
    if (typeof block.text === 'string') {
      array.push(block.text)
    }
    if (typeof block.content === 'object' && Array.isArray(block.content)) {
      block.content.forEach((c) => addText(c, array))
    }
  }

  const allFields = getBlocks(
    blocks,
    (block) =>
      block.isField &&
      (block.attrs.type === FIELD_TYPES.TEXT ||
        block.attrs.type === FIELD_TYPES.RICH_CONTENT ||
        block.attrs.type === FIELD_TYPES.RICH_TEXT)
  ).map((block) => ({
    alias: block.attrs.alias,
    valueType: block.attrs.valueType,
    type: block.attrs.type
  }))
  const data = []
  allFields.forEach((field) => {
    if (!entry?.content?.[field.alias]) return

    if (field.type === FIELD_TYPES.TEXT) {
      if (field.valueType === FIELD_VALUE_TYPES.SINGLE) {
        data.push(entry.content[field.alias])
      } else {
        data.push(...entry.content[field.alias])
      }
    }
    if (field.type === FIELD_TYPES.RICH_TEXT && field.valueType === FIELD_VALUE_TYPES.SINGLE) {
      const dom = document.createElement('div')
      dom.innerHTML = entry.content[field.alias]
      data.push(dom.textContent || dom.innerText || '')
    }
    if (field.type === FIELD_TYPES.RICH_CONTENT && field.valueType === FIELD_VALUE_TYPES.LIST) {
      const content = entry.content[field.alias]
      content.forEach((block) => addText(block, data))
    }
  })
  return data.join(' ')
}

export function getGeneeaContent(blocks, entry, model) {
  const content = getBlocks(
    blocks,
    (block) =>
      block.isField &&
      (block.attrs.type === FIELD_TYPES.SECTION || block.attrs.type === FIELD_TYPES.TEXT)
  ).map((block) => ({
    alias: block.attrs.alias,
    valueType: block.attrs.valueType,
    type: block.attrs.type
  }))
  const data = { sections: [], title: '' }
  content.forEach((field) => {
    if (!entry?.content?.[field.alias]) return

    if (field.type === FIELD_TYPES.SECTION) {
      if (field.valueType === FIELD_VALUE_TYPES.SINGLE) {
        data.sections.push(entry.content[field.alias])
      } else {
        data.sections.push(...entry.content[field.alias])
      }
    }
    if (field.alias === model.titleAlias) {
      data.title = entry.content[field.alias]
    }
  })
  return data
}

// Given field, generate a text with `type` and `valueType` information as string
export function generateFieldText(field) {
  const { type, valueType } = field
  const typeText = Object.keys(FIELD_TYPES).find((key) => FIELD_TYPES[key] === type)
  const valueTypeText = Object.keys(FIELD_VALUE_TYPES).find(
    (key) => FIELD_VALUE_TYPES[key] === valueType
  )

  return `${typeText} (${valueTypeText})`
}

// TODO: Move this to a separate file
export const ASSET_TYPES = Object.freeze({
  IMAGE: 'IMAGE',
  FILE: 'FILE',
  VIDEO: 'VIDEO',
  VIDEO_PLAYLIST: 'VIDEO_PLAYLIST',
  AUDIO: 'AUDIO',
  PODCAST: 'PODCAST'
})
export const ASSET_TYPE_MAPPING = enumMapping({
  [ASSET_TYPES.IMAGE]: 1,
  [ASSET_TYPES.FILE]: 2,
  [ASSET_TYPES.VIDEO]: 3,
  [ASSET_TYPES.VIDEO_PLAYLIST]: 4,
  [ASSET_TYPES.AUDIO]: 5,
  [ASSET_TYPES.PODCAST]: 6
})

/** Validate field value schema */
export async function validateFieldValueSchema(field, fieldValue) {
  const { type, valueType } = field

  const MEDIA_VALIDATION = yup
    .object()
    .shape({
      id: yup.string().required(),
      type: yup.number().oneOf(Object.values(ASSET_TYPE_MAPPING._enum)).required(),
      caption: yup.string().nullable(),
      focalPoints: yup.lazy((value) => {
        if (!isEmpty(value)) {
          const validationObject = {
            x: yup.number().min(0).max(1).required(),
            y: yup.number().min(0).max(1).required()
          }
          const newEntries = Object.keys(value).reduce(
            (acc, val) => ({
              ...acc,
              [val]: yup.object(validationObject)
            }),
            {}
          )
          return yup.object().shape(newEntries).noUnknown()
        }
        return yup.mixed().notRequired()
      })
    })
    .noUnknown()

  const ISO_VALIDATION_REGEX =
    /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/

  const schema = {
    [FIELD_TYPES.INTEGER]: {
      // Checked
      [FIELD_VALUE_TYPES.SINGLE]: yup.number().integer().nullable(),
      [FIELD_VALUE_TYPES.LIST]: yup.array().of(yup.number().integer())
    },
    [FIELD_TYPES.DECIMAL]: {
      // Checked
      [FIELD_VALUE_TYPES.SINGLE]: yup.number().nullable(),
      [FIELD_VALUE_TYPES.LIST]: yup.array().of(yup.number())
    },
    [FIELD_TYPES.TEXT]: {
      // Checked
      [FIELD_VALUE_TYPES.SINGLE]: yup.string(),
      [FIELD_VALUE_TYPES.LIST]: yup.array().of(yup.string())
    },
    [FIELD_TYPES.MEDIA]: {
      // Checked
      [FIELD_VALUE_TYPES.SINGLE]: MEDIA_VALIDATION,
      [FIELD_VALUE_TYPES.LIST]: yup.array().of(MEDIA_VALIDATION)
    },
    [FIELD_TYPES.RICH_TEXT]: {
      // Checked
      [FIELD_VALUE_TYPES.SINGLE]: yup.string()
    },
    [FIELD_TYPES.JSON]: {
      // Checked
      [FIELD_VALUE_TYPES.SINGLE]: yup.object()
    },
    [FIELD_TYPES.BOOLEAN]: {
      // Checked
      [FIELD_VALUE_TYPES.SINGLE]: yup.boolean()
    },
    [FIELD_TYPES.LOCATION]: {
      // Checked
      [FIELD_VALUE_TYPES.SINGLE]: yup
        .object()
        .shape({
          address: yup.string(),
          latitude: yup.number(),
          longitude: yup.number()
        })
        .noUnknown()
    },
    [FIELD_TYPES.REFERENCE]: {
      // Checked
      [FIELD_VALUE_TYPES.SINGLE]: yup
        .object()
        .shape({
          entryId: yup.string().required(),
          model: yup.string().required()
        })
        .noUnknown(),
      [FIELD_VALUE_TYPES.LIST]: yup.array().of(
        yup
          .object()
          .shape({
            entryId: yup.string().required(),
            model: yup.string().required()
          })
          .noUnknown()
      )
    },
    [FIELD_TYPES.DATE_TIME]: {
      // Checked
      [FIELD_VALUE_TYPES.SINGLE]: yup.string().matches(ISO_VALIDATION_REGEX),
      [FIELD_VALUE_TYPES.LIST]: yup.array().of(yup.string().matches(ISO_VALIDATION_REGEX))
    },
    [FIELD_TYPES.RICH_CONTENT]: {
      // Checked - todo: validate content
      [FIELD_VALUE_TYPES.LIST]: yup.array().of(yup.object())
    },
    [FIELD_TYPES.AUTHOR]: {
      // Checked
      [FIELD_VALUE_TYPES.SINGLE]: yup
        .object()
        .shape({
          id: yup.string()
        })
        .noUnknown(),
      [FIELD_VALUE_TYPES.LIST]: yup.array().of(
        yup
          .object()
          .shape({
            id: yup.string()
          })
          .noUnknown()
      )
    },
    [FIELD_TYPES.TAG]: {
      // Checked
      [FIELD_VALUE_TYPES.LIST]: yup.array().of(
        yup
          .object()
          .shape({
            alias: yup.string().required(),
            externalId: yup.string().nullable(),
            id: yup.string(),
            source: yup.number().integer().oneOf([0, 1, 2, 3]).required(),
            tag: yup.string().required()
          })
          .noUnknown()
      )
    },
    [FIELD_TYPES.SECTION]: {
      // Checked
      [FIELD_VALUE_TYPES.SINGLE]: yup.object().shape({
        id: yup.string().required(),
        isMain: yup.boolean().nullable()
      }),
      [FIELD_VALUE_TYPES.LIST]: yup.array().of(
        yup.object().shape({
          id: yup.string().required(),
          isMain: yup.boolean().nullable()
        })
      )
    },
    [FIELD_TYPES.URL]: {
      // Checked
      [FIELD_VALUE_TYPES.SINGLE]: yup.string().nullable()
    }
  }
  const validationSchema = schema[type][valueType]

  if (validationSchema) {
    await validationSchema.validate(fieldValue, { strict: true })
  }

  // If validation fails, yup throws an error
  return true
}

export function checkConditions(config, model, entry) {
  if (config.value && config.conditionsEnabled) {
    let result = false
    config.conditions.forEach((condition) => {
      const isNegativeOperator =
        condition.operator === FIELD_FILTER_OPERATORS.NOT_CONTAINS ||
        condition.operator === FIELD_FILTER_OPERATORS.NOT_EXISTS ||
        condition.operator === FIELD_FILTER_OPERATORS.NOT_EQUALS
      let innerResult = !!isNegativeOperator
      const conditionValue = condition.value?.toLowerCase()

      if (condition.isSystem) {
        const fieldValue = entry.system[condition.field]?.toLowerCase()
        if (isNegativeOperator) {
          innerResult =
            innerResult &&
            ((condition.operator === FIELD_FILTER_OPERATORS.NOT_EXISTS && !fieldValue) ||
              (condition.operator === FIELD_FILTER_OPERATORS.NOT_EQUALS &&
                fieldValue != conditionValue) ||
              (condition.operator === FIELD_FILTER_OPERATORS.NOT_CONTAINS &&
                !fieldValue?.includes(conditionValue)))
        } else {
          innerResult =
            innerResult ||
            (condition.operator === FIELD_FILTER_OPERATORS.EXISTS && !!fieldValue) ||
            (condition.operator === FIELD_FILTER_OPERATORS.EQUALS &&
              fieldValue == conditionValue) ||
            (condition.operator === FIELD_FILTER_OPERATORS.CONTAINS &&
              fieldValue?.includes(conditionValue))
        }
      } else {
        const field = model.fields.find((f) => f.alias === condition.field)
        if (field.valueType === FIELD_VALUE_TYPES.SINGLE) {
          const fieldValue = entry.content[condition.field]?.toLowerCase()

          if (isNegativeOperator) {
            innerResult =
              innerResult &&
              ((condition.operator === FIELD_FILTER_OPERATORS.NOT_EXISTS && !fieldValue) ||
                (condition.operator === FIELD_FILTER_OPERATORS.NOT_EQUALS &&
                  fieldValue != conditionValue) ||
                (condition.operator === FIELD_FILTER_OPERATORS.NOT_CONTAINS &&
                  !fieldValue?.includes(conditionValue)))
          } else {
            innerResult =
              innerResult ||
              (condition.operator === FIELD_FILTER_OPERATORS.EXISTS && !!fieldValue) ||
              (condition.operator === FIELD_FILTER_OPERATORS.EQUALS &&
                fieldValue == conditionValue) ||
              (condition.operator === FIELD_FILTER_OPERATORS.CONTAINS &&
                fieldValue?.includes(conditionValue))
          }
        } else {
          const fieldValue = entry.content[condition.field] || []
          fieldValue.forEach((val) => {
            const preparedValue = val?.toLowerCase()
            if (isNegativeOperator) {
              innerResult =
                innerResult &&
                ((condition.operator === FIELD_FILTER_OPERATORS.NOT_EQUALS &&
                  preparedValue != conditionValue) ||
                  (condition.operator === FIELD_FILTER_OPERATORS.NOT_EXISTS && !preparedValue) ||
                  (condition.operator === FIELD_FILTER_OPERATORS.NOT_CONTAINS &&
                    !preparedValue?.includes(conditionValue)))
            } else {
              innerResult =
                innerResult ||
                (condition.operator === FIELD_FILTER_OPERATORS.EXISTS && !!preparedValue) ||
                (condition.operator === FIELD_FILTER_OPERATORS.EQUALS &&
                  preparedValue == conditionValue) ||
                (condition.operator === FIELD_FILTER_OPERATORS.CONTAINS &&
                  preparedValue?.includes(conditionValue))
            }
          })
        }
      }
      result = result || innerResult
    })
    return result
  }
  return config.value
}
