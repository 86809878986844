// initialize apollo client with rest and graphql endpoints
// and cache implementation
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'
import createHttpLink from '@/@core/apollo/links/httpLink'
import createRestLink from '@/@core/apollo/links/restLink'
import createAuthLink from '@/@core/apollo/links/authLink'
import createErrorLink from '@/@core/apollo/links/errorLink'
import createRetryLink from '@/@core/apollo/links/retryLink'
import { CodexHooks } from '..'
import { provideApolloClients } from '@vue/apollo-composable'
import schema from '@/../schema.graphql.json'
import { createClientHttpLink } from '@/@core/apollo/links/httpLink'

const restLink = createRestLink()
const authLink = createAuthLink()
const errorLink = createErrorLink()
const retryLink = createRetryLink()

function getFragment() {
  const possibleTypes = {}

  schema.__schema.types.forEach((supertype) => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] = supertype.possibleTypes.map((subtype) => subtype.name)
    }
  })

  return possibleTypes
}

function createOrganizationClient(organizationId?: string) {
  /**
   * Wait for a given amount of time
   */
  const wait = (ms) => new Promise((res) => setTimeout(res, ms))
  const delayRefetchedQuery = async (observableQuery) => {
    await wait(1000) // 3s to make it super obvious if working or not
    observableQuery.refetch()
  }

  const _introspectionQueryResultData = getFragment()

  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: _introspectionQueryResultData
    }),
    connectToDevTools: true,
    link: ApolloLink.from([
      authLink,
      errorLink,
      restLink,
      retryLink,
      createHttpLink(organizationId)
    ]),
    defaultOptions: {
      mutate: {
        onQueryUpdated: delayRefetchedQuery
      }
    }
  })
}

function createOrganizationJobClient(organizationId?: string) {
  /**
   * Wait for a given amount of time
   */
  const wait = (ms) => new Promise((res) => setTimeout(res, ms))
  const delayRefetchedQuery = async (observableQuery) => {
    await wait(500) // 3s to make it super obvious if working or not
    observableQuery.refetch()
  }

  const _introspectionQueryResultData = getFragment()

  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: _introspectionQueryResultData
    }),
    connectToDevTools: true,
    link: ApolloLink.from([
      authLink,
      errorLink,
      restLink,
      retryLink,
      createHttpLink(organizationId, true)
    ]),
    defaultOptions: {
      mutate: {
        onQueryUpdated: delayRefetchedQuery
      }
    }
  })
}

function createFrontOrganizationClient(organizationId?: string) {
  /**
   * Wait for a given amount of time
   */
  const wait = (ms) => new Promise((res) => setTimeout(res, ms))
  const delayRefetchedQuery = async (observableQuery) => {
    await wait(500) // 3s to make it super obvious if working or not
    observableQuery.refetch()
  }

  const _introspectionQueryResultData = getFragment()

  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: _introspectionQueryResultData
    }),
    connectToDevTools: true,
    link: ApolloLink.from([
      authLink,
      errorLink,
      restLink,
      retryLink,
      createClientHttpLink(organizationId)
    ]),
    defaultOptions: {
      mutate: {
        onQueryUpdated: delayRefetchedQuery
      }
    }
  })
}

// Create the apollo client
export const client = createOrganizationClient()

export const main = new ApolloClient({
  link: authLink.concat(createHttpLink()),
  cache: new InMemoryCache()
})

export const jobs = createOrganizationJobClient()

export const front = createFrontOrganizationClient()

// Create the apollo provider
export const provider = createApolloProvider({
  clients: {
    client,
    main,
    jobs,
    front: front
  },
  defaultClient: client
})

CodexHooks.addAction('organization/beforeOrganizationIdChanged', (context, newId) => {
  const client = createOrganizationClient(newId)
  const jobs = createOrganizationJobClient(newId)
  const front = createFrontOrganizationClient(newId)
  provider.clients.client = client
  provider.clients.jobs = jobs
  provider.clients.front = front
  provider.defaultClient = client

  provideApolloClients({
    client: client,
    main: main,
    jobs: jobs,
    front: front,
    default: client
  })
})

// @ts-ignore
window.CodexApollo = provider

// Export the apollo provider
export default client
