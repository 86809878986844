import './assets/styles/style.scss'

import { createApp, provide } from 'vue'
import { ApolloClients, DefaultApolloClient, provideApolloClients } from '@vue/apollo-composable'
import { abilitiesPlugin } from '@casl/vue'
import PrimeVue from 'primevue/config'
import { installPrimeVueComponents } from '@/modules/primevue'
// @ts-ignore
import GjIcon from '@gjirafatech/gjirafa-icons/IconCdnTs.vue'

// [Hooks] Load all hooks, before anything else
await import.meta.glob([
  './@core/**/*.hooks.ts*',
  './modules/**/*.hooks.ts*'
], { eager: true })

// @ts-ignore
import App from './App.vue'

import router from '@/@core/router'
import pinia from '@/@core/store'
import i18n from '@/@core/i18n'
import {
  provider as apolloProvider,
  client as apolloClient,
  main as mainApolloClient,
  jobs as jobsApolloClient
} from '@/@core/apollo'
import ability from '@/@core/ability'

import './hooks-test'
import WhyDidYouRender from '@/mixins/why-did-you-render'
import AppLink from './modules/@core/components/AppLink.vue'

import Text from '@/modules/@global/components/Text/Text.vue'
import NoResults from '@/modules/@global/components/NoResults/NoResults.vue'
import Row from '@/modules/@global/components/Layout/Row.vue'
import Column from '@/modules/@global/components/Layout/Column.vue'
import ListingTable from '@/modules/@global/components/ListingTable/ListingTable.vue'
import ListingTreeTable from '@/modules/@global/components/ListingTreeTable/ListingTreeTable.vue'
import Filters from '@/modules/@global/components/Filters/Filters.vue'
import Search from '@/modules/@global/components/Search/Search.vue'
import DynamicForm from '@/modules/@global/components/DynamicForm/DynamicForm.vue'
import DynamicSelect from '@/modules/@global/components/DynamicSelect/DynamicSelect.vue'
import DynamicSelectMultiple from '@/modules/@global/components/DynamicSelectMultiple/DynamicSelectMultiple.vue'
import InputBase from '@/modules/@global/components/InputBase/InputBase.vue'
import { LayoutElements, LayoutElement } from './modules/@core/components/LayoutElements'
import DialogContent from '@/modules/@global/components/DialogContent/DialogContent.vue'
import SidebarContent from '@/modules/@global/components/SidebarContent/SidebarContent.vue'
import DateFormat from '@/modules/@global/components/DateFormat/DateFormat.vue'
import Checkboxes from '@/modules/@global/components/Checkboxes/Checkboxes.vue'
import ProgressSpinner from 'primevue/progressspinner'
import ContextMenu from '@/modules/primevue/components/ContextMenu/ContextMenu.vue'
import ChipGroup from '@/modules/primevue/components/ChipGropup/ChipGroup.vue'
import draggable from 'vuedraggable'
import DynamicSelectTree from '@/modules/@global/components/DynamicSelectTree/DynamicSelectTree.vue'

const app = createApp({
  setup() {
    // [Apollo] Provide for use with composition api
    provide(ApolloClients, apolloProvider)
    provide(DefaultApolloClient, apolloProvider.defaultClient)
  },
  render: () => <App />
})

// Mixins
app.mixin(WhyDidYouRender)

// [Apollo] Provide for use with options api
app.use(apolloProvider)
app.use(pinia)
app.use(i18n)
app.use(router)
app.use(PrimeVue)
app.use(abilitiesPlugin, ability, { useGlobalProperties: true })

// Components
installPrimeVueComponents(app)
app.component('GjIcon', GjIcon)
app.component('AppLink', AppLink)
app.component('Text', Text)
app.component('Row', Row)
app.component('Col', Column)
app.component('ListingTable', ListingTable)
app.component('ListingTreeTable', ListingTreeTable)
app.component('Filters', Filters)
app.component('DynamicForm', DynamicForm)
app.component('DynamicSelect', DynamicSelect)
app.component('DynamicSelectMultiple', DynamicSelectMultiple)
app.component('InputBase', InputBase)
app.component('LayoutElements', LayoutElements)
app.component('LayoutElement', LayoutElement)
app.component('NoResults', NoResults)
app.component('Search', Search)
app.component('DialogContent', DialogContent)
app.component('SidebarContent', SidebarContent)
app.component('DateFormat', DateFormat)
app.component('ProgressSpinner', ProgressSpinner)
app.component('ContextMenu', ContextMenu)
app.component('ChipGroup', ChipGroup)
app.component('Draggable', draggable)
app.component('DynamicSelectTree', DynamicSelectTree)
app.component('Checkboxes', Checkboxes)

// [Apollo] Provide for use with composition api, outside of setup()
provideApolloClients({
  client: apolloClient,
  main: mainApolloClient,
  jobs: jobsApolloClient,
  default: apolloClient
})

router.isReady().then(() => {
  app.mount('#app')
})
