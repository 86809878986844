// useFolders.ts
import { ref, computed, watch, onMounted, watchEffect } from 'vue'
import type { CodexFolder } from '@/gql/graphql'
import { useRoute, useRouter } from 'vue-router'
import { useCodexQuery } from '@/modules/@global/composables/useCodexQuery'
import { GET_FOLDER_BY_ID, LIST_FOLDERS } from '../queries/index.graphql'
import type { Context } from '@/modules/@global/composables/useContext'

// Define shared state outside the function scope
const activeFolder = ref<CodexFolder | null>(null)
const activeFolderId = ref<string | null>(null)
const foldersResult = ref<object>({})
const context = ref<Context>()
let initialLoad = true

export default function useFolders(c?: Context) {
  if (c && !context.value) context.value = c
  const route = useRoute()
  const router = useRouter()

  // Initialize active folder id from route if it's the first load
  if (route.query.folderId && !activeFolderId.value) {
    activeFolderId.value = route.query.folderId as string
  }

  watch(activeFolderId, (value) => {
    if (value) {
      router.replace({ query: { ...route.query, folderId: value } })
    } else {
      const { folderId, ...others } = route.query
      router.replace({ query: { ...others } })
    }
  })

  const { loading: foldersLoading, result } = useCodexQuery(
    LIST_FOLDERS,
    () => ({
      limit: 9999,
      parentId: {
        in: [],
        exists: false
      }
    }),
    { debounce: 100, fetchPolicy: 'cache-and-network' }
  )
  const rootFolders = computed((): CodexFolder[] => foldersResult.value?.data?.items || [])
  const foldersTotal = computed((): number => foldersResult.value?.data?.total || 0)

  watch(
    () => result.value,
    (newValue) => {
      foldersResult.value = newValue
    }
  )

  onMounted(() => {
    watchEffect(() => {
      if (initialLoad && folderResult.value?.data?.path) {
        updateActiveFolder(folderResult.value?.data)
        folderResult.value.data.path.forEach(async (p: any) => {
          loadFolderWithId(p?.id)
        })
        initialLoad = false
      }
    })
  })

  const {
    result: folderResult,
    fetchMore: folderFetchMore,
    loading: folderLoading
  } = useCodexQuery(
    GET_FOLDER_BY_ID,
    () => ({
      id: activeFolderId.value || ''
    }),
    { debounce: 100, fetchPolicy: 'cache-and-network' }
  )

  const folderPath = computed(() => {
    return activeFolder.value?.path
  })

  /*
		Folder Methods
	*/
  async function updateActiveFolderId(folderId: string | null) {
    activeFolderId.value = folderId
    const folder = await loadFolderWithId(folderId)
    updateActiveFolder(folder)
  }

  function updateActiveFolder(folder: CodexFolder) {
    activeFolder.value = folder
    addChildrenToFolderTree(folder)
  }

  async function loadFolderWithId(id: string | null) {
    const { data: fetchMoreResult } = await folderFetchMore({
      variables: { id }
    })
    addChildrenToFolderTree(fetchMoreResult?.data)
    return fetchMoreResult?.data
  }

  function addChildrenToFolderTree(folder: CodexFolder) {
    if (!folder?.children) return foldersResult.value
    const folderId = folder?.id
    const updatedFolders = addChildrenToFolder(
      foldersResult.value?.data,
      folderId,
      folder?.children.items
    )

    foldersResult.value = {
      ...foldersResult.value,
      data: { ...foldersResult.value?.data, items: updatedFolders.items }
    }
    context.value?.getRef('foldersTree')?.addExpandedKeys(folderId)

    return foldersResult.value
  }

  function addChildrenToFolder(
    folderCollection: { items: CodexFolder[] },
    folderId: string,
    newChildren: any[]
  ) {
    if (!folderCollection) return { items: [] }
    const findAndUpdateFolder = (folders: any[]): any[] => {
      return folders.map((folder) => {
        if (folder.id === folderId) {
          return { ...folder, children: { ...folder.children, items: newChildren } }
        }
        if (folder.children?.items?.length) {
          return {
            ...folder,
            children: { ...folder.children, items: findAndUpdateFolder(folder.children.items) }
          }
        }
        return folder
      })
    }
    return { ...folderCollection, items: findAndUpdateFolder(folderCollection.items) }
  }

  return {
    context,
    activeFolder,
    activeFolderId,
    foldersResult,
    folderLoading,
    foldersLoading,
    rootFolders,
    folderPath,
    foldersTotal,
    updateActiveFolder,
    updateActiveFolderId,
    loadFolderWithId,
    addChildrenToFolderTree
  }
}
