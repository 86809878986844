import {
  FIELD_FILTER_OPERATORS,
  FIELD_TYPES,
  FIELD_VALUE_TYPES
} from '@/modules/models/editor-ts/fields/constants'
import { CodexHooks } from '@/@core'
import type {
  FieldAttrsType,
  FieldConditionalValidationType
} from '@/modules/@global/models/FieldTypes'
import { isNil } from 'lodash'

const required = {
  name: 'required',
  type: FIELD_TYPES.BOOLEAN,
  valueType: [FIELD_VALUE_TYPES.SINGLE],
  component: () => import('@/modules/@global/models/field-validations/general/Required.vue'),
  attr: {
    value: false,
    errorMessage: 'Boolean is required.',
    conditionsEnabled: false,
    conditions: [
      {
        isSystem: false,
        field: '',
        operator: FIELD_FILTER_OPERATORS.EXISTS,
        value: ''
      }
    ]
  },
  validation: (
    value: boolean | null,
    attr: FieldConditionalValidationType,
    field: FieldAttrsType
  ) => {
    return attr.value && isNil(value)
      ? { isValid: false, message: attr.errorMessage }
      : { isValid: true }
  }
}

const readOnly = {
  name: 'readOnly',
  type: FIELD_TYPES.BOOLEAN,
  valueType: [FIELD_VALUE_TYPES.SINGLE, FIELD_VALUE_TYPES.LIST],
  component: () => import('@/modules/@global/models/field-validations/general/ReadOnly.vue'),
  attr: {
    value: false,
    conditionsEnabled: false,
    conditions: [
      {
        isSystem: false,
        field: '',
        operator: FIELD_FILTER_OPERATORS.EXISTS,
        value: ''
      }
    ]
  },
  validation: () => ({ isValid: true })
}

CodexHooks.addFilter('fields/validations', (items) => {
  return [...items, required, readOnly]
})
