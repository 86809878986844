<script setup lang="ts">
import PrimeVueInputNumber from 'primevue/inputnumber'
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    size?: 'small' | 'large' | null
    format: boolean
  }>(),
  {
    size: null,
    format: false
  }
)

const modelValue = ref()
const refValue = ref()

const getInputNumberClasses = computed(() => {
  return {
    [`p-inputnumber-sm`]: props.size === 'small',
    [`p-inputnumber-lg`]: props.size === 'large'
  }
})
</script>

<template>
  <PrimeVueInputNumber
    ref="refValue"
    v-model="modelValue"
    @keyup="refValue.onInputBlur($event)"
    :class="getInputNumberClasses"
    :format="format"
  ></PrimeVueInputNumber>
</template>
