import { RANGE_OPERATORS } from '@/modules/models/editor-ts/fields/constants'

export type RangeValidationType = {
  isEnabled: boolean
  rangeOperator: RANGE_OPERATORS
  min: number
  max: number
  exactly: number
  errorMessage: string
  errorTemplate: string
}

export const rangeValidation = (length: number, config: RangeValidationType) => {
  if (
    length > 0 &&
    config.isEnabled &&
    ((config.rangeOperator === RANGE_OPERATORS.GTE && length < config.min) ||
      (config.rangeOperator === RANGE_OPERATORS.LTE && length > config.max) ||
      (config.rangeOperator === RANGE_OPERATORS.BETWEEN &&
        (length < config.min || length > config.max)) ||
      (config.rangeOperator === RANGE_OPERATORS.EXACTLY && length !== config.exactly))
  ) {
    return { isValid: false, message: config.errorMessage, length: length }
  }
  return { isValid: true, length: length }
}
