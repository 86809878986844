<script setup lang="tsx">
import { computed, inject, ref } from 'vue'
import { useI18nStore } from '@/@core/i18n'
import useContext from '@/modules/@global/composables/useContext'
import SidebarContent from '@/modules/@global/components/SidebarContent/SidebarContent.vue'
import { SECTIONS_NAME } from '@/modules/sections/constants'
import { LIST_SECTIONS, LIST_SECTIONS_NESTED } from '@/modules/sections/queries/index.graphql'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context = useContext({
  name: SECTIONS_NAME,
  type: 'sidebar'
})

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.sections.popups.add-sections')
const ctListing = useI18nStore().createI18nContext('ct.sections.pages.listing')

/**
 * Dialog
 */
const dialog = inject(DialogRefKey)

async function createUrlHandler(values) {
  dialog?.value?.close(values)
}

const selectionMode = dialog?.value.options?.props?.selectionMode
const alreadySelected = dialog?.value.options?.props?.alreadySelected || []

const selected = ref([...alreadySelected])

const listingColumns = computed(() => [
  {
    field: 'title',
    header: ctListing.$t('table.columns.name'),
    expander: true,
    body: [
      {
        name: 'parent',
        element: (rowData: any) => {
          const data = rowData?.node?.data || rowData
          return (
            <span>
              {data?.title}

              {data?._query?.loading.value && (
                <ProgressSpinner
                  style="width: 14px; height: 14px; margin-left: 8px;"
                  strokeWidth="4"
                  fill="var(--surface-ground)"
                />
              )}
            </span>
          )
        }
      }
    ]
  },
  {
    header: ctListing.$t('table.columns.parent'),
    body: [
      {
        name: 'parent',
        element: (rowData: any) => {
          const data = rowData?.node?.data || rowData
          return <span>{data?.parent?.title}</span>
        }
      }
    ]
  }
])
</script>

<template>
  <div class="add-section-popup">
    <SidebarContent :title="ct.$t('title')">
      <LayoutElements class="layout-view__leaf" layout="vertical" :name="SECTIONS_NAME">
        <LayoutElement name="table" :main="true">
          <ListingTreeTable
            class="p-table-full"
            :ref="(r: any) => context.setRef('table', r)"
            :name="SECTIONS_NAME"
            :query="LIST_SECTIONS"
            :nested-query="LIST_SECTIONS_NESTED"
            :columns="listingColumns"
            :labels="{
              empty: {
                title: ct.$t('empty.title'),
                description: ct.$t('empty.description')
              }
            }"
            v-model:selection="selected"
            :selection-mode="selectionMode"
          >
            <template #emptyButtons>
              <Button icon="Plus" :label="$t('New Section')" />
            </template>
          </ListingTreeTable>
        </LayoutElement>
      </LayoutElements>
      <template #footer>
        <Button @click="createUrlHandler(selected)">{{ ct.$t('insert') }}</Button>
      </template>
    </SidebarContent>
  </div>
</template>

<style lang="scss">
.create-section-form {
  .entity-type-radiobuttons {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;

    .p-radiobutton {
      width: 100%;
    }

    .section-custom-radiobuttons {
      width: calc(50% - 8px);
      background: #f6f7f9;
      border-radius: 8px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 50px;
      }
    }

    .section-switch-button {
      background-color: red;
    }
  }
}
</style>
