import { CodexHooks } from '@/@core'

import TeamsSelect from './TeamsSelect.vue'

CodexHooks.addFilter('dynamic-form/fields', (items) => {
  return [
    ...items,
    {
      type: 'TeamsSelect',
      component: TeamsSelect
    }
  ]
})
