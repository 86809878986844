import { CodexHooks } from '@/@core'
import CreateSection from '@/modules/sections/popups/CreateSection.vue'
import UpdateSection from '@/modules/sections/popups/UpdateSection.vue'
import ConfirmDelete from '@/modules/@global/popups/ConfirmDelete.vue'
import { useI18nStore } from '@/@core/i18n'
import AddSectionPopup from '@/modules/sections/popups/AddSectionPopup.vue'

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  const ct = useI18nStore().createI18nContext('ct.sections.popups.confirm-delete-sections')

  items = [
    ...items,

    /* Create Section */
    {
      type: 'Sidebar',
      name: 'create-section',
      component: CreateSection
    },

    /* Update Section */
    {
      type: 'Sidebar',
      name: 'update-section',
      component: UpdateSection
    },
    {
      type: 'Dialog',
      name: 'confirm-delete-sections',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('cancel'),
          confirm: ct.$p('confirm'),
          title: ct.$p('title'),
          message: ct.$p('message')
        }
      }
    },
    {
      type: 'Sidebar',
      name: 'add-sections',
      component: AddSectionPopup,
      size: 'large'
    }
  ]

  return items
})
