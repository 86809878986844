import { CodexHooks } from '@/@core'
import {
  FIELD_FILTER_OPERATORS,
  FIELD_TYPES,
  FIELD_VALUE_TYPES,
  RANGE_OPERATORS
} from '@/modules/models/editor-ts/fields/constants'
import { isNumber } from 'lodash'
import {
  rangeValidation,
  type RangeValidationType
} from '@/modules/@global/models/field-validations/general/helpers'
import type {
  FieldAttrsType,
  FieldConditionalValidationType
} from '@/modules/@global/models/FieldTypes'

const requiredGenerator = (type) => ({
  name: 'required',
  type: type,
  valueType: [FIELD_VALUE_TYPES.SINGLE, FIELD_VALUE_TYPES.LIST],
  component: () => import('@/modules/@global/models/field-validations/general/Required.vue'),
  attr: {
    value: false,
    errorMessage: 'Number is required.',
    conditionsEnabled: false,
    conditions: [
      {
        isSystem: false,
        field: '',
        operator: FIELD_FILTER_OPERATORS.EXISTS,
        value: ''
      }
    ]
  },
  validation: (value: any, attr: FieldConditionalValidationType, field: FieldAttrsType) => {
    if (attr.value) {
      if (field.valueType === FIELD_VALUE_TYPES.SINGLE && !isNumber(value)) {
        return { isValid: false, message: attr.errorMessage }
      } else {
        const length = value?.length || 0
        if (length === 0) {
          return { isValid: false, message: attr.errorMessage }
        }
      }
    }

    return { isValid: true }
  }
})

const readOnlyGenerator = (type) => ({
  name: 'readOnly',
  type: type,
  valueType: [FIELD_VALUE_TYPES.SINGLE, FIELD_VALUE_TYPES.LIST],
  component: () => import('@/modules/@global/models/field-validations/general/ReadOnly.vue'),
  attr: {
    value: false,
    conditionsEnabled: false,
    conditions: [
      {
        isSystem: false,
        field: '',
        operator: FIELD_FILTER_OPERATORS.EXISTS,
        value: ''
      }
    ]
  },
  validation: (value, attrs, field) => ({ isValid: true })
})

const listRangeGenerator = (type) => ({
  name: 'listRange',
  type: type,
  valueType: [FIELD_VALUE_TYPES.LIST],
  component: () =>
    import('@/modules/@global/models/field-validations/general/ArrayLengthRange.vue'),
  attr: {
    isEnabled: false,
    rangeOperator: RANGE_OPERATORS.BETWEEN,
    min: 2,
    max: 5,
    exactly: 1,
    errorMessage: 'List length between: 2-5',
    errorTemplate: 'List length between: {min}-{max}'
  },
  validation: (value: any, config: RangeValidationType, attrs: FieldAttrsType) => {
    return rangeValidation(value?.length || 0, config)
  }
})

const uniqueInCollection = (type) => ({
  name: 'uniqueInCollection',
  type: type,
  valueType: [FIELD_VALUE_TYPES.SINGLE],
  component: () => import('@/modules/@global/models/field-validations/general/Unique.vue'),
  attr: {
    value: false,
    errorMessage: 'Number should be unique'
  },
  validation: () => ({ isValid: true })
})

const rangeGenerator = (type) => ({
  name: 'range',
  type: type,
  valueType: [FIELD_VALUE_TYPES.SINGLE, FIELD_VALUE_TYPES.LIST],
  component: () =>
    import('@/modules/@global/models/field-validations/fields/Number/SpecifyNumberRange.vue'),
  attr: {
    isEnabled: false,
    rangeOperator: RANGE_OPERATORS.BETWEEN,
    min: 2,
    max: 10,
    exactly: 1,
    errorMessage: 'Number between: 2-10',
    errorTemplate: 'Number between: {min}-{max}'
  },
  validation: (value, attrs: RangeValidationType, field: FieldAttrsType) => {
    if (attrs.isEnabled) {
      if (field.valueType === FIELD_VALUE_TYPES.SINGLE) {
        if (value != null && value != undefined) {
          if (
            attrs.rangeOperator === RANGE_OPERATORS.BETWEEN &&
            (value < attrs.min || value > attrs.max)
          ) {
            return { isValid: false, message: attrs.errorMessage }
          }
          if (attrs.rangeOperator === RANGE_OPERATORS.GTE && attrs.min > value) {
            return { isValid: false, message: attrs.errorMessage }
          }
          if (attrs.rangeOperator === RANGE_OPERATORS.LTE && attrs.max < value) {
            return { isValid: false, message: attrs.errorMessage }
          }
        }
      } else {
        const indexes = []
        for (let i = 0; i < value.length; i++) {
          const numberValue = value[i]
          if (
            attrs.rangeOperator === RANGE_OPERATORS.BETWEEN &&
            (numberValue < attrs.min || numberValue > attrs.max)
          ) {
            indexes.push(i)
          }
          if (attrs.rangeOperator === RANGE_OPERATORS.GTE && attrs.min > numberValue) {
            indexes.push(i)
          }
          if (attrs.rangeOperator === RANGE_OPERATORS.LTE && attrs.max < numberValue) {
            indexes.push(i)
          }
        }
        if (indexes.length > 0) {
          return { isValid: false, message: attrs.errorMessage, indexes }
        }
      }
    }

    return { isValid: true }
  }
})

CodexHooks.addFilter('fields/validations', (items) => {
  return [
    ...items,
    requiredGenerator(FIELD_TYPES.INTEGER),
    requiredGenerator(FIELD_TYPES.DECIMAL),

    readOnlyGenerator(FIELD_TYPES.INTEGER),
    readOnlyGenerator(FIELD_TYPES.DECIMAL),

    uniqueInCollection(FIELD_TYPES.INTEGER),
    uniqueInCollection(FIELD_TYPES.DECIMAL),

    rangeGenerator(FIELD_TYPES.INTEGER),
    rangeGenerator(FIELD_TYPES.DECIMAL),

    listRangeGenerator(FIELD_TYPES.INTEGER),
    listRangeGenerator(FIELD_TYPES.DECIMAL)
  ]
})
