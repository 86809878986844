import { CodexHooks } from '@/@core'
import SelectParent from '../components/SelectParent.vue'

CodexHooks.addFilter('dynamic-form/fields', (items) => {
  return [
    ...items,
    {
      type: 'SelectSectionParent',
      component: SelectParent
    }
  ]
})
