import { CodexHooks } from '@/@core'
import { isString } from 'lodash'
import { useSite } from '@/modules/sites/store/site'
import { URL_ENTITY_TYPE_MAPPING } from '../constants'
import { CodexUrlEntityType } from '@/gql/graphql'

CodexHooks.addFilter('apollo/restFetchOptions?type=CodexUrl', (options, context, uri, headers) => {
  if (isString(options.body)) {
    try {
      const bodyParsed = JSON.parse(options.body)
      bodyParsed.siteId = useSite().siteId

      bodyParsed.entityType = bodyParsed.redirectState
        ? CodexUrlEntityType.Redirect
        : bodyParsed.entityType
      bodyParsed.entityType = URL_ENTITY_TYPE_MAPPING.toNumber(bodyParsed.entityType)

      options.body = JSON.stringify(bodyParsed)
    } catch (e) {
      // Do nothing
    }
  }

  return options
})

CodexHooks.addFilter('apollo/responseTransformer?type=CodexUrl', (response) => {
  response.entityType = URL_ENTITY_TYPE_MAPPING.toString(response.entityType)
  return response
})
