import { CodexHooks } from '@/@core'

import SelectParentId from '../components/SelectParentId.vue'

CodexHooks.addFilter('dynamic-form/fields', (items) => {
  return [
    ...items,
    {
      type: 'SelectParentId',
      component: SelectParentId
    }
  ]
})
