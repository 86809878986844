import { CodexHooks } from '@/@core'
import { generateNavigationItem } from '@/@core/navigation'
import { useOrganization } from '@/modules/organizations/store/organization'
import AppLink from '@/modules/@core/components/AppLink.vue'
import { useRoute } from '@/@core/router'


CodexHooks.addFilter('navigation/items?parent=null', (items) => {
  const route = useRoute()

  // if route or any parent route is not design-system, return original items
  if (route?.value?.path?.indexOf('design-system') === -1) return items

  return [
    generateNavigationItem({
      name: 'ds-rich-content',
      title: 'Rich Content',
      route: { name: 'ds-rich-content', params: { organizationId: useOrganization().organizationId }}
    }),
    generateNavigationItem({
      name: 'ds-alerts',
      title: 'Alerts',
      route: { name: 'ds-alerts', params: { organizationId: useOrganization().organizationId }}
    }),
    generateNavigationItem({
      name: 'ds-buttons',
      title: 'Buttons',
      route: { name: 'ds-buttons', params: { organizationId: useOrganization().organizationId }}
    }),
    generateNavigationItem({
      name: 'ds-chips-listing',
      title: 'Chips Listing',
      route: { name: 'ds-chips-listing', params: { organizationId: useOrganization().organizationId }}
    }),
    generateNavigationItem({
      name: 'ds-data-tables',
      title: 'DataTable & Pagination',
      route: { name: 'ds-data-tables', params: { organizationId: useOrganization().organizationId }}
    }),
    generateNavigationItem({
      name: 'ds-dialogs',
      title: 'Dialogs & Sidebars',
      route: { name: 'ds-dialogs', params: { organizationId: useOrganization().organizationId }}
    }),
    generateNavigationItem({
      name: 'ds-dynamic-forms',
      title: 'Dynamic Forms',
      route: { name: 'ds-dynamic-forms', params: { organizationId: useOrganization().organizationId }}
    }),
    generateNavigationItem({
      name: 'ds-dynamic-selects',
      title: 'Dynamic Selects',
      route: { name: 'ds-dynamic-selects', params: { organizationId: useOrganization().organizationId }}
    }),
    generateNavigationItem({
      name: 'ds-filters',
      title: 'Filters',
      route: { name: 'ds-filters', params: { organizationId: useOrganization().organizationId }}
    }),
    generateNavigationItem({
      name: 'ds-forms',
      title: 'Forms',
      route: { name: 'ds-forms', params: { organizationId: useOrganization().organizationId }}
    }),
    generateNavigationItem({
      name: 'ds-grid-system',
      title: 'Grid System',
      route: { name: 'ds-grid-system', params: { organizationId: useOrganization().organizationId }}
    }),
    generateNavigationItem({
      name: 'ds-responsive',
      title: 'Responsive',
      route: { name: 'ds-responsive', params: { organizationId: useOrganization().organizationId }}
    }),
    generateNavigationItem({
      name: 'ds-tabs',
      title: 'Tabs',
      route: { name: 'ds-tabs', params: { organizationId: useOrganization().organizationId }}
    }),
    generateNavigationItem({
      name: 'ds-tags',
      title: 'Tags',
      route: { name: 'ds-tags', params: { organizationId: useOrganization().organizationId }}
    }),
    generateNavigationItem({
      name: 'ds-texts',
      title: 'Texts',
      route: { name: 'ds-texts', params: { organizationId: useOrganization().organizationId }}
    }),
    generateNavigationItem({
      name: 'ds-tiered-menus',
      title: 'Tiered Menus',
      route: { name: 'ds-tiered-menus', params: { organizationId: useOrganization().organizationId }}
    })
  ]
})

CodexHooks.addFilter('layout/elements?name=topbar', (items) => {
  return [
    ...items,
    {
      name: 'organization',
      element: (
        <div>
          <AppLink
            to={{
              name: 'design-system',
              params: { organizationId: useOrganization().organizationId || 'codex-dev' }
            }}
          >
            Design System
          </AppLink>
        </div>
      )
    }
  ]
})
