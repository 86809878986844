import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function someParentHasClass(element, classname) {
  if (
    element.className &&
    element.className.constructor === String &&
    element.className.split(' ').indexOf(classname) >= 0
  )
    return element
  return element.parentNode && someParentHasClass(element.parentNode, classname)
}

export function moveInArray(arr, from, to) {
  // Make sure a valid array is provided
  if (Object.prototype.toString.call(arr) !== '[object Array]') {
    throw new Error('Please provide a valid array')
  }

  // Delete the item from it's current position
  const item = arr.splice(from, 1)

  // Make sure there's an item to move
  if (!item.length) {
    throw new Error(`There is no item in the array at index ${from}`)
  }

  // Move the item to its new position
  arr.splice(to, 0, item[0])

  return arr
}

// Email validator

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

// Generate logo

export function getLogo(url, full = true) {
  if (!url) return null

  if (url.startsWith('ast_')) {
    url = url.replace('ast_', '').split('-')
  } else {
    url = url.slice(2).split('-')
  }

  const suffixMapping = {
    j: 'jpg',
    je: 'jpeg',
    p: 'png',
    s: 'svg',
    w: 'web',
    wp: 'webp',
    i: 'ico',
    g: 'gif',
    t: 'tif',
    tf: 'tiff',
    m4: 'mp4',
    a: 'avi',
    m: 'mov',
    mg: 'mpeg',
    wm: 'webm'
  }

  const fileName = url[0]
  const suffix = suffixMapping[url[1]]

  return full
    ? `https://noah.gjirafa.com/codex/images/${fileName}.${suffix}`
    : `/codex/images/${fileName}.${suffix}`
}

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const fileToText = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export function checkPath(path) {
  return path && path.includes('/codex') ? path : `/codex${path}`
}

export function toSlugV2(text, separator) {
  text = text.toString().toLowerCase().trim()

  const sets = [
    { to: 'a', from: '[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
    { to: 'c', from: '[ÇĆĈČ]' },
    { to: 'd', from: '[ÐĎĐÞ]' },
    { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
    { to: 'g', from: '[ĜĞĢǴ]' },
    { to: 'h', from: '[ĤḦ]' },
    { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
    { to: 'j', from: '[Ĵ]' },
    { to: 'ij', from: '[Ĳ]' },
    { to: 'k', from: '[Ķ]' },
    { to: 'l', from: '[ĹĻĽŁ]' },
    { to: 'm', from: '[Ḿ]' },
    { to: 'n', from: '[ÑŃŅŇ]' },
    { to: 'o', from: '[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
    { to: 'oe', from: '[Œ]' },
    { to: 'p', from: '[ṕ]' },
    { to: 'r', from: '[ŔŖŘ]' },
    { to: 's', from: '[ßŚŜŞŠ]' },
    { to: 't', from: '[ŢŤ]' },
    { to: 'u', from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
    { to: 'w', from: '[ẂŴẀẄ]' },
    { to: 'x', from: '[ẍ]' },
    { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
    { to: 'z', from: '[ŹŻŽ]' },
    { to: '-', from: "[·/_,:;']" }
  ]

  sets.forEach((set) => {
    text = text.replace(new RegExp(set.from, 'gi'), set.to)
  })
  text = text
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text

  if (typeof separator !== 'undefined' && separator !== '-') {
    text = text.replace(/-/g, separator)
  }

  return text
}

export function timeDuration(value) {
  const duration = dayjs.duration(value, 'seconds')
  const hours = `${duration.hours() < 10 ? '0' : ''}${duration.hours()}`
  const minutes = `${duration.minutes() < 10 ? '0' : ''}${duration.minutes()}`
  const seconds = `${duration.seconds() < 10 ? '0' : ''}${duration.seconds()}`
  return duration.hours() > 0 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`
}

export function toCamelize(text) {
  text = text.toString().trim()
  return text
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, '')
}

export function isValidAlias(value) {
  const regex = /^[a-zA-Z][a-zA-Z0-9]*$/
  return !!(value && value.match(regex))
}

export function flatten(arr, key, result = []) {
  for (let i = 0; i < arr.length; i++) {
    result.push(arr[i])
    if (arr[i][key]) {
      flatten(arr[i][key], key, result)
    }
  }
  return result
}

export function isNumeric(n) {
  return !Number.isNaN(n)
}

export function isValidURL(url) {
  try {
    // eslint-disable-next-line no-new
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

export function bytesToSize(bytes, digits = 0) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  // eslint-disable-next-line radix
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  // eslint-disable-next-line no-restricted-properties
  return `${(bytes / Math.pow(1024, i)).toFixed(digits)} ${sizes[i]}`
}

export function numberWithThousandsCommas(value) {
  return new Intl.NumberFormat('en-US').format(value)
}

export function downloadAsset(asset) {
  const xhr = new XMLHttpRequest()
  xhr.onload = () => {
    const reader = new FileReader()
    reader.onloadend = () => {
      // Function to download sset with base64
      const link = document.createElement('a')
      link.setAttribute(
        'download',
        asset.title ? `${asset.title}.${asset.fileName.split('.').pop()}` : asset.fileName
      )
      link.href = reader.result
      this.imageUrl = reader.result
      link.click()
    }
    reader.readAsDataURL(xhr.response)
  }

  try {
    let url = asset.originalUrl
    if (!url) {
      const urlObj = new URL(asset.url)
      urlObj.search = ''
      url = urlObj.toString()
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  } catch (e) {
    console.log(e)
  }
}

export function getEntryModel(entry) {
  return entry?.system?.model || { iconId: 'Modeling', name: '' }
}

export function getFieldPermissions(canFields, model) {
  const fields = {}
  model.fields.forEach((field) => {
    fields[field.alias] = !canFields?.fields?.allowed
  })

  if (canFields?.fields?.allowed) {
    canFields.fields.allowed.forEach((field) => {
      if (field === '*') {
        Object.keys(fields).forEach((key) => {
          fields[key] = true
        })
      } else {
        fields[field] = true
      }
    })
  }

  if (canFields?.fields?.denied) {
    canFields.fields.denied.forEach((field) => {
      if (field === '*') {
        Object.keys(fields).forEach((key) => {
          fields[key] = false
        })
      } else {
        fields[field] = false
      }
    })
  }

  return fields
}

export function secondsToHMS(seconds) {
  const hours = Math.floor(seconds / 3600)
  const remainingSeconds = seconds % 3600
  const minutes = Math.floor(remainingSeconds / 60)
  const remainingSecondsFinal = remainingSeconds % 60

  return {
    hours,
    minutes,
    seconds: remainingSecondsFinal
  }
}

export function toLowerFirstCharObjectKeys(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  const newObj = Array.isArray(obj) ? [] : {}

  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const newKey = key.charAt(0).toLowerCase() + key.slice(1)
      newObj[newKey] = toLowerFirstCharObjectKeys(obj[key]) // Recursively apply to nested objects
    }
  }

  return newObj
}

export function capitalizeObjectKeys(obj) {
  // Check if obj is an object
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  // If obj is an array, capitalize keys for each element
  if (Array.isArray(obj)) {
    return obj.map((item) => capitalizeObjectKeys(item))
  }

  // Create a new object to store capitalized keys
  const newObj = {}

  // Iterate over each key-value pair in the object
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // Check if the property is a direct property of the object (not inherited)
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      // Capitalize the key
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1)
      // Recursively capitalize keys of nested objects
      newObj[capitalizedKey] = capitalizeObjectKeys(obj[key])
    }
  }

  return newObj
}

export function flattenObject(obj, prefix = '') {
  return Object.entries(obj).flatMap(([k, v]) =>
    typeof v === 'object' && v !== null && !Array.isArray(v)
      ? flattenObject(v, `${prefix}${k}.`)
      : [[`${prefix}${k}`, v]]
  )
}

export function expandToObject(key, value) {
  const obj = {}
  let pointer = obj
  key?.split('.').map((key, index, arr) => {
    pointer = pointer[key] = index === arr.length - 1 ? value : {}
  })
  return obj
}
