<script setup lang="ts">
import Chip from 'primevue/chip'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    rounded?: boolean
    removable?: boolean
  }>(),
  {
    rounded: false,
    removable: false
  }
)

const chipClasses = computed(() => {
  return [
    {
      'p-chip-rounded': props.rounded,
      'p-chip-removable': props.removable
    }
  ]
})
</script>

<template>
  <Chip :class="chipClasses" :removable="removable">
    <slot></slot>
    <template #removeicon="{ onClick, onKeydown }">
      <GjIcon size="14" tabindex="0" :name="$attrs.removeIcon || 'Close'" @click="onClick" @keydown="onKeydown" />
    </template>

    <!-- Implement GjIcon -->
    <template #icon="scope" v-if="$attrs.icon">
      <GjIcon v-bind="scope" :name="$attrs.icon" :class="$attrs.iconClass" size="24" />
    </template>
  </Chip>
</template>

<style scoped></style>
