import { CodexHooks } from '@/@core'
import EntryUnpublishPopup from '@/modules/entries/components/EntryUnpublish/EntryUnpublishPopup.vue'

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  return [
    ...items,
    {
      type: 'Dialog',
      name: 'entry-unpublish',
      component: EntryUnpublishPopup,
      size: 'medium'
    }
  ]
})
