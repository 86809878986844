import {
  FIELD_FILTER_OPERATORS,
  FIELD_TYPES,
  FIELD_VALUE_TYPES,
  RANGE_OPERATORS
} from '@/modules/models/editor-ts/fields/constants'
import { CodexHooks } from '@/@core'
import type { FieldConditionalValidationType } from '@/modules/@global/models/FieldTypes'
import {
  rangeValidation,
  type RangeValidationType
} from '@/modules/@global/models/field-validations/general/helpers'
import { isNil } from 'lodash'

const required = {
  name: 'required',
  type: FIELD_TYPES.JSON,
  valueType: [FIELD_VALUE_TYPES.SINGLE],
  component: () => import('@/modules/@global/models/field-validations/general/Required.vue'),
  attr: {
    isEnabled: false,
    value: false,
    errorMessage: 'JSON is required.',
    conditionsEnabled: false,
    conditions: [
      {
        isSystem: false,
        field: '',
        operator: FIELD_FILTER_OPERATORS.EXISTS,
        value: ''
      }
    ]
  },
  validation: (value: string | null, attr: FieldConditionalValidationType) => {
    const length =
      value && typeof value == 'object' ? JSON.stringify(value)?.length : value?.length || 0
    return attr.value && length < 0
      ? { isValid: false, message: attr.errorMessage }
      : { isValid: true }
  }
}

const readOnly = {
  name: 'readOnly',
  type: FIELD_TYPES.JSON,
  valueType: [FIELD_VALUE_TYPES.SINGLE],
  component: () => import('@/modules/@global/models/field-validations/general/ReadOnly.vue'),
  attr: {
    isEnabled: false,
    value: false,
    conditionsEnabled: false,
    conditions: [
      {
        isSystem: false,
        field: '',
        operator: FIELD_FILTER_OPERATORS.EXISTS,
        value: ''
      }
    ]
  },
  validation: () => ({ isValid: true })
}

//not being used, commented for now
// const invalidJSON = {
//   name: 'invalidJSON',
//   type: FIELD_TYPES.JSON,
//   valueType: [FIELD_VALUE_TYPES.SINGLE],
//   component: () => import('./InvalidJSON.vue'),
//   attr: 'Invalid JSON',
//   validation: (value: object, errorMessage: any) => {
//     try {
//       if (typeof value === 'object') return { isValid: true }
//       if (value) {
//         JSON.parse(value)
//         return { isValid: true }
//       }
//     } catch (error) {
//       return { isValid: false, message: errorMessage }
//     }
//   }
// }

const numberOfProperties = {
  name: 'numberOfProperties',
  type: FIELD_TYPES.JSON,
  valueType: [FIELD_VALUE_TYPES.SINGLE],
  component: () => import('./NumberOfProperties.vue'),
  attr: {
    isEnabled: false,
    rangeOperator: RANGE_OPERATORS.BETWEEN,
    min: 1,
    max: 2,
    exactly: 0,
    errorTemplate: 'Number of properties should be {min}-{max}',
    errorMessage: 'Number of properties should be 1-2'
  },
  validation: (value: any, config: RangeValidationType) => {
    try {
      const parsedObject = typeof value === 'string' ? JSON.parse(value) : value
      const propertyCount = Object.keys(parsedObject).length
      return rangeValidation(propertyCount, config)
    } catch (e) {
      return { isValid: true }
    }
  }
}

CodexHooks.addFilter('fields/validations', (items) => {
  return [...items, required, readOnly, numberOfProperties]
})
