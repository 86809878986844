<script setup lang="ts">
import Tag from 'primevue/tag'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    size?: 'small'
    indicator?: boolean
    icon?: string
  }>(),
  {
    indicator: false,
    icon: null
  }
)

const getTagClasses = computed(() => {
  return {
    [`p-tag-sm`]: props.size === 'small'
  }
})
</script>

<template>
  <Tag :class="getTagClasses" v-bind="$attrs">
    <template #icon>
      <GjIcon v-if="props.icon" :name="props.icon" :key="props.icon" size="16" />
      <span v-else-if="props.indicator" class="p-tag-indicator"></span>
    </template>
    <slot></slot>
  </Tag>
</template>

<style scoped></style>
