import { loadLocaleMessages } from './locales'
import { locales as _locales } from './constants'

import type { Locales } from './constants'
import { createI18n, useI18n } from 'vue-i18n'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { CodexDebug, CodexHooks } from '@/@core'
import dayjs from 'dayjs'

// create i18n instance
const i18n = createI18n<[any], Locales, false>({
  legacy: false,
  locale: 'en',
  fallbackWarn: CodexDebug.i18nEnabled,
  missingWarn: false,
  missing: (locale, key, instance, type) => {
    CodexDebug.i18nWarn(`Missing locale message: ${key}`, { locale, key, instance, type })

    const showMissingKey = CodexHooks.applyFilters(
      'i18n/showMissingKey',
      true,
      null,
      key,
      locale,
      instance,
      type
    )

    // handle translation missing
    return CodexHooks.applyFilters(
      'i18n/missing',
      showMissingKey ? key : '',
      null,
      key,
      locale,
      instance,
      type
    )
  }
})

// Load locale messages
loadLocaleMessages(_locales, i18n)

export const useI18nStore = defineStore('i18n', () => {
  const locales: any = computed(() => _locales)
  const { t: $t, rt: $tc, te: $te } = useI18n()

  const enLang = import.meta.glob('@/../node_modules/dayjs/locale/en.js')
  const frLang = import.meta.glob('@/../node_modules/dayjs/locale/fr.js')
  const csLang = import.meta.glob('@/../node_modules/dayjs/locale/cs.js')
  const sqLang = import.meta.glob('@/../node_modules/dayjs/locale/sq.js')

  const locale = computed({
    // @ts-ignore
    get() {
      return i18n.global.locale.value
    },
    async set(locale: Locales) {
      try {
        CodexDebug.i18nInfo(`Setting locale: ${locale}`)

        // ToDo: Remove this hack
        // @ts-ignore
        const originalModule = window.module
        // @ts-ignore
        const originalExports = window.exports
        // @ts-ignore
        const originalRequire = window.require

        // @ts-ignore
        window.module = { exports: {} }
        // @ts-ignore
        window.exports = {}
        // @ts-ignore
        window.require = (e) => {
          if (e === 'dayjs') {
            return dayjs
          }
          return originalRequire(e)
        }

        if (locale === 'en') {
          await enLang()
        } else if (locale === 'fr') {
          await frLang()
        } else if (locale === 'cs') {
          await csLang()
        } else {
          await sqLang()
        }

        dayjs.locale(locale)

        // @ts-ignore
        window.module = originalModule
        // @ts-ignore
        window.exports = originalExports
        // @ts-ignore
        window.require = originalRequire

        // [I18n Config Hook] On set locale
        CodexHooks.applyActions('i18n/setLocale', null, locale)

        i18n.global.locale.value = locale
        return locale
      } catch (e) {
        CodexDebug.i18nError(`Failed to set locale: ${locale}`, e)
      }
    }
  })

  function createI18nContext(basePath: string) {
    return {
      $p(path: string, ...args: any[]) {
        if (!basePath) return `${path}`
        return `${basePath}.${path}`
      },
      $t(path: string, ...args: any[]) {
        if (!basePath) return $t(`${path}`, ...args)
        return $t(`${basePath}.${path}`, ...args)
      },
      $tc(path: string, ...args: any[]) {
        if (!basePath) return $tc(`${path}`, ...args)
        return $tc(`${basePath}.${path}`, ...args)
      },
      $te(path: string) {
        if (!basePath) return $te(`${path}`)
        return $te(`${basePath}.${path}`)
      },
      d$t(_default: string, path: string, ...args: any[]) {
        if (_default && !_default.startsWith('ct.')) return _default

        if (_default) {
          const translation = $t(`${_default}`, ...args)
          if (translation && !translation.startsWith('ct.')) return translation
        }

        if (!basePath) return $t(`${path}`, ...args)
        return $t(`${basePath}.${path}`, ...args)
      },
      d$tc(_default: string, path: string, ...args: any[]) {
        if (_default && !_default.startsWith('ct.')) return _default

        if (_default) {
          const translation = $t(`${_default}`, ...args)
          if (translation && !translation.startsWith('ct.')) return translation
        }

        if (!basePath) return $t(`${path}`, ...args)
        return $t(`${basePath}.${path}`, ...args)
      }
    }
  }

  return { locales: locales.value, locale, createI18nContext }
})

// export i18n instance
export default i18n
