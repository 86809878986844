import { CodexHooks } from '@/@core'
import EventFilter from './events/EventFilter'
import StatusFilter from './status/StatusFilter'

CodexHooks.addFilter('filters/types', (items) => {
  return [
    ...items,
    {
      type: 'workflows-event',
      component: EventFilter
    },
    {
      type: 'workflows-status',
      component: StatusFilter
    }
  ]
})
