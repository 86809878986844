<script setup lang="ts">
import { inject } from 'vue'
import { useI18nStore } from '@/@core/i18n'
import DialogContent from '@/modules/@global/components/DialogContent/DialogContent.vue'
import type { Context } from '@/modules/@global/composables/useContext'
import { useToast } from 'primevue/usetoast'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.apikeys.popups.apikey-response')
const toast = useToast()
/**
 * Dialog
 */
const dialog = inject(DialogRefKey)
const data = { ...dialog?.value?.data }

const copy = async () => {
  await navigator.clipboard.writeText(data.apiKey)
  toast.add({ severity: 'success', summary: ct.$t('copied-successfully'), life: 3000 })
}
</script>

<template>
  <DialogContent :title="ct.$t('success-message')">
    <!-- TODO: update UI -->
    <Text class="apikey" type="body-regular-base">{{ data.apiKey }}</Text>
    <InlineMessage :plain-text="true" severity="warn" :text="ct.$t('save-apikey-warning')" />
    <template #footer>
      <Button size="small" icon="Copy" :label="ct.$t('copy')" @click="copy" severity="primary" />
    </template>
  </DialogContent>
</template>

<style scoped>
.apikey {
  min-height: 50px !important;
  padding: 15px;
  background: #00000050;
  color: black;
  border-radius: 5px;
}
</style>
