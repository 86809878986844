import { v4 as uuidv4 } from 'uuid'
export const GenerateUUID = {
  ARTICLE: () => `ar${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  BLOCK: () => `bl${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  QUERY: () => `qu${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  GENERAL: () => `ge${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  ARTICLE_FILTER: () => `af${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  ARTICLE_LIST: () => `al${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  ARTICLE_VERSION: () => `av${uuidv4().replaceAll('-', '').substr(0, 8)}`,
  BREAKING_NEWS_VERSION: () => `bv${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  GALLERY: () => `ga${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  LAYOUT: () => `la${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  LAYOUT_VERSION: () => `lv${uuidv4().replaceAll('-', '').substr(0, 8)}`,
  LABEL: () => `lb${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  ORGANIZATION: () => `or${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  ROLE: () => `ro${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  SECTION: () => `se${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  SITE: () => `si${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  URL_RECORD: () => `ur${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  USER_INVITATION: () => `ui${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  USER: () => `us${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  AUTHOR: () => `at${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  SECTION_SCRIPT: () => `ss${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  API_KEY: () => `ak${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  TAG: () => `tg${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  ASSET: () => `as${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  FOLDER: () => `fl${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  THEME: () => `th${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  MODEL: () => `ml${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  ENTRY: () => `me${uuidv4().replaceAll('-', '').substr(0, 20)}`,
  VERSION: () => `ve${uuidv4().replaceAll('-', '').substr(0, 8)}`
}
