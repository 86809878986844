import ResizeFilterComponent from './Resize.vue'
import { useI18nStore } from '@/@core/i18n'
import { CodexPresetResize } from '@/gql/graphql'

export default class ResizeFilter {
  static COMPONENT = ResizeFilterComponent
  static OPERATORS: {
    include: string
    exclude: string
  } = {
    include: 'in',
    exclude: 'notIn',
  }

  static type: string = 'preset-resize'

  operator: string | null = null

  value: Array<CodexPresetResize> | null = null

  constructor(
    {
      operator = ResizeFilter.OPERATORS.include,
      value = [],
    }: { operator: string; value?: Array<CodexPresetResize>; } = {
      operator: ResizeFilter.OPERATORS.include
    }
  ) {
    this.operator = operator
    this.value = value
  }
  set(
    {
      operator = ResizeFilter.OPERATORS.include,
      value = [],
    }: { operator: string; value?: Array<CodexPresetResize>; } = {
      operator: ResizeFilter.OPERATORS.include
    }
  ) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value?.length
  }

  getValueLabel(): string | null {
		const ct = useI18nStore().createI18nContext('ct.presets')

    switch (this.operator) {
      case ResizeFilter.OPERATORS.include:
        return ct.$t('filters.resize.active-label.is', {value: ct.$t(`general.enums.resize.${this.value?.[0]}`)})
      case ResizeFilter.OPERATORS.exclude:
        return ct.$t('filters.resize.active-label.is-not', {value: ct.$t(`general.enums.resize.${this.value?.[0]}`)})
      default:
        return null
    }
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case ResizeFilter.OPERATORS.include:
        return {
          in: this.value
        }
      case ResizeFilter.OPERATORS.exclude:
        return {
          notIn: this.value
        }
      default:
        return null
    }
  }

  static validate({ operator, value }: { operator: string; value?: Array<CodexPresetResize> }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (value?.length === 0) {
      return 'Value is required'
    }

    return true
  }
  static fromQueryParam({
    operator,
    value
  }: {
    operator: string
    value?: Array<CodexPresetResize>
  }) {
    return new ResizeFilter({
      operator,
      value
    })
  }
}
