import gql from 'graphql-tag'

export const PUBLISHABLE_MODEL_INPUT = gql`
  fragment PublishableModelInput on CodexModel {
    alias
    blocks
    configurations
    createdAt
    createdBy
    deletedBlocks
    deletedFields
    description
    externalId
    featuredMediaAlias
    fields
    iconId
    id
    name
    normalizedDescription
    normalizedName
    restrictions
    revisionId
    slugAlias
    status
    titleAlias
    updatedAt
    updatedBy
    versionId
    defaultFolderId
  }
`

export const READ_MODEL = gql`
  ${PUBLISHABLE_MODEL_INPUT}

  query ReadModel($id: String!) {
    model(id: $id)
      @rest(type: "CodexModel", path: "organizationId/models/{args.id}", method: "GET") {
      ...PublishableModelInput
    }
  }
`

export const CREATE_MODEL = gql`
  ${PUBLISHABLE_MODEL_INPUT}

  mutation CreateModel($input: PublishableModelInput!) {
    createModel(input: $input)
      @rest(type: "CodexModel", path: "organizationId/models", method: "POST") {
      ...PublishableModelInput
    }
  }
`

export const UPDATE_MODEL = gql`
  ${PUBLISHABLE_MODEL_INPUT}

  mutation UpdateModel($input: PublishableModelInput!) {
    updateModel(input: $input)
      @rest(type: "CodexModel", path: "organizationId/models/{args.input.id}", method: "PUT") {
      ...PublishableModelInput
    }
  }
`

export const DELETE_MODEL = gql`
  ${PUBLISHABLE_MODEL_INPUT}

  mutation DeleteModel($id: String!, $input: PublishableModelInput!) {
    deleteModel(id: $id, input: $input)
      @rest(type: "CodexModel", path: "organizationId/models/{args.id}", method: "DELETE") {
      NoResponse
    }
  }
`
