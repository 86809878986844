import { CodexHooks } from '@/@core'
import { GenerateUUID } from '@/modules/@global/utils/GenerateUUID'

// TipTap Extensions
import Focus from '@tiptap/extension-focus'
import UniqueID from '@tiptap-pro/extension-unique-id'
import Dropcursor from '@tiptap/extension-dropcursor'
import Gapcursor from '@tiptap/extension-gapcursor'
import History from '@tiptap/extension-history'
import TextAlign from '@tiptap/extension-text-align'

// Custom Extensions
import Commands from '@/modules/@global/components/RichContent/extensions/Commands'
import Selections from '@/modules/@global/components/RichContent/extensions/Selections'
import suggestion from '@/modules/@global/components/RichContent/slash-commands/suggestion'
import { SelectedClasses } from '@/modules/@global/components/RichContent/extensions/Selected'

CodexHooks.addFilter('rich-content/extensions', (extensions, context) => {
  const nodes = CodexHooks.applyFilters('rich-content/nodes', [], null)

  extensions = [
    ...extensions,

    /**
     * Focus
     */
    Focus.configure({
      mode: 'all'
    }),

    SelectedClasses,

    /**
     * Unique ID, generate IDs for all blocks
     */
    UniqueID.configure({
      attributeName: 'blockId',
      generateID: () => {
        return GenerateUUID.BLOCK()
      },

      // ToDo: Get all blocks
      types: nodes.map((node) => node.name).filter((name) => name !== 'text')
    }),

    /**
     * Dropcursor
     */
    Dropcursor,

    /**
     * Gapcursor
     */
    Gapcursor,

    /**
     * History
     */
    History,

    /**
     * Text Align
     */
    TextAlign.configure({
      types: ['paragraph', 'heading']
    }),

    /**
     * Slash Commands
     */
    Commands.configure({
      suggestion
    }),

    /**
     * Selections
     */
    Selections
  ]

  return extensions
})
