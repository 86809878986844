import gql from 'graphql-tag'

export const PUBLISHABLE_URL_INPUT = gql`
  fragment PublishableUrlInput on CodexUrl {
    id
    entityType
    level
    siteId
    fieldAlias
    url
    isCustom
    statusCode
    redirectUrl
    entityId
    createdAt
    updatedAt
    createdBy
    updatedBy
  }
`

export const READ_URL = gql`
  ${PUBLISHABLE_URL_INPUT}

  query ReadUrl($id: String!, $input: PublishableUrlInput!) {
    url(id: $id, input: $input)
      @rest(type: "CodexUrl", path: "organizationId/urls/{args.id}", method: "GET") {
      ...PublishableUrlInput
    }
  }
`

export const CREATE_URL = gql`
  ${PUBLISHABLE_URL_INPUT}

  mutation CreateUrl($input: PublishableUrlInput!) {
    createUrl(input: $input) @rest(type: "CodexUrl", path: "organizationId/urls", method: "POST") {
      ...PublishableUrlInput
    }
  }
`

export const UPDATE_URL = gql`
  ${PUBLISHABLE_URL_INPUT}

  mutation UpdateUrl($input: PublishableUrlInput!) {
    updateUrl(input: $input)
      @rest(type: "CodexUrl", path: "organizationId/urls/{args.input.id}", method: "PUT") {
      ...PublishableUrlInput
    }
  }
`

export const DELETE_URL = gql`
  ${PUBLISHABLE_URL_INPUT}

  mutation DeleteUrl($id: String!, $input: PublishableUrlInput!) {
    deleteUrl(id: $id, input: $input)
      @rest(type: "CodexUrl", path: "organizationId/urls/{args.id}", method: "DELETE") {
      NoResponse
    }
  }
`
