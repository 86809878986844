<script setup lang="ts">
import { CodexPresetGravity } from '@/gql/graphql'
import { defineComponent, computed } from 'vue'
import { useI18nStore } from '@/@core/i18n'

defineComponent({
  name: 'PresetGravitySelect'
})

const ct = useI18nStore().createI18nContext('ct.presets.general.enums.gravity')

const options = computed(() => {
  return [
    { label: ct.$t(CodexPresetGravity.North), value: CodexPresetGravity.North },
    { label: ct.$t(CodexPresetGravity.South), value: CodexPresetGravity.South },
    { label: ct.$t(CodexPresetGravity.East), value: CodexPresetGravity.East },
    { label: ct.$t(CodexPresetGravity.West), value: CodexPresetGravity.West },
    { label: ct.$t(CodexPresetGravity.Center), value: CodexPresetGravity.Center },
    { label: ct.$t(CodexPresetGravity.NorthEast), value: CodexPresetGravity.NorthEast },
    { label: ct.$t(CodexPresetGravity.NorthWest), value: CodexPresetGravity.NorthWest },
    { label: ct.$t(CodexPresetGravity.SouthEast), value: CodexPresetGravity.SouthEast },
    { label: ct.$t(CodexPresetGravity.SouthWest), value: CodexPresetGravity.SouthWest }
  ]
})
</script>

<template>
  <Dropdown :options="options"></Dropdown>
</template>
