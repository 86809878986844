import { CodexHooks } from '@/@core'
import type { Context } from '@/modules/@global/composables/useContext'
import { ENTRIES_NAME, MY_ENTRIES_NAME } from '@/modules/entries/constants'
import { MODEL_DETAILS } from '@/modules/entries/queries/index.graphql'
import { useQuery } from '@vue/apollo-composable'
import { mergeWith } from 'lodash'
import { computed } from 'vue'
import CheckboxFilter from '@/modules/@global/filters/checkbox/CheckboxFilter'
import { useEntriesListingFunctions } from '@/modules/entries/composables/entriesListingComposable'
import { useRoute } from 'vue-router'
import { useOrganization } from '@/modules/organizations/store/organization'
import DateFormat from '@/modules/@global/components/DateFormat/DateFormat.vue'
import { NumberFormat } from 'vue-i18n'

const validPageNames = [ENTRIES_NAME, MY_ENTRIES_NAME]

CodexHooks.addAction(`context/created`, (context, contextObj) => {
  if (validPageNames.indexOf(contextObj?.name) >= 0) {
    CodexHooks.addFilter(`table/variables?name=${contextObj.name}`, addFilterVariables, contextObj)
    CodexHooks.addFilter(`table/columns?name=${contextObj.name}`, addModelFieldColumns, contextObj)
    CodexHooks.addFilter(
      `filters/filters?name=${contextObj.name}`,
      addModelFieldFilters,
      contextObj
    )
  }
})

CodexHooks.addAction(`context/destroyed`, (context, contextObj) => {
  if (validPageNames.indexOf(contextObj?.name) >= 0) {
    CodexHooks.removeFilter(
      `table/variables?name=${contextObj.name}`,
      addFilterVariables,
      contextObj
    )
    CodexHooks.removeFilter(
      `table/columns?name=${contextObj.name}`,
      addModelFieldColumns,
      contextObj
    )
    CodexHooks.removeFilter(
      `filters/filters?name=${contextObj.name}`,
      addModelFieldFilters,
      contextObj
    )
  }
})

function addFilterVariables(variables: any, context: Context) {
  const filters = context.getRef('filters').filters
  const search = context.getRef('search').search

  if (search) {
    variables.where = {
      ...variables.where,
      query: search
    }
  }

  if (filters?.hasActiveFilters()) {
    variables.where = mergeWith(variables.where, filters.asGraphQL)
  }

  return variables
}

function addModelFieldFilters(filters: any, context: Context) {
  const { operator = '', value = [] } =
    context.getRef('filters').filters?.activeFilters?.model ?? {}
  if (value?.length === 1 && operator === CheckboxFilter.OPERATORS.include) {
    const model = computed(() => fetchModelDetails(value[0])).value
    if (model)
      return [
        ...filters,
        {
          type: 'group',
          label: model.name,
          isModelFilterGroup: true,
          filters: mapModelFieldsToFilters(model.alias, model.fields)
        }
      ]
  }
  return filters
}

function addModelFieldColumns(columns: any, context: Context) {
  const { operator = '', value = [] } =
    context.getRef('filters').filters?.activeFilters?.model ?? {}

  if (value?.length === 1 && operator === CheckboxFilter.OPERATORS.include) {
    const model = computed(() => fetchModelDetails(value[0])).value
    if (model)
      columns = [...columns, ...mapModelFieldsToColumns(model.alias, model.fields, context)]
  }

  for (let index = 0; index < columns.length; index++) {
    const element = columns[index]
    if (element.field === 'actions') continue
    const newIndex = context?.data?.columns?.indexOf(element.field)
    element.hidden = newIndex < 0
    element.order = newIndex
  }
  return columns
}

function fetchModelDetails(modelId: string) {
  const modelDetailsQuery = useQuery(MODEL_DETAILS, { modelId: modelId })
  const items = modelDetailsQuery.result?.value?.data?.items
  if (items && items.length) return items[0]
  return null
}

function mapModelFieldsToFilters(modelAlias: string, modelFields: any[]) {
  return modelFields
    .filter((field) => field.configuration.filterable)
    .map((field) => {
      const type = () => {
        if (field.valueType === 'LIST') return null
        //TODO: implement all non Text types, since Text is default
        switch (field.type) {
          case 'INTEGER':
            return 'number'
          case 'DATE_TIME':
            return 'datetime'
          default:
            return 'text'
        }
      }

      return {
        label: field.name,
        name: field.alias,
        graphQLPath: `${modelAlias}.${field.alias}`,
        type: type(),
        isModelField: true
      }
    })
}

function mapModelFieldsToColumns(modelAlias: string, modelFields: any[], context: Context) {
  const { defaultColumns } = useEntriesListingFunctions(context)
  const viewId = useRoute()?.params?.viewId
  let columns = context.data.columns ?? defaultColumns

  if (viewId !== 'all' && viewId !== 'my') {
    columns =
      useOrganization().views?.find((x: any) => x.id === viewId)?.customizations?.columns ?? columns
  }
  return modelFields.map((field) => {
    const index = columns.indexOf(field.alias)
    return {
      field: field.alias,
      header: field.name,
      isModelField: true,
      hidden: index < 0,
      order: index >= 0 ? index : undefined,
      modelAlias,
      body: [
        {
          name: field.alias,
          element: (rowData: any) => {
            switch (field.type) {
              //TODO: implement all non Text types, since Text is default
              case 'INTEGER':
                return (
                  <Text>
                    {Number.isInteger(rowData.data[field.alias]) ? (
                      <NumberFormat value={Number(rowData.data[field.alias])} />
                    ) : (
                      ''
                    )}
                  </Text>
                )
              case 'DATE_TIME':
                return (
                  <Text>
                    <DateFormat time={true} date={rowData.data[field.alias]} />
                  </Text>
                )
              default:
                return <Text>{rowData.data[field.alias]}</Text>
            }
          }
        }
      ]
    }
  })
}
