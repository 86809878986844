import {
  FIELD_FILTER_OPERATORS,
  FIELD_TYPES,
  FIELD_VALUE_TYPES,
  PATTERN_TYPE
} from '@/modules/models/editor-ts/fields/constants'
import { CodexHooks } from '@/@core'
import type {
  FieldAttrsType,
  FieldConditionalValidationType
} from '@/modules/@global/models/FieldTypes'

// TODO: Change this validation for URL field
// if (urlLength > 0 && !value.startsWith('/')) {
//   return { isValid: false, message: this.$t('fields.codex-field-url.render.url-validation') }
// }

const required = {
  name: 'required',
  type: FIELD_TYPES.URL,
  valueType: [FIELD_VALUE_TYPES.SINGLE],
  component: () => import('@/modules/@global/models/field-validations/general/Required.vue'),
  attr: {
    value: false,
    errorMessage: 'URL is required.',
    conditionsEnabled: false,
    conditions: [
      {
        isSystem: false,
        field: '',
        operator: FIELD_FILTER_OPERATORS.EXISTS,
        value: ''
      }
    ]
  },
  validation: (value, attrs: FieldConditionalValidationType, field: FieldAttrsType) => {
    const urlLength = value?.length || 0
    if (attrs.value && urlLength === 0) {
      return { isValid: false, message: attrs.errorMessage }
    }
    return { isValid: true }
  }
}

const readOnly = {
  name: 'readOnly',
  type: FIELD_TYPES.URL,
  valueType: [FIELD_VALUE_TYPES.SINGLE],
  component: () => import('@/modules/@global/models/field-validations/general/ReadOnly.vue'),
  attr: {
    value: false,
    conditionsEnabled: false,
    conditions: [
      {
        isSystem: false,
        field: '',
        operator: FIELD_FILTER_OPERATORS.EXISTS,
        value: ''
      }
    ]
  },
  validation: () => ({ isValid: true })
}

const matchingRegex = {
  name: 'matchingRegex',
  type: FIELD_TYPES.URL,
  valueType: [FIELD_VALUE_TYPES.SINGLE],
  component: () => import('@/modules/@global/models/field-validations/general/MatchingRegex.vue'),
  attr: {
    isEnabled: false,
    type: PATTERN_TYPE.CUSTOM,
    patterns: [],
    flags: [],
    errorMessage: 'Pattern does not match'
  },
  validation: (value, attrs, field) => {
    const urlLength = value?.length || 0
    if (attrs.isEnabled && urlLength > 0 && attrs.patterns.length > 0) {
      let errors = false
      attrs.patterns.forEach((pattern: any) => {
        const regex = new RegExp(pattern, attrs.flags.join(''))
        if (!regex.test(value)) {
          errors = true
        }
      })
      if (errors) return { isValid: false, message: attrs.errorMessage }
    }
    return { isValid: true }
  }
}

CodexHooks.addFilter('fields/validations', (items) => {
  return [...items, required, readOnly, matchingRegex]
})
