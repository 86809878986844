<script setup lang="ts">
import { computed } from 'vue'
import { uniqueId } from 'lodash'

const props = withDefaults(
  defineProps<{
    options: any[]
    horizontal?: boolean
    labelKey?: string
    valueKey?: string
    size?: 'small' | 'large' | null
    disabled?: boolean
  }>(),
  {
    options: () => [],
    horizontal: false,
    labelKey: 'label',
    valueKey: 'value',
    size: null,
    disabled: false
  }
)

const modelValue = defineModel<string>()
const id = uniqueId('radiobuttons_')

const radiobuttonsClasses = computed(() => {
  return {
    'radiobuttons--horizontal': props.horizontal,
    'radiobuttons--small': props.size === 'small',
    'radiobuttons--large': props.size === 'large'
  }
})
const textSize = (size: 'small' | 'large' | null) => {
  const sizeMappings: Record<'small' | 'large' | null, string> = {
    small: 'body-regular-small',
    large: 'body-regular-headline'
  }
  return sizeMappings[size] || 'body-regular-base'
}
</script>

<template>
  <div class="radiobuttons__wrapper" :class="radiobuttonsClasses">
    <label class="radiobuttons" v-for="option in options" :key="option.value">
      <RadioButton
        :input-id="`${id}-${option[valueKey]}`"
        v-model="modelValue"
        :size="size"
        :value="option[valueKey]"
        :disabled="disabled"
      />
      <Text is="label" :for="`${id}-${option[valueKey]}`" :type="textSize(size)">
        {{ option[labelKey] }}
      </Text>
    </label>
  </div>
</template>

<style scoped lang="scss"></style>
