import type { FormField } from '@/modules/@global/components/DynamicForm/DynamicForm'
import * as yup from 'yup'
import { useI18nStore } from '@/@core/i18n'
import { CodexAuthorStatus } from '@/gql/graphql'

/**
 * Author forms fields definition
 */
interface AuthorFormsFields {
  name: FormField
  biography: FormField
  email: FormField
  url: FormField
  website: FormField
  facebook: FormField
  instagram: FormField
  twitter: FormField
  linkedin: FormField
  telegram: FormField
  tiktok: FormField
  status: FormField
}

/**
 * Author forms fields
 */
export const AuthorFormsFields = (): AuthorFormsFields => {
  const ct = useI18nStore().createI18nContext('ct.authors.forms.fields')
  return {
    name: {
      type: 'Text',
      name: 'byline',
      label: ct.$t('byline.label'),
      rules: yup.string().required('Name is required')
    },
    biography: {
      type: 'Text',
      name: 'biography',
      label: ct.$t('biography.label')
    },
    email: {
      type: 'Text',
      name: 'email',
      label: ct.$t('email.label'),
      rules: yup.string().email('Invalid email')
    },
    url: {
      type: 'Text',
      name: 'url',
      label: ct.$t('url.label'),
      rules: yup.string()
    },
    website: {
      type: 'Text',
      name: 'website',
      label: ct.$t('website.label')
    },
    facebook: {
      type: 'Text',
      name: 'facebook',
      label: ct.$t('facebook.label')
    },
    instagram: {
      type: 'Text',
      name: 'instagram',
      label: ct.$t('instagram.label')
    },
    twitter: {
      type: 'Text',
      name: 'twitter',
      label: ct.$t('twitter.label')
    },
    linkedin: {
      type: 'Text',
      name: 'linkedin',
      label: ct.$t('linkedin.label')
    },
    telegram: {
      type: 'Text',
      name: 'telegram',
      label: ct.$t('telegram.label')
    },
    tiktok: {
      type: 'Text',
      name: 'tiktok',
      label: ct.$t('tiktok.label')
    },
    status: {
      type: 'InputSwitch',
      name: 'status',
      props: {
        trueValue: CodexAuthorStatus.Published,
        falseValue: CodexAuthorStatus.Unpublished
      },
      label: ct.$t('status.label')
    }
  }
}

/**
 * Create Author form
 */
const CreateAuthorForm = (): FormField[] => {
  const fields = AuthorFormsFields()

  return [
    {
      type: 'Row',
      children: [
        // Name
        {
          ...fields.name,
          layout: { col: 12 }
        },
        // Biography
        {
          ...fields.biography,
          layout: { col: 12 }
        },
        // Email
        {
          ...fields.email,
          layout: { col: 12 }
        },
        // Url
        {
          ...fields.url,
          layout: { col: 12 }
        },
        // Visibility
        {
          ...fields.status,
          layout: { col: 12 }
        },
        // Website
        {
          ...fields.website,
          layout: { col: 12 }
        },
        // Facebook
        {
          ...fields.facebook,
          layout: { col: 12 }
        },
        // Instagram
        {
          ...fields.instagram,
          layout: { col: 12 }
        },
        // Twitter
        {
          ...fields.twitter,
          layout: { col: 12 }
        },
        // Linkedin
        {
          ...fields.linkedin,
          layout: { col: 12 }
        },
        // Telegram
        {
          ...fields.telegram,
          layout: { col: 12 }
        },
        // Tiktok
        {
          ...fields.tiktok,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

/**
 * Update Author form
 */
const UpdateAuthorForm = (): FormField[] => {
  const fields = AuthorFormsFields()
  return [
    {
      type: 'Row',
      children: [
        // Name
        {
          ...fields.name,
          layout: { col: 12 }
        },
        // Biography
        {
          ...fields.biography,
          layout: { col: 12 }
        },
        // Email
        {
          ...fields.email,
          layout: { col: 12 }
        },
        // Url
        {
          ...fields.url,
          layout: { col: 12 }
        },
        // status
        {
          ...fields.status,
          layout: { col: 12 }
        },
        // Website
        {
          ...fields.website,
          layout: { col: 12 }
        },
        // Facebook
        {
          ...fields.facebook,
          layout: { col: 12 }
        },
        // Instagram
        {
          ...fields.instagram,
          layout: { col: 12 }
        },
        // Twitter
        {
          ...fields.twitter,
          layout: { col: 12 }
        },
        // Linkedin
        {
          ...fields.linkedin,
          layout: { col: 12 }
        },
        // Telegram
        {
          ...fields.telegram,
          layout: { col: 12 }
        },
        // Tiktok
        {
          ...fields.tiktok,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

export { CreateAuthorForm, UpdateAuthorForm }
