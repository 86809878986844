<script setup lang="ts">
import SourceFilter from './SourceFilter'
import { computed } from 'vue'
import { useI18nStore } from '@/@core/i18n/index'
import { CodexTagSource } from '@/gql/graphql'
import Checkboxes from '@/modules/@global/components/Checkboxes/Checkboxes.vue'

const ct = useI18nStore().createI18nContext('ct.tags.filters.source')
const ctGlobalFilters = useI18nStore().createI18nContext('ct.global.components.filters')

// eslint-disable-next-line no-undef
const modelValue = defineModel<{
  value: CodexTagSource[]
}>()

const sourceOptions = computed(() => {
  return [
    {
      value: CodexTagSource.Codex,
      label: ct.$t(CodexTagSource.Codex)
    },
    {
      value: CodexTagSource.Dandelion,
      label: ct.$t(CodexTagSource.Dandelion)
    },
    {
      value: CodexTagSource.Genea,
      label: ct.$t(CodexTagSource.Genea)
    }
  ]
})
</script>

<template>
  <div class="new-filters-dropdown__filter-body new-filters-dropdown__filter-body--scroll">
    <Row align-items="center">
      <Col>
        <Checkboxes v-model="modelValue.value" :options="sourceOptions" />
      </Col>
    </Row>
  </div>
</template>

<style scoped></style>
