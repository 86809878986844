<script setup lang="ts">
import PrimeColorPicker from 'primevue/colorpicker'
import { computed } from 'vue'

const modelValue = defineModel<string>()

const color = computed({
  get: () => {
    // if string
    if( typeof modelValue.value === 'string' ) {
      return modelValue.value?.replace('#', '')
    } else {
      return modelValue.value
    }
  },
  set: (value) => {
    if( typeof value === 'string' ) {
      modelValue.value = `#${value}`.toUpperCase()
    } else {
      modelValue.value = value
    }
  }
})
</script>

<template>
  <PrimeColorPicker v-bind="$attrs" v-model="color">
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </PrimeColorPicker>
</template>
