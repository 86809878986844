import { graphql } from '@/gql/gql'

export const USER = graphql(/* GraphQL */ `
  query User($id: String!) {
    data: user(id: $id) {
      id
      email
      firstName
      imageUrl
      lastName
      createdAt
      updatedAt
    }
  }
`)

export const LIST_USERS = graphql(/* GraphQL */ `
  query ListUsers($limit: Int!, $offset: Int!, $where: CodexUserFilter, $order: CodexUserOrder) {
    data: userCollection(limit: $limit, offset: $offset, where: $where, order: $order) {
      total
      items {
        id
        email
        teams {
          items {
            id
            name
          }
        }
        firstName
        lastName
        imageUrl
        createdAt
      }
    }
  }
`)

export const LIST_SELECTED_DROPDOWN_USERS = graphql(/* GraphQL */ `
  query ListSelectedDropdownUsers($ids: [String!]) {
    existing: userCollection(limit: 1000, where: { id: { in: $ids } }) {
      total
      items {
        id
        firstName
        lastName
        imageUrl
      }
    }
  }
`)
export const LIST_DROPDOWN_USERS = graphql(/* GraphQL */ `
  query ListDropdownUsers(
    $limit: Int!
    $offset: Int!
    $where: CodexUserFilter
    $order: CodexUserOrder
  ) {
    data: userCollection(limit: $limit, offset: $offset, where: $where, order: $order) {
      total
      items {
        id
        firstName
        lastName
        imageUrl
      }
    }
  }
`)
