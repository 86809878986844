import { CodexHooks } from '@/@core'
import { TYPE_MAPPING } from '@/modules/assets/constants'

CodexHooks.addFilter('apollo/responseTransformer?type=CodexAsset', (response) => {
  response.type = TYPE_MAPPING.toString(response.type)

  return response
})

CodexHooks.addFilter('apollo/responseTransformer?type=PreSignedURL', (response) => {
  response.url = response.result.url
  response.requestKey = response.result.requestKey

  return response
})
CodexHooks.addFilter('apollo/responseTransformer?type=MultipartPreSignedURL', (response) => {
  response.requestKey = response.result.requestKey
  response.presignedUrls = response.result.presignedUrl

  return response
})
