import { CodexHooks } from '@/@core'
import LinkBubbleMenu from '@/modules/@global/components/RichContent/bubble-menus/link/LinkBubbleMenu.vue'
import { isTextSelection } from '@tiptap/core'

CodexHooks.addFilter('rich-content/bubble-menus', (menus) => {
  return [
    ...menus,
    {
      name: 'link',
      placement: 'bottom',
      component: LinkBubbleMenu,
      shouldShow: ({ editor }) => {
        return editor?.isActive('link') || editor?.storage?.link?.createLink
      }
    }
  ]
})
