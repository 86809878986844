<script setup lang="ts">
import { computed, ref } from 'vue'
import Tree, { type TreeExpandedKeys, type TreeNode } from 'primevue/tree'
import type { CodexFolder } from '../../../../gql/graphql'
import FolderGridItem from './FolderGridItem.vue'
import { useDragAndDrop } from '../../composables/useDragAndDrop'
import useFolders from '../../composables/useFolders'

const { rootFolders, updateActiveFolderId, loadFolderWithId } = useFolders()

const expandedKeys = ref<TreeExpandedKeys>([])
const loadedKeys = ref<Set<string>>(new Set())

const foldersComputed = computed(() => convertFoldersToTree(rootFolders.value))

function convertFoldersToTree(folders: CodexFolder[] = [], index: string = ''): TreeNode[] {
  return (
    folders.map((f, i) => {
      const key = `${(index ? `${index}-` : '') + i}`
      return {
        ...f,
        key,
        label: f?.name || '',
        children: f.children?.items ? convertFoldersToTree(f.children.items, key) : []
      }
    }) || []
  )
}

// Handle expanding a node
function handleNodeExpand(node: TreeNode) {
  if (!loadedKeys.value.has(node?.key)) {
    loadFolderWithId(node.id)
    loadedKeys.value.add(node?.key)
  }
}

// Track expanded keys to handle folder open/close states
function handleExpandedKeys(keys: TreeExpandedKeys) {
  expandedKeys.value = keys
}

function addExpandedKeys(folderId: TreeExpandedKeys) {
  function findKeyById(data = foldersComputed.value) {
    for (const folder of data) {
      if (folder.id === folderId) {
        return folder.key
      }

      if (folder.children && folder.children.length > 0) {
        const foundKey = findKeyById(folder.children)
        if (foundKey) {
          return foundKey
        }
      }
    }
    return null // Return null if the id is not found
  }

  const key = findKeyById()
  if (key) {
    expandedKeys.value = { ...expandedKeys.value, [key]: true }
  }
}

// Handle folder click to update the active folder
function handleFolderClick(node: TreeNode) {
  updateActiveFolderId(node.id)
  addExpandedKeys(node.id)
}

const { handleAssetDragstart, handleDragEnter, handleFolderDrop, scrollWhenHover } =
  useDragAndDrop()

defineExpose({
  addExpandedKeys
})
</script>

<template>
  <FolderGridItem
    :folder="{ name: 'Root', parent: null, children: [], id: null, parentId: null }"
    @click="updateActiveFolderId(null)"
  />
  <Tree
    :value="foldersComputed"
    :expanded-keys="expandedKeys"
    class="w-full md:w-30rem"
    @node-expand="handleNodeExpand"
    @update:expanded-keys="handleExpandedKeys"
    @dragenter.prevent="handleDragEnter"
    @dragover.prevent="scrollWhenHover"
    @drop.prevent="handleFolderDrop"
  >
    <template #loadingicon>
      <p>asdasdasd</p>
    </template>
    <template #default="{ node }">
      <FolderGridItem
        :folder="node"
        @click="handleFolderClick(node)"
        :draggable="true"
        @dragstart="handleAssetDragstart($event, node, false, [])"
      />
    </template>
  </Tree>
</template>

<style lang="scss" scoped></style>
