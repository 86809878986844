// HTTP connection to the API ( GraphQL )
import { createHttpLink as apolloCreateHttpLink } from '@apollo/client/core'
import { CodexDebug } from '@/@core'

export default function createHttpLink(organizationId: string | null = null, jobs = false) {
  let uri: string

  if (organizationId) {
    uri = `https://api.codex.gjirafa.dev/v2/${organizationId}/graphql`

    if (jobs) {
      uri = `https://api.codex.gjirafa.dev/v2/${organizationId}/jobs/graphql`
    }

    CodexDebug.apolloInfo(`HttpLink creating link for organization "${organizationId}"`)
  } else {
    uri = 'https://api.codex.gjirafa.dev/v2/graphql'

    CodexDebug.apolloInfo(`HttpLink creating main link`)
  }

  return apolloCreateHttpLink({ uri })
}

export function createClientHttpLink(organizationId: string) {
  let uri: string

  if (organizationId) {
    uri = `https://graphql.codex.gjirafa.dev/v2/${organizationId}`

    CodexDebug.apolloInfo(`HttpLink creating link for organization "${organizationId}"`)
  }

  return apolloCreateHttpLink({ uri })
}
