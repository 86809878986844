import { CodexHooks } from '@/@core'
import { isString, merge } from 'lodash'
import {
  ACCESS_STRATEGY_MAPPING,
  modelDefaults,
  MODEL_STATUS_MAPPING
} from '@/modules/models/constants'

CodexHooks.addFilter(
  'apollo/restFetchOptions?type=CodexModel',
  (options, context, uri, headers) => {
    if (isString(options.body)) {
      try {
        const bodyParsed = JSON.parse(options.body)

        bodyParsed.status = MODEL_STATUS_MAPPING.toNumber(bodyParsed.status)

        if (bodyParsed?.configurations?.accessStrategy) {
          bodyParsed.configurations.accessStrategy = ACCESS_STRATEGY_MAPPING.toNumber(
            bodyParsed.configurations.accessStrategy
          )
        }

        options.body = JSON.stringify(bodyParsed)
      } catch (e) {
        // Do nothing
      }
    }

    return options
  }
)

CodexHooks.addFilter('apollo/responseTransformer?type=CodexModel', (response) => {
  response = modelDefaults(response)

  response.status = MODEL_STATUS_MAPPING.toString(response.status)

  response.configurations.accessStrategy = ACCESS_STRATEGY_MAPPING.toString(
    response.configurations.accessStrategy
  )

  if (!response.deletedBlocks) {
    response.deletedBlocks = []
  }

  if (!response.configurations.lockDurationInSeconds) {
    response.configurations.lockDurationInSeconds = 900
  }
  const sidebar = {
    general: {
      isEnabled: true,
      items: {
        preview: { isEnabled: true, order: 0, pattern: '/preview/{system.modelAlias}/{id}' },
        status: { isEnabled: true, order: 1 },
        labels: { isEnabled: true, order: 2 },
        publishedAt: { isEnabled: true, order: 3 },
        metrics: { isEnabled: true, order: 4 },
        created: { isEnabled: true, order: 5 },
        updated: { isEnabled: true, order: 6 },
        prolexis: { isEnabled: true, order: 7 },
        id: { isEnabled: true, order: 8 },
        externalId: { isEnabled: true, order: 9 },
        addToList: { isEnabled: true, order: 10 },
        firstPublishedAt: { isEnabled: true, order: 11 },
        view: { isEnabled: true, order: 12 },
        aiContentAnalyzer: { isEnabled: false, order: 13 },
        scheduledVersions: { isEnabled: true, order: 14 }
      }
    },
    history: { isEnabled: true },
    referencedBy: { isEnabled: true },
    clonedReferences: { isEnabled: true }
  }

  merge(sidebar, response.configurations.sidebar)
  Object.assign(response.configurations.sidebar, sidebar)

  if (!response.configurations.labels) {
    response.configurations.labels = {
      allowAll: true,
      allowed: []
    }
  }

  return response
})
