<script setup lang="ts">
import GravityFilter from './GravityFilter'
import { computed } from 'vue'
import { useI18nStore } from '@/@core/i18n/index'
import { CodexPresetGravity } from '@/gql/graphql'
import Checkboxes from '@/modules/@global/components/Checkboxes/Checkboxes.vue'

const ct = useI18nStore().createI18nContext('ct.presets.general.enums.gravity')
const ctGlobalFilters = useI18nStore().createI18nContext('ct.global.components.filters')

// eslint-disable-next-line no-undef
const modelValue = defineModel<{
	operator: string
	value: CodexPresetGravity[]
}>()

const options = computed(() => [
	{
		label: ctGlobalFilters.$t('operators.include'),
		value: GravityFilter.OPERATORS.include
	},
	{
		label: ctGlobalFilters.$t('operators.exclude'),
		value: GravityFilter.OPERATORS.exclude
	},
])

const gravityOptions = computed(() => {
	return [
		{
			value: CodexPresetGravity.North,
			label: ct.$t(CodexPresetGravity.North),
		},
		{
			value: CodexPresetGravity.South,
			label: ct.$t(CodexPresetGravity.South),
		},
		{
			value: CodexPresetGravity.East,
			label: ct.$t(CodexPresetGravity.East),
		},
		{
			value: CodexPresetGravity.West,
			label: ct.$t(CodexPresetGravity.West),
		},
		{
			value: CodexPresetGravity.Center,
			label: ct.$t(CodexPresetGravity.Center),
		},
		{
			value: CodexPresetGravity.NorthEast,
			label: ct.$t(CodexPresetGravity.NorthEast),
		},
		{
			value: CodexPresetGravity.NorthWest,
			label: ct.$t(CodexPresetGravity.NorthWest),
		},
		{
			value: CodexPresetGravity.SouthEast,
			label: ct.$t(CodexPresetGravity.SouthEast),
		},
		{
			value: CodexPresetGravity.SouthWest,
			label: ct.$t(CodexPresetGravity.SouthWest),
		},
	]
})
</script>

<template>
	<div class="new-filters-dropdown__filter-body new-filters-dropdown__filter-body--scroll">
		<Row align-items="center">
			<Col>
			<Dropdown v-model="modelValue.operator" :options="options" size="small" />
			</Col>
		</Row>

		<Row align-items="center">
			<Col>
			<Checkboxes v-model="modelValue.value" :options="gravityOptions" />
			</Col>
		</Row>
	</div>
</template>

<style scoped></style>
