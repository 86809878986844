import { CodexHooks } from '@/@core'

// prettier-ignore
CodexHooks.addFilter('ability/items', function (items: Array<any>, context, permissionsData: any, allow: any, deny: any) {
	if( permissionsData.organization.label_manage ) {
		allow('create', 'Label')
		allow('update', 'Label')
		allow('delete', 'Label')
	}

	if( permissionsData.organization.label_view ) {
		allow('view', 'Label')
	}

	return items
})
