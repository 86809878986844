import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import { Decoration, DecorationSet } from '@tiptap/pm/view'
import { filterSelectedNodes } from '@/modules/@global/components/RichContent/utils'

export interface SelectedOptions {
  className: string
  mode: 'all' | 'deepest' | 'shallowest'
}

export const SelectedClasses = Extension.create<SelectedOptions>({
  name: 'selected',

  addOptions() {
    return {
      className: 'is-selected',
      mode: 'all'
    }
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('selected'),
        props: {
          decorations: ({ doc, selection }) => {
            const { isEditable } = this.editor
            const decorations: Decoration[] = []

            if (!isEditable) {
              return DecorationSet.create(doc, [])
            }

            // prettier-ignore
            const nodes = filterSelectedNodes({editor: this.editor, doc, selection}, ({node, pos, parent, index, nodeFrom, nodeTo, selection}) => {
                if (node.type.name === 'text') return false
                // if node is inside selection
                if (nodeFrom >= selection.from && nodeTo <= selection.to && node.isAtom && !node.isInline) return true
            })

            nodes.nodes.map((node) => {
              decorations.push(
                Decoration.node(node.nodeFrom, node.nodeTo, {
                  class: this.options.className
                })
              )
            })

            return DecorationSet.create(doc, decorations)
          }
        }
      })
    ]
  }
})
