import { graphql } from '@/gql/gql'

export const LIST_AUTHORS = graphql(/* GraphQL */ `
  query ListAuthors(
    $limit: Int!
    $offset: Int!
    $where: CodexAuthorFilter
    $order: CodexAuthorOrder
  ) {
    data: authorCollection(limit: $limit, offset: $offset, where: $where, order: $order) {
      total
      items {
        id
        byline
        firstName
        lastName
        url
        imageId
        image {
          url
        }
        biography
        email
        createdAt
      }
    }
  }
`)
