import { useI18nStore } from '@/@core/i18n'
import { CodexTagSource } from '@/gql/graphql'
import { truncate } from 'lodash'
import ReferencesListFilterComponent from './ReferencesList.vue'

export default class ReferencesListFilter {
  static COMPONENT = ReferencesListFilterComponent

  static type: string = 'tag-references-list'
  static graphqlType = 'ReferenceListFieldFilter'

  static OPERATORS = {
    all: 'all',
    some: 'some',
    none: 'none',
    exists: 'exists',
    notExists: 'notExists'
  }

  operator: string | null = null

  value: [] | null = null

  constructor({
    operator = ReferencesListFilter.OPERATORS.some,
    value = []
  }: { operator?: string; value?: [] } = {}) {
    this.value = value
    this.operator = operator
  }

  set({ operator = ReferencesListFilter.OPERATORS.some, value = [] }) {
    console.log(this.operator, 'operatori', this.value, 'vlera')

    this.operator = operator
    this.value = value
  }
  getCount() {
    if (
      this.operator === ReferencesListFilter.OPERATORS.exists ||
      this.operator === ReferencesListFilter.OPERATORS.notExists
    ) {
      return 1
    }
    return this.value?.length
  }

  getValueLabel({ cache }: { cache: any }): string | null {
    console.log(this.operator, 'operatori', this.value, 'vlera')

    const ct = useI18nStore().createI18nContext('ct.tags')

    if (
      this.operator === ReferencesListFilter.OPERATORS.exists ||
      this.operator === ReferencesListFilter.OPERATORS.notExists
    ) {
      return this.operator === ReferencesListFilter.OPERATORS.exists
        ? ct.$t(`filters.references.${this.operator}`)
        : ct.$t(`filters.references.${this.operator}`)
    }
    const entry = this.value.map((entryId) => cache[entryId] || { id: entryId })
    return entry.map((m) => truncate(m?.system?.title, { length: 15, separator: ' ' })).join(', ')
  }

  toLoad() {
    return {
      entries: this.value
    }
  }

  asQueryParam() {
    console.log(this.operator, 'operatori', this.value, 'vlera')

    if (
      this.operator === ReferencesListFilter.OPERATORS.exists ||
      this.operator === ReferencesListFilter.OPERATORS.notExists
    ) {
      return {
        operator: this.operator
      }
    }

    return {
      operator: this.operator,
      value: this.value
    }
  }

  asGraphQL() {
    console.log(this.operator, 'operatori', this.value, 'vlera')

    switch (this.operator) {
      case ReferencesListFilter.OPERATORS.all:
        return {
          all: this.value
        }
      case ReferencesListFilter.OPERATORS.some:
        return {
          some: this.value
        }
      case ReferencesListFilter.OPERATORS.none:
        return {
          none: this.value
        }
      case ReferencesListFilter.OPERATORS.exists:
        return {
          exists: true
        }
      case ReferencesListFilter.OPERATORS.notExists:
        return {
          exists: false
        }
      default:
        return null
    }
  }

  asGraphQLDynamicList() {
    if (this.asGraphQL()) {
      return {
        ...this.asGraphQL(),
        _t: ReferencesListFilter.graphqlType
      }
    }
    return null
  }

  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (
      operator === ReferencesListFilter.OPERATORS.exists ||
      operator === ReferencesListFilter.OPERATORS.notExists
    ) {
      return true
    }
    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({ operator, value }) {
    return new ReferencesListFilter({
      operator,
      value
    })
  }

  static shouldApplyPredefinedValue(filter) {
    const forOperators = [ReferencesListFilter.OPERATORS.all, ReferencesListFilter.OPERATORS.some]
    return forOperators.includes(filter.operator)
  }
}
