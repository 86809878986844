import {
  FIELD_TYPES,
  FIELD_VALUE_TYPES,
  generateComputedPropsFromAttrs
} from '@/modules/models/editor-ts/fields/constants'

export const fields = {
  type: FIELD_TYPES,
  valueType: FIELD_VALUE_TYPES,
  generateComputedPropsFromAttrs
}
