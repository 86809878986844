import EventFilterComponent from './Event.vue'
import { useI18nStore } from '@/@core/i18n'
import { CodexAutomationEventType } from '@/gql/graphql'

export default class EventFilter {
  static COMPONENT = EventFilterComponent
  static OPERATORS: {
    include: string
    exclude: string
    some: string
  } = {
    include: 'all',
    exclude: 'none',
    some: 'some'
  }

  static type: string = 'workflows-event'

  operator: string | null = null

  value: Array<CodexAutomationEventType> | null = null

  constructor(
    {
      operator = EventFilter.OPERATORS.include,
      value = []
    }: { operator: string; value?: Array<CodexAutomationEventType> } = {
      operator: EventFilter.OPERATORS.include
    }
  ) {
    this.operator = operator
    this.value = value
  }
  set(
    {
      operator = EventFilter.OPERATORS.include,
      value = []
    }: { operator: string; value?: Array<CodexAutomationEventType> } = {
      operator: EventFilter.OPERATORS.include
    }
  ) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value?.length
  }

  getValueLabel(): string | null {
    const ct = useI18nStore().createI18nContext('ct.workflows')

    switch (this.operator) {
      case EventFilter.OPERATORS.include:
        return ct.$t('filters.event.active-label.is', {
          value: ct.$t(`general.enums.eventTypes.${this.value?.[0]}`)
        })
      case EventFilter.OPERATORS.exclude:
        return ct.$t(`filters.event.active-label.is-not`, {
          value: ct.$t(`general.enums.eventTypes.${this.value?.[0]}`)
        })
      default:
        return null
    }
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case EventFilter.OPERATORS.include:
        return {
          all: this.value
        }
      case EventFilter.OPERATORS.exclude:
        return {
          none: this.value
        }
      case EventFilter.OPERATORS.some:
        return {
          some: this.value
        }
      default:
        return null
    }
  }

  static validate({
    operator,
    value
  }: {
    operator: string
    value?: Array<CodexAutomationEventType>
  }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (value?.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({
    operator,
    value
  }: {
    operator: string
    value?: Array<CodexAutomationEventType>
  }) {
    return new EventFilter({
      operator,
      value
    })
  }
}
