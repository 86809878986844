<script setup lang="tsx">
import { computed, type ComputedRef, inject, onMounted, ref } from 'vue'
import { useI18nStore } from '@/@core/i18n'
import SidebarContent from '@/modules/@global/components/SidebarContent/SidebarContent.vue'

import { URL_REDIRECT_STATUS_CODE_MAPPING, URLS_NAME } from '@/modules/urls/constants'
import { LayoutElement } from '@/modules/@core/components/LayoutElements'
import { CodexUrlEntityType } from '@/gql/graphql'
import { LIST_URLS } from '@/modules/urls/queries/index.graphql'
import type { Filter } from '@/modules/@global/components/Filters/Filters'
import useContext from '@/modules/@global/composables/useContext'

/**
 * Context
 */
const context = useContext({
  name: URLS_NAME,
  type: 'sidebar'
})

/**
 * Translation Context
 */

const ctListing = useI18nStore().createI18nContext('ct.urls.pages.listing')
const ct = useI18nStore().createI18nContext('ct.urls.popups.add-urls')
const ctEntityTypes = useI18nStore().createI18nContext('ct.urls.general.entity-types')

/**
 * Dialog
 */
const dialog = inject('dialogRef')

async function createUrlHandler(values) {
  dialog?.value?.close(values)
}

const filterName = computed(() => dialog?.value?.data?.filterName)
const selected = ref([])

const listingColumns = computed(() => [
  {
    field: 'icon',
    header: ctListing.$t('table.columns.icon'),
    class: 'w-1rem',
    body: [
      {
        name: 'icon',
        element: (rowData: any) => {
          if (rowData?.data?.entityType === CodexUrlEntityType.Section)
            return <GjIcon name="Section" />
          if (rowData?.data?.entityType === CodexUrlEntityType.Entry) {
            const icon = rowData?.data?.entity?.system?.featuredMedia?.url ? (
              <Image
                src={rowData?.data?.entity?.system?.featuredMedia.url}
                alt="Image"
                width="25"
                style="flex-shrink: 0"
              />
            ) : (
              <GjIcon name={rowData?.data?.entity?.system?.model?.iconId} />
            )
            return <span>{icon}</span>
          }
          if (rowData?.data?.entityType === CodexUrlEntityType.Redirect)
            return <GjIcon name="Redirect" />
        }
      }
    ]
  },
  {
    field: 'entity',
    header: ctListing.$t('table.columns.entity'),
    body: [
      {
        name: 'entity',
        element: (rowData: any) => {
          let title = null
          if (rowData?.data?.entityType === CodexUrlEntityType.Section)
            title = <Text is="span">{rowData?.data?.entity?.title}</Text>
          if (rowData?.data?.entityType === CodexUrlEntityType.Entry)
            title = <Text is="span">{rowData?.data?.entity?.system?.title}</Text>
          if (rowData?.data?.entityType === CodexUrlEntityType.Redirect)
            title = <Text is="span">{rowData?.data?.redirectUrl}</Text>
          const custom = (
            <Text is="span" type="body-regular-smallest">
              {ct.$t('table.entity.custom')}
            </Text>
          )
          if (rowData?.data?.isCustom) {
            return (
              <span class="flex flex-column">
                {title}
                {custom}
              </span>
            )
          }
          return title
        }
      }
    ]
  },
  {
    field: 'entityType',
    columnKey: 'entityType',
    header: ctListing.$t('table.columns.entity-type'),
    body: [
      {
        element: (rowData: any) => {
          let text = ctEntityTypes.$t(rowData?.data?.entityType)
          if (rowData?.data?.entityType === CodexUrlEntityType.Redirect) {
            text += `- ${URL_REDIRECT_STATUS_CODE_MAPPING.toNumber(rowData?.data?.statusCode)}`
          }
          return <div>{text}</div>
        }
      }
    ]
  },
  {
    field: 'url',
    columnKey: 'url',
    header: ctListing.$t('table.columns.url')
  }
])

const filters: ComputedRef<Filter[]> = computed(() => [
  {
    type: 'group',
    label: ct.$t('filters.label'),
    filters: [
      {
        type: 'urlEntityType',
        name: 'entityType',
        label: ct.$t('filters.entity-type.title')
      }
    ]
  }
])

onMounted(() => {
  if (context.value.getRef('filters').filters && filterName.value) {
    context.value.getRef('filters').filters.setActiveFilter('entityType', {
      operator: 'includeOneOf',
      value: [filterName.value]
    })
  }
})
</script>

<template>
  <div class="add-url-popup">
    <SidebarContent :title="ct.$t('title')">
      <LayoutElement name="filters">
        <Row align-items="center" class="search-filters">
          <Col>
            <Search
              :name="URLS_NAME"
              :query-params="false"
              :placeholder="ctListing.$t('search')"
              :ref="(r: any) => context.setRef('search', r)"
            />
          </Col>
          <Col v-show="false">
            <Filters
              :name="URLS_NAME"
              :query-params="false"
              :filters="filters"
              :ref="(r: any) => context.setRef('filters', r)"
            />
          </Col>
        </Row>
      </LayoutElement>
      <LayoutElement name="table" :main="true">
        <ListingTable
          class="p-table-full"
          :ref="(r: any) => context.setRef('table', r)"
          :name="URLS_NAME"
          :editable="true"
          selection-mode="single"
          :query="LIST_URLS"
          :initial-columns="listingColumns"
          :variables="{
            where: {
              siteId: { eq: context.siteId }
            }
          }"
          :labels="{
            empty: {
              title: ct.$t('empty.title'),
              description: ct.$t('empty.description')
            }
          }"
          v-model:selection="selected"
        >
          <template #emptyButtons>
            <Button icon="Plus" :label="ct.$t('new')" />
          </template>
        </ListingTable>
      </LayoutElement>
      <template #footer>
        <Button @click="createUrlHandler(selected)">{{ ct.$t('insert') }}</Button>
      </template>
    </SidebarContent>
  </div>
</template>

<style lang="scss">
.create-url-form {
  .entity-type-radiobuttons {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;

    .p-radiobutton {
      width: 100%;
    }

    .url-custom-radiobuttons {
      width: calc(50% - 8px);
      background: #f6f7f9;
      border-radius: 8px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 50px;
      }
    }

    .url-switch-button {
      background-color: red;
    }
  }
}
</style>
