import { graphql } from '@/gql/gql'

export const LIST_TEAMS = graphql(/* GraphQL */ `
  query ListTeams($limit: Int!, $offset: Int!, $where: CodexTeamFilter, $order: CodexTeamOrder) {
    data: teamCollection(limit: $limit, offset: $offset, where: $where, order: $order) {
      total
      items {
        id
        name
        description
        members(limit: 100) {
          total
          items {
            id
            firstName
            lastName
            imageUrl
          }
        }
      }
    }
  }
`)

export const LIST_SELECTED_DROPDOWN_TEAMS = graphql(/* GraphQL */ `
  query ListSelectedDropdownTeams($ids: [String!]) {
    existing: teamCollection(limit: 1000, where: { id: { in: $ids } }) {
      total
      items {
        id
        name
      }
    }
  }
`)

export const LIST_DROPDOWN_TEAMS = graphql(/* GraphQL */ `
  query ListDropdownTeams(
    $limit: Int!
    $offset: Int!
    $where: CodexTeamFilter
    $order: CodexTeamOrder
  ) {
    data: teamCollection(limit: $limit, offset: $offset, where: $where, order: $order) {
      total
      items {
        id
        name
      }
    }
  }
`)
