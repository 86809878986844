import { computed, nextTick, ref } from 'vue'
import { defineStore } from 'pinia'
import { CodexHooks } from '@/@core'
import { useQuery } from '@vue/apollo-composable'
import { SITE } from '@/modules/sites/queries/index.graphql'
import { useRoute } from '@/@core/router'

export const useSite = defineStore('site', () => {
  const route = useRoute()
  const siteId = ref()
  const query = ref()

  CodexHooks.addAction('site/siteIdChanged', siteIdChanged)

  function siteIdChanged(context: any, newId?: string) {
    siteId.value = newId

    if (newId) {
      query.value = useQuery(SITE, () => ({
        id: newId
      }))
    } else {
      query.value = null
    }
  }

  nextTick(() => {
    siteIdChanged(route?.params?.siteId as string)
  })

  return {
    siteId,
    query,
    result: computed(() => query?.value?.result),
    loading: computed(() => query?.value?.loading),
    error: computed(() => query?.value?.error)
  }
})
