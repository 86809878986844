<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable'
import { inject, ref } from 'vue'
import { CREATE_URL } from '../queries/index.rest'
import { CreateUrlForm } from '../forms/Url'
import { useI18nStore } from '@/@core/i18n'
import { urlDefaults } from '../constants'
import type { Context } from '@/modules/@global/composables/useContext'
import SidebarContent from '@/modules/@global/components/SidebarContent/SidebarContent.vue'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.urls.popups.create-url')

/**
 * Dialog
 */
const dialog = inject(DialogRefKey)

/**
 * Create Url
 */
const createUrl = useMutation(CREATE_URL, {
  refetchQueries: ['ListUrls']
})

async function createUrlHandler(values) {
  dialog?.value?.close(values)
}

/**
 * Default values
 */
const defaultValues = ref(urlDefaults())
</script>

<template>
  <div class="create-url-popup">
    <SidebarContent :title="ct.$t('title')">
      <DynamicForm
        name="create-url"
        :ref="(r: any) => context?.setRef('form', r)"
        :show-submit="false"
        class="create-url-form"
        :schema="CreateUrlForm()"
        :mutation="createUrl"
        :data="defaultValues"
        :labels="{
          submit: ct.$t('submit')
        }"
        @submit="createUrlHandler"
      ></DynamicForm>
      <template #footer>
        <Button v-bind="context.getRef('form')?.props?.submit.value"></Button>
      </template>
    </SidebarContent>
  </div>
</template>

<style lang="scss">
.create-url-form {
  .entity-type-radiobuttons {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;

    .p-radiobutton {
      width: 100%;
    }

    .url-custom-radiobuttons {
      width: calc(50% - 8px);
      background: #f6f7f9;
      border-radius: 8px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 50px;
      }
    }

    .url-switch-button {
      background-color: red;
    }
  }
}
</style>
