<script setup lang="ts">
import { CodexAutomationStatus } from '@/gql/graphql';
import { defineComponent, computed } from 'vue';
import { useI18nStore } from '@/@core/i18n';

defineComponent({
	name: 'WorkflowStatusSelect'
})

const ct = useI18nStore().createI18nContext('ct.workflows.general.enums.status')

const options = computed(() => {
	return [
		{
			label: ct.$t(CodexAutomationStatus.Active),
			value: CodexAutomationStatus.Active
		},
		{
			label: ct.$t(CodexAutomationStatus.Inactive),
			value: CodexAutomationStatus.Inactive
		},
		{
			label: ct.$t(CodexAutomationStatus.Deleted),
			value: CodexAutomationStatus.Deleted
		},
		{
			label: ct.$t(CodexAutomationStatus.Draft),
			value: CodexAutomationStatus.Draft
		},
	]
})
</script>
<template>
	<Dropdown :options="options"></Dropdown>
</template>