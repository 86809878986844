<script lang="ts" setup>
import { ref, nextTick, inject, defineProps, computed } from 'vue'
import { useI18nStore } from '@/@core/i18n'
import TieredMenu from 'primevue/tieredmenu'
import DialogContent from '@/modules/@global/components/DialogContent/DialogContent.vue'
import { FIELD_TYPES, FIELD_VALUE_TYPES } from '@/modules/models/editor-ts/fields/constants'

const props = defineProps({
  placeholder: {
    type: String,
    default: 'Enter URL pattern'
  },
  iconName: {
    type: String,
    default: 'PlusAlt'
  }
})

const ct = useI18nStore().createI18nContext('ct.models.components.url-configuration')
const dialog = inject('dialogRef')
const urlTemplate = ref(dialog?.value?.data?.pattern || '')
const menu = ref(null)

const getPossibleParams = (field) => {
  const configs = Object.freeze({
    [FIELD_TYPES.SECTION]: {
      [FIELD_VALUE_TYPES.SINGLE]: [
        `content.${field.attrs.alias}.id`,
        `content.${field.attrs.alias}.url`
      ],
      [FIELD_VALUE_TYPES.LIST]: [
        `getMain(content.${field.attrs.alias}).id`,
        `getMain(content.${field.attrs.alias}).url`
      ]
    },
    [FIELD_TYPES.AUTHOR]: {
      [FIELD_VALUE_TYPES.SINGLE]: [
        `content.${field.attrs.alias}.id`,
        `content.${field.attrs.alias}.url`
      ],
      [FIELD_VALUE_TYPES.LIST]: [
        `getFirst(content.${field.attrs.alias}).id`,
        `getFirst(content.${field.attrs.alias}).url`
      ]
    }
  })
  return configs[field.attrs.type]?.[field.attrs.valueType]
}

const fieldsOptions = computed(() => {
  const options = ['{id}', '{system.modelAlias}', '{system.modelId}']

  if (dialog?.value?.data?.getFieldsObjects) {
    const fields = dialog.value.data.getFieldsObjects(
      (block) =>
        (block.attrs.type == FIELD_TYPES.TEXT &&
          block.attrs.valueType == FIELD_VALUE_TYPES.SINGLE) ||
        (block.attrs.type == FIELD_TYPES.INTEGER &&
          block.attrs.valueType == FIELD_VALUE_TYPES.SINGLE) ||
        (block.attrs.type == FIELD_TYPES.DECIMAL &&
          block.attrs.valueType == FIELD_VALUE_TYPES.SINGLE) ||
        (block.attrs.type == FIELD_TYPES.DATE_TIME &&
          block.attrs.valueType == FIELD_VALUE_TYPES.SINGLE) ||
        block.attrs.type == FIELD_TYPES.SECTION ||
        block.attrs.type == FIELD_TYPES.AUTHOR
    )

    fields.forEach((f) => {
      const params = getPossibleParams(f)

      if (params) {
        options.push(...params.map((a) => `{${a}}`))
      } else {
        options.push(`{content.${f.attrs.alias}}`)
      }
    })
  }
  return options
})

function handleMenuItemSelect(code: string) {
  urlTemplate.value = `${
    urlTemplate.value.endsWith('/') ? urlTemplate.value : urlTemplate.value + '/'
  }${code}`
}

function toggleMenu(event: Event) {
  nextTick(() => {
    if (menu.value) {
      menu.value.toggle(event)
    } else {
      console.error('TieredMenu reference is not available')
    }
  })
}

function save() {
  dialog?.value?.close(urlTemplate.value)
}

function close() {
  dialog?.value?.close(false)
}
</script>

<template>
  <DialogContent :title="ct.$t('title')" :subtitle="ct.$t('urlInfo')" class="generalContent">
    <div class="url__sidebar-form-container">
      <input
        v-model="urlTemplate"
        :placeholder="urlTemplate"
        class="url__sidebar-value"
        type="text"
      />

      <div class="url__sidebar-value-dropdown" @click="toggleMenu" tabindex="0">
        <GjIcon :name="iconName" style="color: #667c99" />
      </div>
      <TieredMenu ref="menu" :model="fieldsOptions" popup>
        <template #item="{ item }">
          <span @click="handleMenuItemSelect(item)" class="items">
            <p>{{ item }}</p>
          </span>
        </template>
      </TieredMenu>
    </div>

    <div class="content">
      <div class="title">
        <GjIcon name="InfoAlt_fill" color="rgb(32, 110, 213)" />
      </div>
      <div>
        <h3>{{ ct.$t('urlInfo') }}</h3>
        <p>
          {{ ct.$t('description.1') }}
          <b>{{ ct.$t('description.2') }} </b>
          {{ ct.$t('description.3') }}
          {{ ct.$t('description.4') }}<br />
          <br />
          {{ ct.$t('description.5') }}
        </p>
        <ul>
          <li>
            {{ ct.$t('description.types.text-field') }}
            <code> {slugify(content.{{ ct.$t('description.field-alias') }})} </code>
          </li>
          <li>
            {{ ct.$t('description.types.date-time') }}
            <code> {getDate(content.{{ ct.$t('description.field-alias') }})} </code>,
            <code> {getMonth(content.{{ ct.$t('description.field-alias') }})} </code>,
            <code> {getYear(content.{{ ct.$t('description.field-alias') }})} </code>
          </li>
          <li>
            {{ ct.$t('description.types.section-single') }}
            <code>{content.{{ ct.$t('description.field-alias') }}.id}</code>,
            <code>{content.{{ ct.$t('description.field-alias') }}.url}</code>
          </li>
          <li>
            {{ ct.$t('description.types.section-list') }}
            <code>{getMain(content.{{ ct.$t('description.field-alias') }}).id}</code>,
            <code>{getMain(content.{{ ct.$t('description.field-alias') }}).url}</code>
          </li>
          <li>
            {{ ct.$t('description.types.author-single') }}
            <code>{content.{{ ct.$t('description.field-alias') }}.id}</code>,
            <code>{content.{{ ct.$t('description.field-alias') }}.url}</code>
          </li>
          <li>
            {{ ct.$t('description.types.author-list') }}
            <code>{getFirst(content.{{ ct.$t('description.field-alias') }}).id}</code>,
            <code>{getFirst(content.{{ ct.$t('description.field-alias') }}).url}</code>
          </li>
        </ul>
      </div>
    </div>
    <template #footer>
      <Button :label="ct.$t('cancel')" severity="secondary" @click="close" />
      <Button :label="ct.$t('save')" @click="save" />
    </template>
  </DialogContent>
</template>

<style scoped lang="scss">
.url__sidebar-form-container {
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #e0e5eb;
  border-radius: 5px;
  margin-bottom: 10px;
}

.url__sidebar-value {
  flex-grow: 1;
  border: none;
  width: 100%;
  height: 35px;
  font-size: 1rem;
}
input:focus {
  outline: none;
}
.url__sidebar-value-dropdown {
  cursor: pointer;

  display: flex;
  align-items: center;
}

.content {
  background-color: #f4f6fa;
  border: 1px solid #e0e5eb;
  border-radius: 4px;
  padding: 14px;
  font-size: 0.85rem;
  color: #667c99;
  line-height: 1.4;
  width: 50vw;
  overflow-x: hidden;
}

code {
  background-color: #eee;
  padding: 2px 4px;
  border-radius: 3px;
}
.items {
  cursor: pointer;
}
.items p {
  //background-color: red;
  padding: 5px;
  margin: 0;
}
h3 {
  margin: 0 0 10px;
  font-weight: bold;
}

.title {
  margin-right: 10px;
}
</style>
