<script setup lang="ts">
import FormSkeleton from '@/modules/@global/components/Skeletons/FormSkeleton.vue'
import { inject } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { READ_SITE, UPDATE_SITE } from '@/modules/sites/queries/index.rest'
import DynamicForm from '@/modules/@global/components/DynamicForm/DynamicForm.vue'
import { UpdateSiteForm } from '@/modules/sites/forms/Site'
import { cloneDeep } from 'lodash'
import { useI18nStore } from '@/@core/i18n'
import type { Context } from '@/modules/@global/composables/useContext'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.sites.popups.update-site')

/**
 * Dialog
 */
const dialog = inject(DialogRefKey)

/**
 * Read site
 */
const { loading, result, error } = useQuery(
  READ_SITE,
  () => ({
    id: dialog?.value?.data?.id
  }),
  {
    fetchPolicy: 'network-only'
  }
)

/**
 * Update site
 */
const updateSite = useMutation(UPDATE_SITE)

async function updateSiteHandler(values) {
  dialog?.value?.close(values)
}
</script>

<template>
  <SidebarContent :title="ct.$t('title')">
    <template v-if="loading">
      <FormSkeleton />
    </template>

    <template v-else-if="error">
      <InlineMessage severity="error" :text="error"></InlineMessage>
    </template>

    <template v-else>
      <DynamicForm
        name="update-site"
        :ref="(r:any) => context?.setRef('form', r)"
        :show-submit="false"
        :schema="UpdateSiteForm()"
        :data="cloneDeep(result?.site)"
        :mutation="updateSite"
        :labels="{
          submit: ct.$t('submit')
        }"
        @submit="updateSiteHandler"
      ></DynamicForm>
    </template>

    <template #footer>
      <Button
        v-if="!loading && !error"
        v-bind="context.getRef('form')?.props?.submit.value"
      ></Button>
    </template>
  </SidebarContent>
</template>
