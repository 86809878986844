import StatusFilterComponent from './Status.vue'
import { useI18nStore } from '@/@core/i18n'
import { CodexAutomationStatus } from '@/gql/graphql'

export default class StatusFilter {
  static COMPONENT = StatusFilterComponent
  static OPERATORS: {
    include: string
    exclude: string
  } = {
    include: 'in',
    exclude: 'notIn'
  }

  static type: string = 'workflows-status'

  operator: string | null = null

  value: Array<CodexAutomationStatus> | null = null

  constructor(
    {
      operator = StatusFilter.OPERATORS.include,
      value = []
    }: { operator: string; value?: Array<CodexAutomationStatus> } = {
      operator: StatusFilter.OPERATORS.include
    }
  ) {
    this.operator = operator
    this.value = value
  }
  set(
    {
      operator = StatusFilter.OPERATORS.include,
      value = []
    }: { operator: string; value?: Array<CodexAutomationStatus> } = {
      operator: StatusFilter.OPERATORS.include
    }
  ) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value?.length
  }

  getValueLabel(): string | null {
    const ct = useI18nStore().createI18nContext('ct.workflows')

    switch (this.operator) {
      case StatusFilter.OPERATORS.include:
        return ct.$t('filters.status.active-label.is', {
          value: ct.$t(`general.enums.status.${this.value?.[0]}`)
        })
      case StatusFilter.OPERATORS.exclude:
        return ct.$t('filters.status.active-label.is-not', {
          value: ct.$t(`general.enums.status.${this.value?.[0]}`)
        })
      default:
        return null
    }
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case StatusFilter.OPERATORS.include:
        return {
          in: this.value
        }
      case StatusFilter.OPERATORS.exclude:
        return {
          notIn: this.value
        }
      default:
        return null
    }
  }

  static validate({ operator, value }: { operator: string; value?: Array<CodexAutomationStatus> }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (value?.length === 0) {
      return 'Value is required'
    }

    return true
  }

  static fromQueryParam({
    operator,
    value
  }: {
    operator: string
    value?: Array<CodexAutomationStatus>
  }) {
    return new StatusFilter({
      operator,
      value
    })
  }
}
