import { computed } from 'vue'

export function filterSelectedNodesComputed(
  options: Object = { keepLast: false, editor: null },
  func: Function = () => true
) {
  return computed(() => {
    return _filterSelectedNodes(options, func)
  })
}

export function filterSelectedNodes(
  options: Object = { keepLast: false, editor: null, doc: null, selection },
  func: Function = () => true
) {
  if (!options.editor) return []

  // prettier-ignore
  const { state: { selection: stateSelection }, view } = options.editor
  const selection = options.selection || stateSelection
  const doc = options.doc || view.docView.node

  let lastBlock = null
  const selectedNodes = []

  // if (options.doc) console.log(selection.from, selection.to)

  // prettier-ignore
  doc.nodesBetween(selection.from, selection.to, (node, pos, parent, index) => {
    const nodeFrom = pos
    const nodeTo = pos + node.nodeSize
    const nodeInfo = { node, nodeFrom, nodeTo }

    if (func({ node, pos, parent, index, nodeFrom, nodeTo, selection })) {
      selectedNodes.push(nodeInfo)
    }

    if (node.type.name !== 'text') {
      lastBlock = nodeInfo
    }
  })

  // if (options.doc) console.log(selectedNodes)

  if (options?.keepLast && selectedNodes.length === 0 && lastBlock) {
    selectedNodes.push(lastBlock)
  }

  const range = { from: Math.min(), to: Math.max() }
  selectedNodes.forEach((node) => {
    range.from = Math.min(range.from, node.nodeFrom)
    range.to = Math.max(range.to, node.nodeTo)
  })

  return {
    nodes: selectedNodes,
    range: range
  }
}
