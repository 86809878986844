import { CodexHooks } from '@/@core'
import CreateModel from '@/modules/models/popups/CreateModel.vue'
import ConfirmDelete from '@/modules/@global/popups/ConfirmDelete.vue'
import { useI18nStore } from '@/@core/i18n'

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  const ct = useI18nStore().createI18nContext('ct.models.popups.confirm-delete-models')

  items = [
    ...items,

    /* Create Model */
    {
      type: 'Sidebar',
      name: 'create-model',
      component: CreateModel
    },
    {
      type: 'Dialog',
      name: 'confirm-delete-models',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('cancel'),
          confirm: ct.$p('confirm'),
          title: ct.$p('title'),
          message: ct.$p('message')
        }
      }
    }
  ]

  return items
})