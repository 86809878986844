<script setup lang="ts">
import { useMutation, useQuery } from '@vue/apollo-composable'
import { inject } from 'vue'
import { cloneDeep } from 'lodash'
import { UpdateTagForm } from '@/modules/tags/forms/Tag'
import FormSkeleton from '@/modules/@global/components/Skeletons/FormSkeleton.vue'
import { useI18nStore } from '@/@core/i18n'
import type { Context } from '@/modules/@global/composables/useContext'
import { READ_TAG, UPDATE_TAG } from '../queries/index.rest'
import usePopups from '@/modules/@global/composables/usePopups'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.tags.popups.update-tag')

const { showPopup } = usePopups(context)

/**
 * Dialog
 */
let dialog = inject(DialogRefKey)
/**
 * Read url
 */
const { loading, result, error } = useQuery(
  READ_TAG,
  () => ({
    id: dialog?.value?.data?.id
  }),
  {
    fetchPolicy: 'network-only'
  }
)

/**
 * Update tag
 */
const updateTag = useMutation(UPDATE_TAG)

async function updateTagHandler(values) {
  dialog?.value?.close(values)
}

async function mergeTag(tag: any) {
  await showPopup('merge-tag')
}
</script>

<template>
  <SidebarContent :title="ct.$t('title')">
    <template v-if="loading">
      <FormSkeleton />
    </template>

    <template v-else-if="error">
      <InlineMessage severity="error" :text="error"></InlineMessage>
    </template>

    <template v-else>
      <div class="merge-popup-header">
        <Text type="title-medium-small">{{ result.tag.tagValue }}</Text>
        <Button
          :label="ct.$t('merge')"
          icon="IconparkShuffleOne"
          @click="mergeTag"
          icon-pos="right"
          class="merge-button"
        />
      </div>
      <TabView>
        <TabPanel :header="ct.$t('panels.details')">
          <DynamicForm
            name="update-tag"
            :ref="(r: any) => context?.setRef('form', r)"
            :show-submit="false"
            :schema="UpdateTagForm()"
            :data="cloneDeep(result?.tag)"
            :mutation="updateTag"
            :labels="{
              submit: ct.$t('submit')
            }"
            @submit="updateTagHandler"
          ></DynamicForm>
        </TabPanel>
        <TabPanel :header="ct.$t('panels.usage')">TO DO: Usage </TabPanel>
      </TabView>
    </template>
    <template #footer>
      <Button v-if="!loading && !error" v-bind="context.getRef('form')?.props?.submit.value" />
    </template>
  </SidebarContent>
</template>
<style lang="scss">
.update-tag {
  position: relative;
  height: 100%;
  width: 700px !important;
  max-width: 700px;

  .merge-popup-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .merge-button {
      height: 30px;

      svg {
        height: 16px;
      }
    }
  }

  .p-sidebar-content-footer {
    position: absolute;
    z-index: 1;
    width: 100%;
    bottom: 0;
  }

  .p-tabview-panels {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
