import { AbilityBuilder, defineAbility, createMongoAbility } from '@casl/ability'
import { defineStore } from 'pinia'
import { ref, watchEffect } from 'vue'
import { CodexDebug, CodexHooks } from '@/@core'
import pinia from '../store'

export const ability = defineAbility((can) => {})

export const useAbilityStore = defineStore('codex-ability', () => {
  const refreshKey = ref(0)

  /**
   * This is a hack to force the ability to refresh when a new filter is added or removed.
   */
  CodexHooks.addAction('hooks/filter-added?name=ability/items', () => {
    refreshKey.value++
  })

  CodexHooks.addAction('hooks/filter-removed?name=ability/items', () => {
    refreshKey.value++
  })

  watchEffect(() => {
    // Use a ref to force the computed to refresh when the refreshKey changes
    refreshKey.value

    CodexDebug.abilityInfo('abilityItems - Start')

    let items: Array<any> = []
    const permissionsData: any = CodexHooks.applyFilters('ability/permissions', null)

    const { can, cannot, rules } = new AbilityBuilder(createMongoAbility)

    // Use hooks to process ability items
    try {
      items = CodexHooks.applyFilters('ability/items', items, null, permissionsData, can, cannot)
    } catch (error) {
      CodexDebug.abilityError('abilityItems - Root', error)
    }

    ability.update(rules)

    // Debug
    CodexDebug.abilityInfo('abilityItems - Final', items)

    return items
  })
})

// ToDo: Test this to see if it works without 'initializing' the store here
// const abilityStore = useAbilityStore(pinia)

export default ability
