import { CodexHooks } from '@/@core'
import { WIDGET_GROUPS } from '@/modules/models/editor-ts/fields/constants'

CodexHooks.addFilter('layouts/types', (items) => {
  return [
    ...items,
    {
      pluginName: 'codex',
      displayName: 'Row',
      name: 'row',
      render: () => import('./Row.vue'),
      renderEditor: () => import('./Row.vue'),
      renderEditorPanel: [
        {
          label: 'General',
          component: () => import('./RowSidebar.vue'),
          isGeneral: true,
          icon: 'Settings'
        }
      ],
      group: WIDGET_GROUPS.GENERAL,
      icon: () => import('./icon.svg'),
      attrs: {
        columns: {
          default: 12,
          responsive: true
        }
      }
    }
  ]
})
