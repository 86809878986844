<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable'
import { inject } from 'vue'
import { CreateViewForm } from '@/modules/entries/forms/View'
import { useI18nStore } from '@/@core/i18n'
import type { Context } from '@/modules/@global/composables/useContext'
import { CREATE_VIEW } from '@/modules/entries/queries/index.rest'
import { viewDefaults } from '@/modules/entries/constants'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.views.popups.create-view')

/**
 * Dialog
 */
let dialog = inject(DialogRefKey)

/**
 * Create View
 */
const createView = useMutation(CREATE_VIEW, {
  refetchQueries: ['Organization']
})

async function createViewHandler(values, response) {
  dialog?.value?.close(response)
}

/**
 * Default values
 */
const defaultValues = viewDefaults()
defaultValues.customizations = {
  columns: dialog.value.data.columns,
  order: dialog.value.data.order
}
defaultValues.searchTerm = dialog.value.data.searchTerm
defaultValues.filters = dialog.value.data.filters
</script>

<template>
  <SidebarContent :title="ct.$t('title')">
    <DynamicForm
      name="create-view"
      :ref="(r: any) => context?.setRef('form', r)"
      :show-submit="false"
      :schema="CreateViewForm()"
      :mutation="createView"
      :data="defaultValues"
      :labels="{
        submit: ct.$t('submit')
      }"
      @submit="createViewHandler"
    ></DynamicForm>

    <template #footer>
      <Button v-bind="context.getRef('form')?.props?.submit.value"></Button>
    </template>
  </SidebarContent>
</template>

<style scoped></style>
