import { useI18nStore } from '@/@core/i18n'
import type { FormField } from '@/modules/@global/components/DynamicForm/DynamicForm'
import * as yup from 'yup'

/**
 * ApiKey forms fields definition
 */
interface ViewFormFields {
  name: FormField
  path: FormField
  type: FormField
  teams: FormField
}

/**
 * ApiKey forms fields
 */
export const ViewFormFields = (): ViewFormFields => {
  const ct = useI18nStore().createI18nContext('ct.views.forms.fields')

  return {
    name: {
      type: 'Text',
      name: 'name',
      label: ct.$t('name.label'),
      rules: yup.string().required('Name is required')
    },
    path: {
      type: 'Text',
      name: 'path',
      label: ct.$t('path.label')
    },
    type: {
      type: 'Dropdown',
      name: 'type',
      label: '',
      props: {
        options: [
          {
            label: ct.$t('type.private'),
            value: 1
          },
          {
            label: ct.$t('type.shared'),
            value: 2
          }
        ]
      }
    },
    teams: {
      type: 'TeamsSelect',
      name: 'teams',
      label: ct.$t('teams.label'),
      show: (values) => values.type === 2
    }
  }
}

/**
 * Create view form
 */
const CreateViewForm = (): FormField[] => {
  const fields = ViewFormFields()

  return [
    {
      type: 'Row',
      children: [
        // Name
        {
          ...fields.name,
          layout: { col: 12 }
        },
        // Path
        {
          ...fields.path,
          layout: { col: 12 }
        },
        // Type
        {
          ...fields.type,
          layout: { col: 12 }
        },
        // Teams
        {
          ...fields.teams,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

/**
 * Create view form
 */
const UpdateViewForm = (): FormField[] => {
  const fields = ViewFormFields()

  return [
    {
      type: 'Row',
      children: [
        // Name
        {
          ...fields.name,
          layout: { col: 12 }
        },
        // Path
        {
          ...fields.path,
          layout: { col: 12 }
        },
        // Type
        {
          ...fields.type,
          layout: { col: 12 }
        },
        // Teams
        {
          ...fields.teams,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

export { CreateViewForm, UpdateViewForm }
