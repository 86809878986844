import { enumMapping } from '@/modules/@global/utils/EnumMapping'
import { CodexPresetGravity, CodexPresetResize } from '@/gql/graphql'
import { merge } from 'lodash'

/**
 * Preset Name
 */
export const PRESETS_NAME = 'presets-listing'

/**
 * Preset Enums
 */
export const GRAVITY_MAPPING = enumMapping({
  [CodexPresetGravity.North]: 1,
  [CodexPresetGravity.South]: 2,
  [CodexPresetGravity.East]: 3,
  [CodexPresetGravity.West]: 4,
  [CodexPresetGravity.Center]: 5,
  [CodexPresetGravity.NorthEast]: 6,
  [CodexPresetGravity.NorthWest]: 7,
  [CodexPresetGravity.SouthEast]: 8,
  [CodexPresetGravity.SouthWest]: 9
})

export const RESIZE_MAPPING = enumMapping({
  [CodexPresetResize.Fit]: 1,
  [CodexPresetResize.Fill]: 2,
  [CodexPresetResize.Auto]: 3
})

/**
 * Preset Defaults
 */
export function presetDefaults(preset = {}) {
  return merge(
    {
      name: null,
      alias: null,
      width: 0,
      height: 0,
      resize: CodexPresetResize.Fit,
      gravity: CodexPresetGravity.North,
      sites: []
    },
    preset
  )
}
