import { CodexHooks } from '@/@core'

// TipTap Extensions
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import Paragraph from '@tiptap/extension-paragraph'
import Heading from '@tiptap/extension-heading'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import CodeBlock from '@tiptap/extension-code-block'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import HardBreak from '@tiptap/extension-hard-break'
import Blockquote from '@tiptap/extension-blockquote'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableHeader from '@tiptap/extension-table-header'
import TableCell from '@tiptap/extension-table-cell'

// Custom Extensions
import InteractiveBlock from '@/modules/@global/components/RichContent/nodes/InteractiveBlock'

CodexHooks.addFilter('rich-content/nodes', (extensions, context) => {
  extensions = [
    ...extensions,

    /**
     * Document
     */
    Document,

    /**
     * Text
     */
    Text,

    /**
     * Paragraph
     */
    Paragraph.extend({
      // draggable: true
      // ToDo: Make all elements draggable
      // renderHTML({ HTMLAttributes }) {
      //   return [
      //     'p',
      //     mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      //     ['span', { 'data-drag-handle': true, contenteditable: 'false', draggable: 'true' }],
      //     ['span', {}, 0]
      //   ]
      // }
    }),

    /**
     * Heading
     */
    Heading,

    /**
     * Bullet List
     */
    BulletList,

    /**
     * Ordered List
     */
    OrderedList,

    /**
     * List Item
     * For some reason listItem had the content of 'paragraph block*' by default
     * This wouldn't allow us to have any other element as first child of a list item
     */
    ListItem.extend({
      content: 'block+'
    }),

    /**
     * Table
     */
    Table.configure({
      resizable: true
    }),
    TableRow,
    TableHeader,
    TableCell,

    /**
     * Code Block
     */
    CodeBlock,

    /**
     * Horizontal Rule
     */
    HorizontalRule,

    /**
     * Hard Break
     */
    HardBreak,

    /**
     * Blockquote
     */
    Blockquote,

    /**
     * InteractiveBlock ( test block )
     */
    InteractiveBlock
  ]

  return extensions
})
