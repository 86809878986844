import { CodexDebug } from '@/@core'
import type { NavigationItem } from '@/@core/navigation/types'
import Link from '@/modules/@core/components/Sidebar/Link.vue'
import Modules from '@/modules/@core/components/Sidebar/Modules.vue'
import { type Component } from 'vue'
import { useLink } from 'vue-router'
import { useRoute } from '@/@core/router'

/**
 * Resolve the component type for a navigation item
 *
 * @param item The navigation item to resolve the component type for
 */
export function resolveNavComponentType(item: NavigationItem): Component | string | undefined {
  switch (item.type) {
    case 'modules':
      return Modules
    case 'item':
      return Link
    case 'group':
      return 'group'
    case 'divider':
      return 'divider'
    case 'custom':
      return item.component
    default:
      return 'item'
  }
}

/**
 * Check if a navigation item is active
 *
 * @param item The navigation item to check if it is active
 */
export function isItemActive(item: NavigationItem): boolean {
  try {
    const route = useRoute()
    const link = useLink({ to: item.route })

    if (route?.value?.meta?.activeNavItem === item.name) return true

    return link.isActive.value
  } catch (error) {
    CodexDebug.navigationError('isItemActive', item, error)
    return false
  }
}

/**
 * Find active module
 */
export function findActiveModule(
  items: NavigationItem[],
  parent: NavigationItem | undefined = undefined
): NavigationItem | undefined {
  for (const item of items) {
    if (parent && isItemActive(item)) {
      return parent ? parent : undefined
    }

    if (item.children && item.children.length > 0) {
      const active = findActiveModule(item.children, parent ? parent : item)
      if (active) return active
    }
  }
}
