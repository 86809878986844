import { CodexHooks } from '@/@core'

CodexHooks.addFilter('layouts/types', (items) => {
  return [
    ...items,
    {
      pluginName: 'codex',
      displayName: 'Column',
      name: 'column',
      render: () => import('./Col.vue'),
      renderEditor: () => import('./Col.vue'),
      renderEditorPanel: [
        {
          icon: 'Settings',
          label: 'General',
          component: () => import('./ColSidebar.vue'),
          isGeneral: true
        }
      ],
      group: null,
      attrs: {
        columns: {
          default: 2,
          responsive: true
        },
        order: {
          default: 1,
          responsive: true
        },
        orientation: {
          default: 'vertical',
          responsive: true
        },
        align: {
          default: 'left',
          responsive: true
        }
      }
    }
  ]
})
