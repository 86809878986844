<script setup lang="ts">
import CheckboxFilter from './CheckboxFilter'
import { computed, ref } from 'vue'
import { useI18nStore } from '@/@core/i18n/index'
import Checkboxes from '@/modules/@global/components/Checkboxes/Checkboxes.vue'
import type { Filter } from '@/modules/@global/components/Filters/Filters'
// import Checkboxes

const ct = useI18nStore().createI18nContext('ct.global.filters.checkbox')
const ctGlobalFilters = useI18nStore().createI18nContext('ct.global.components.filters')
const search = ref('')
// eslint-disable-next-line no-undef
const modelValue = defineModel<{
	operator: string,
	value: string[]
}>()

const props = withDefaults(
	defineProps<{
		filter: Filter
	}>(),
	{
	}
)

const checkboxOptions = computed(() => {
	return props.filter.options?.filter(x => {
		return x.label.toLowerCase().includes(search.value.toLowerCase()) ||
			x.value.toLowerCase().includes(search.value.toLowerCase())
	});
})

const options = computed(() => [
	{
		label: ctGlobalFilters.$t('operators.include'),
		value: CheckboxFilter.OPERATORS.include
	},
	{
		label: ctGlobalFilters.$t('operators.exclude'),
		value: CheckboxFilter.OPERATORS.exclude
	},
])
</script>

<template>
	<div class="new-filters-dropdown__filter-body new-filters-dropdown__filter-body--scroll">
		<!-- TODO: check if dropdown for operator (include, exclude) is necessary -->
		<Row v-if="!props.filter.hideDropdown" align-items="center">
			<Col>
			<Dropdown v-model="modelValue.operator" :options="options" size="small" />
			</Col>
		</Row>
		<Row v-if="!props.filter.hideSearch">
			<Col>
			<InputText v-model="search" type="text" :placeholder="`${ct.$t('search')} ${props.filter.label}`" />
			</Col>
		</Row>
		<Row align-items="center">
			<Col>
			<Checkboxes v-model="modelValue.value" :options="checkboxOptions" />
			</Col>
		</Row>
		<Row v-if="!checkboxOptions.length">
			<Col>
			<InlineMessage :plain-text="true" severity="warn" :text="ct.$t('no-options')" />
			</Col>
		</Row>
	</div>
</template>

<style scoped></style>
