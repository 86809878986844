<script setup lang="ts">
import { inject } from 'vue'
import { useI18nStore } from '@/@core/i18n'

let dialog = inject('dialogRef')

const ct = useI18nStore().createI18nContext('ct.entries.popups.confirm-takeover-lock')

const handleCancel = () => {
  dialog?.value?.close(false)
}
const handleOk = () => {
  dialog?.value?.close(true)
}
</script>

<template>
  <DialogContent :title="ct.$t('title')">
    <Text type="body-regular-base" :text="ct.$t('description')" />
    <template #footer>
      <Button @click="handleCancel" :label="ct.$t('actions.cancel')" />
      <Button severity="danger" @click="handleOk" :label="ct.$t('actions.take-over')" />
    </template>
  </DialogContent>
</template>

<style scoped></style>
