import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=organization', (items) => {
  return [
    ...items,
    {
      path: 'models',
      name: 'models',
      component: () => import('@/modules/models/pages/ModelsListing.vue')
    },
    {
      path: 'models/:modelId',
      name: 'models-edit',
      component: () => import('@/modules/models/pages/ModelsEdit.vue'),
      meta: {
        activeNavItem: 'models'
      }
    }
  ]
})
