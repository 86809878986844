import { merge } from 'lodash'
import { ref, type Ref, provide, onMounted, onUnmounted } from 'vue'
import { GenerateUUID } from '@/modules/@global/utils/GenerateUUID'
import { CodexHooks } from '@/@core'
import { useRoute } from 'vue-router'

export interface Context {
  __context: true
  id: string
  name: string
  type: 'page' | 'sidebar' | 'popup'
  params?: Record<string, any>
  siteId: string
  organizationId: string
  data: object
  refs: Record<string, Ref>
  parentContext?: Context
  setRef: (name: string, value: any) => void
  getRef: (name: string) => any
}

interface ContextConstructor {
  name: string
  type: 'page' | 'sidebar' | 'popup'
  siteId?: string
  organizationId?: string
  data?: object
  parentContext?: Context
}

export default function useContext({
  name,
  type,
  data,
  siteId,
  parentContext,
  organizationId
}: ContextConstructor): Ref<Context> {
  if (!name) throw new Error('Context name is required')
  if (!type) throw new Error('Context type is required')

  // prettier-ignore
  if (!['page', 'sidebar', 'popup'].includes(type)) throw new Error('Context type must be one of: page, sidebar, popup')

  const route = useRoute()

  const context = ref({
    __context: true,
    id: GenerateUUID.GENERAL(),
    name,
    type,
    parentContext,
    params: route.params || {},
    siteId: siteId || route.params.siteId,
    organizationId: organizationId || route.params.organizationId,
    data: merge({}, data),
    refs: {} as Record<string, Ref>,
    setRef: (name: string, value: any) => {
      if (context.value.refs[name] || !value) return
      context.value.refs[name] = ref(value)
    },
    getRef: (name: string) => {
      return context.value.refs[name]
    }
  })

  provide('context', context)

  onMounted(() => {
    CodexHooks.applyActions('context/created', null, context.value)

    CodexHooks.applyActions(`context/created?type=${type}`, null, context.value)

    CodexHooks.applyActions(`context/created?name=${name}`, null, context.value)
  })

  onUnmounted(() => {
    CodexHooks.applyActions('context/destroyed', null, context.value)

    CodexHooks.applyActions(`context/destroyed?type=${type}`, null, context.value)

    CodexHooks.applyActions(`context/destroyed?name=${name}`, null, context.value)
  })

  return context
}
