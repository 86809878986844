import { CodexHooks } from '@/@core'
import ConfirmDelete from '@/modules/@global/popups/ConfirmDelete.vue'
import PreviewAsset from './PreviewAsset.vue'
import VpUploadEdit from './VpUploadEdit.vue'

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  items = [
    ...items,
    // TODO: Add multiple assets delete popup
    {
      type: 'Dialog',
      name: 'confirm-delete',
      component: ConfirmDelete
    },
    {
      // TODO: Create a FullScreen type. This is a global component.
      type: 'Dialog',
      name: 'preview-asset',
      component: PreviewAsset
    },
    {
      // TODO: Create a FullScreen type. This is a global component.
      type: 'Sidebar',
      name: 'vp-upload-edit',
      component: VpUploadEdit
    }
  ]

  return items
})
