import SiteListFilterComponent from './SiteList.vue'
import { useOrganization } from '@/modules/organizations/store/organization'

export default class SiteListFilter {
  static COMPONENT = SiteListFilterComponent
  static OPERATORS: {
    all: string
    some: string
    none: string
  } = {
    all: 'all',
    some: 'some',
    none: 'none',
  }

  static type: string = 'site-list'

  operator: string | null = null

  value: Array<string> | null = null

  constructor(
    {
      operator = SiteListFilter.OPERATORS.some,
      value = [],
    }: { operator: string; value?: Array<string>; } = {
      operator: SiteListFilter.OPERATORS.some
    }
  ) {
    this.operator = operator
    this.value = value
  }
  set(
    {
      operator = SiteListFilter.OPERATORS.some,
      value = [],
    }: { operator: string; value?: Array<string>; } = {
      operator: SiteListFilter.OPERATORS.some
    }
  ) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value?.length
  }

  getValueLabel(): string | null {
		const organization = useOrganization()
		const sites = organization.getSitesByIDs(this.value ? this.value : [])
    return sites.map((site: { name: string }) => site.name).join(', ')
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value
    }
  }

  asGraphQL() {
    switch (this.operator) {
			case SiteListFilter.OPERATORS.all:
				return {
					all: this.value
				}
			case SiteListFilter.OPERATORS.some:
				return {
					some: this.value
				}
			case SiteListFilter.OPERATORS.none:
				return {
					none: this.value
				}
      default:
        return null
    }
  }

  static validate({ operator, value }: { operator: string; value?: Array<string> }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (value?.length === 0) {
      return 'Value is required'
    }

    return true
  }
	
  static fromQueryParam({
    operator,
    value
  }: {
    operator: string
    value?: Array<string>
  }) {
    return new SiteListFilter({
      operator,
      value
    })
  }
}
