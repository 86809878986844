<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
	defineProps<{
		type?:
		| 'body-regular-smallest'
		| 'body-regular-smaller'
		| 'body-regular-small'
		| 'body-regular-base'
		| 'body-regular-headline'
		| 'body-medium-smallest'
		| 'body-medium-smaller'
		| 'body-medium-small'
		| 'body-medium-base'
		| 'body-medium-headline'
		| 'body-semi-bold-smallest'
		| 'body-semi-bold-smaller'
		| 'body-semi-bold-small'
		| 'body-semi-bold-base'
		| 'body-semi-bold-headline'
		| 'title-medium-base'
		is?: 'p' | 'span' | 'div' | 'label'
		clamp?: number
		severity?:
		| 'danger'
		| 'warning'
		| 'info'
		| 'success'
		| 'help'
		| 'primary'
		| 'main'
		| 'subtitle'
		| 'body'
		text?: string
	}>(),
	{
		type: 'body-regular-base',
		is: 'p'
	}
)

const getTextClasses = computed(() => {
	return {
		[`text--${props.type}`]: props.type,
		['text--clamp']: props.clamp,
		[`text--${props.severity}`]: props.severity
	}
})
</script>
<template>
	<component :is="is" class="text" :style="{ '--text-line-clamp': props.clamp }" :class="getTextClasses">
		<slot>{{ text }}</slot>
	</component>
</template>
