import gql from 'graphql-tag'

export const PUBLISHABLE_APIKEY_INPUT = gql`
  fragment PublishableApiKeyInput on CodexApiKey {
    createdAt
    createdBy
    id
    teams
    name
    expiresAt
    hasExpiry
    apiKey
    updateAt
    updatedBy
  }
`

export const READ_APIKEY = gql`
  ${PUBLISHABLE_APIKEY_INPUT}

  query ReadApiKey($id: String!, $input: PublishableApiKeyInput!) {
    apiKey(id: $id, input: $input)
      @rest(type: "CodexApiKey", path: "organizationId/api-keys/{args.id}", method: "GET") {
      ...PublishableApiKeyInput
    }
  }
`

export const CREATE_APIKEY = gql`
  ${PUBLISHABLE_APIKEY_INPUT}

  mutation CreateApiKey($input: PublishableApiKeyInput!) {
    createApiKey(input: $input)
      @rest(type: "CodexApiKey", path: "organizationId/api-keys", method: "POST") {
      ...PublishableApiKeyInput
    }
  }
`

export const DELETE_APIKEY = gql`
  ${PUBLISHABLE_APIKEY_INPUT}

  mutation DeleteApiKey($id: String!, $input: PublishableApiKeyInput!) {
    deleteApiKey(id: $id, input: $input)
      @rest(type: "CodexApiKey", path: "organizationId/api-keys/{args.id}", method: "DELETE") {
      NoResponse
    }
  }
`

export const REVOKE_APIKEY = gql`
  ${PUBLISHABLE_APIKEY_INPUT}

  mutation RevokeApiKey($id: String!) {
    revokeApiKey(id: $id, input: {})
      @rest(type: "CodexApiKey", path: "organizationId/api-keys/{args.id}/revoke", method: "PUT") {
      NoResponse
    }
  }
`

export const REGENERATE_APIKEY = gql`
  ${PUBLISHABLE_APIKEY_INPUT}

  mutation RegenerateApiKey($id: String!) {
    regenerateApiKey(id: $id, input: {})
      @rest(
        type: "CodexApiKey"
        path: "organizationId/api-keys/{args.id}/regenerate"
        method: "PUT"
      ) {
      ...PublishableApiKeyInput
    }
  }
`
