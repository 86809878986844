import { CodexHooks } from '@/@core'
import { defineStore } from 'pinia'
import { ref, type Ref, computed } from 'vue'

export const usePopupsStore = defineStore('popups', () => {
  const refreshKey: Ref<number> = ref(0)

  /**
   * This is a hack to force form popups to refresh when a new filter is added or removed.
   */
  CodexHooks.addAction('hooks/filter-added?name=popups/popups', () => {
    refreshKey.value++
  })

  CodexHooks.addAction('hooks/filter-removed?name=popups/popups', () => {
    refreshKey.value++
  })

  /**
   * Gather popups from hooks
   */
  const popups = computed(() => {
    // Use a ref to force the computed to refresh when the refreshKey changes
    refreshKey.value

    // Get the popups from the hooks
    const items: any[] = CodexHooks.applyFilters('popups/popups', [])

    return items
  })

  return {
    popups
  }
})
