import { CodexHooks } from '@/@core'
import UrlConfigurationPopup from '@/modules/@global/components/UrlConfiguration/UrlConfigurationPopup.vue'

CodexHooks.addFilter('popups/popups', (items = []) => {
  items.push({
    type: 'Dialog',
    name: 'url-configuration',
    component: UrlConfigurationPopup
  })

  return items
})
