import { CodexHooks } from '@/@core'

import UrlEntityTypeFilter from './entity-type/UrlEntityTypeFilter'

CodexHooks.addFilter('filters/types', (items) => {
  return [
    ...items,
    {
      type: 'urlEntityType',
      component: UrlEntityTypeFilter
    }
  ]
})
