import { useI18nStore } from '@/@core/i18n'
import { CodexPresetGravity, CodexPresetResize } from '@/gql/graphql'
import type { FormField } from '@/modules/@global/components/DynamicForm/DynamicForm'
import * as yup from 'yup'

/**
 * Preset forms fields definition
 */
interface PresetFormsFields {
  name: FormField
  alias: FormField
  width: FormField
  height: FormField
  resize: FormField
  gravity: FormField
  sites: FormField
}

/**
 * Preset forms fields
 */
export const PresetFormsFields = (): PresetFormsFields => {
  const ct = useI18nStore().createI18nContext('ct.presets.forms.fields')

  return {
    name: {
      type: 'Text',
      name: 'name',
      label: ct.$t('name.label')
    },
    alias: {
      type: 'Text',
      name: 'alias',
      label: ct.$t('alias.label'),
      rules: yup.string().required('Alias is required')
    },
    width: {
      type: 'Number',
      name: 'width',
      label: ct.$t('width.label'),
      rules: yup.number().required('Width is required').min(1, 'Width must be greater than 0')
    },
    height: {
      type: 'Number',
      name: 'height',
      label: ct.$t('height.label'),
      rules: yup.number().required('Height is required').min(1, 'Height must be greater than 0')
    },
    resize: {
      type: 'preset-resize-select',
      name: 'resize',
      label: ct.$t('resize.label')
    },
    gravity: {
      type: 'preset-gravity-select',
      name: 'gravity',
      label: ct.$t('gravity.label')
    },
    sites: {
      type: 'SitesSelect',
      name: 'sites',
      label: ct.$t('sites.label')
    }
  }
}

/**
 * Create preset form
 */
const CreatePresetForm = (): FormField[] => {
  const fields = PresetFormsFields()

  return [
    {
      type: 'Row',
      children: [
        // Name
        {
          ...fields.name,
          layout: { col: 12 }
        },
        // Alias
        {
          ...fields.alias,
          layout: { col: 12 }
        },
        // Width
        {
          ...fields.width,
          layout: { col: 6 }
        },
        // Height
        {
          ...fields.height,
          layout: { col: 6 }
        },
        // Resize
        {
          ...fields.resize,
          layout: { col: 12 }
        },
        // Gravity
        {
          ...fields.gravity,
          layout: { col: 12 }
        },
        // Sites
        {
          ...fields.sites,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

/**
 * Update preset form
 */
const UpdatePresetForm = (): FormField[] => {
  const fields = PresetFormsFields()

  return [
    {
      type: 'Row',
      children: [
        // Name
        {
          ...fields.name,
          layout: { col: 12 }
        },
        // Alias
        {
          ...fields.alias,
          props: {
            disabled: true
          },
          layout: { col: 12 }
        },
        // Width
        {
          ...fields.width,
          layout: { col: 6 }
        },
        // Height
        {
          ...fields.height,
          layout: { col: 6 }
        },
        // Resize
        {
          ...fields.resize,
          layout: { col: 12 }
        },
        // Gravity
        {
          ...fields.gravity,
          layout: { col: 12 }
        },
        // Sites
        {
          ...fields.sites,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

export { CreatePresetForm, UpdatePresetForm }
