import { CodexHooks } from '@/@core'

// TipTap Marks
import Link from '@tiptap/extension-link'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Strike from '@tiptap/extension-strike'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import Code from '@tiptap/extension-code'
import Color from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'

CodexHooks.addFilter('rich-content/marks', (marks) => {
  return [
    ...marks,

    /**
     * Link
     */
    Link.extend({
      addStorage() {
        return {
          createLink: false
        }
      }
    }).configure({
      openOnClick: false
    }),

    /**
     * Color
     */
    Color.configure({
      types: ['textStyle']
    }),

    /**
     * Text Style
     */
    TextStyle,

    /**
     * Bold
     */
    Bold,

    /**
     * Italic
     */
    Italic,

    /**
     * Underline
     */
    Underline,

    /**
     * Strike
     */
    Strike,

    /**
     * Subscript
     */
    Subscript,

    /**
     * Superscript
     */
    Superscript,

    /**
     * Code
     */
    Code
  ]
})
