<script setup lang="ts">
import { ref } from 'vue'
import { useI18nStore } from '@/@core/i18n'
import { CodexEntryStatus } from '@/gql/graphql'

const ct = useI18nStore().createI18nContext('ct.entries.general.enums.status')

const props = withDefaults(
  defineProps<{
    status: string
  }>(),
  {
    status: ''
  }
)
// TYPES
type SeverityMappingType = {
  [key: string]: 'warning' | 'success' | 'info' | 'danger' | 'primary'
}
//  MAPPINGS
const severityMapping = ref<SeverityMappingType>({
  [CodexEntryStatus.Published]: 'success',
  [CodexEntryStatus.Draft]: 'warning',
  [CodexEntryStatus.Archived]: 'warning',
  [CodexEntryStatus.Deleted]: 'danger',
  [CodexEntryStatus.Edited]: 'info',
  [CodexEntryStatus.Scheduled]: 'warning',
  [CodexEntryStatus.Unpublished]: 'primary'
})
</script>

<template>
  <Tag :severity="severityMapping[props.status]" indicator rounded>{{ ct.$t(props.status) }}</Tag>
</template>

<style scoped></style>
