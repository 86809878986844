<script setup lang="ts">
import icons from '@gjirafatech/gjirafa-icons/icons-full.json'

const modelValue = defineModel<string>()
</script>

<template>
  <Dropdown
    v-model="modelValue"
    :options="icons"
    filter
    :auto-filter-focus="true"
    option-label="iconName"
    option-value="iconName"
    :virtual-scroller-options="{ itemSize: 38, numToleratedItems: 20 }"
    class="w-full"
    list-style="height:250px"
  >
    <template #option="{ option }">
      <GjIcon :name="option.iconName" />
      {{ option.iconName }}
    </template>
    <template #value="{ value }">
      <GjIcon v-if="value" :name="value" />
      {{ value }}
    </template>
  </Dropdown>
</template>
