<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable'
import { computed, inject, ref } from 'vue'
// import { CreateAuthorForm } from '@/modules/authors/forms/Authors'
import { useI18nStore } from '@/@core/i18n'
// import { authorDefaults } from '../constants'
import type { Context } from '@/modules/@global/composables/useContext'
import { bytesToSize } from '../../@global/utils/formatNumbers'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.assets.popups.vp-upload-edit')

/**
 * Dialog
 */
let dialog = inject('dialogRef')

const list = ref(Array.from(dialog?.value?.data?.list))
/**
 * Default values
 */
// const defaultValues = authorDefaults()

// const project = ref(null)

const removeFile = (i: number) => {
  let updatedList = list.value
  updatedList.splice(i, 1)
  list.value = updatedList
}

async function editFilesHandler() {
  dialog?.value?.close(list)
}
</script>

<template>
  <SidebarContent :title="ct.$t('title')">
    <!-- <DynamicSelect v-model="project" /> -->
    <!-- <Dropdown
    v-model="project"
    :options="icons"
    filter
    :auto-filter-focus="true"
    option-label="iconName"
    option-value="iconName"
    :virtual-scroller-options="{ itemSize: 38, numToleratedItems: 20 }"
    class="w-full"
    list-style="height:250px"
  >
    <template #option="{ option }">
      <GjIcon :name="option.iconName" />
      {{ option.iconName }}
    </template>
    <template #value="{ value }">
      <GjIcon v-if="value" :name="value" />
      {{ value }}
    </template>
  </Dropdown> -->
    <div class="file-wrapper">
      <Text>{{ ct.$t('added-files') }}</Text>
      <div v-for="(file, index) in list" :key="file.name">
        <div class="file">
          <div class="file__icon">
            <GjIcon
              :name="file.type.includes('video') ? 'CameraVideo' : 'IconparkMicrophone'"
              size="20"
            />
          </div>
          <div class="file__description">
            <Text>{{ file.name }}</Text>
            <Text>{{ bytesToSize(file.size) }}</Text>
          </div>
          <div class="file__action" @click="removeFile(index)">
            <GjIcon name="Delete" size="20" />
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <Button @click="editFilesHandler" v-bind="{ label: ct.$t('save') }">
        <!-- <template #text>
          {{  }}
        </template> -->
      </Button>
      <!-- v-bind="context.getRef('form')?.props?.submit.value" -->
    </template>
  </SidebarContent>
</template>

<style lang="scss" scoped>
.file-wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;

  .file {
    display: flex;
    gap: 12px;
    justify-content: space-between;
    align-items: center;

    &__action {
      cursor: pointer;

      &:hover {
        color: red;
      }
    }

    &__description {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}
</style>
