import { kebabCase } from 'lodash'
import type { ColumnProps } from './types'

export default function generateColumnClasses(props: ColumnProps) {
  const classes: Record<string, boolean> = {
    col: true
  }

  const availableProps: Array<keyof ColumnProps> = ['col', 'order', 'flex', 'grow', 'shrink']
  const flexProps: Array<string> = ['order', 'grow', 'shrink']

  availableProps.forEach((prop) => {
    const value: ColumnProps[keyof ColumnProps] = props[prop]
    if (!value) return

    if (typeof value === 'number' || typeof value === 'string') {
      const append = flexProps.includes(prop) ? 'flex-' : ''
      classes[`${append}${kebabCase(prop)}-${value}`] = true
    } else if (typeof value === 'object') {
      Object.entries(value).forEach(([bp, value]) => {
        const append = flexProps.includes(prop) ? 'flex-' : ''
        classes[`${bp}:${append}${kebabCase(prop)}-${value}`] = true
      })
    }
  })

  return classes
}
