import { CodexHooks } from '@/@core'
import EntitiesField from '@/modules/labels/fields/EntitiesField.vue'
import ColorField from '@/modules/labels/fields/ColorField.vue'
import RestrictionsField from '@/modules/labels/fields/RestrictionsField.vue'

CodexHooks.addFilter('dynamic-form/fields', (items) => {
  return [
    ...items,
    {
      type: 'label-color',
      component: ColorField
    },
    {
      type: 'label-entities-select',
      component: EntitiesField
    },
    {
      type: 'label-restrictions',
      component: RestrictionsField
    }
  ]
})
