import { CodexHooks } from "@/@core";


CodexHooks.addFilter('router/items?parent=site', (items) => { 
	return [
		...items,
		{
			path: 'urls',
			name: 'urls',
			component: () => import('@/modules/urls/pages/UrlsListing.vue')
		}
	]
})
