<script setup lang="ts">
import { inject } from 'vue'
import { useI18nStore } from '@/@core/i18n'
import type { Context } from '@/modules/@global/composables/useContext'
import SidebarContent from '@/modules/@global/components/SidebarContent/SidebarContent.vue'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.urls.popups.create-url')

/**
 * Dialog
 */
const dialog = inject('dialogRef')

async function createUrlHandler(values) {
  dialog?.value?.close(values)
}
</script>

<template>
  <div class="add-entry-popup">
    <SidebarContent :title="ct.$t('add-entry')">
      Add Entry Popup Here
      <template #footer>
        <Button @click="createUrlHandler(selected)">{{ ct.$t('insert') }}</Button>
      </template>
    </SidebarContent>
  </div>
</template>
