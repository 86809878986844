<script setup lang="ts">
import DialogContent from '@/modules/@global/components/DialogContent/DialogContent.vue'
import { computed, inject } from 'vue'
import { useI18nStore } from '@/@core/i18n'
import { bytesToSize } from '../utils/formatNumbers'

/**
 * Dialog
 */
const dialog = inject('dialogRef')

const ct = useI18nStore().createI18nContext('ct.global.popups.upload-assets')

const list = computed(() => dialog?.value?.data?.list)

function handleCancel() {
  dialog?.value?.close(false)
}

function handleConfirm() {
  dialog?.value?.close()
}
</script>

<template>
  <DialogContent :title="ct.$t('title')">
    <!-- {{ list }} -->
    <div class="file-wrapper">
      <template v-for="file in list" :key="file.requestKey">
        <!-- {{ file }} -->
        <div class="file">
          <div class="file__icon">
            <GjIcon
              :name="file.file.type.includes('video') ? 'CameraVideo' : 'IconparkMicrophone'"
              size="20"
            />
          </div>
          <div class="file__description">
            <Text :clamp="1">{{ file.file.name }}</Text>
            <div class="file__description--progress">
              <Text>{{ bytesToSize(file.file.size) }}</Text>
              <Text>{{ file.progress }}</Text>
            </div>
          </div>
          <GjIcon name="Delete" size="20" />
        </div>
      </template>
    </div>
    <!-- <p class="confirm-popup__message">
      {{
        ct.d$tc(dialog?.data?.labels?.message, 'message', list.length, {
          value: list.join(', ')
        })
      }}
    </p>

    <div v-if="list.length > 1" class="confirm-popup__list">
      <ul>
        <li v-for="item in dialog?.data?.list" :key="item">{{ item }}</li>
      </ul>
    </div> -->

    <template #footer>
      <!-- <Button
        :label="ct.d$tc(dialog?.data?.labels?.cancel, 'cancel')"
        severity="secondary"
        @click="handleCancel"
      />
      <Button :label="ct.d$tc(dialog?.data?.labels?.confirm, 'confirm')" @click="handleConfirm" /> -->
    </template>
  </DialogContent>
</template>

<style scoped lang="scss">
.file-wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-height: 250px;
  overflow-y: auto;

  .file {
    display: flex;
    gap: 12px;
    justify-content: space-between;
    align-items: center;

    &__action {
      cursor: pointer;

      &:hover {
        color: red;
      }
    }

    &__description {
      display: flex;
      flex-direction: column;
      flex: 1;

      &--progress {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
