<script setup lang="ts">
import TreeSelect from 'primevue/treeselect'
import { computed, inject, ref } from 'vue'
import { useCodexQuery } from '@/modules/@global/composables/useCodexQuery'
import { useI18nStore } from '@/@core/i18n'

const context = inject('context')
// TODO: LOAD MORE
// TODO: SEARCH

const props = withDefaults(
  defineProps<{
    query?: Object
    selectedQuery?: Object
    variables: Object
    selectionMode?: 'single' | 'multiple'
    optionLabel?: string | ((data: any) => string)
    optionValue?: string
    display?: 'chip' | 'comma'
    placeholder?: string
  }>(),
  {
    selectionMode: 'single',
    optionLabel: 'name',
    optionValue: 'id',
    display: 'chip',
    placeholder: null
  }
)

const modelValue = defineModel()

const limit = ref(1000)
const searchTerm = ref('')

const { loading, result, error, fetchMore } = useCodexQuery(
  props.query,
  () => {
    return {
      offset: 0,
      limit: limit.value,
      where: {
        ...props.variables,
        query: searchTerm.value
      }
    }
  },
  {
    debounce: 100
  }
)

const records = computed(() => {
  return result?.value?.data?.items || []
})

const transformedRecords = computed(() => {
  return records.value.map(
    (item: { id: string; tagValue: string; children: { items: any[] } }) => ({
      key: item[props.optionValue],
      label: item[props.optionLabel],
      children:
        item.children?.items?.length > 0
          ? item.children.items.map((child) => ({
              key: child[props.optionValue],
              label: child[props.optionLabel]
            }))
          : []
    })
  )
})

const selection = computed({
  get() {
    const ids = {}
    let value = props.selectionMode === 'single' ? [modelValue.value] : modelValue.value
    value = value.filter(Boolean)

    value?.forEach((s) => {
      ids[s] =
        props.selectionMode === 'checkbox'
          ? {
              checked: true,
              partialChecked: false
            }
          : true
    })

    return ids
  },
  set(v) {
    const ids = Object.keys(v)
    const items = []

    const getItems = (allItems = []) => {
      allItems.forEach((s) => {
        if (ids.includes(s.id)) {
          items.push(s)
        }
        getItems(s.children?.items)
      })
    }

    getItems(records.value)
    modelValue.value = props.selectionMode === 'single' ? items[0]?.id : items?.map((e) => e.id)
  }
})

const onNodeSelect = (v) => {
  if (props.selectionMode === 'single') {
    selection.value = { [v.key]: true }
    return
  }
  selection.value = { ...selection.value, [v.key]: true }
}

const onNodeUnselect = (v) => {
  delete selection.value[v.key]
  selection.value = { ...selection.value }
}
</script>

<template>
  <TreeSelect
    :model-value="selection"
    :options="transformedRecords"
    :selection-mode="props.selectionMode"
    :placeholder="props.placeholder"
    :display="props.display"
    class="p-md:w-20rem w-full"
    :propagate-selection-up="false"
    :propagate-selection-down="false"
    :meta-key-selection="false"
    @node-select="onNodeSelect"
    @node-unselect="onNodeUnselect"
  />
</template>
