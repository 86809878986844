import { type CodexEntry, CodexEntryStatus, type CodexModel } from '@/gql/graphql'
import { computed, ref, type Ref, watch } from 'vue'
import {
  FIELD_TYPES,
  FIELD_VALUE_TYPES,
  getBlocks
} from '@/modules/models/editor-ts/fields/constants'
import * as yup from 'yup'
import { useQuery } from '@vue/apollo-composable'
import { LIST_ENTRIES } from '@/modules/entries/queries/index.graphql'

interface UnpublishedReferencesInput {
  entry: Ref<CodexEntry>
  model: Ref<CodexModel>
}

export function useUnpublishedReferences(props: UnpublishedReferencesInput) {
  const ids = computed(() => {
    const ids = []

    try {
      const referenceFields = getBlocks(
        props.model.blocks,
        (block) => block.isField && block.attrs.type == FIELD_TYPES.REFERENCE
      ).map((f) => ({
        valueType: f.attrs.valueType,
        alias: f.attrs.alias
      }))

      referenceFields.forEach((field) => {
        if (
          field.valueType === FIELD_VALUE_TYPES.SINGLE &&
          props.entry?.content?.[field.alias]?.entryId
        ) {
          ids.push(props.entry.content[field.alias].entryId)
        } else if (props.entry?.content?.[field.alias]?.length) {
          ids.push(...props.entry?.content?.[field.alias].map((e) => e.entryId))
        }
      })

      const validateReferences = yup.array().of(
        yup.object().shape({
          entryId: yup.string().required(),
          model: yup.string().required()
        })
      )

      const richContentFields = getBlocks(
        props.model.blocks,
        (block) => block.isField && block.attrs.type == FIELD_TYPES.RICH_CONTENT
      ).map((f) => ({ alias: f.attrs.alias }))
      const marks = []
      richContentFields.forEach((field) => {
        const referenceBlocks = getBlocks(props.entry?.content?.[field.alias], (block) => {
          if (block.marks) marks.push(...block.marks.filter((mark) => mark.type === 'link'))
          try {
            if (block.attrs?.references?.length > 0) {
              validateReferences.validateSync(block.attrs.references)
              return true
            }
          } catch {
            return false
          }
          return false
        })
        const referenceIds = referenceBlocks.flatMap((rf) =>
          rf.attrs.references?.map((r) => r.entryId)
        )
        if (referenceIds?.length) ids.push(...referenceIds)
      })

      if (marks.length) {
        ids.push(...marks.filter((e) => e.attrs.entryId).map((m) => m.attrs.entryId))
      }
    } catch (e) {
      console.log(e)
    }
    return ids.filter(Boolean)
  })

  const loading = ref(true)
  const referencedEntries = ref([])

  const fetchReferences = async () => {
    if (ids.value.length === 0) {
      referencedEntries.value = []
      loading.value = false
      return
    }
    const { onResult } = await useQuery(
      LIST_ENTRIES,
      computed(() => ({
        offset: 0,
        limit: 1000,
        where: {
          id: {
            in: ids.value
          },
          system: {
            status: {
              notIn: [CodexEntryStatus.Published]
            }
          }
        }
      })),
      {
        fetchPolicy: 'no-cache'
      }
    )

    onResult((result) => {
      referencedEntries.value = result?.data?.data?.items || []
      loading.value = false
    })
  }

  watch(
    ids,
    () => {
      fetchReferences()
    },
    { immediate: true }
  )

  return { referencedEntries, loading }
}
