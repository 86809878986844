<script setup lang="ts">
import PrimeSidebar from 'primevue/sidebar'
import { ref } from 'vue'

const el = ref()

function close() {
  if (!el.value) return
  el.value.close()
}
defineExpose({
  close
})
</script>

<template>
  <PrimeSidebar class="p-sidebar-custom-content" v-bind="$attrs">
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>

    <!-- Implement GjIcon -->
    <template #closeicon="scope">
      <GjIcon v-bind="scope" :name="$attrs.closeicon || 'Close'" size="24" />
    </template>
  </PrimeSidebar>
</template>
