import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=organization', (items) => {
  return [
    ...items,
    {
      path: 'localizations',
      name: 'localizations',
      component: () => import('@/modules/localizations/pages/Listing.vue')
    },
    {
      path: 'localizations/:language',
      name: 'localizations-edit',
      component: () => import('@/modules/localizations/pages/Edit.vue'),
      meta: {
        activeNavItem: 'localizations'
      }
    }
  ]
})
