import type { Context } from '@/modules/@global/composables/useContext'
import { generateNavigationItem } from '@/@core/navigation'
import { useOrganization } from '@/modules/organizations/store/organization'
import { useSite } from '@/modules/sites/store/site'

export function useEntriesListingFunctions(
  context: Context,
  viewId
): {
  defaultColumns: any[]
  setColumns: (newColumns: any[], viewId: string) => void
  generateViewsNavigationItems: () => any[]
  getVisibleColumns: () => any[]
} {
  const defaultColumns = ['id', 'created-at', 'created-by', 'published-at', 'status', 'actions']
  const setColumns = (newColumns: any[], viewId: string): void => {
    context.data.columns = newColumns

    if (viewId === 'all' || viewId === 'my') {
      window.localStorage.setItem(
        `${context.organizationId}/${context.siteId}/table/columns/${viewId}-${context.name}`,
        JSON.stringify(newColumns)
      )
    }
  }

  if (context) {
    const localStorageColumns = window.localStorage.getItem(
      `${context.organizationId}/${context.siteId}/table/columns/${viewId}-${context.name}`
    )
    console.log(localStorageColumns)
    if (localStorageColumns) {
      context.data.columns = JSON.parse(localStorageColumns)
    }
  }

  const generateViewsNavigationItems = (): any[] => {
    const siteViews = useOrganization().views.filter((view: any) => view.siteId == useSite().siteId)
    const uniquePaths = [...new Set(siteViews.map((item: any) => item.path))]

    return {
      rootViews: siteViews
        .filter((view: any) => view.path === '')
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(mapViewToNavigationItem),
      groups: uniquePaths
        .filter((item: any) => item !== '')
        .sort((a, b) => a.localeCompare(b))
        .map((item: any) => {
          return generateNavigationItem({
            name: `${item}-entries`,
            title: item,
            route: { path: '/empty' }, //TODO check what to do with folders
            children: siteViews
              .filter((siteView: any) => siteView.path === item)
              .map(mapViewToNavigationItem)
          })
        })
    }
  }
  const mapViewToNavigationItem = (view: any) => {
    return generateNavigationItem({
      name: `${view.id}-entries`,
      title: view.name,
      route: { name: 'entries', params: { viewId: view.id } }
    })
  }
  const getVisibleColumns = () => {
    return context
      .getRef('table')
      .columns.filter((item: any) => !item.hidden)
      .sort((a: any, b: any) => a.order - b.order)
  }

  return { defaultColumns, setColumns, generateViewsNavigationItems, getVisibleColumns }
}
