import {
  FIELD_TYPES,
  FIELD_VALUE_TYPES,
  WIDGET_GROUPS,
  FIELD_FILTER_OPERATORS,
  RANGE_OPERATORS
} from '@/modules/models/editor-ts/fields/constants'
import { CodexHooks } from '@/@core'

CodexHooks.addFilter('fields/types', (items) => {
  return [
    ...items,
    {
      pluginName: 'codex',
      displayName: 'Rich Text',
      name: 'field_rich_text',
      render: () => import('./Render.vue'),
      renderEditor: () => import('./Render.vue'),
      renderEditorPanel: [
        {
          icon: 'Settings',
          label: 'General',
          component: () => import('./General.vue'),
          isGeneral: true
        }
      ],
      renderEditorInitialPanel: [
        {
          icon: 'Settings',
          label: 'Initial',
          component: () => import('./Initial.vue')
        }
      ],
      group: WIDGET_GROUPS.BASIC,
      icon: () => import('./icon.svg'),

      type: FIELD_TYPES.RICH_TEXT,
      valueType: FIELD_VALUE_TYPES.SINGLE,

      attrs: {
        calculateMetrics: {
          default: false
        },
        showCharacterCount: {
          default: false
        },
        appearance: {
          default: 1
        },
        helpText: {
          default: ''
        },
        helpTextDisplay: {
          default: 'tooltip'
        },
        hyperlinks: {
          default: {
            url: true,
            articles: true,
            images: true,
            files: true
          }
        },
        embedded: {
          default: {
            images: true
          }
        },
        formattingOption: {
          default: {
            headers: [1, 2, 3, 4, 5, 6, ''],
            bold: true,
            italicize: true,
            underline: true,
            bulletPoint: true,
            numberLine: true,
            quote: true,
            codeBlock: true,
            hyphen: false,
            superscript: true,
            subscript: true
          }
        },
        validation: {
          default: {
            required: {
              value: false,
              errorMessage: 'RichText is required.',
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: false,
                  field: '',
                  operator: FIELD_FILTER_OPERATORS.EXISTS,
                  value: ''
                }
              ]
            },
            readOnly: {
              value: false,
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: false,
                  field: '',
                  operator: FIELD_FILTER_OPERATORS.EXISTS,
                  value: ''
                }
              ]
            },
            limitCharacterCount: {
              value: false,
              rangeOperator: RANGE_OPERATORS.BETWEEN,
              min: 20,
              max: 50,
              errorMessage: 'Number of characters: 20-50.',
              errorTemplate: 'Number of characters: {min}-{max}'
            },
            codexModelIds: [],
            fileType: {
              value: false,
              image: false,
              video: false,
              files: false,
              errorMessage: ''
            },
            richTextEmbedding: {
              model: false,
              article: false,
              media: false
            }
          }
        },
        configuration: {
          default: {
            searchable: true,
            filterable: true
          }
        },
        hidden: {
          default: {
            value: false,
            conditionsEnabled: false,
            conditions: [
              {
                isSystem: false,
                field: '',
                operator: FIELD_FILTER_OPERATORS.EXISTS,
                value: ''
              }
            ]
          }
        }
      }
    }
  ]
})
