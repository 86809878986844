import { CodexHooks } from '@/@core'
import CreatePreset from './CreatePreset.vue'
import UpdatePreset from './UpdatePreset.vue'
import ConfirmDelete from '@/modules/@global/popups/ConfirmDelete.vue'
import { useI18nStore } from '@/@core/i18n'
import type { CodexPreset } from '@/gql/graphql'


/**
 * @title Preset
 * @component Preset
 */
export interface PresetComponentPreview {
  props: CodexPreset
}

/**
 * interface for a function with params and return value
 * @function updatePreset
 * @title Update Preset Function
 */
export interface FunctionNameComponentPreview {
  props: {
    /**
     * Description of the parameter
     * @deprecated use `param` instead
     */
    param: string
  }
}

/**
 * @popup create-preset
 * @title Create Preset
 */
export interface CreatePresetComponentPreview {
  props: {
  }
}

/**
 * @popup update-preset
 * @title Update Preset
 * @defaultProps {
 *  "id": "ip2R2C0QkV"
 * }
 */
export interface UpdatePresetComponentPreview {
  props: {
    /**
     * ID of the preset to edit
     */
    id: string
  }
}

/**
 * @popup confirm-delete-preset
 * @title Confirm Delete Preset
 * @defaultProps {
 * "list": ['Item 1', 'Item 2', 'Item 3']
 * }
 */
export interface ConfirmDeletePresetComponentPreview {
  props: {
    /**
     * List of items to delete
     */
    list: string[]
  }
}

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  const ct = useI18nStore().createI18nContext('ct.presets.popups.confirm-delete-preset')

  items = [
    ...items,

    {
      type: 'Sidebar',
      name: 'create-preset',
      component: CreatePreset
    },
    {
      type: 'Sidebar',
      name: 'update-preset',
      component: UpdatePreset
    },
    {
      type: 'Dialog',
      name: 'confirm-delete-preset',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('cancel'),
          confirm: ct.$p('confirm'),
          title: ct.$p('title'),
          message: ct.$p('message')
        }
      }
    }
  ]

  return items
})
