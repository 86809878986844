<script lang="tsx">
import { computed, defineComponent, inject, type PropType, type Ref, ref } from 'vue'
import { LayoutElements } from '@/modules/@core/components/LayoutElements'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { CodexHooks } from '@/@core'
import { useI18nStore } from '@/@core/i18n'
import { merge } from 'lodash'
import { useDisplay } from '@/modules/@global/composables/useDisplay'
import { useCodexQuery } from '@/modules/@global/composables/useCodexQuery'
import gql from 'graphql-tag'
import { CodexOrderDirection } from '@/gql/graphql'
import { expandToObject } from '@/modules/@global/utils/helpers'

export default defineComponent({
  name: 'ListingTable',
  props: {
    clientId: {
      type: String,
      default: 'client'
    },
    name: {
      type: String,
      default: ''
    },
    variables: {
      type: Object as PropType<any>,
      default: () => ({})
    },
    query: {
      type: Object as PropType<any>,
      default: () => null
    },
    queryText: {
      type: String,
      default: () => null
    },
    initialColumns: {
      type: Array,
      default: () => []
    },
    class: {
      type: String,
      default: ''
    },
    labels: {
      type: Object,
      default: () => ({})
    },
    editable: {
      type: Boolean,
      default: false
    },
    menuOptions: {
      type: Array,
      default: () => []
    },
    selectionMode: {
      type: String,
      default: ''
    },
    selection: {
      type: Array,
      default: null
    },
    defaultSortField: {
      type: String,
      default: null
    },
    defaultSortOrder: {
      type: String,
      default: null
    }
  },
  inject: ['context'],
  setup(props, { emit }) {
    if (!props.name) {
      return {}
    }

    const menu = ref<ContextMenu | null>(null)

    const selectedRows = computed({
      get() {
        return props.selection || []
      },
      set(v) {
        emit('update:selection', v)
      }
    })

    const display = useDisplay()
    const context: any = inject('context')

    const ct = useI18nStore().createI18nContext('ct.global.components.listing-table')

    let skeletonRecords = ref([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}])

    const page: Ref<Number> = ref(0)
    const limit: Ref<Number> = ref(10)

    const order: Ref<any> = ref({})
    if (props.defaultSortField)
      order.value = expandToObject(props.defaultSortField, props.defaultSortOrder)

    const items = computed(() => {
      return [
        {
          label: ct.$t('selected', { count: selectedRows.value.length }),
          disabled: true
        },
        ...props.menuOptions,
        {
          label: ct.$t('deselect'),
          icon: 'Close_fill',
          command: () => deselectRows()
        }
      ]
    })

    const onRowContextMenu = (event) => {
      const originalEvent = event.originalEvent
      originalEvent.preventDefault()

      const isSelected = selectedRows.value.some(
        (selectedRow) => JSON.stringify(selectedRow) === JSON.stringify(event.data)
      )

      if (isSelected && menu.value) {
        menu.value.showMenu(originalEvent)
      }
    }

    const deselectRows = () => {
      selectedRows.value = []
    }

    const query = () => {
      const columns = CodexHooks.reactiveFilter(
        `table/columns?name=${props.name}`,
        props.initialColumns,
        context?.value
      ).value.filter((x: any) => !x.hidden && x.isModelField)

      let fragmentString = ''
      if (columns && columns.length) {
        const capitalized = columns[0].modelAlias[0].toUpperCase() + columns[0].modelAlias.slice(1)
        fragmentString = `... on ${capitalized} {`
        columns.forEach((column: any) => {
          fragmentString += ` ${column.field} `
        })
        fragmentString += '}'
      }
      return props.query
        ? props.query
        : gql(props.queryText.replace('__FRAGMENT_PLACEHOLDER__', fragmentString))
    }

    const theQuery = useCodexQuery(
      query,
      () => {
        return CodexHooks.reactiveFilter(
          `table/variables?name=${props.name}`,
          merge(
            {
              limit: limit.value,
              offset: page.value * limit.value,
              order: order.value
            },
            props.variables
          ),
          context?.value
        ).value
      },
      {
        fetchPolicy: 'cache-and-network',
        clientId: props.clientId
      }
    )

    // prettier-ignore
    const errors = computed(() => {
			return theQuery?.error?.value?.message
		})
    const records = computed(() => {
      if (errors.value) return []
      return theQuery?.result?.value?.data?.items
    })
    const totalRecords = computed(() => {
      if (errors.value) return 0
      return theQuery?.result?.value?.data?.total
    })

    const refreshKey = ref(0)

    CodexHooks.addAction('hooks/filter-added?name=table/columns', () => {
      refreshKey.value++
    })
    CodexHooks.addAction('hooks/filter-removed?name=table/columns', () => {
      refreshKey.value++
    })
    CodexHooks.addAction('i18n/setLocale', () => {
      refreshKey.value++
    })
    return {
      context,
      ct,
      refreshKey,
      limit,
      page,
      order,
      loading: theQuery?.loading,
      firstLoad: theQuery?.firstLoading,
      records,
      totalRecords,
      skeletonRecords,
      errors,
      display,
      columns: ref([]),
      selectedRows,
      menu,
      items,
      onRowContextMenu
    }
  },
  unmounted() {},
  methods: {
    onPageChange(event: any) {
      this.page = event.page
      this.limit = event.rows
    },
    onSortChange(event: any) {
      const field = event.sortField
      const value = event.sortOrder === 1 ? CodexOrderDirection.Asc : CodexOrderDirection.Desc

      this.order = expandToObject(field, value)
    }
  },
  render() {
    /* prettier-ignore */

    this.refreshKey

    const updatedProps = [
      ...(this.editable
        ? [
            {
              field: 'select',
              columnKey: 'select',
              responsive: ['mobile', 'tablet', 'desktop'],
              header: '',
              selectionMode: this.selectionMode
            }
          ]
        : []),
      ...this.initialColumns
    ]

    // Filter the columns
    this.columns = CodexHooks.applyFilters(
      `table/columns?name=${this.name}`,
      updatedProps,
      this.context
    )
      .filter((col: any) => {
        if (col.responsive) {
          return col.responsive.includes(this.display?.currentBreakpoint?.value)
        }
        return true
      })
      .sort((a: any, b: any) => a.order - b.order)

    return (
      <>
        <ProgressBar v-show={this.loading} mode="indeterminate" style="height: 1px"></ProgressBar>
        <div style="height: 1px" v-show={!this.loading}></div>
        <ContextMenu ref="menu" model={this.items} />

        <DataTable
          class={this.class}
          scrollable
          key={this.refreshKey}
          lazy={true}
          value={this.firstLoad ? this.skeletonRecords : this.records}
          paginator={true}
          rows={this.limit}
          totalRecords={this.totalRecords}
          first={(this.page || 0) * (this.limit || 5)}
          onPage={this.onPageChange}
          onSort={this.onSortChange}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate={this.ct?.$t('current-page-template')}
          contextMenu
          contextMenuSelection={this.selectedRows}
          onRowContextmenu={this.editable ? this.onRowContextMenu : null} //nese eshte editable me u shfaqe edhe kontekst menuja
          editable={this.editable}
          dataKey="id"
          v-model:selection={this.selectedRows}
          removableSort
          sortField={this.defaultSortField}
          sortOrder={this.defaultSortOrder === CodexOrderDirection.Asc ? 1 : -1}
        >
          {{
            empty: () => {
              if (this.errors) {
                return <InlineMessage severity="error" title={this.errors} block={true} />
              }

              return (
                <NoResults
                  title={this.ct?.d$t?.(this.labels?.empty?.title, 'empty.title')}
                  description={this.ct?.d$t?.(this.labels?.empty?.description, 'empty.description')}
                >
                  {{
                    default: () => this.$slots?.emptyButtons?.()
                  }}
                </NoResults>
              )
            },
            default: () => {
              if (this.firstLoad) {
                return this.columns
                  ?.filter((column: any) => !column.hidden || column.field === 'actions')
                  ?.map((column: any, index: Number) => {
                    return (
                      <Column key={column.columnKey} header={column.header} {...column}>
                        {{
                          body: (rowData: any) => <Skeleton></Skeleton>
                        }}
                      </Column>
                    )
                  })
              } // end if loading

              return this.columns?.map((column: any, index: Number) => {
                if (column.body) {
                  return (
                    <Column key={column.columnKey} header={column.header} {...column}>
                      {{
                        body: (rowData: any) => (
                          <LayoutElements
                            name={`${this.name}-${column.columnKey}`}
                            layout="horizontal"
                            items={column.body}
                            data={[rowData]}
                          />
                        )
                      }}
                    </Column>
                  )
                }

                return (
                  <Column
                    key={column.columnKey}
                    field={column.field}
                    header={column.header}
                    {...column}
                  />
                )
              })
            }
          }}
        </DataTable>
      </>
    )
  }
})
</script>
