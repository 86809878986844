import { CodexHooks } from '@/@core'
import { SourceType } from '@/@core/hooks/types/hooks'


CodexHooks.addAction(
  'organization/organizationLoaded',
  async (context: any, organizationRes: any) => {
    const modules = await import.meta.glob('@/plugins/**/*/*.hooks.ts')

    const organizationPlugins =
      organizationRes?.data?.organization?.organizationPlugins?.items || []

    const activePlugins = []
    for (const plugin of organizationPlugins) {
      const pluginName = plugin?.plugin?.name
      const pluginVersion = plugin?.plugin?.activeVersion?.version

      if (pluginName && pluginVersion) {
        activePlugins.push(`/plugins/${pluginName}/${pluginVersion}`)
      }
    }

    for (const originalPath in modules) {
      if (!activePlugins.some((folder) => originalPath.includes(folder))) {
        continue
      }

      /**
       * Each modules[originalPath] is a function that returns a promise that resolves to the module.
       * The function looks like this in build files: ()=>Pn(()=>import("./index.hooks-2425578f.js"),[])
       * But the `originalPath` is actually the file path in code like, ex: "/src/plugins/cmi-france/1.0.0/index.hooks.ts"
       * So we need to extract the module name from the function string.
       * That way we can import the module.
       */
      const compiledPath = modules[originalPath].toString().match(/import\("(.*)"\)/)?.[1]

      /**
       * For now, we are adding a timestamp to the path to force a reload/re-execution of the module.
       */
      try {
        const uniquePath = `${compiledPath}?t=${Date.now()}`
        await import(/* @vite-ignore */ uniquePath)
      } catch (error) {
        console.error(`Error loading plugin: ${compiledPath}`, error)
      }
    }
  }
)

CodexHooks.addAction(
  'organization/organizationUnloaded',
  async (context: any, organization: any) => {
    setTimeout(() => {
      CodexHooks.removeActionsBySource(SourceType.Plugin)
      CodexHooks.removeFiltersBySource(SourceType.Plugin)
    }, 100);
  }
)
