<script setup lang="ts">
import { CreateSectionForm } from '@/modules/sections/forms/Section'
import { useMutation } from '@vue/apollo-composable'
import { CREATE_SECTION } from '@/modules/sections/queries/index.rest'
import { inject } from 'vue'
import { useI18nStore } from '@/@core/i18n'
import { sectionDefaults } from '@/modules/sections/constants'
import type { Context } from '@/modules/@global/composables/useContext'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.sections.popups.create-section')

/**
 * Dialog
 */
const dialog = inject(DialogRefKey)

/**
 * Create section
 */
const createSection = useMutation(CREATE_SECTION, {
	refetchQueries: ['ListSections']
})

async function createSectionHandler(values) {
	dialog?.value?.close(values)
}

/**
 * Default values
 */
const defaultValues = sectionDefaults()
</script>

<template>
  <SidebarContent :title="ct.$t('title')">
    <DynamicForm
      name="create-section"
      :ref="(r: any) => context?.setRef('form', r)"
      :show-submit="false"
      :schema="CreateSectionForm()"
      :mutation="createSection"
      :data="defaultValues"
      :labels="{
        submit: ct.$t('submit')
      }"
      @submit="createSectionHandler"
    ></DynamicForm>
    <template #footer>
      <Button v-bind="context.getRef('form')?.props?.submit.value"></Button>
    </template>
  </SidebarContent>
</template>
