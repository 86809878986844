import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { CodexDebug, CodexHooks } from '@/@core'
import type { NavigationItem, NavigationItemGenerate } from './types'
import { isArray } from 'lodash'

export function generateNavigationItem(item: NavigationItemGenerate): NavigationItem {
  const menuItem: NavigationItem = {
    ...item,
    id: item.id || item.name,
    title: item.title || item.name,
    route: item.route || { name: item.name },
    group: item.group || 'top',
    staticName: item.staticName || item.name,
    siteId: item.siteId,
    image: item.image,
    icon: item.icon,
    isOpen: item.isOpen || false,
    children: item.children || [],
    type: item.type || 'item',
    component: item.component || ''
  }

  return menuItem
}

export const useNavigation = defineStore('codex-navigation', () => {
  const refreshKey = ref(0)

  /**
   * This is a hack to force the navigation to refresh when a new filter is added or removed.
   */
  CodexHooks.addAction('hooks/filter-added?name=navigation/items', () => {
    refreshKey.value++
  })

  CodexHooks.addAction('hooks/filter-removed?name=navigation/items', () => {
    refreshKey.value++
  })

  const navigationItems = computed(() => {
    // Use a ref to force the computed to refresh when the refreshKey changes
    refreshKey.value

    CodexDebug.navigationInfo('navigationItems - Start')

    let items: NavigationItem[] = []

    // ToDo: Handle not allowing duplicate names

    // Use hooks to process navigation items
    try {
      items = CodexHooks.applyFilters('navigation/items?parent=null', items, null)
      items = CodexHooks.applyFilters('navigation/items', items, null)
    } catch (error) {
      CodexDebug.navigationError('navigationItems - Root', error)
    }

    // Call navigation/items for all childrens recursively
    const processChildren = (items: NavigationItem[] | undefined, params: Object = {}) => {
      if (!items || !isArray(items)) return

      try {
        items.forEach((item) => {
          // Add params to item
          item.route.params = {
            ...params,
            ...item.route?.params
          }

          Object.keys(params).forEach((key) => {
            Object.keys(item.route.params).forEach((paramKey) => {
              // replace
              item.route.params[paramKey] = item.route.params[paramKey]?.replaceAll(
                `{${key}}`,
                params[key]
              ) || item.route.params[paramKey]
            })
          })

          if (item.children) {
            item.children = CodexHooks.applyFilters(
              `navigation/items?parent=${item.name}`,
              item.children,
              null,
              item
            )
            if (item.staticName) {
              item.children = CodexHooks.applyFilters(
                `navigation/items?parent-static=${item.staticName}`,
                item.children,
                null,
                item
              )
            }
            item.children = CodexHooks.applyFilters('navigation/items', item.children, null, item)

            processChildren(item.children, item.route.params)
          }
        })
      } catch (error) {
        CodexDebug.navigationError('navigationItems - Recursive', error)
      }
    }

    processChildren(items, {})

    // Debug
    CodexDebug.navigationInfo('navigationItems - Final', items)

    return items
  })

  return {
    navigationItems
  }
})
