import TagParentFilterComponent from './TagParent.vue'
import { useI18nStore } from '@/@core/i18n'
import { truncate } from 'lodash'

export default class TagParentFilter {
  static COMPONENT = TagParentFilterComponent
  static OPERATORS: {
    include: string
    exclude: string
  }

  static type: string = 'tag-parent'

  operator: string | null = null

  value: Array<[]> | null = null

  constructor({ operator = TagParentFilter.OPERATORS.include, value = [] } = {}) {
    this.operator = operator
    this.value = value
  }

  set({ operator = TagParentFilter.OPERATORS.include, value = [] }) {
    this.operator = operator
    this.value = value
  }
  getCount() {
    return this.value?.length
  }
  getValueLabel() {
    //@ts-ignore
    return this.value?.map((m) => truncate(m.tagValue, { length: 20, separator: ' ' })).join(', ')
  }

  toLoad() {
    return {
      authors: this.value
    }
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case TagParentFilter.OPERATORS.include:
        return {
          //@ts-ignore
          in: this.value?.map((t) => t.id)
        }
      case TagParentFilter.OPERATORS.exclude:
        return {
          notIn: this.value
        }
      default:
        return null
    }
  }

  // @ts-ignore
  static validate({ operator, value }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (!value || value.length === 0) {
      return 'Value is required'
    }

    return true
  }

  // @ts-ignore
  static fromQueryParam({ operator, value }) {
    return new TagParentFilter({
      operator,
      value
    })
  }
}
