import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=organization', (items) => {
  return [
    ...items,
    {
      path: 'sites',
      name: 'sites',
      component: () => import('@/modules/sites/pages/SitesListing.vue')
    },
    {
      path: ':siteId',
      name: 'site',
      component: () => import('@/modules/sites/pages/SiteView.vue')
    }
  ]
})
