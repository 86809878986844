import {
  FIELD_TYPES,
  FIELD_VALUE_TYPES,
  WIDGET_GROUPS,
  FIELD_FILTER_OPERATORS,
  RANGE_OPERATORS,
  NUMBER_FIELD_APPEARANCES
} from '@/modules/models/editor-ts/fields/constants'
import { CodexHooks } from '@/@core'

CodexHooks.addFilter('fields/types', (items) => {
  return [
    ...items,
    {
      pluginName: 'codex',
      displayName: 'Number',
      name: 'field_number',
      render: () => import('./Render.vue'),
      renderEditor: () => import('./Render.vue'),
      renderEditorPanel: [
        {
          icon: 'Settings',
          label: 'General',
          component: () => import('./General.vue'),
          isGeneral: true
        },
        {
          icon: 'Brush',
          label: 'Appearance',
          component: () => import('./Appearance.vue')
        },
        {
          icon: 'SettingsPreferencesTools',
          label: 'Configurations',
          component: () => import('./Configuration.vue')
        }
      ],
      renderEditorInitialPanel: [
        {
          icon: 'Settings',
          label: 'Initial',
          component: () => import('./Initial.vue')
        }
      ],
      group: WIDGET_GROUPS.BASIC,
      icon: () => import('./icon.svg'),

      type: FIELD_TYPES.INTEGER,
      valueType: FIELD_VALUE_TYPES.SINGLE,
      defaultValue: [],

      attrs: {
        // New configs
        appearance: {
          default: NUMBER_FIELD_APPEARANCES.INPUT
        },
        validation: {
          default: {
            required: {
              value: false,
              errorMessage: 'Number is required.',
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: false,
                  field: '',
                  operator: FIELD_FILTER_OPERATORS.EXISTS,
                  value: ''
                }
              ]
            },
            readOnly: {
              value: false,
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: false,
                  field: '',
                  operator: FIELD_FILTER_OPERATORS.EXISTS,
                  value: ''
                }
              ]
            },
            uniqueInCollection: {
              value: false,
              errorMessage: 'Number should be unique'
            },
            range: {
              isEnabled: false,
              rangeOperator: RANGE_OPERATORS.BETWEEN,
              min: 2,
              max: 10,
              errorMessage: 'Number range: 2-10',
              errorTemplate: 'Number range: {min}-{max}'
            },
            listRange: {
              isEnabled: false,
              rangeOperator: RANGE_OPERATORS.BETWEEN,
              min: 2,
              max: 5,
              exactly: 1,
              errorMessage: 'List length between: 2-5',
              errorTemplate: 'List length between: {min}-{max}'
            }
          }
        },
        placeholder: {
          default: ''
        },
        predefinedValues: {
          default: []
        },
        stars: {
          default: 5
        },
        helpText: {
          default: ''
        },
        helpTextDisplay: {
          default: 'tooltip'
        },
        configuration: {
          default: {
            searchable: true,
            filterable: true
          }
        },
        hidden: {
          default: {
            value: false,
            conditionsEnabled: false,
            conditions: [
              {
                isSystem: false,
                field: '',
                operator: FIELD_FILTER_OPERATORS.EXISTS,
                value: ''
              }
            ]
          }
        }
      }
    }
  ]
})
