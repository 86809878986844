import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=site', (items) => {
  return [
    ...items,
    {
      path: 'tags',
      name: 'tags',
      component: () => import('@/modules/tags/pages/TagsListing.vue')
    }
  ]
})
