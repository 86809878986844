<script setup lang="ts">
import DialogContent from '@/modules/@global/components/DialogContent/DialogContent.vue'
import { computed, inject, ref } from 'vue'
import gql from 'graphql-tag'
import { useQuery } from '@vue/apollo-composable'
import { type CodexEntryFilter, CodexEntryStatus, CodexFieldType } from '@/gql/graphql'
import { useOrganization } from '@/modules/organizations/store/organization'
import EntriesDisplay from '@/modules/entries/components/EntriesDisplay.vue'
import { useI18nStore } from '@/@core/i18n'

const ct = useI18nStore().createI18nContext('ct.entries.popups.entry-unpublish')

const organization = useOrganization()

const dialog = inject('dialogRef')

const entry = computed(() => dialog?.value?.data?.entry)
const loading = ref(false)

const fields = computed(() => {
  const allFields = []
  organization.allModels.forEach((m) => {
    const fff = m.fields
      .filter((f) => f.type == CodexFieldType.Reference || f.type == CodexFieldType.RichContent)
      .map((f) => ({
        entryId: entry.value.id,
        modelAlias: m.alias,
        ...f
      }))

    allFields.push(...fff)
  })

  return {
    required: allFields.filter((f) => f?.validation?.required?.value === true),
    nonRequired: allFields.filter((f) => !f?.validation?.required?.value)
  }
})

const requiredFields = computed(() => {
  return fields.value.required.map((f) => `${f.entryId}_${f.modelAlias}_${f.alias}`)
})

const nonRequiredFields = computed(() => {
  return fields.value.nonRequired.map((f) => `${f.entryId}_${f.modelAlias}_${f.alias}`)
})

const theQuery = gql`
  query ($where: CodexEntryFilter) {
    entries: entryCollection(limit: 100, offset: 0, where: $where) {
      total
      items {
        id
        system {
          title
          modelAlias
          status
          siteId
          scheduledVersions(where: { status: { in: [SCHEDULED] } }) {
            items {
              id
              versionId
              publishScheduledDate
              unpublishScheduledDate
              versionId
              createdAt
            }
          }
        }
      }
    }
  }
`

const theQueryFront = gql`
  query ($where: CodexEntryFilter) {
    entries: entryCollection(limit: 100, offset: 0, where: $where) {
      total
      items {
        id
        system {
          title
          modelAlias
          status
          siteId
        }
      }
    }
  }
`

const getQueryCount = (
  where: CodexEntryFilter,
  skip: boolean,
  client: 'front' | undefined = undefined,
  query = theQuery
) => {
  return new Promise((resolve, reject) => {
    if (skip) resolve(0)
    const { onResult, onError } = useQuery(
      query,
      { where: where },
      {
        fetchPolicy: 'no-cache',
        clientId: client
      }
    )
    onResult(({ data }) => {
      if (data) {
        if (data?.entries?.items) {
          data.entries.items.forEach((e) => {
            e.system.model = organization.getModelById(e.system.modelAlias)
          })
        }

        resolve(data?.entries || { items: [], total: 0 })
      }
    })
    onError((error) => {
      reject(error)
    })
  })
}

const entryIds = ref({
  admin: {
    required: { total: 0, items: [] },
    nonRequired: { total: 0, items: [] }
  },
  client: {
    required: { total: 0, items: [] },
    nonRequired: { total: 0, items: [] }
  }
})

const fetchEntries = async () => {
  loading.value = true
  const adminRequiredEntries = getQueryCount(
    {
      system: {
        references: { referencingFields: { some: requiredFields.value } },
        status: { notIn: [CodexEntryStatus.Published] }
      }
    },
    requiredFields.value.length === 0
  )

  const adminNonRequiredEntries = getQueryCount(
    {
      system: {
        references: { referencingFields: { some: nonRequiredFields.value } },
        status: { notIn: [CodexEntryStatus.Published] }
      }
    },
    nonRequiredFields.value.length == 0
  )

  const clientRequiredEntries = getQueryCount(
    { system: { references: { referencingFields: { some: requiredFields.value } } } },
    requiredFields.value.length === 0,
    'front',
    theQueryFront
  )

  const clientNonRequiredEntries = getQueryCount(
    { system: { references: { referencingFields: { some: nonRequiredFields.value } } } },
    nonRequiredFields.value.length === 0,
    'front',
    theQueryFront
  )

  const responses = await Promise.allSettled([
    adminRequiredEntries,
    adminNonRequiredEntries,
    clientRequiredEntries,
    clientNonRequiredEntries
  ])

  entryIds.value.admin.required = responses[0].value
  entryIds.value.admin.nonRequired = responses[1].value

  entryIds.value.client.required = responses[2].value
  entryIds.value.client.nonRequired = responses[3].value

  const sumRequired =
    (entryIds.value.admin?.required?.total || 0) + (entryIds.value.client?.required?.total || 0)
  if (sumRequired == 0) {
    tabIndex.value = 1
  }
  loading.value = false
}

fetchEntries()

const tabIndex = ref(0)

const requiredEntries = computed(() => {
  return {
    items: [
      ...(entryIds.value.admin?.required?.items || []),
      ...(entryIds.value.client?.required?.items || [])
    ],
    total:
      (entryIds.value.admin?.required?.total || 0) + (entryIds.value.client?.required?.total || 0)
  }
})

const nonRequiredEntries = computed(() => {
  return {
    items: [
      ...(entryIds.value.admin?.nonRequired?.items || []),
      ...(entryIds.value.client?.nonRequired?.items || [])
    ],
    total:
      (entryIds.value.admin?.nonRequired?.total || 0) +
      (entryIds.value.client?.nonRequired?.total || 0)
  }
})

const isOkDisabled = computed(() => {
  return requiredEntries.value.items.some((e) => e.system.status === CodexEntryStatus.Published)
})

const cancel = () => {
  dialog.value?.close(false)
}

const ok = (redirect) => {
  if (isOkDisabled.value) return
  dialog.value?.close({
    redirect: redirect
  })
}
</script>

<template>
  <DialogContent :title="ct.$t('title')">
    <InlineMessage severity="warn" :text="ct.$t('warning')" />

    <TabView v-model:active-index="tabIndex">
      <TabPanel :header="ct.$t('tabs.required.title')">
        <div style="min-height: 190px; display: flex; align-items: center; justify-content: center">
          <EntriesDisplay
            :entries="requiredEntries.items"
            v-if="requiredEntries.total"
            @refetch="fetchEntries"
            style="max-height: 300px"
          />
          <Text v-else type="title-medium-base" :text="ct.$t('tabs.required.empty-state')" />
        </div>
      </TabPanel>
      <TabPanel :header="ct.$t('tabs.non-required.title')">
        <div style="min-height: 190px; display: flex; align-items: center; justify-content: center">
          <EntriesDisplay
            :entries="nonRequiredEntries.items"
            v-if="nonRequiredEntries.total"
            @refresh="fetchEntries"
            style="max-height: 300px"
          />
          <Text v-else type="title-medium-base" :text="ct.$t('tabs.non-required.empty-state')" />
        </div>
      </TabPanel>
    </TabView>

    <template #footer>
      <Button severity="secondary" @click="cancel" :label="ct.$t('actions.cancel')" />
      <SplitButton
        :disabled="loading"
        :model="[{ label: ct.$t('actions.unpublish-current.continue'), command: () => ok(false) }]"
        severity="primary"
        :label="ct.$t('actions.unpublish-current.redirect')"
        @click="ok(true)"
      />
    </template>
  </DialogContent>
</template>

<style lang="scss">
@at-root div.entry-unpublish.p-dialog {
  width: 700px !important;
}
</style>
