import { CodexHooks } from '@/@core'
import InviteUser from './InviteUser.vue'
import ConfirmDelete from '@/modules/@global/popups/ConfirmDelete.vue'
import { useI18nStore } from '@/@core/i18n'

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  const ct = useI18nStore().createI18nContext('ct.users.popups.confirm-delete-users')

  items = [
    ...items,

    /* Invite User */
    {
      type: 'Dialog',
      name: 'invite-user',
      component: InviteUser
    },
    {
      type: 'Dialog',
      name: 'confirm-delete-users',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('cancel'),
          confirm: ct.$p('confirm'),
          title: ct.$p('title'),
          message: ct.$p('message')
        }
      }
    }
  ]

  return items
})
