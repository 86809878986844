import type { I18n, Locale, LocaleMessages } from 'vue-i18n'
import { CodexDebug } from '@/@core'

const _default = (r: any) => r.default || r

export async function load<Locales>(locales: readonly Locale[]) {
  const messages: Record<string, any> = {}
  for (const locale of locales) {
    try {
      CodexDebug.i18nInfo(`Loading locale: ${locale}`)

      // prettier-ignore
      // eslint-disable-next-line no-constant-condition
      if (false) { // for development
        // load from back-end server
        const resource = await (await fetch(`/api/resources/${locale}`)).json()
        messages[locale] = resource
      } else { // for production
        // load from assets
        const resource = _default(await import(`./locales/${locale}.json`))
        messages[locale] = resource
      }
    } catch (e) {
      CodexDebug.i18nError(`Failed to load locale messages: ${locale}`, e)
    }
  }
  return messages as LocaleMessages<any, Locales>
}

export async function loadLocaleMessages<Locales>(locales: readonly Locale[], i18n: I18n) {
  load(locales).then((messages: LocaleMessages<any, Locales>) => {
    locales.forEach((locale) => {
      if (locale in messages) {
        // @ts-ignore
        const localMessages = messages[locale]

        CodexDebug.i18nInfo(`Loaded locale: ${locale}`, localMessages)

        i18n.global.setLocaleMessage(locale, localMessages)
      }
    })
  })
}
