import TextFilterComponent from './Text.vue'
import { useI18nStore } from '@/@core/i18n'

export default class TextFilter {
  static COMPONENT = TextFilterComponent
  static OPERATORS: {
    equals: string
    notEquals: string
    contains: string
    notContains: string
    exists: string
    notExists: string
    startsWith: string
    endsWith: string
  } = {
    equals: 'eq',
    notEquals: 'ne',
    contains: 'contains',
    notContains: 'notContains',
    exists: 'exists',
    notExists: 'notExists',
    startsWith: 'startsWith',
    endsWith: 'endsWith'
  }

  static type: string = 'text'

  operator: string | null = null

  value?: string | null = null

  constructor(
    { operator = TextFilter.OPERATORS.equals, value }: { operator: string; value?: string } = {
      operator: TextFilter.OPERATORS.equals
    }
  ) {
    this.operator = operator
    this.value = value
  }
  set(
    { operator = TextFilter.OPERATORS.equals, value }: { operator: string; value?: string } = {
      operator: TextFilter.OPERATORS.equals
    }
  ) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return 1
  }

  getValueLabel(): string | null {
    const ct = useI18nStore().createI18nContext('ct.global.filters.text')
    switch (this.operator) {
      case TextFilter.OPERATORS.equals:
        return ct.$t('active-label.is', { value: this.value })
      case TextFilter.OPERATORS.notEquals:
        return ct.$t('active-label.is-not', { value: this.value })
      case TextFilter.OPERATORS.contains:
        return ct.$t('active-label.contains', { value: this.value })
      case TextFilter.OPERATORS.notContains:
        return ct.$t('active-label.not-contains', { value: this.value })
      case TextFilter.OPERATORS.exists:
        return ct.$t('active-label.exists', {})
      case TextFilter.OPERATORS.notExists:
        return ct.$t('active-label.not-exists', {})
      case TextFilter.OPERATORS.startsWith:
        return ct.$t('active-label.starts-with', { value: this.value })
      case TextFilter.OPERATORS.endsWith:
        return ct.$t('active-label.ends-with', { value: this.value })
      default:
        return null
    }
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case TextFilter.OPERATORS.equals:
        return {
          eq: this.value
        }
      case TextFilter.OPERATORS.notEquals:
        return {
          ne: this.value
        }
      case TextFilter.OPERATORS.contains:
        return {
          contains: this.value
        }
      case TextFilter.OPERATORS.notContains:
        return {
          notContains: this.value
        }
      case TextFilter.OPERATORS.exists:
        return {
          exists: true
        }
      case TextFilter.OPERATORS.notExists:
        return {
          exists: false
        }
      case TextFilter.OPERATORS.startsWith:
        return {
          startsWith: this.value
        }
      case TextFilter.OPERATORS.endsWith:
        return {
          endsWith: this.value
        }
      default:
        return null
    }
  }

  static validate({ operator, value }: { operator: string; value?: string }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (operator !== TextFilter.OPERATORS.exists && operator !== TextFilter.OPERATORS.notExists)
      if (!value) {
        return 'Value 1 is required'
      }

    return true
  }
  static fromQueryParam({ operator, value }: { operator: string; value?: string }) {
    return new TextFilter({
      operator,
      value
    })
  }
}
