<script setup lang="ts">
import DateTimeFilter from './DateTimeFilter'
import DatePicker from '@/modules/primevue/components/DatePicker/DatePicker.vue'
import { computed } from 'vue'
import { useI18nStore } from '@/@core/i18n'

const ct = useI18nStore().createI18nContext('ct.global.components.filters')

// eslint-disable-next-line no-undef
const modelValue = defineModel<{
  operator: string
  value1: Date
  value2?: Date
}>()

const showInput = computed(() => {
  return (
    modelValue.value?.operator !== DateTimeFilter.OPERATORS.exists &&
    modelValue.value?.operator !== DateTimeFilter.OPERATORS.notExists
  )
})
const isBetween = computed(() => modelValue.value?.operator === DateTimeFilter.OPERATORS.between)
const options = computed(() => [
  {
    label: ct.$t('operators.is'),
    value: DateTimeFilter.OPERATORS.equals
  },
  {
    label: ct.$t('operators.greater-than'),
    value: DateTimeFilter.OPERATORS.greaterThan
  },
  {
    label: ct.$t('operators.lower-than'),
    value: DateTimeFilter.OPERATORS.lowerThan
  },
  {
    label: ct.$t('operators.between'),
    value: DateTimeFilter.OPERATORS.between
  },
  {
    label: ct.$t('operators.exists'),
    value: DateTimeFilter.OPERATORS.exists
  },
  {
    label: ct.$t('operators.not-exists'),
    value: DateTimeFilter.OPERATORS.notExists
  }
])
</script>

<template>
  <div class="new-filters-dropdown__filter-body">
    <Row>
      <Col>
        <Dropdown v-model="modelValue.operator" :options="options" size="small" />
      </Col>
    </Row>

    <Row v-if="showInput">
      <Col>
        <DatePicker v-model="modelValue.value1" />
      </Col>
      <Col v-if="isBetween">
        <DatePicker v-model="modelValue.value2" />
      </Col>
    </Row>
  </div>
</template>

<style scoped></style>
