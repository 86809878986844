import { merge } from 'lodash'

/**
 * Section Name
 */
export const SECTIONS_NAME = 'sections-listing'

/**
 * Section Defaults
 */
export function sectionDefaults(section = {}) {
  return merge(
    {
      title: null,
      parentId: null,
      description: null,
      url: null,
      references: [],
      media: []
    },
    section
  )
}
