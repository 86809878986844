import { CodexHooks } from '@/@core'
import { useI18nStore } from '@/@core/i18n'
import { generateNavigationItem } from '@/@core/navigation'

CodexHooks.addFilter('navigation/items?parent-static=site', (items) => {
  const ct = useI18nStore().createI18nContext('ct.tags.navigation')

  return [
    ...items,
    generateNavigationItem({
      name: 'tags',
      title: ct.$t('listing'),
      route: { name: 'tags' }
    })
  ]
})
