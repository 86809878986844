import { CodexAuthorStatus } from '@/gql/graphql'
import { merge } from 'lodash'
import { enumMapping } from '@/modules/@global/utils/EnumMapping'

/**
 * Author Name
 */
export const AUTHORS_NAME = 'authors-listing'
export const AUTHOR_NAME = 'author-edit'

/**
 * Author Enums
 */
export const AUTHOR_STATUS_MAPPING = enumMapping({
  [CodexAuthorStatus.Unpublished]: 3,
  [CodexAuthorStatus.Published]: 1
})

/**
 * Author Defaults
 */
export function authorDefaults(author = {}) {
  return merge(
    {
      byline: '',
      id: '',
      name: '',
      email: '',
      url: '',
      biography: '',
      website: '',
      status: CodexAuthorStatus.Unpublished,
      facebook: '',
      instagram: '',
      twitter: '',
      linkedin: '',
      telegram: '',
      tiktok: ''
    },
    author
  )
}
