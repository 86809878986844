<script setup lang="ts">
import { computed } from 'vue'
import PrimeVueEditor from 'primevue/editor'

const props = withDefaults(
  defineProps<{
    size?: 'small' | 'large' | null
  }>(),
  {
    size: null,
  }
)

const getEditorClasses = computed(() => {
  return {
    ['p-editor-sm']: props.size === 'small',
    ['p-editor-lg']: props.size === 'large'
  }
})
</script>

<template>
	<PrimeVueEditor v-bind="$attrs" :class="getEditorClasses"/>
</template>
