<script setup lang="ts">
import { provide } from 'vue'
import Grid from '../../components/folders/FoldersGrid.vue'
import Tree from '../../components/folders/FoldersTree.vue'
import useContext from '@/modules/@global/composables/useContext'
import useFolders from '../../composables/useFolders'

/**
 * Context
 */
const FOLDERS_NAME = 'folders-listing'
const context = useContext({
  name: FOLDERS_NAME,
  type: 'page'
})

type FolderGridType = 'tree' | 'grid'

const props = withDefaults(
  defineProps<{
    type: FolderGridType
  }>(),
  {
    type: 'tree'
  }
)

/**
 * FOLDERS
 */

const {
  foldersTotal,
  activeFolderId,
  activeFolder,
  context: foldersContext
} = useFolders(context.value)

provide('type', props.type)

defineExpose({
  activeFolderId,
  activeFolder,
  foldersTotal
})
</script>

<template>
  <div class="f-grid-full" style="overflow-x: hidden">
    <Grid v-if="props.type === 'grid'" />
    <Tree
      v-if="props.type === 'tree'"
      :ref="(r: any) => foldersContext?.setRef('foldersTree', r)"
    />
  </div>
</template>

<style lang="scss" scoped></style>
