<template>
  <component
    :is="instance.options.type"
    v-model:visible="instance.visible"
    v-bind="instance.options.props"
    :pt="pt"
    :unstyled="unstyled"
    @hide="onDialogHide(instance)"
    @after-hide="onDialogAfterHide"
    :_instance="instance"
  >
    <slot></slot>
  </component>
</template>

<script type="ts">
import BaseComponent from 'primevue/basecomponent'
import { computed } from 'vue'
import useContext from '@/modules/@global/composables/useContext'
import { DialogRefKey } from '@/symbols'

export default {
	name: 'DynamicDialogInstance',
	extends: BaseComponent,
	inheritAttrs: false,
	emits: ['hide', 'after-hide'],
	props: {
		instance: {
			type: Object,
			default: () => ({})
		}
	},
	provide() {
		return {
			[DialogRefKey]: computed(() => this.instance)
		}
	},
	methods: {
		onDialogHide(instance) {
			this.$emit('hide', instance)
		},
		onDialogAfterHide() {
			this.$emit('after-hide')
		}
	},
	setup(props) {
		const context = useContext({
			...props.instance.options.context,
			name: props.instance.options.name,
			type: 'popup',
			parentContext: props.instance.options.parentContext,
		})

	}
}
</script>
