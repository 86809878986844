import { graphql } from '@/gql/gql'
import gql from 'graphql-tag'

const SECTION_LISTING_FRAGMENT = graphql(/* GraphQL */ `
  fragment SectionListingFragment on CodexSection {
    __typename
    key: id
    id
    title
    slug
    siteId
    parent {
      id
      title
    }
    children {
      total
    }
  }
`)

export const LIST_SECTIONS = graphql(/* GraphQL */ `
  query ListSections($limit: Int!, $offset: Int!, $siteId: String!, $order: CodexSectionOrder) {
    data: sectionCollection(
      limit: $limit
      offset: $offset
      where: { siteId: { eq: $siteId }, parentId: { exists: false } }
      order: $order
    ) {
      total
      items {
        ...SectionListingFragment
      }
    }
  }
`)

export const LIST_SECTIONS_NESTED = graphql(/* GraphQL */ `
  query ListSectionsNested(
    $limit: Int!
    $offset: Int!
    $siteId: String!
    $parentId: String
    $order: CodexSectionOrder
  ) {
    data: sectionCollection(
      limit: $limit
      offset: $offset
      where: { siteId: { eq: $siteId }, parentId: { eq: $parentId } }
      order: $order
    ) {
      total
      items {
        ...SectionListingFragment
      }
    }
  }
`)

const SECTION_PARENT_FRAGMENT = gql`
  fragment Section on CodexSection {
    id
    title
    parentId
  }
`

export const SECTIONS_PARENT_DROPDOWN = gql`
  ${SECTION_PARENT_FRAGMENT}

  query Sections($limit: Int, $offset: Int, $where: CodexSectionFilter) {
    data: sectionCollection(limit: $limit, offset: $offset, where: $where) {
      total
      items {
        ...Section
        children {
          items {
            ...Section
            children {
              items {
                ...Section
                children {
                  items {
                    ...Section
                    children {
                      items {
                        ...Section
                        children {
                          items {
                            ...Section
                            children {
                              items {
                                ...Section
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const SECTION_FIELD_DATA = graphql(/* GraphQL */ `
  query SectionsField($sectionsIds: [String!], $limit: Int) {
    data: sectionCollection(where: { id: { in: $sectionsIds } }, limit: $limit) {
      items {
        ...SectionListingFragment
      }
    }
  }
`)
