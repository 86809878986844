import { CodexHooks } from '@/@core'
import CreateTag from './CreateTag.vue'
import UpdateTag from './UpdateTag.vue'
import ConfirmDelete from '@/modules/@global/popups/ConfirmDelete.vue'
import { useI18nStore } from '@/@core/i18n'
import MergeTag from './MergeTag.vue'
CodexHooks.addFilter('popups/popups', (items: any[]) => {
  const ct = useI18nStore().createI18nContext('ct.sections.popups.confirm-delete-tags')

  items = [
    ...items,

    /* Create Tag */
    {
      type: 'Sidebar',
      name: 'create-tag',
      component: CreateTag
    },
    /* Update Tag */
    {
      type: 'Sidebar',
      name: 'update-tag',
      component: UpdateTag
    },
    {
      type: 'Dialog',
      name: 'confirm-delete-tag',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('cancel'),
          confirm: ct.$p('confirm'),
          title: ct.$p('title'),
          message: ct.$p('message')
        }
      }
    },
    {
      type: 'Dialog',
      name: 'merge-tag',
      component: MergeTag
    }
  ]

  return items
})
