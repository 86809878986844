import { CodexHooks } from '@/@core'
import UsersSelect from './UsersSelect.vue'

CodexHooks.addFilter('dynamic-form/fields', (items) => {
  return [
    ...items,
    {
      type: 'UsersSelect',
      component: UsersSelect
    }
  ]
})
