<script setup lang="ts">
import { computed } from 'vue'
import InputSwitch from 'primevue/inputswitch'

const props = withDefaults(
	defineProps<{
		size?: 'small' | 'large',
		value?: any
	}>(),
	{
		size: 'small',
		value: ""
	}
)


const getInputTextClasses = computed(() => {
	return {
		[`p-inputswitch-sm`]: props.size === 'small',
		[`p-inputswitch-lg`]: props.size === 'large'
	}
})


</script>

<template>
	<InputSwitch v-bind="$attrs" :class="getInputTextClasses" :value-on="props.value" :value-off="null">
	</InputSwitch>
</template>
