<script setup lang="ts">
import { useI18nStore } from '@/@core/i18n'
import DialogContent from '@/modules/@global/components/DialogContent/DialogContent.vue'
import { computed, inject, ref } from 'vue'
import { DROPDOWN_TAGS, SELECTED_DROPDOWN_TAGS } from '../queries/index.graphql'
import { useMutation } from '@vue/apollo-composable'
import { MERGE_TAGS } from '../queries/index.rest'
const dialog = inject('dialogRef')
const context = inject('context')

function handleCancel() {
  dialog?.value?.close(false)
}

const selectedTag = ref(null)
const selectedValues = ref<string[]>([])

const ct = useI18nStore().createI18nContext('ct.tags.popups.merge-tags')

const { mutate: mergeTagsMutation } = useMutation(MERGE_TAGS)

const variables = computed(() => {
  return {
    siteId: {
      eq: context?.value.siteId
    },
    parentId: {
      exists: false
    }
  }
})

async function handleConfirm() {
  // Filter out the keys that have `true` values
  const tagsToMerge = Object.keys(selectedValues.value).filter(
    (key) => selectedValues.value[key] === true
  )

  if (!selectedTag.value || tagsToMerge.length === 0) {
    console.error('Please select both target tag and tags to merge')
    return
  }

  try {
    await mergeTagsMutation({
      input: {
        targetTag: selectedTag.value,
        tagsToMerge: tagsToMerge // Use the filtered array of keys
      }
    })
    dialog?.value?.close(true)
  } catch (error) {
    console.error('Failed to merge tags:', error)
  }
}
</script>
<template>
  <div style="display: flex; height: 500px">
    <div
      class="bg-primary"
      style="width: 262px; display: flex; align-items: flex-end; position: relative"
    >
      <img
        alt="Codex logo"
        src="@/modules/tags/images/merge-popup-image.svg"
        style="width: 100%; position: absolute"
      />
      <div class="tag-tips-message">
        <!--        <Text type="body-medium-headline">{{ ct.$t('tag-tips') }}</Text>-->
        <!--        <Text type="body-regular-base">{{ ct.$t('tag-tips-description') }}</Text>-->
      </div>
    </div>
    <div class="merge-popup">
      <DialogContent :title="ct.$t('title')" :subtitle="ct.$t('description')">
        <div class="merge-popup-select">
          <Text is="label" :text="ct.$t('from-tags')" for="selectedValues" />
          <DynamicSelectTree
            input-id="selectedValues"
            v-model="selectedValues"
            :query="DROPDOWN_TAGS"
            :selected-query="SELECTED_DROPDOWN_TAGS"
            :variables="variables"
            selection-mode="multiple"
            option-value="id"
            option-label="tagValue"
          />
        </div>
        <div class="merge-popup-select">
          <Text is="span" :text="ct.$t('to-tag')" for="selectedTag" />
          <DynamicSelectTree
            input-id="selectedTag"
            v-model="selectedTag"
            :query="DROPDOWN_TAGS"
            :selected-query="SELECTED_DROPDOWN_TAGS"
            :variables="variables"
            selection-mode="single"
            option-value="id"
            option-label="tagValue"
          />
        </div>
        <template #footer>
          <Button :label="ct.$t('cancel')" severity="secondary" @click="handleCancel" />
          <Button :label="ct.$t('merge')" @click="handleConfirm" />
        </template>
      </DialogContent>
    </div>
  </div>
</template>

<style lang="scss">
.p-dialog-header-icons {
  z-index: 2 !important;
}

.tag-tips-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  text-align: center;
  z-index: 1;
  gap: 6px;

  .text {
    color: white !important;
  }
}

.merge-popup {
  position: relative;

  .text {
    max-width: 500px !important;
    margin-bottom: 4px;
  }

  .p-dialog-content-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .p-dialog-content-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .confirm-popup__message {
    max-width: 500px;
    margin: 0;
  }

  .merge-popup-select {
    width: 500px;
    max-width: 500px;

    label {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 27px;
      text-align: left;
      color: #868693;
    }
  }
}
</style>
