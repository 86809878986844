<script setup lang="ts">
import { CodexAutomationEventType } from '@/gql/graphql';
import { defineComponent, computed } from 'vue';
import { useI18nStore } from '@/@core/i18n';

defineComponent({
	name: 'WorkflowsEventsSelect'
})

const ct = useI18nStore().createI18nContext('ct.workflows.general.enums.eventTypes')

const options = computed(() => {
	return [
		{
			labels: ct.$t(CodexAutomationEventType.CommentResolved),
			value: CodexAutomationEventType.CommentResolved
		},
		{
			labels: ct.$t(CodexAutomationEventType.CommentAdded),
			value: CodexAutomationEventType.CommentAdded
		},
		{
			labels: ct.$t(CodexAutomationEventType.CommentReplyAdded),
			value: CodexAutomationEventType.CommentReplyAdded
		},
		{
			labels: ct.$t(CodexAutomationEventType.UserMentioned),
			value: CodexAutomationEventType.UserMentioned
		},
		{
			labels: ct.$t(CodexAutomationEventType.AssetUploaded),
			value: CodexAutomationEventType.AssetUploaded
		},
		{
			labels: ct.$t(CodexAutomationEventType.EntryCreated),
			value: CodexAutomationEventType.EntryCreated
		},
		{
			labels: ct.$t(CodexAutomationEventType.EntryDeleted),
			value: CodexAutomationEventType.EntryDeleted
		},
		{
			labels: ct.$t(CodexAutomationEventType.EntryUpdated),
			value: CodexAutomationEventType.EntryUpdated
		}
	]
})
</script>

<template>
	<Dropdown :options="options"></Dropdown>
</template>