<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router'
import Topbar from '@/modules/@core/components/Topbar.vue'
import Sidebar from '@/modules/@core/components/Sidebar/Sidebar.vue'
import { useOrganization } from '@/modules/organizations/store/organization'
import { useAbility } from '@casl/vue'
import { subject } from '@casl/ability'
import DynamicDialog from '@/modules/primevue/components/DynamicDialog/DynamicDialog.vue'
import Toast from '@/modules/primevue/components/Toast/Toast.vue'
import { useGlobalStore } from '@/modules/@global/store/global'

const route = useRoute()
const organization = useOrganization()
let { can } = useAbility()

const store = useGlobalStore()
</script>

<template>
  <div class="layout-wrapper">
    <header class="layout-header">
      <Topbar />
    </header>

    <main class="layout-main">
      <aside
        class="layout-sidebar"
        :class="{ 'layout-sidebar--open': store.isMenuOpen }"
        v-if="!route.meta.hideSidebar"
      >
        <Sidebar />
      </aside>

      <div class="layout-view">
        <!-- DEBUG {{ organization?.loading }}
        {{ organization?.sites?.length }}
        {{ can('read', 'Section') ? 'can read Section' : 'cannot read Section' }}-->
        <RouterView :key="route.path" />
      </div>
    </main>

    <!-- PrimeVue -->
    <DynamicDialog />
    <Toast />
    <div v-if="store.isMenuOpen" @click="store.toggleMenu()" class="backdrop" />
  </div>
</template>

<style scoped></style>
