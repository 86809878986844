import {
  FIELD_TYPES,
  FIELD_VALUE_TYPES,
  WIDGET_GROUPS,
  FIELD_FILTER_OPERATORS,
  RANGE_OPERATORS,
  TEXT_FIELD_APPEARANCES
} from '@/modules/models/editor-ts/fields/constants'
import { CodexHooks } from '@/@core'
import { fields } from '@/modules/@global/models/_exports'

CodexHooks.addFilter('fields/types', (items) => {
  return [
    ...items,
    {
      pluginName: 'codex',
      displayName: 'Text',
      name: 'field_text',
      render: () => import('./Render.vue'),
      renderEditor: () => import('./Render.vue'),
      renderEditorPanel: [
        {
          icon: 'Settings',
          label: 'General',
          component: () => import('./General.vue'),
          isGeneral: true
        },
        {
          icon: 'Brush',
          label: 'Appearance',
          component: () => import('./Appearance.vue')
        },
        {
          icon: 'SettingsPreferencesTools',
          label: 'Configurations',
          component: () => import('./Configuration.vue')
        }
      ],
      renderEditorInitialPanel: [
        {
          icon: 'Settings',
          label: 'ct.models.general.fields.initial-panel.label',
          component: () => import('./Initial.vue')
        }
      ],
      group: WIDGET_GROUPS.BASIC,
      icon: () => import('./icon.svg'),

      type: fields.type.TEXT,
      valueType: fields.valueType.SINGLE,
      defaultValue: null,

      attrs: {
        // New configs
        appearance: {
          default: TEXT_FIELD_APPEARANCES.SINGLE_LINE
        },
        showCharacterCount: {
          default: false
        },
        helpText: {
          default: ''
        },
        helpTextDisplay: {
          default: 'tooltip'
        },
        generateSlug: {
          default: ''
        },
        validation: {
          default: {
            isSlug: {
              value: false,
              errorMessage: ''
            },
            required: {
              value: false,
              errorMessage: 'Text is required',
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: false,
                  field: '',
                  operator: FIELD_FILTER_OPERATORS.EXISTS,
                  value: ''
                }
              ]
            },
            readOnly: {
              value: false,
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: false,
                  field: '',
                  operator: FIELD_FILTER_OPERATORS.EXISTS,
                  value: ''
                }
              ]
            },
            uniqueInCollection: {
              value: false,
              errorMessage: 'Text should be unique'
            },
            textLengthRange: {
              isEnabled: false,
              rangeOperator: RANGE_OPERATORS.BETWEEN,
              min: 10,
              max: 50,
              exactly: 0,
              errorMessage: 'Text length should be 10-50',
              errorTemplate: 'Text length should be {min}-{max}'
            },
            selectFrom: {
              value: false,
              predefinedValues: [],
              inputValue: ''
            },
            listLengthRange: {
              isEnabled: false,
              rangeOperator: RANGE_OPERATORS.BETWEEN,
              min: 2,
              max: 5,
              exactly: 0,
              errorMessage: 'List length should be 2-5',
              errorTemplate: 'List length should be {min}-{max}'
            },
            matchingRegex: {
              isEnabled: false,
              type: 1,
              patterns: [],
              flags: [],
              errorMessage: 'Pattern does not match'
            },
            prohibitedRegex: {
              isEnabled: false,
              patterns: [],
              flags: [],
              errorMessage: 'This words are prohibited'
            },
            predefinedValues: {
              isEnabled: false,
              values: [],
              errorMessage: 'Only specified values are accepted'
            }
          }
        },
        placeholder: {
          default: ''
        },
        configuration: {
          default: {
            searchable: true,
            filterable: true,
            aiFeatures: false
          }
        },
        hidden: {
          default: {
            value: false,
            conditionsEnabled: false,
            conditions: [
              {
                isSystem: false,
                field: '',
                operator: FIELD_FILTER_OPERATORS.EXISTS,
                value: ''
              }
            ]
          }
        }
      }
    }
  ]
})
