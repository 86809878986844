import dayjs from 'dayjs'

export enum DateFormatOptions {
  Numeric = 'DD/MM/YYYY',
  Full = 'DD MMMM YYYY',
  Medium = 'DD MMM YYYY',
  Iso = 'YYYY-MM-DD',
  UsNumeric = 'MM/DD/YYYY',
  WeekdayFull = 'dddd, DD MMMM YYYY',
  WeekdayMedium = 'ddd, DD MMM YYYY',
  MonthDayYear = 'MMMM DD, YYYY'
}

export type DateFormatKeys = keyof typeof DateFormatOptions

export function formatDate(value: string, format: DateFormatKeys, time: boolean) {
  return dayjs(value).format(`${DateFormatOptions[format]}${time ? ' HH:mm' : ''}`)
}
