<script setup lang="ts">
import { useI18nStore } from '@/@core/i18n'
import type { Context } from '@/modules/@global/composables/useContext'
import { computed, inject, ref, watch, watchEffect } from 'vue'

import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'

import NinePointLayout from '../components/NinePointLayout.vue'
import { useRoute, useRouter } from 'vue-router'
import { CodexAssetType, type CodexAsset } from '@/gql/graphql'
import { downloadAsset } from '../constants'
import { bytesToSize, toLocale } from '@/modules/@global/utils/formatNumbers'
import { CreateEditAssetForm } from '../forms/EditAsset'
import { cloneDeep } from 'lodash'
import { useToast } from 'primevue/usetoast'
import { UPDATE_ASSET } from '../queries/index.rest'
import { useCodexQuery } from '@/modules/@global/composables/useCodexQuery'
import { GET_ASSET_BY_ID } from '../queries/index.graphql'
import DynamicDialogEventBus from 'primevue/dynamicdialogeventbus'
import { useMutation } from '@vue/apollo-composable'

const route = useRoute()
const router = useRouter()

/**
 * Context
 */
const context: Context = inject('context') as Context
/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.assets.popups.preview-asset')
/**
 * Dialog
 */
const dialog = inject('dialogRef')

const props = defineProps<{
  previewNext: () => any
  previewPrev: () => any
  deleteAssetHandler: (a: CodexAsset) => any
}>()

function hide() {
  DynamicDialogEventBus.emit('close', { instance: dialog.value })
  const { assetId, ...others } = route.query
  router.replace({
    query: {
      ...others
    }
  })
}

const assetLoading = ref(true)
const errorLoadingAsset = ref(false)

function handleError() {
  assetLoading.value = false
  errorLoadingAsset.value = true
}

/*
	->	DATA
*/
const activeAsset = ref<string | null>(null)

if (route?.query?.assetId) activeAsset.value = route.query.assetId as string

const currentIndex = ref('')
const total = ref('')

watch(
  dialog.value,
  (newValue) => {
    if (newValue) {
      currentIndex.value = toLocale(newValue?.options?.parentContext?.data?.currentIndex)
      total.value = toLocale(newValue?.options?.parentContext?.data?.total)
    }
  },
  {
    immediate: true,
    deep: true
  }
)
const { previewNext, previewPrev } = props

watch(
  () => route.query.assetId,
  (newAssetId) => {
    if (newAssetId) {
      activeAsset.value = newAssetId as string

      // Re-trigger the query to fetch new asset data
      fetchMore({
        variables: {
          id: activeAsset.value
        }
      })
    } else {
      activeAsset.value = null
    }
  }
)

/**
 * Load Asset
 */

const { loading, result, error, fetchMore, variables } = useCodexQuery(
  GET_ASSET_BY_ID,
  () => ({
    id: activeAsset.value
  }),
  {
    debounce: 100,
    fetchPolicy: 'network-only'
  }
)

const asset = ref<CodexAsset | null>(null)

watchEffect(() => {
  if (result.value && result.value.asset) {
    asset.value = result.value.asset
  } else {
    asset.value = null
  }
})

const title = computed(() => {
  return asset?.value?.title
})

const height = computed(() => {
  if (asset.value?.type === CodexAssetType.Audio) {
    return 'height: 180px;'
  }
  if (asset.value?.type === CodexAssetType.Podcast) {
    return 'height: 410px;'
  }
  return ''
})
/**
 * EDIT Asset
 */

const assetEditObject = computed(() => {
  return {
    // externalId: asset.value?.externalId,
    caption: asset.value?.caption,
    title: asset.value?.title,
    alt: asset.value?.alt,
    author: asset.value?.author,
    copyright: asset.value?.copyright,
    source: asset.value?.source,
    // tags: asset.value?.tags,
    expiresAt: asset.value?.expiresAt,
    // focalPoint: asset.value?.focalPoint,
    // attrs: asset.value?.attrs,
    labels: asset.value?.labels
  }
})

const editAsset = useMutation(UPDATE_ASSET, {
  refetchQueries: [{ query: GET_ASSET_BY_ID, variables: { id: activeAsset.value } }]
})

async function editAssetHandler(v: any) {
  try {
    await editAsset.mutate({ id: asset.value?.id, input: v })
  } catch (e) {
    console.log(e)
  }
}

const passThroughObject = {
  headerAction: () => ({
    style: {
      background: 'white',
      'border-radius': '4px',
      border: 'none',
      padding: '1rem 0'
    }
  }),
  header: () => ({
    style: {
      'z-index': '2',
      padding: '0',
      'background-color': 'var(--color-neutrals-n20)'
    }
  }),
  content: () => ({
    style: {
      padding: '0px'
    }
  })
}

const toast = useToast()

const copy = async () => {
  await navigator.clipboard.writeText(asset.value?.id as string)
  toast.add({ severity: 'success', summary: ct.$t('copied-successfully'), life: 3000 })
}
</script>

<template>
  <div
    class="preview-edit-modal"
    style="
      width: 100%;
      min-height: 100vh;
      max-height: 100vh;
      display: flex;
      justify-content: space-between;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(26, 30, 57, 0.6);
      z-index: 10;
    "
    @keyup.esc="hide"
    @keyup.left="previewPrev"
    @keyup.right="previewNext"
    tabindex="0"
  >
    <div class="preview" style="width: 100%; height: auto; position: relative; display: flex">
      <NinePointLayout
        :style="{
          top: {
            height: '46px',
            width: '100%'
          },
          middle: {
            height: '75%',
            width: '75%',
            margin: 'auto',
            aspectRatio: '16 / 9'
          },
          bottom: {
            height: '32px',
            width: 'fit-content',
            margin: '24px'
          }
        }"
      >
        <template #top>
          <div class="preview__topbar">
            <div class="preview__topbar--details" @click="hide">
              <GjIcon name="Close" size="24" />
              <Text style="color: white; max-width: 50%" :clamp="1">{{ title }}</Text>
            </div>
            <div class="preview__topbar--actions">
              <GjIcon v-if="asset?.type === CodexAssetType.Image" name="Crop" size="24" />
              <GjIcon
                v-if="asset?.type === CodexAssetType.Image || asset?.type === CodexAssetType.File"
                name="Download_fill"
                @click="downloadAsset(asset)"
                size="24"
              />
              <GjIcon name="Delete" size="24" @click="deleteAssetHandler(asset)" />
            </div>
          </div>
        </template>
        <template #middle>
          <div class="asset-preview-wrapper">
            <div v-if="!loading && asset" class="asset-preview">
              <img
                v-if="asset.type === CodexAssetType.Image"
                :src="asset.url"
                class="curtain-image"
                @load="assetLoading = false"
                @error="handleError"
              />
              <iframe
                v-else-if="
                  asset.type === CodexAssetType.Video ||
                  asset.type === CodexAssetType.VideoPlaylist ||
                  asset.type === CodexAssetType.Audio ||
                  asset.type === CodexAssetType.Podcast
                "
                :src="asset.url"
                class="curtain-image"
                :style="height"
                allow="autoplay *; encrypted-media *; fullscreen *"
                frameborder="0"
                width="100%"
                height="100%"
                sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                @load="assetLoading = false"
                @error="handleError"
              />
            </div>
            <div v-if="loading || assetLoading" class="asset-preview-loader">
              <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="6"
                stroke="white"
                fill="rgba(255,255,255,.5)"
                animationDuration="1s"
                aria-label="Custom ProgressSpinner"
              />
            </div>
            <div class="asset-preview-error" v-if="!loading && !assetLoading && errorLoadingAsset">
              ERROR
            </div>
          </div>
        </template>
        <template #bottom>
          <div style="width: inherit; height: 100%; color: white; display: flex; gap: 16px">
            <div @click="previewPrev">
              <GjIcon
                style="
                  border: 1px solid rgba(222, 222, 226, 1);
                  border-radius: 50%;
                  cursor: pointer;
                  pointer-events: none;
                "
                name="ArrowLeft"
                size="32"
              />
            </div>
            <p v-if="currentIndex && total" style="margin: auto">
              {{ currentIndex }} / {{ total }}
            </p>
            <div @click="previewNext">
              <GjIcon
                style="
                  border: 1px solid rgba(222, 222, 226, 1);
                  border-radius: 50%;
                  cursor: pointer;
                  pointer-events: none;
                "
                name="ArrowRight"
                size="32"
              />
            </div>
          </div>
        </template>
      </NinePointLayout>
    </div>
    <div
      class="edit p-sidebar-custom-content"
      style="background-color: white; max-width: 400px; min-width: 400px"
    >
      <SidebarContent class="p-sidebar-content" :showHeader="false">
        <div v-if="loading" class="asset-edit-loader">
          <ProgressSpinner
            style="width: 50px; height: 50px"
            strokeWidth="6"
            stroke="white"
            fill="rgba(255,255,255,.5)"
            animationDuration="1s"
            aria-label="Custom ProgressSpinner"
          />
        </div>
        <div v-else class="scroll-edit">
          <span>
            <Text type="body-medium-headline">{{ ct.$t('title') }}</Text>
            <Text>{{ ct.$t('description') }}</Text>
            <br />
            <Text type="body-medium-headline">{{ ct.$t('url') }}</Text>
            <span style="display: flex; cursor: pointer">
              <Text :clamp="1">
                {{ asset?.url }}
              </Text>
              <GjIcon name="Redirect" size="24" />
            </span>
          </span>

          <Accordion
            :active-index="[0]"
            multiple
            :pt="{
              root: (options) => ({
                style: {
                  gap: '10px',
                  display: 'flex',
                  'flex-direction': 'column'
                }
              })
            }"
          >
            <AccordionTab :header="ct.$t('general')" :pt="passThroughObject">
              <DynamicForm
                name="edit-asset"
                :ref="(r: any) => context?.setRef('edit-asset', r)"
                :show-submit="false"
                :schema="CreateEditAssetForm()"
                :data="cloneDeep(assetEditObject)"
                :labels="{
                  submit: ct.$t('submit')
                }"
              ></DynamicForm>
              <!-- :mutation="editAsset" -->
            </AccordionTab>

            <AccordionTab :header="ct.$t('file.title')" :pt="passThroughObject">
              <span class="file-detail-row" v-if="asset?.type === CodexAssetType.Image">
                <Text>{{ ct.$t('file.resolution') }}</Text>
                <Text>{{ `${asset?.width} x ${asset?.height}` }}</Text>
              </span>
              <span
                class="file-detail-row"
                v-if="asset?.type === CodexAssetType.Image || asset?.type === CodexAssetType.File"
              >
                <Text>{{ ct.$t('file.size') }}</Text>
                <Text>{{ `${bytesToSize(asset?.size)}` }}</Text>
              </span>
              <span class="file-detail-row">
                <Text>{{ ct.$t('file.name') }}</Text>
                <Text style="max-width: 50%" :clamp="1">{{ `${asset?.title}` }}</Text>
              </span>
              <span class="file-detail-row">
                <Text>{{ ct.$t('file.type') }}</Text>
                <Text>{{ asset?.contentType }}</Text>
              </span>
              <span class="file-detail-row">
                <Text>{{ ct.$t('file.id') }}</Text>
                <Text @click="copy" style="cursor: pointer">
                  {{ `${asset?.id}` }}
                  <GjIcon name="Copy" size="16" />
                </Text>
              </span>
            </AccordionTab>
            <AccordionTab :header="ct.$t('logs')" :pt="passThroughObject"> </AccordionTab>
          </Accordion>
        </div>
        <template #footer>
          <Button
            v-bind="context.getRef('edit-asset')?.props?.submit.value"
            @click="editAssetHandler(context.getRef('edit-asset')?.values)"
            :disabled="loading"
          ></Button>
        </template>
      </SidebarContent>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.preview {
  &__topbar {
    width: 100%;
    background-color: var(--color-background-topbar-alt);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 24px;

    &--details,
    &--actions {
      display: flex;
      gap: 24px;

      & svg {
        cursor: pointer;
      }
    }
  }

  .asset-preview-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .asset-preview,
    .asset-preview-loader,
    .asset-preview-error {
      position: absolute;
      width: inherit;
      height: inherit;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .asset-preview {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    color: white;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.edit {
  .scroll-edit {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
  }

  .file-detail-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .asset-edit-loader {
    margin: 50px auto;
    display: flex;
    flex-shrink: 1;
  }
}
</style>
