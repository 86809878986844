import NumberFilterComponent from './Number.vue'
import { useI18nStore } from '@/@core/i18n'

export default class NumberFilter {
  static COMPONENT = NumberFilterComponent
  static OPERATORS: {
    equals: string
    notEquals: string
    greaterThan: string
    lowerThan: string
    between: string
    exists: string
    notExists: string
  } = {
    equals: 'eq',
    notEquals: 'ne',
    greaterThan: 'gt',
    lowerThan: 'lt',
    between: 'bt',
    exists: 'exists',
    notExists: 'notExists'
  }

  static type: string = 'number'

  operator: string | null = null

  value1?: number | null = null

  value2?: number | null = null

  constructor(
    {
      operator = NumberFilter.OPERATORS.equals,
      value1,
      value2
    }: { operator: string; value1?: number; value2?: number } = {
      operator: NumberFilter.OPERATORS.equals
    }
  ) {
    this.operator = operator
    this.value1 = value1 && Number(value1)
    this.value2 = value2 && Number(value2)
  }
  set(
    {
      operator = NumberFilter.OPERATORS.equals,
      value1,
      value2
    }: { operator: string; value1?: number; value2?: number } = {
      operator: NumberFilter.OPERATORS.equals
    }
  ) {
    this.operator = operator
    this.value1 = value1
    this.value2 = value2
  }

  getCount() {
    return 1
  }

  getValueLabel(): string | null {
    const ct = useI18nStore().createI18nContext('ct.global.filters.number')
    switch (this.operator) {
      case NumberFilter.OPERATORS.equals:
        return ct.$t('active-label.is', { value: this.value1 })
      case NumberFilter.OPERATORS.notEquals:
        return ct.$t('active-label.is-not', { value: this.value1 })
      case NumberFilter.OPERATORS.greaterThan:
        return ct.$t('active-label.is-greater-than', { value: this.value1 })
      case NumberFilter.OPERATORS.lowerThan:
        return ct.$t('active-label.is-lower-than', { value: this.value1 })
      case NumberFilter.OPERATORS.between:
        return ct.$t('active-label.is-between', { value1: this.value1, value2: this.value2 })
      case NumberFilter.OPERATORS.exists:
        return ct.$t('active-label.exists', {})
      case NumberFilter.OPERATORS.notExists:
        return ct.$t('active-label.not-exists', {})
      default:
        return null
    }
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value1: this.value1,
      value2: this.value2
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case NumberFilter.OPERATORS.equals:
        return {
          eq: this.value1
        }
      case NumberFilter.OPERATORS.notEquals:
        return {
          ne: this.value1
        }
      case NumberFilter.OPERATORS.greaterThan:
        return {
          gt: this.value1
        }
      case NumberFilter.OPERATORS.lowerThan:
        return {
          lt: this.value1
        }
      case NumberFilter.OPERATORS.between:
        return {
          gt: this.value1,
          lt: this.value2
        }
      case NumberFilter.OPERATORS.exists:
        return {
          exists: true
        }
      case NumberFilter.OPERATORS.notExists:
        return {
          exists: false
        }
      default:
        return null
    }
  }

  static validate({
    operator,
    value1,
    value2
  }: {
    operator: string
    value1?: number
    value2?: number
  }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (operator !== NumberFilter.OPERATORS.exists && operator !== NumberFilter.OPERATORS.notExists)
      if (!value1) {
        return 'Value 1 is required'
      }

    if (operator === NumberFilter.OPERATORS.between && !value2) {
      return 'Value 2 is required because operator is "between"'
    }

    return true
  }
  static fromQueryParam({
    operator,
    value1,
    value2
  }: {
    operator: string
    value1?: number
    value2?: number
  }) {
    return new NumberFilter({
      operator,
      value1,
      value2
    })
  }
}
