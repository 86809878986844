<script setup lang="tsx">
import { computed, inject, ref } from 'vue'
import { useI18nStore } from '@/@core/i18n'
import useContext from '@/modules/@global/composables/useContext'
import SidebarContent from '@/modules/@global/components/SidebarContent/SidebarContent.vue'
import { AUTHORS_NAME } from '@/modules/authors/constants'
import type Button from 'primevue/button'
import { LIST_AUTHORS } from '../queries/index.graphql'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context = useContext({
  name: AUTHORS_NAME,
  type: 'sidebar'
})

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.authors.pages.listing')
const ctPopup = useI18nStore().createI18nContext('ct.authors.popups.add-author')

/**
 * Dialog
 */
const dialog = inject(DialogRefKey)

async function createUrlHandler() {
  dialog?.value?.close(selected.value)
}

const selectionMode = dialog?.value.options?.props?.selectionMode
const alreadySelected = dialog?.value.options?.props?.alreadySelected || []

const selected = ref([...alreadySelected])

const listingColumns = computed(() => [
  {
    field: 'byline',
    header: ct.$t('table.columns.name'),
    responsive: ['mobile', 'tablet', 'desktop'],
    columnKey: 'byline'
  },
  {
    field: 'email',
    columnKey: 'email',
    header: ct.$t('table.columns.email'),
    responsive: ['tablet', 'desktop']
  },
  {
    field: 'createdAt',
    header: ct.$t('table.columns.date'),
    body: [
      {
        name: 'createdAt',
        element: (rowData: any) => {
          return <DateFormat date={rowData.data.createdAt} />
        }
      }
    ],
    responsive: ['tablet', 'desktop']
  }
])
</script>

<template>
  <div class="add-section-popup">
    <SidebarContent :title="ct.$t('title')">
      <LayoutElements class="layout-view__leaf" layout="vertical" name="authors-listing">
        <LayoutElement name="header">
          <Row align-items="center">
            <Col>
              <Text type="title-medium-base">{{ ct.$t('title') }}</Text>
              <Text>{{ ct.$t('description') }}</Text>
            </Col>
          </Row>
        </LayoutElement>

        <LayoutElement name="table" :main="true">
          <ListingTable
            class="p-table-full"
            :ref="(r:any) => context.setRef('table', r)"
            :name="AUTHORS_NAME"
            :query="LIST_AUTHORS"
            :initial-columns="listingColumns"
            :labels="{
              empty: {
                title: ct.$t('empty.title'),
                description: ct.$t('empty.description')
              }
            }"
            :editable="true"
            v-model:selection="selected"
            :selection-mode="selectionMode"
          >
            <template #emptyButtons>
              <Button icon="Plus" :label="ct.$t('new')" />
            </template>
          </ListingTable>
        </LayoutElement>
      </LayoutElements>
      <template #footer>
        <Button @click="createUrlHandler">{{ ctPopup.$t('insert') }}</Button>
      </template>
    </SidebarContent>
  </div>
</template>

<style lang="scss">
.create-section-form {
  .entity-type-radiobuttons {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;

    .p-radiobutton {
      width: 100%;
    }

    .section-custom-radiobuttons {
      width: calc(50% - 8px);
      background: #f6f7f9;
      border-radius: 8px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 50px;
      }
    }

    .section-switch-button {
      background-color: red;
    }
  }
}
</style>
