import gql from 'graphql-tag'

export const LIST_ENTRIES = gql(/* GraphQL */ `
  query ListEntries(
    $limit: Int!
    $offset: Int!
    $where: CodexEntryFilter
    $order: CodexEntryOrder
  ) {
    data: entryCollection(limit: $limit, offset: $offset, where: $where, order: $order) {
      items {
        id
        system {
          title
          slug
          status
          publishedAt
          unpublishedAt
          firstPublishedAt
          featuredMedia {
            url(transformation: { width: 70, height: 70, format: THUMBNAIL })
          }
          labels {
            id
            name
            description
            color
          }
          siteId
          createdAt
          modelId
          modelAlias
          model {
            id
            name
            iconId
          }
          createdBy {
            id
            email
            firstName
            lastName
            imageUrl
          }
          updatedAt
          updatedBy {
            id
            email
            firstName
            lastName
            imageUrl
          }
        }
      }
      offset
      limit
      total
    }
  }
`)

export const LIST_ENTRIES_TEXT = `
  query ListEntries($limit: Int!, $offset: Int!, $where: CodexEntryFilter, $order: CodexEntryOrder) {
    data: entryCollection(limit: $limit, offset: $offset, where: $where, order: $order) {
      items {
        id
				__FRAGMENT_PLACEHOLDER__
        system {
          title
          slug
          status
          publishedAt
          unpublishedAt
          firstPublishedAt
          featuredMedia {
            url(transformation: { width: 70, height: 70, format: THUMBNAIL })
          }
          labels {
            id
            name
            description
            color
          }
          siteId
          createdAt
          modelId
          modelAlias
          model {
            id
            name
            iconId
          }
          createdBy {
            id
            email
            firstName
            lastName
            imageUrl
          }
          updatedAt
          updatedBy {
            id
            email
            firstName
            lastName
            imageUrl
          }
        }
      }
      offset
      limit
      total
    }
  }
`

export const ENTRY_SCHEDULES = gql(/* GraphQL */ `
  query GetSchedules($where: CodexScheduledVersionFilter) {
    data: scheduledVersionCollection(where: $where, offset: 0, limit: 1000) {
      total
      items {
        id
        versionId
        publishScheduledDate
        unpublishScheduledDate
        status
        versionId
        createdAt
      }
    }
  }
`)

export const MODEL_DETAILS = gql(/* GraphQL */ `
  query GetModelDetails($modelId: String!) {
    data: modelCollection(
      limit: 1000
      where: { and: { status: { in: [PUBLISHED] }, id: { eq: $modelId } } }
    ) {
      items {
        id
        name
        alias
        fields {
          type
          valueType
          alias
          index
          name
          configuration {
            filterable
          }
        }
      }
    }
  }
`)
