<script setup lang="ts">
import type { NavigationItem } from '@/@core/navigation/types'
import { computed, type ComputedRef, ref } from 'vue'
import SidebarNavigation from './SidebarNavigation.vue'
import { findActiveModule } from '@/modules/@core/utils/navigation'

const modulesOverlay = ref()

let props = defineProps<{
  item: NavigationItem
  indent: number
}>()

let activeModule: ComputedRef<NavigationItem | null | undefined> = computed(() => {
  if (props.item.children && props.item.children.length) {
    return findActiveModule(props.item.children)
  }
  return null
})

function toggleOverlay(event: MouseEvent) {
  if (!modulesOverlay.value) return
  modulesOverlay.value.toggle(event)
}
</script>

<template>
  <div>
    <div class="sidebar-modules">
      <div class="sidebar-modules__toggle-wrapper">
        <Button class="sidebar-modules__toggle" @click="toggleOverlay" link>
          <div class="sidebar-modules__toggle-title">
            <template v-if="activeModule">
              {{ activeModule.title }}
            </template>
            <template v-else>
              {{ `No active module` }}
            </template>
          </div>

          <GjIcon name="ArrowDown" class="sidebar-modules__toggle-icon" />
        </Button>
      </div>

      <OverlayPanel ref="modulesOverlay" class="sidebar-modules__overlay">
        <div class="sidebar-modules__groups">
          <template v-for="group in item.attrs.groups" :key="group.key">
            <div
              class="sidebar-modules__group"
              :data-group="group.key"
              v-if="props.item.children?.filter((item) => item.group === group.key).length"
            >
              <div class="sidebar-modules__group-title" v-if="group.title">
                {{ group.title }}
              </div>

              <SidebarNavigation
                :navigation-items="props.item.children?.filter((item) => item.group === group.key)"
                :indent="0"
                :depth="1"
                @click="toggleOverlay"
              />
            </div>
          </template>
        </div>
      </OverlayPanel>

      <template v-if="activeModule && activeModule.children && activeModule.children.length">
        <SidebarNavigation :navigation-items="activeModule.children" :indent="0" />
      </template>
    </div>
  </div>
</template>

<style scoped></style>
