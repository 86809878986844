import { CodexHooks } from '@/@core'
import { PLUGIN_STATUS_MAPPING } from '@/modules/plugins/constants'
import { isString } from 'lodash'

CodexHooks.addFilter(
  'apollo/restFetchOptions?type=CodexPlugin',
  (options, context, uri, headers) => {
    if (isString(options.body)) {
      try {
        const bodyParsed = JSON.parse(options.body)

        if (bodyParsed.status) {
          bodyParsed.status = PLUGIN_STATUS_MAPPING.toNumber(bodyParsed.status)
        }

        options.body = JSON.stringify(bodyParsed)
      } catch (e) {
        // Do nothing
      }
    }

    return options
  }
)

CodexHooks.addFilter('apollo/responseTransformer?type=CodexPlugin', (response) => {
  if (response?.status) {
    response.status = PLUGIN_STATUS_MAPPING.toString(response.status)
  }
  return response
})
