<script lang="tsx">
import { computed, defineComponent, ref, type PropType, type Component, watch, inject } from 'vue'
import { LayoutElements } from '@/modules/@core/components/LayoutElements'
import TreeTable from 'primevue/treetable'
import Column from 'primevue/column'
import { CodexHooks } from '@/@core'
import useNestedItems from '@/modules/@global/composables/useNestedItems'
import { useI18nStore } from '@/@core/i18n'
import { useDisplay } from '@/modules/@global/composables/useDisplay'

export default defineComponent({
  name: 'ListingTreeTable',
  props: {
    name: {
      type: String,
      default: ''
    },
    variables: {
      type: Object as PropType<any>,
      default: () => ({})
    },
    query: {
      type: Object as PropType<any>,
      default: () => ({})
    },
    nestedQuery: {
      type: Object as PropType<any>,
      default: () => ({})
    },
    columns: {
      type: Array,
      default: () => []
    },
    class: {
      type: String,
      default: ''
    },
    children: {
      type: String,
      default: 'children'
    },
    labels: {
      type: Object,
      default: () => ({})
    },
    selection: {
      type: Array
    },
    selectionMode: {
      type: String,
      default: ''
    }
  },
  inject: ['context'],
  setup(props, { emit }) {
    if (!props.name) {
      return {}
    }
    const display = useDisplay()
    const context: any = inject('context')

    const ct = useI18nStore().createI18nContext('ct.global.components.listing-table')

    const firstLoad = ref(true)

    let skeletonRecords = ref([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}])

    var nestedItemsStore = useNestedItems({
      name: props.name,
      query: props.query,
      nestedQuery: props.nestedQuery
    })

    const records = computed(() => nestedItemsStore.records.value)
    const totalRecords = computed(() => nestedItemsStore.totalRecords.value)
    const loading = computed(() => nestedItemsStore.loading.value)
    const errors = computed(() => nestedItemsStore.error.value)
    const page = computed({
      get: () => nestedItemsStore.page.value,
      set: (value) => nestedItemsStore.setPage(value)
    })
    const limit = computed({
      get: () => nestedItemsStore.limit.value,
      set: (value) => nestedItemsStore.setLimit(value)
    })
    const expandedKeys = computed({
      get: () => nestedItemsStore.expandedKeys.value,
      set: (value) => nestedItemsStore.setExpandedKeys(value)
    })

    watch(
      records,
      (result) => {
        if (result) {
          firstLoad.value = false
        }
      },
      { immediate: true }
    )

    const selectedKey = computed({
      get() {
        const ids = {}

        props.selection?.forEach((s) => {
          ids[s.id] =
            props.selectionMode === 'checkbox'
              ? {
                  checked: true,
                  partialChecked: false
                }
              : true
        })

        return ids
      },
      set(v) {
        const ids = Object.keys(v)
        const sections = []
        const getItems = (records) => {
          records.forEach((s) => {
            if (ids.includes(s.data.id)) {
              sections.push(s.data)
            }
            getItems(s.children)
          })
        }

        getItems(records.value)

        emit('update:selection', sections)
      }
    })

    const refreshKey = ref(0)
    CodexHooks.addAction('hooks/filter-added?name=table/columns', () => {
      refreshKey.value++
    })
    CodexHooks.addAction('hooks/filter-removed?name=table/columns', () => {
      refreshKey.value++
    })
    CodexHooks.addAction('i18n/setLocale', () => {
      refreshKey.value++
    })

    const onNodeSelect = (e) => {
      const selectedData =
        props.selectionMode === 'single' ? [e?.data] : [...(props.selection ?? []), e?.data]

      emit('update:selection', selectedData)
    }

    const onNodeUnselect = (e) => {
      emit('update:selection', props.selection?.filter((s) => s.id !== e?.data?.id) ?? [])
    }

    return {
      ct,
      refreshKey,
      limit,
      page,
      loading,
      firstLoad,
      records,
      totalRecords,
      expandedKeys,
      nestedItemsStore,
      skeletonRecords,
      errors,
      display,
      selectedKey,
      onNodeSelect,
      onNodeUnselect
    }
  },
  unmounted() {
    // this.nestedItemsStore.$reset()
  },
  methods: {
    onPageChange(event: any) {
      this.page = event.page
      this.limit = event.rows
    },
    onExpand(node: any) {
      this.nestedItemsStore.onExpand(node)
    }
  },
  render() {
    /* prettier-ignore */

    this.refreshKey

    // Filter the columns
    let columnsFiltered = CodexHooks.applyFilters(
      `table/columns?name=${this.name}`,
      this.columns
    ).filter((col) => {
      if (col.responsive) {
        return col.responsive.includes(this.display.currentBreakpoint.value)
      }
      return true
    })

    return (
      <>
        <ProgressBar v-show={this.loading} mode="indeterminate" style="height: 1px"></ProgressBar>
        <div style="height: 1px" v-show={!this.loading}></div>

        <TreeTable
          class={this.class}
          scrollable
          key={this.refreshKey}
          lazy={true}
          value={this.firstLoad ? this.skeletonRecords : this.records}
          paginator={true}
          rows={this.limit}
          totalRecords={this.totalRecords}
          first={(this.page || 0) * (this.limit || 5)}
          onPage={this.onPageChange}
          v-model:expandedKeys={this.expandedKeys}
          selectionKeys={this.selectedKey}
          selectionMode={this.selectionMode}
          onNodeExpand={this.onExpand}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate={this.ct.$t('current-page-template')}
          metaKeySelection={false}
          onNodeSelect={this.onNodeSelect}
          onNodeUnselect={this.onNodeUnselect}
        >
          {{
            empty: () => {
              if (this.errors) {
                return <InlineMessage severity="error" title={this.errors} block={true} />
              }

              return (
                <NoResults
                  title={this.ct.d$t?.(this.labels?.empty?.title, 'empty.title')}
                  description={this.ct.d$t?.(this.labels?.empty?.description, 'empty.description')}
                >
                  {{
                    default: () => this.$slots.emptyButtons()
                  }}
                </NoResults>
              )
            },
            default: () => {
              if (this.firstLoad) {
                return columnsFiltered?.map((column: any, index: Number) => {
                  return (
                    <Column key={column.columnKey} header={column.header} {...column}>
                      {{
                        body: (rowData: any) => <Skeleton></Skeleton>
                      }}
                    </Column>
                  )
                })
              } // end if loading

              return columnsFiltered?.map((column: any, index: Number) => {
                if (column.body) {
                  return (
                    <Column key={column.columnKey} header={column.header} {...column}>
                      {{
                        body: (rowData: any) => (
                          <LayoutElements
                            name={`${this.name}-${column.columnKey}`}
                            layout="horizontal"
                            items={column.body}
                            data={[rowData]}
                            alignItems={column.field === 'tagValue' ? 'center' : ''}
                            flexDirection={column.field === 'tagValue' ? 'row' : 'column'}
                          />
                        )
                      }}
                    </Column>
                  )
                }

                return (
                  <Column
                    key={column.columnKey}
                    field={column.field}
                    header={column.header}
                    expander={column.expander}
                    {...column}
                  />
                )
              })
            }
          }}
        </TreeTable>
      </>
    )
  }
})
</script>
