<script setup lang="ts">
import { useI18nStore } from '@/@core/i18n'
import { computed, inject } from 'vue'
import { SECTIONS_PARENT_DROPDOWN } from '../queries/index.graphql'

const context = inject('context')

const modelValue = defineModel()

const variables = computed(() => {
  return {
    siteId: {
      eq: context?.value.siteId
    },
    parentId: {
      exists: false
    }
  }
})

const ct = useI18nStore().createI18nContext('ct.sections.popups.create-section')
</script>
<template>
  <div class="select-parent">
    <DynamicSelectTree
      :query="SECTIONS_PARENT_DROPDOWN"
      v-model="modelValue"
      :variables="variables"
      option-value="id"
      option-label="title"
      display="comma"
      :placeholder="ct.$t('select-parent')"
    />
  </div>
</template>

<style lang="scss"></style>
