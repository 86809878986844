import { CodexHooks } from '@/@core'

import Checkboxes from './Checkboxes.vue'
import RadioButtons from './RadioButtons.vue'
import Repeater from './Repeater.vue'
import * as yup from 'yup'
import { resolveComponent } from 'vue'
import IconField from './IconField.vue'
import DatePicker from '@/modules/primevue/components/DatePicker/DatePicker.vue'
import InputSwitch from '@/modules/primevue/components/InputSwitch/InputSwitch.vue'
import InputTextGroup from '@/modules/primevue/components/InputText/InputTextGroup.vue'

CodexHooks.addFilter('dynamic-form/schema?name=design-system', (schema) => {
  schema[1] = {
    ...schema[1],
    children: [
      ...schema[1].children,
      {
        name: 'lastname',
        type: 'Text',
        label: 'Last Name',
        rules: yup.string().required('Last name is required'),
        layout: { col: { sm: 12, md: 6, lg: 4, xl: 3 } },
        show: (values) => values.name === 'John'
      }
    ]
  }

  return schema
})

CodexHooks.addFilter('dynamic-form/fields', (items) => {
  return [
    ...items,
    {
      type: 'Checkboxes',
      component: Checkboxes
    },
    {
      type: 'RadioButtons',
      component: RadioButtons
    },
    {
      type: 'Repeater',
      component: Repeater,
      field: false
    },
    {
      type: 'Message',
      component: resolveComponent('Text')
    },
    {
      type: 'Row',
      component: resolveComponent('Row')
    },
    {
      type: 'Column',
      component: resolveComponent('Column')
    },
    {
      type: 'Button',
      component: resolveComponent('Button')
    },
    {
      type: 'Text',
      component: 'InputText',
      props: {
        type: 'text',
        autocomplete: 'off'
      }
    },
    {
      type: 'InputTextGroup',
      component: InputTextGroup
    },
    {
      type: 'Email',
      component: 'InputText',
      props: {
        type: 'email',
        autocomplete: 'off'
      }
    },
    {
      type: 'Password',
      component: 'InputText',
      props: {
        type: 'password',
        autocomplete: 'off'
      }
    },
    {
      type: 'Number',
      component: 'InputNumber',
      props: {
        type: 'number',
        autocomplete: 'off',
        useGrouping: false
      }
    },
    {
      type: 'Select',
      component: 'Dropdown',
      props: {
        optionLabel: 'label',
        optionValue: 'value'
      }
    },
    {
      type: 'Dropdown',
      component: 'Dropdown',
      props: {
        optionLabel: 'label',
        optionValue: 'value'
      }
    },
    {
      type: 'ColorPicker',
      component: 'ColorPicker'
    },
    {
      type: 'Chips',
      component: 'Chips'
    },
    {
      type: 'MultiSelect',
      component: 'MultiSelect'
    },
    {
      type: 'TextArea',
      component: 'TextArea'
    },
    {
      type: 'RichText',
      component: 'Editor'
    },
    {
      type: 'InputSwitch',
      component: InputSwitch
    },
    {
      type: 'IconField',
      component: IconField
    },
    {
      type: 'RichText',
      component: 'Editor'
    },
    {
      type: 'DatePicker',
      component: DatePicker
    },
  ]
})
