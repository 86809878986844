import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=organization', (items) => {
  return [
    ...items,
    {
      path: 'presets',
      name: 'presets',
      component: () => import('@/modules/presets/pages/PresetsListing.vue')
    }
  ]
})
