<script setup lang="ts">
import { useMutation, useQuery } from '@vue/apollo-composable'
import { READ_PRESET, UPDATE_PRESET } from '@/modules/presets/queries/index.rest'
import { inject } from 'vue'
import { cloneDeep } from 'lodash'
import { UpdatePresetForm } from '@/modules/presets/forms/Preset'
import FormSkeleton from '@/modules/@global/components/Skeletons/FormSkeleton.vue'
import { useI18nStore } from '@/@core/i18n'
import type { Context } from '@/modules/@global/composables/useContext'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.presets.popups.update-preset')

/**
 * Dialog
 */
let dialog = inject(DialogRefKey)

/**
 * Read preset
 */
const { loading, result, error } = useQuery(
	READ_PRESET,
	() => ({
		id: dialog?.value?.data?.id
	}),
	{
		fetchPolicy: 'network-only'
	}
)

/**
 * Update preset
 */
const updatePreset = useMutation(UPDATE_PRESET)

async function updatePresetHandler(values) {
	dialog?.value?.close(values)
}
</script>

<template>
	<SidebarContent :title="ct.$t('title')">
		<template v-if="loading">
			<FormSkeleton />
		</template>

		<template v-else-if="error">
			<InlineMessage severity="error" :text="error"></InlineMessage>
		</template>

		<template v-else>
			<DynamicForm name="update-preset" :ref="(r: any) => context?.setRef('form', r)" :show-submit="false"
				:schema="UpdatePresetForm()" :data="cloneDeep(result?.preset)" :mutation="updatePreset" :labels="{
		submit: ct.$t('submit')
	}" @submit="updatePresetHandler"></DynamicForm>
		</template>

		<template #footer>
			<Button v-if="!loading && !error" v-bind="context.getRef('form')?.props?.submit.value"></Button>
		</template>
	</SidebarContent>
</template>
