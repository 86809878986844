<script setup lang="ts">
import {
  LIST_DROPDOWN_SITES,
  LIST_SELECTED_DROPDOWN_SITES
} from '@/modules/sites/queries/index.graphql'
import { ref, nextTick, computed } from 'vue'
import { useCodexQuery } from '@/modules/@global/composables/useCodexQuery'
import { isArray } from 'lodash'

// ToDo: Translations

const props = withDefaults(
  defineProps<{
    query?: Object
    selectedQuery?: Object
    optionLabel?: string
    optionValue?: string
    variables: Object
  }>(),
  {
    query: LIST_DROPDOWN_SITES,
    selectedQuery: LIST_SELECTED_DROPDOWN_SITES,
    optionLabel: 'name',
    optionValue: 'id',
    variables: () => ({})
  }
)

/**
 * Model
 */
const modelValue = defineModel<string>()

/**
 * Pagination / Filter
 */
const page = ref(0)
const limit = ref(20)
const query = ref('')

/**
 * Load Selected Items
 */
// prettier-ignore
const { firstLoading: existingFirstLoading, result: existingResult, error: existingError } = useCodexQuery(
	props.selectedQuery,
	() => {
		return {
			// prettier-ignore
			ids: (modelValue?.value ? (isArray(modelValue?.value) ? modelValue?.value : [modelValue?.value]) : ['n/a']).filter((id: string) => id !== null)
		}
	}
)

/**
 * Load Dropdown Items
 */
const { loading, result, error, fetchMore, variables } = useCodexQuery(
  props.query,
  () => {
    return {
      offset: 0,
      limit: limit.value,
      where: {
        ...props.variables,
        query: query.value
      }
    }
  },
  {
    debounce: 100
  }
)

/**
 * Dropdown Items
 */
const records = computed(() => {
  return result.value?.data?.items || []
})

/**
 * Return current item placeholder
 */
const current = computed(() => {
  if (!modelValue.value) return null

  // Find in props.path
  const found = result.value?.data?.items.find((item: any) => item.id === modelValue.value)
  if (found) return found?.[props.optionLabel]

  if (existingFirstLoading.value) return 'Loading...'

  return (
    existingResult.value?.data?.items[0]?.[props.optionLabel] ||
    `Item '${modelValue.value}' not found`
  )
})

/**
 * On filter reset page and set query
 */
function onFilter(event: any) {
  page.value = 0
  query.value = event.value
}

/**
 * On change reset page
 */
function onChange() {
  page.value = 0
}

/**
 * On lazy load fetch more
 */
function onLazyLoad(event: any) {
  if (
    event.last !== result.value?.data?.items?.length ||
    event.last === result.value?.data?.total ||
    loading.value
  ) {
    return
  }

  loading.value = true
  page.value += 1

  fetchMore({
    variables: {
      ...props.variables,
      offset: page.value * limit.value,
      limit: limit.value
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      nextTick(() => {
        loading.value = false
      })

      if (!fetchMoreResult) return prev
      return Object.assign({}, prev, {
        data: {
          ...prev?.data,
          ...fetchMoreResult?.data,
          items: [...(prev?.data?.items || []), ...(fetchMoreResult?.data?.items || [])]
        }
      })
    }
  })
}
</script>

<template>
  <Dropdown
    v-model="modelValue"
    :loading="loading"
    :options="records"
    :filter="true"
    :filter-match-mode="'true'"
    :auto-option-focus="true"
    :option-label="props.optionLabel"
    :option-value="props.optionValue"
    :data-key="props.optionValue"
    :show-clear="true"
    :virtual-scroller-options="{
      lazy: true,
      itemSize: 36,
      onLazyLoad: onLazyLoad,
      numToleratedItems: 10
    }"
    @filter="onFilter"
    @change="onChange"
  >
    <template #value="props">
      <div>{{ current ? current : props.placeholder }}</div>
    </template>
  </Dropdown>
</template>
