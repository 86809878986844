import type { PiniaOidcClientSettings } from '@/@core/auth/types/oidc'

function oidcCallbackUrl(url: string): string {
  if (!url) return `${window.location.origin}/callback`

  if (url.startsWith('/')) {
    return `${window.location.origin}${url}`
  }

  return url
}

const oidcSettings: PiniaOidcClientSettings = {
  authority: import.meta.env.VITE_OIDC_AUTHORITY,
  client_id: import.meta.env.VITE_OIDC_CLIENT_ID,
  response_type: import.meta.env.VITE_OIDC_RESPONSE_TYPE,
  scope: import.meta.env.VITE_OIDC_SCOPE,
  redirect_uri: oidcCallbackUrl(import.meta.env.VITE_OIDC_REDIRECT_URI),
  popup_redirect_uri: oidcCallbackUrl(import.meta.env.VITE_OIDC_POPUP_REDIRECT_URI),
  silent_redirect_uri: oidcCallbackUrl(import.meta.env.VITE_OIDC_SILENT_REDIRECT_URI),

  automaticSilentSignin: false,
  automaticSilentRenew: false
}

export default oidcSettings
