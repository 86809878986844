<script setup lang="ts">
import DialogContent from '@/modules/@global/components/DialogContent/DialogContent.vue'
import { computed, inject } from 'vue'
import { useI18nStore } from '@/@core/i18n'
import { DialogRefKey } from '@/symbols'

/**
 * Dialog
 */
const dialog = inject(DialogRefKey)

function handleCancel() {
  dialog?.value?.close(false)
}

const ct = useI18nStore().createI18nContext('ct.global.popups.confirm-delete')

const list = computed(() => dialog?.value?.data?.list)

function handleConfirm() {
  dialog?.value?.close(true)
}
</script>

<template>
  <DialogContent
    :title="
      ct.d$tc(dialog?.data?.labels?.title, 'title', list.length, {
        value: list.join(', ')
      })
    "
  >
    <p class="confirm-popup__message">
      {{
        ct.d$tc(dialog?.data?.labels?.message, 'message', list.length, {
          value: list.join(', ')
        })
      }}
    </p>

    <div v-if="list.length > 1" class="confirm-popup__list">
      <ul>
        <li v-for="item in dialog?.data?.list" :key="item">{{ item }}</li>
      </ul>
    </div>

    <template #footer>
      <Button
        :label="ct.d$tc(dialog?.data?.labels?.cancel, 'cancel')"
        severity="secondary"
        @click="handleCancel"
      />
      <Button
        :label="ct.d$tc(dialog?.data?.labels?.confirm, 'confirm')"
        @click="handleConfirm"
        severity="danger"
      />
    </template>
  </DialogContent>
</template>

<style scoped lang="scss"></style>
