import * as yup from 'yup'
import type { FormField } from '@/modules/@global/components/DynamicForm/DynamicForm'
import { useI18nStore } from '@/@core/i18n'

/**
 * Section forms fields definition
 */
interface SectionFormsFields {
  title: FormField
  parentId: FormField
  description: FormField
  url: FormField
  references: FormField
  media: FormField
}
/**
 * Section forms fields
 */
export const SectionFormsFields = (): SectionFormsFields => {
  const ct = useI18nStore().createI18nContext('ct.sections.forms.fields')
  return {
    title: {
      type: 'Text',
      name: 'title',
      label: ct.$t('title.label'),
      rules: yup.string().required('Title is required')
    },
    parentId: {
      type: 'SelectSectionParent',
      name: 'parentId',
      label: ct.$t('parent-id.label')
    },
    description: {
      type: 'Text',
      name: 'description',
      label: ct.$t('description.label')
    },
    url: {
      type: 'Text',
      name: 'url',
      label: ct.$t('url.label')
    },
    references: {
      type: 'Text',
      name: 'references',
      label: ct.$t('references.label')
    },
    media: {
      type: 'Text',
      name: 'media',
      label: ct.$t('media.label')
    }
  }
}

/**
 * Create Section Form
 */
const CreateSectionForm = (): FormField[] => {
  const fields = SectionFormsFields()

  return [
    {
      type: 'Row',
      children: [
        // Title
        {
          ...fields.title,
          layout: { col: 12 }
        },
        // Parent ID
        {
          ...fields.parentId,
          layout: { col: 12 }
        },
        // Description
        {
          ...fields.description,
          layout: { col: 12 }
        },
        // URL
        {
          ...fields.url,
          layout: { col: 12 }
        },
        // References
        {
          ...fields.references,
          layout: { col: 12 }
        },
        // Media
        {
          ...fields.media,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

/**
 * Update Section Form
 */
const UpdateSectionForm = (): FormField[] => {
  const fields = SectionFormsFields()

  return [
    {
      type: 'Row',
      children: [
        // Title
        {
          ...fields.title,
          layout: { col: 12 }
        },
        // Parent ID
        {
          ...fields.parentId,
          layout: { col: 12 }
        },
        // Description
        {
          ...fields.description,
          layout: { col: 12 }
        },
        // URL
        {
          ...fields.url,
          layout: { col: 12 }
        },
        // References
        {
          ...fields.references,
          layout: { col: 12 }
        },
        // Media
        {
          ...fields.media,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

export { CreateSectionForm, UpdateSectionForm }
