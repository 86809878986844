import gql from 'graphql-tag'

export const PUBLISHABLE_TAG_INPUT = gql`
  fragment PublishableTagInput on CodexTag {
    id
    tagAlias
    tagValue
    source
    description
    parentId
    siteId
    createdBy {
      id
    }
    references {
      total
    }
  }
`

export const MERGE_TAG_INPUT = gql`
  fragment MergeTagInput on CodexTag {
    tags
    target
  }
`

export const READ_TAG = gql`
  ${PUBLISHABLE_TAG_INPUT}

  query ReadTag($id: String!, $input: PublishableTagInput!) {
    tag(id: $id, input: $input)
      @rest(type: "CodexTag", path: "organizationId/tags/{args.id}", method: "GET") {
      ...PublishableTagInput
    }
  }
`

// CHECK IF IT NEEDS TO BE REPLACED

export const READ_TAGS = gql`
  ${PUBLISHABLE_TAG_INPUT}

  query ReadTags($q: String, $pageSize: Int = 20, $type: Int = 1) {
    tags(search: $q, pageSize: $pageSize, type: $type)
      @rest(
        type: "CodexTags"
        path: "organizationId/tag?q={args.q}&Size={args.pageSize}&Type={args.type}"
        method: "GET"
      ) {
      ...PublishableTagInput
    }
  }
`

// TO GET BACK TO

export const CREATE_TAG = gql`
  ${PUBLISHABLE_TAG_INPUT}

  mutation CreateUrl($input: PublishableUrlInput!) {
    createUrl(input: $input) @rest(type: "CodexUrl", path: "organizationId/tags", method: "POST") {
      ...PublishableUrlInput
    }
  }
`

// TO GET BACK TO

export const UPDATE_TAG = gql`
  ${PUBLISHABLE_TAG_INPUT}

  mutation UpdateUrl($input: PublishableTagInput!) {
    updateUrl(input: $input)
      @rest(type: "CodexUrl", path: "organizationId/tags/{args.input.id}", method: "PUT") {
      ...PublishableUrlInput
    }
  }
`

export const DELETE_TAG = gql`
  ${PUBLISHABLE_TAG_INPUT}

  mutation DeleteTag($input: PublishableTagInput!) {
    deleteTag(input: $input)
      @rest(type: "CodexTag", path: "organizationId/tags/{args.input.id}", method: "DELETE") {
      NoResponse
    }
  }
`

export const MERGE_TAGS = gql`
  ${MERGE_TAG_INPUT}

  mutation MergeTags($input: MergeTagInput!) {
    mergeTag(input: $input)
      @rest(type: "CodexTag", path: "organizationId/tags/merge", method: "PUT", bodyKey: "input") {
      ...MergeTagInput
    }
  }
`
