<script setup lang="ts">
import { computed, watch } from 'vue'
import { CodexFieldType } from '@/gql/graphql'

const props = defineProps<{ currentModel: any; toModel: any }>()
const modelValue = defineModel<any>()

const currentFields = computed(() => {
  return props.currentModel?.fields.filter((f) => f.type !== CodexFieldType.Url)
})

const targetModelFields = computed(() => {
  const temp = {}
  currentFields?.value?.forEach((f) => {
    temp[f.alias] = props.toModel?.fields.filter((b) => b.type === f.type)
  })
  return temp
})
</script>

<template>
  <div>
    <Row
      v-for="(field, index) in currentFields"
      :key="index"
      style="display: flex; align-items: center"
    >
      <Col>
        <Text>{{ field.name }}</Text>
      </Col>
      <Col>
        <Dropdown
          v-model="modelValue[field.alias]"
          :options="targetModelFields[field.alias]"
          option-label="name"
          option-value="alias"
          show-clear
        />
      </Col>
    </Row>
  </div>
</template>

<style scoped></style>
