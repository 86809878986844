import {
  FIELD_TYPES,
  FIELD_VALUE_TYPES,
  WIDGET_GROUPS,
  FIELD_FILTER_OPERATORS
} from '@/modules/models/editor-ts/fields/constants'
import { CodexHooks } from '@/@core'

CodexHooks.addFilter('fields/types', (items) => {
  return [
    ...items,
    {
      pluginName: 'codex',
      displayName: 'Boolean',
      name: 'field_boolean',
      render: () => import('./Render.vue'),
      renderEditor: () => import('./Render.vue'),
      renderEditorPanel: [
        {
          icon: 'Settings',
          label: 'General',
          component: () => import('./General.vue'),
          isGeneral: true
        },
        {
          icon: 'Brush',
          label: 'Appearance',
          component: () => import('./Appearance.vue')
        },
        {
          icon: 'SettingsPreferencesTools',
          label: 'Configurations',
          component: () => import('./Configuration.vue')
        }
      ],
      renderEditorInitialPanel: [
        {
          icon: 'Settings',
          label: 'Initial',
          component: () => import('./Initial.vue')
        }
      ],
      group: WIDGET_GROUPS.BASIC,
      icon: () => import('./icon.svg'),

      type: FIELD_TYPES.BOOLEAN,
      valueType: FIELD_VALUE_TYPES.SINGLE,
      defaultValue: null,

      attrs: {
        validation: {
          default: {
            required: {
              value: false,
              errorMessage: 'Boolean is required.',
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: false,
                  field: '',
                  operator: FIELD_FILTER_OPERATORS.EXISTS,
                  value: ''
                }
              ]
            },
            readOnly: {
              value: false,
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: false,
                  field: '',
                  operator: FIELD_FILTER_OPERATORS.EXISTS,
                  value: ''
                }
              ]
            }
          }
        },
        appearance: {
          default: 1
        },
        trueCustomLabel: {
          default: 'Yes'
        },
        falseCustomLabel: {
          default: 'No'
        },
        checkboxLabel: {
          default: 'Yes'
        },
        helpText: {
          default: ''
        },
        helpTextDisplay: {
          default: 'tooltip'
        },
        configuration: {
          default: {
            searchable: true,
            filterable: true
          }
        },
        hidden: {
          default: {
            value: false,
            conditionsEnabled: false,
            conditions: [
              {
                isSystem: false,
                field: '',
                operator: FIELD_FILTER_OPERATORS.EXISTS,
                value: ''
              }
            ]
          }
        }
      }
    }
  ]
})
