import gql from 'graphql-tag'

export const PUBLISHABLE_USER_INPUT = gql`
  fragment PublishableUserInput on CodexUser {
    id
    email
    firstName
    lastName
    imageUrl
    createdAt
    updatedAt
    createdBy
    updatedBy
  }
`

export const DELETE_USER = gql`
  ${PUBLISHABLE_USER_INPUT}

  mutation DeleteUser($id: String!, $input: PublishableUserInput!) {
    deleteUser(id: $id, input: $input)
      @rest(type: "CodexUser", path: "organizationId/users/{args.id}", method: "DELETE") {
      NoResponse
    }
  }
`

export const INVITE_USER = gql`
  ${PUBLISHABLE_USER_INPUT}

  mutation InviteUser($input: PublishableUserInput!) {
    inviteUser(input: $input)
      @rest(type: "CodexUser", path: "organizationId/users/invitations", method: "POST") {
      ...PublishableUserInput
    }
  }
`

export const LIST_INVITATIONS = gql`
  ${PUBLISHABLE_USER_INPUT}

  query ListInvitations {
    data: listInvitations
      @rest(type: "CodexUser", path: "organizationId/users/invitations", method: "GET") {
      ...PublishableUserInput
    }
  }
`
