import { CodexTagSource } from '@/gql/graphql'
import { merge } from 'lodash'

/**
 * Tag Name
 */
export const TAGS_NAME = 'tags-listing'

/**
 * Tag Defaults
 */
export function tagsDefaults(tag = {}) {
  return merge(
    {
      externalId: null,
      id: null,
      parentId: null,
      path: [],
      source: CodexTagSource.Codex,
      tagAlias: '',
      tagValue: '',
      references: [],
      description: ''
    },
    tag
  )
}
