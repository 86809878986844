<script setup lang="ts">
import ResizeFilter from './ResizeFilter'
import { computed } from 'vue'
import { useI18nStore } from '@/@core/i18n/index'
import { CodexPresetResize } from '@/gql/graphql'
import Checkboxes from '@/modules/@global/components/Checkboxes/Checkboxes.vue'

const ct = useI18nStore().createI18nContext('ct.presets.general.enums.resize')
const ctGlobalFilters = useI18nStore().createI18nContext('ct.global.components.filters')

// eslint-disable-next-line no-undef
const modelValue = defineModel<{
  operator: string
  value: CodexPresetResize[]
}>()

const options = computed(() => [
  {
    label: ctGlobalFilters.$t('operators.include'),
    value: ResizeFilter.OPERATORS.include
  },
  {
		label: ctGlobalFilters.$t('operators.exclude'),
    value: ResizeFilter.OPERATORS.exclude
  },
])

const resizeOptions = computed(() => {
	return [
		{
			value: CodexPresetResize.Fit,
			label: ct.$t(CodexPresetResize.Fit),
		},
		{
			value: CodexPresetResize.Fill,
			label: ct.$t(CodexPresetResize.Fill),
		},
		{
			value: CodexPresetResize.Auto,
			label: ct.$t(CodexPresetResize.Auto),
		},
	]
	})
</script>

<template>
	<div class="new-filters-dropdown__filter-body new-filters-dropdown__filter-body--scroll">
		<Row align-items="center">
			<Col>
				<Dropdown 
					v-model="modelValue.operator"
					:options="options"
					size="small"
				/>
			</Col>
		</Row>
	
		<Row align-items="center">
			<Col>
				<Checkboxes 
					v-model="modelValue.value" 
					:options="resizeOptions"
				/> 
			</Col>
		</Row>
	</div>
</template>

<style scoped>

</style>
