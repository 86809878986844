<script setup lang="ts">
import { inject } from 'vue'
import { CreateLabelForm } from '@/modules/labels/forms/Label'
import { CREATE_LABEL } from '@/modules/labels/queries/index.rest'
import { useMutation } from '@vue/apollo-composable'
import { useI18nStore } from '@/@core/i18n'
import { labelDefaults } from '@/modules/labels/constants'
import type { Context } from '@/modules/@global/composables/useContext'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.labels.popups.create-label')

/**
 * Dialog
 */
const dialog = inject(DialogRefKey)

/**
 * Create label
 */
const createLabel = useMutation(CREATE_LABEL, {
  refetchQueries: ['ListLabels']
})

async function createLabelHandler(values) {
  dialog?.value?.close(values)
}

/**
 * Default values
 */
const defaultValues = labelDefaults()
</script>

<template>
  <SidebarContent :title="ct.$t('title')">
    <DynamicForm
      name="create-label"
      :ref="(r:any) => context?.setRef('form', r)"
      :show-submit="false"
      :schema="CreateLabelForm()"
      :mutation="createLabel"
      :data="defaultValues"
      :labels="{
        submit: ct.$t('submit')
      }"
      @submit="createLabelHandler"
    ></DynamicForm>
    <template #footer>
      <Button v-bind="context.getRef('form')?.props?.submit.value"></Button>
    </template>
  </SidebarContent>
</template>
