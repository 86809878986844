import { useI18nStore } from '@/@core/i18n'
import type { FormField } from '@/modules/@global/components/DynamicForm/DynamicForm'
import * as yup from 'yup'

/**
 * Link forms fields definition
 */
interface LinkFormsFields {
  href: FormField
}

/**
 * Link forms fields
 */
export const LinkFormsFields = (): LinkFormsFields => {
  // const ct = useI18nStore().createI18nContext('ct.presets.forms.fields')

  return {
    href: {
      type: 'Text',
      name: 'href',
      label: 'Href',
      attrs: {
        autofocus: true
      }
    }
  }
}

/**
 * Create preset form
 */
const CreateLinkForm = (): FormField[] => {
  const fields = LinkFormsFields()

  return [
    {
      type: 'Row',
      children: [
        // Name
        {
          ...fields.href,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

export { CreateLinkForm }
