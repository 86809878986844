<script setup lang="ts">
import generateColumnClasses from '@/modules/@global/utils/generateColumnClasses'
import { type ColumnProps } from '@/modules/@global/utils/types'
import { computed } from 'vue'

const props = defineProps<ColumnProps>()

const columnClasses = computed(() => {
  return generateColumnClasses(props)
})
</script>

<template>
  <div :class="columnClasses">
    <slot></slot>
  </div>
</template>
