<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable'
import { inject } from 'vue'
import { CREATE_TAG } from '../queries/index.rest'
import { CreateTagForm } from '../forms/Tag'
import { useI18nStore } from '@/@core/i18n'
import { tagsDefaults } from '../constants'
import type { Context } from '@/modules/@global/composables/useContext'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.tags.popups.create-tag')

/**
 * Dialog
 */
const dialog = inject('dialogRef')

/**
 * Create Tag
 */
const createTag = useMutation(CREATE_TAG, {
  refetchQueries: ['ListTags']
})

async function createTagHandler(values) {
  dialog?.value?.close(values)
}

/**
 * Default values
 */
const defaultValues = tagsDefaults()
const valuesWithParent = {
  ...defaultValues,
  parentId: dialog?.value?.data?.tag.id
}

const tagData = dialog?.value?.data?.tag ? valuesWithParent : defaultValues
</script>

<template>
  <SidebarContent :title="ct.$t('title')" class="create-tag-popup">
    <DynamicForm
      name="create-tag"
      :ref="(r: any) => context?.setRef('form', r)"
      :show-submit="false"
      :schema="CreateTagForm()"
      :mutation="createTag"
      :data="tagData"
      :labels="{
        submit: ct.$t('submit')
      }"
      @submit="createTagHandler"
    />
    <template #footer>
      <Button v-bind="context.getRef('form')?.props?.submit.value"></Button>
    </template>
  </SidebarContent>
</template>

<style lang="scss">
.create-tag-popup {
  position: relative;
  height: 100%;

  .p-sidebar-content-body {
    padding: 24px !important;
  }

  .p-sidebar-content-footer {
    position: absolute;
    z-index: 1;
    width: 100%;
    bottom: 0;
  }
  .p-treeselect.p-treeselect-chip .p-treeselect-token {
    background: unset;
  }
}
</style>
