import SourceFilterComponent from './Source.vue'
import { useI18nStore } from '@/@core/i18n'
import { CodexTagSource } from '@/gql/graphql'

export default class SourceFilter {
  static COMPONENT = SourceFilterComponent

  static type: string = 'tag-source'

  static OPERATORS = {
    includeOneOf: 'includeOneOf',
    includeAllOf: 'includeAllOf',
    exclude: 'exclude'
  }

  operator: string | null = null

  value: Array<CodexTagSource> | null = null

  constructor({
    operator = SourceFilter.OPERATORS.includeOneOf,
    value = []
  }: { operator?: string; value?: Array<CodexTagSource> } = {}) {
    this.value = value
    this.operator = operator
  }
  set({ value = [] }: { value?: Array<CodexTagSource> } = {}) {
    this.value = value
  }

  getCount() {
    return this.value?.length
  }

  getValueLabel(): string | null {
    const ct = useI18nStore().createI18nContext('ct.tags')

    return ct.$t(`filters.source.${this.value?.[0]}`)
  }

  asQueryParam() {
    return {
      value: this.value
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case SourceFilter.OPERATORS.includeOneOf:
        return {
          in: this.value
        }
      case SourceFilter.OPERATORS.includeAllOf:
        return {
          notIn: this.value
        }
      default:
        return null
    }
  }

  static validate({ value }: { value?: Array<CodexTagSource> }) {
    if (value?.length === 0) {
      return 'Value is required'
    }

    return true
  }
  static fromQueryParam({ value }: { value?: Array<CodexTagSource> }) {
    return new SourceFilter({
      value
    })
  }
}
