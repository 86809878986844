import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=organization', (items) => {
  return [
    ...items,
    {
      path: 'authors',
      name: 'authors',
      component: () => import('@/modules/authors/pages/AuthorListing.vue')
    },
    {
      path: 'authors/create',
      name: 'author-create',
      component: () => import('@/modules/authors/pages/AuthorCreate.vue'),
      meta: {
        activeNavItem: 'authors'
      }
    },
    {
      path: 'authors/:id',
      name: 'author-update',
      component: () => import('@/modules/authors/pages/AuthorUpdate.vue'),
      meta: {
        activeNavItem: 'authors'
      }
    }
  ]
})
