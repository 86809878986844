<script setup lang="ts">
import PrimeVueCheckbox from 'primevue/checkbox'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    size?: 'small' | 'large' | null
  }>(),
  {
    size: null
  }
)
const modelValue = defineModel<Array<String>>()

const getCheckboxClasses = computed(() => {
  return {
    [`p-checkbox-sm`]: props.size === 'small',
    [`p-checkbox-lg`]: props.size === 'large'
  }
})
</script>

<template>
  <PrimeVueCheckbox v-model="modelValue" :class="getCheckboxClasses">
    <template #icon>
      <GjIcon style="color: white" name="Check" />
    </template>
  </PrimeVueCheckbox>
</template>
