<script setup lang="ts">
import RadioButton from 'primevue/radiobutton'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    size?: 'small' | 'large' | null
  }>(),
  {
    size: null
  }
)

const getRadioButtonClasses = computed(() => {
  return {
    [`p-radiobutton-sm`]: props.size === 'small',
    [`p-radiobutton-lg`]: props.size === 'large'
  }
})

const modelValue = defineModel<string>()
</script>

<template>
  <RadioButton v-model="modelValue" :class="getRadioButtonClasses" />
</template>
