import { CodexHooks } from '@/@core'
import CreateSite from '@/modules/sites/popups/CreateSite.vue'
import UpdateSite from '@/modules/sites/popups/UpdateSite.vue'

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  items = [
    ...items,

    /* Create site */
    {
      type: 'Sidebar',
      name: 'create-site',
      component: CreateSite
    },

    /* Update site */
    {
      type: 'Sidebar',
      name: 'update-site',
      component: UpdateSite
    }
  ]
  return items
})
