import { CodexHooks } from '@/@core'
import AuthorCreate from './AuthorCreate.vue'
import AuthorUpdate from './AuthorUpdate.vue'
import ConfirmDelete from '@/modules/@global/popups/ConfirmDelete.vue'
import { useI18nStore } from '@/@core/i18n'
import AddAuthorsPopup from './AddAuthorsPopup.vue'

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  const ct = useI18nStore().createI18nContext('ct.authors.popups.confirm-delete-authors')

  items = [
    ...(items || []),

    /* Create Author */
    {
      type: 'Sidebar',
      name: 'create-author',
      component: AuthorCreate
    },

    /* Update Author */
    {
      type: 'Sidebar',
      name: 'update-author',
      component: AuthorUpdate
    },
    {
      type: 'Dialog',
      name: 'confirm-delete-authors',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('cancel'),
          confirm: ct.$p('confirm'),
          title: ct.$p('title'),
          message: ct.$p('message')
        }
      }
    },
    {
      type: 'Sidebar',
      name: 'add-authors',
      component: AddAuthorsPopup,
      size: 'large'
    }
  ]

  return items
})
