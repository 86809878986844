<script setup lang="ts">
import InputBase from '@/modules/@global/components/InputBase/InputBase.vue'

let props = defineProps({
  field: {
    type: Object,
    required: true
  },
  component: {
    type: Object,
    default: null
  },
  mergedProps: {
    type: Object,
    default: () => ({})
  }
})

import { useFieldArray, useForm } from 'vee-validate'
import { inject } from 'vue'
import RepeaterItem from './RepeaterItem.vue'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const form = inject('dynamicForm') as ReturnType<typeof useForm>

const { fields, remove, push } = useFieldArray(props.field.name)
</script>

<template>
  <InputBase class="field-repeater" :label="props.field.name">
    <div class="field-repeater__item" v-for="(field2, index) in fields" :key="field2.id">
      <RepeaterItem :field="field" :repeater-index="index" />
      <Button class="field-repeater__remove" @click="remove(index)">Remove</Button>
    </div>

    <Button class="field-repeater__add" @click="push({})">Add {{ props.field.name }}</Button>
  </InputBase>
</template>
