import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=organization', (items) => {
  return [
    ...items,
    {
      path: 'users',
      name: 'users-route',
      component: () => import('@/modules/users/pages/Users.vue'),
      children: [
        {
          path: '',
          name: 'users',
          component: () => import('@/modules/users/pages/UsersListing.vue')
        },
        {
          path: 'invitations',
          name: 'invitations',
          component: () => import('@/modules/users/pages/InvitationsListing.vue')
        }
      ]
    }
  ]
})
