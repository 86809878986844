import { enumMapping } from '@/modules/@global/utils/EnumMapping'
import { CodexEntryStatus } from '@/gql/graphql'
import { merge } from 'lodash'

/**
 * Entries Name
 */
export const ENTRIES_NAME = 'entries-listing'
export const MY_ENTRIES_NAME = 'my-entries-listing'

export const ENTRY_STATUS_MAPPING = enumMapping({
  [CodexEntryStatus.Published]: 1,
  [CodexEntryStatus.Deleted]: 2,
  [CodexEntryStatus.Draft]: 3,
  [CodexEntryStatus.Unpublished]: 4,
  [CodexEntryStatus.Archived]: 5,
  [CodexEntryStatus.Scheduled]: 6,
  [CodexEntryStatus.Edited]: 7
})

export function entryDefaults(entry = {}) {
  return {
    system: {
      siteId: '',
      modelId: '',
      modelAlias: '',
      externalId: '',
      status: CodexEntryStatus.Draft,
      createdAt: '',
      updatedAt: '',
      publishedAt: '',
      unpublishedAt: '',
      sections: [],
      createdBy: '',
      updatedBy: '',
      versionId: '',
      revisionId: '',
      title: '',
      slug: '',
      labels: [],
      metrics: {
        wordCount: 0,
        characterCount: 0,
        sentenceCount: 0,
        imageCount: 0,
        videoCount: 0,
        filesCount: 0,
        playlistsCount: 0,
        audioCount: 0,
        podcastCount: 0,
        readingTime: 0
      }
    },
    content: {},
    attrs: {},
    references: [],
    ...entry
  }
}

/**
 * View Defaults
 */
export function viewDefaults(view = {}) {
  return merge(
    {
      name: '',
      path: '/',
      type: 1,
      teams: []
    },
    view
  )
}
