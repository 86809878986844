import { defineStore } from 'pinia'
import { CodexHooks } from '@/@core'
import { computed, type Ref, ref } from 'vue'

export const useDynamicFilters = defineStore('dynamic-filters', () => {
  const refreshKey: Ref<number> = ref(0)

  CodexHooks.addAction('hooks/filter-added?name=filters/types', () => {
    refreshKey.value++
  })

  CodexHooks.addAction('hooks/filter-removed?name=filters/types', () => {
    refreshKey.value++
  })

  const filters = computed(() => {
    refreshKey.value

    return CodexHooks.applyFilters('filters/types', [])
  })

  return {
    filters
  }
})
