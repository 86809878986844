<script setup lang="ts">
import SiteListFilter from './SiteListFilter'
import { computed } from 'vue'
import { useOrganization } from '@/modules/organizations/store/organization'
import { useI18nStore } from '@/@core/i18n/index'
import Checkboxes from '@/modules/@global/components/Checkboxes/Checkboxes.vue'

const ctGlobalFilters = useI18nStore().createI18nContext('ct.global.components.filters')

// eslint-disable-next-line no-undef
const modelValue = defineModel<{
  operator: string
  value: string[]
}>()

const options = computed(() => [
  {
    label: ctGlobalFilters.$t('operators.some'),
    value: SiteListFilter.OPERATORS.some
  },
  {
		label: ctGlobalFilters.$t('operators.none'),
    value: SiteListFilter.OPERATORS.none
  },
  {
		label: ctGlobalFilters.$t('operators.all'),
    value: SiteListFilter.OPERATORS.all
  },
])

const sitesOptions = computed(() => { return useOrganization()?.sites || [] })
</script>

<template>
	<div class="new-filters-dropdown__filter-body new-filters-dropdown__filter-body--scroll">
		<Row align-items="center">
			<Col>
				<Dropdown 
					v-model="modelValue.operator"
					:options="options"
					size="small"
				/>
			</Col>
		</Row>
	
		<Row align-items="center">
			<Col>
				<Checkboxes 
					v-model="modelValue.value" 
					value-key="id"
					label-key="name"
					:options="sitesOptions"
				/> 
			</Col>
		</Row>
	</div>
</template>

<style scoped>

</style>
