<script setup lang="ts">
import { useI18nStore } from '@/@core/i18n'
import { computed, inject } from 'vue'

import { DROPDOWN_TAGS, SELECTED_DROPDOWN_TAGS } from '../queries/index.graphql'
import DynamicSelectTree from '@/modules/@global/components/DynamicSelectTree/DynamicSelectTree.vue'

const context = inject('context')

const modelValue = defineModel()

const ct = useI18nStore().createI18nContext('ct.tags.forms.fields.parent-id')

const variables = computed(() => {
  return {
    siteId: {
      eq: context?.value.siteId
    },
    parentId: {
      exists: false
    }
  }
})
</script>
<template>
  <div>
    <DynamicSelectTree
      v-model="modelValue"
      :query="DROPDOWN_TAGS"
      :selected-query="SELECTED_DROPDOWN_TAGS"
      :variables="variables"
      selection-mode="single"
      id="treeSelectParentId"
      :placeholder="ct.$t('placeholder')"
      display="comma"
      option-value="id"
      option-label="tagValue"
    />
  </div>
</template>

<style lang="scss"></style>
