import { CodexHooks } from '@/@core'
import { APIKEYS_NAME } from '../constants'
import type { Context } from '@/modules/@global/composables/useContext'

CodexHooks.addAction(`context/created?name=${APIKEYS_NAME}`, (context, contextObj) => {
  CodexHooks.addFilter(`table/variables?name=${APIKEYS_NAME}`, addFilterVariables, contextObj)
})

CodexHooks.addAction(`context/destroyed?name=${APIKEYS_NAME}`, (context, contextObj) => {
  CodexHooks.removeFilter(`table/variables?name=${APIKEYS_NAME}`, addFilterVariables, contextObj)
})

function addFilterVariables(variables: any, context: Context) {
  const search = context.getRef('search').search

  if (search) {
    variables.where = {
      ...variables.where,
      query: search
    }
  }

  return variables
}
