import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalizedLoadedGeneric,
  type RouteRecordNormalized
} from 'vue-router'
import { defineStore } from 'pinia'
import { watchEffect, ref, type Ref, computed, type ComputedRef} from 'vue'
import { CodexDebug, CodexHooks } from '@/@core'
import pinia from '@/@core/store'
import { isArray } from 'lodash'

const router: ReturnType<typeof createRouter> = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: []
})

export const useRouterStore = defineStore('codex-router', () => {
  const refreshKey: Ref<number> = ref(0)
  const routerItems: Ref<any[]> = ref([])

  /**
   * This is a hack to force the router to refresh when a new filter is added or removed.
   */
  CodexHooks.addAction('hooks/filter-added?name=router/items', () => {
    refreshKey.value++
  })

  CodexHooks.addAction('hooks/filter-removed?name=router/items', () => {
    refreshKey.value++
  })

  /**
   * Gather routes from hooks
   */
  function gatherRoutesFromHooks(items: any[], routeNames: Set<string>) {
    try {
      items = CodexHooks.applyFilters('router/items?parent=null', items, null)
      items = CodexHooks.applyFilters('router/items', items, null)
    } catch (error) {
      CodexDebug.routerError('routerItems - Root', error)
    }

    // Call router/items for all childrens recursively
    const processChildren = (items: any[] | undefined) => {
      if (!items || !isArray(items)) return

      try {
        items.forEach((item) => {
          routeNames.add(item.name)

          if (!item.children) {
            item.children = []
          }

          item.children = CodexHooks.applyFilters(
            `router/items?parent=${item.name}`,
            item.children,
            null,
            item
          )
          item.children = CodexHooks.applyFilters('router/items', item.children, null, item)

          processChildren(item.children)
        })
      } catch (error) {
        CodexDebug.routerError('routerItems - Recursive', error)
      }
    }

    processChildren(items)

    return items
  }

  /**
   * Remove routes that are no longer in the routerItems
   *
   * @param routeNames The route names to keep
   */
  function removeRoutes(routeNames: Set<string>): void {
    router?.getRoutes().forEach((route: RouteRecordNormalized) => {
      if (!routeNames.has(route.name as string)) {
        router?.removeRoute(route.name as string)

        CodexDebug.routerInfo('routerItems - Remove Route', route)
      }
    })
  }

  /**
   * Add routes to the router
   *
   * @param items The items to add to the router
   * @param parent The parent route
   */
  const addRoutes = (items: any[] | undefined, parent: any = null) => {
    if (!items && !isArray(items)) return

    try {
      items.forEach((item) => {
        if (!router?.hasRoute(item.name)) {
          try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { children, ...route } = item

            if (parent) {
              router?.addRoute(parent.name, route)
            } else {
              router?.addRoute(route)
            }

            CodexDebug.routerInfo('routerItems - Add Route', route)
          } catch (error) {
            CodexDebug.routerError('routerItems - Add Route', error)
          }
        }

        if (item.children) {
          addRoutes(item.children, item)
        }
      })
    } catch (error) {
      CodexDebug.routerError('routerItems - Recursive', error)
    }
  }

  /**
   * Watch for changes to the router items and update the router accordingly.
   */
  watchEffect(async () => {
    // ToDo: Handle not allowing duplicate names

    // Use a ref to force the computed to refresh when the refreshKey changes
    refreshKey.value

    CodexDebug.routerInfo('routerItems - Start')

    const routeNames: Set<string> = new Set()

    // Use hooks to process router items
    const items: any[] = gatherRoutesFromHooks([], routeNames)

    if (router) {
      // Remove routes that are no longer in the routerItems
      removeRoutes(routeNames)

      // Recursively add routes
      addRoutes(items)
    }

    CodexDebug.routerInfo('routerItems - End', items)

    // Force the router to refresh
    // @ts-ignore
    const goToPath = router?.currentRoute?.fullPath || window.location.pathname
    const route = router?.resolve(goToPath)
    if (
      route?.name !== router?.currentRoute?._value.name &&
      router?.currentRoute?._value.name !== undefined
    ) {
      // console.log({ ...route }, { ...router?.currentRoute })
      await router?.replace(route)
    }

    routerItems.value = items
  })

  return {
    refreshKey,
    routerItems
  }
})

useRouterStore(pinia)

router.onError(error => {
  CodexDebug.routerError('router/onError', error)
});

router.beforeEach(async (to, from) => {
  CodexDebug.routerInfo('router/beforeEach', to, from)

  // If organizationId changed
  if (to.params.organizationId !== from.params.organizationId) {
    // prettier-ignore
    await CodexHooks.applyActionsAwait('organization/beforeOrganizationIdChanged', null,to.params.organizationId)
  }

  // If siteId changed
  if (to.params.siteId !== from.params.siteId) {
    await CodexHooks.applyActionsAwait('site/beforeSiteIdChanged', null, to.params.siteId)
  }
})

router.afterEach((to, from) => {
  CodexDebug.routerInfo('router/afterEach', to, from)

  // If organizationId changed
  if (to.params.organizationId !== from.params.organizationId) {
    CodexHooks.applyActions('organization/organizationIdChanged', null, to.params.organizationId)
  }

  // If siteId changed
  if (to.params.siteId !== from.params.siteId) {
    CodexHooks.applyActions('site/siteIdChanged', null, to.params.siteId)
  }
})

// @ts-ignore
window.CodexRouter = router

export function useRouter() {
  return router;
}

export function useRoute():  Ref<RouteLocationNormalizedLoadedGeneric, RouteLocationNormalizedLoadedGeneric> {
  return router.currentRoute;
}

export default router as ReturnType<typeof createRouter>
