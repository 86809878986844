import { CodexHooks } from '@/@core'
import { generateNavigationItem } from '@/@core/navigation'
import { useRoute } from 'vue-router'

CodexHooks.addFilter('navigation/items?parent=null', (items) => {
  // if (!useRoute()?.params?.organizationId) return [...items]
  return items

  return [
    ...items,
    generateNavigationItem({
      title: 'Modules',
      name: 'main-modules',
      type: 'modules',
      route: { path: '/about' },
      children: [
        generateNavigationItem({
          name: 'home-module',
          title: 'Home',
          route: { path: '/about' },
          children: [
            generateNavigationItem({
              name: 'about',
              title: 'About',
              route: { path: '/about' }
            })
          ]
        }),
        generateNavigationItem({
          name: 'assets-module',
          title: 'Assets',
          route: { path: '/entries' },
          children: [
            generateNavigationItem({
              name: 'entries',
              title: 'Entries',
              route: { path: '/entries' }
            })
          ]
        }),
        generateNavigationItem({
          name: 'builder-module',
          title: 'Builder',
          route: { name: 'Builder' }
        }),
        generateNavigationItem({
          name: 'administration-module',
          title: 'Administration',
          route: { name: 'Builder' }
        }),
        generateNavigationItem({
          name: 'administration-module',
          title: 'Administration',
          route: { name: 'Administration' }
        })
      ]
    })
  ]
})
