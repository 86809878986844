import { CodexHooks } from '@/@core'

// CodexHooks.addFilter('rich-content/panel-items?name=text-format', (items, context) => {
//   return [
//     ...items,
//     {
//       label: 'Bold',
//       name: 'bold',
//       icon: 'FormatBold',
//       command: ({ editor }): {editor: any => {
//         editor?.chain().focus().toggleBold().run()
//       },
//       active: ({ editor, item }) => {
//         return editor?.isActive('bold')
//       },
//       show: ({ editor, item }) => {
//         console.log('should show', item)
//         return editor?.isActive('heading')
//       }
//     }
//   ]
// })

CodexHooks.addFilter('rich-content/panel-items', (items, context) => {
  return [
    ...items,

    /**
     * Select Parent
     */
    {
      label: 'selected-block',
      name: 'selected-block',
      items: [
        {
          label: 'Select Parent',
          name: 'select-parent',
          icon: 'IconparkArrowUp',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().selectParentNode().run()
          }
        }
      ]
    },

    /**
     * Text Alignment
     */
    {
      label: 'Text Alignment',
      name: 'text-alignment',
      items: [
        {
          label: 'Left',
          name: 'left',
          icon: 'AlignLeftAlt',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().setTextAlign('left').run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive({ textAlign: 'left' })
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextAlignNodes?.nodes?.length
          }
        },
        {
          label: 'Center',
          name: 'center',
          icon: 'AlignCenterAlt',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().setTextAlign('center').run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive({ textAlign: 'center' })
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextAlignNodes?.nodes?.length
          }
        },
        {
          label: 'Right',
          name: 'right',
          icon: 'AlignRightAlt',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().setTextAlign('right').run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive({ textAlign: 'right' })
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextAlignNodes?.nodes?.length
          }
        }
      ]
    },

    /**
     * Text Format
     */

    {
      label: 'Text Format',
      name: 'text-format',
      items: [
        {
          label: 'Paragraph',
          name: 'paragraph',
          icon: 'Paragraph',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().setParagraph().run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive('paragraph')
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextNodes?.nodes?.length
          }
        },
        {
          label: 'H1',
          name: 'h1',
          icon: 'IconparkTitleLevel',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().toggleHeading({ level: 1 }).run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive('heading', { level: 1 })
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextNodes?.nodes?.length
          }
        },
        {
          label: 'Code block',
          name: 'code',
          icon: 'IconparkCode',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().toggleCodeBlock().run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive('codeBlock')
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextNodes?.nodes?.length
          }
        },
        {
          label: 'Table',
          name: 'table',
          icon: 'Table',
          command: ({ editor }: { editor: any }) => {
            editor?.commands.insertTable({ rows: 3, cols: 3, withHeaderRow: true })
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive('table')
          }
        }
      ]
    },

    /**
     * Text Style
     */
    {
      label: 'Text Style',
      name: 'text-style',
      items: [
        {
          label: 'Bold',
          name: 'bold',
          icon: 'FormatBold',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().toggleBold().run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive('bold')
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextNodes?.nodes?.length
          }
        },
        {
          label: 'Italic',
          name: 'italic',
          icon: 'FormatItalic',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().toggleItalic().run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive('italic')
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextNodes?.nodes?.length
          }
        },
        {
          label: 'Underline',
          name: 'underline',
          icon: 'FormatUnderline',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().toggleUnderline().run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive('underline')
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextNodes?.nodes?.length
          }
        },
        {
          label: 'Superscript',
          name: 'superscript',
          icon: 'IconparkCircleRightUp',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().toggleSuperscript().run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive('superscript')
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextNodes?.nodes?.length
          }
        },
        {
          label: 'Subscript',
          name: 'subscript',
          icon: 'IconparkCircleRightDown',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().toggleSubscript().run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive('subscript')
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextNodes?.nodes?.length
          }
        },
        {
          label: 'Strike',
          name: 'strike',
          icon: 'FormatStrikethrough',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().toggleStrike().run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive('strike')
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextNodes?.nodes?.length
          }
        },
        {
          label: 'Red',
          name: 'color-red',
          icon: 'ColorPaintDropBlur',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().setColor('#FF0000').run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.getAttributes('textStyle').color === '#FF0000'
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextNodes?.nodes?.length
          }
        },
        {
          label: 'Unset Color',
          name: 'color-unset',
          icon: 'ColorPaintDropBlurDisabled',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().unsetColor().run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.getAttributes('textStyle').color
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextNodes?.nodes?.length
          }
        },
        {
          label: 'Link',
          name: 'link',
          icon: 'Hyperlink',
          command: ({ editor }: { editor: any }) => {
            // ToDo: Add command for updating storage
            editor.storage.link.createLink = true
            editor?.chain().focus().run()

            // const previousUrl = editor.getAttributes('link').href
            // const url = window.prompt('URL', previousUrl)
            //
            // // cancelled
            // if (url === null) {
            //   return
            // }
            //
            // // empty
            // if (url === '') {
            //   editor.chain().focus().extendMarkRange('link').unsetLink().run()
            //
            //   return
            // }
            //
            // // update link
            // editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive('link')
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextNodes?.nodes?.length
          }
        },
        {
          label: 'Inline Code',
          name: 'inline-code',
          icon: 'IconparkCode',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().toggleCode().run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive('code')
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextNodes?.nodes?.length
          }
        },
        {
          label: 'Clear all marks',
          name: 'clear-all-marks',
          icon: 'IconparkClear',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().unsetAllMarks().run()
          },
          show: ({ editor }: { editor: any }) => {
            return editor?.storage.selections?.selectedTextNodes?.nodes?.length
          }
        }
      ]
    },

    /**
     * List Format
     */
    {
      label: 'List Format',
      name: 'list-format',
      items: [
        {
          label: 'Unordered list',
          name: 'unordered-list',
          icon: 'UnorderedList',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().toggleBulletList().run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive('bulletList')
          }
        },
        {
          label: 'Ordered list',
          name: 'ordered-list',
          icon: 'NumberdList',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().toggleOrderedList().run()
          },
          active: ({ editor }: { editor: any }) => {
            return editor?.isActive('orderedList')
          }
        }
      ]
    },

    /**
     * Block Format
     */
    {
      label: 'Block Format',
      name: 'block-format',
      items: [
        {
          label: 'Delete block',
          name: 'delete-block',
          icon: 'Delete_fill',
          command: ({ editor }: { editor: any }) => {
            editor
              ?.chain()
              .focus()
              .deleteRange(editor?.storage?.selections?.selectedNodes.range)
              .run()
          }
        },
        {
          label: 'Insert block',
          name: 'insert-block',
          icon: 'IconparkAdd',
          command: ({ editor }: { editor: any }) => {
            editor?.chain().focus().setInteractiveBlock().run()
          }
        }
      ]
    }
  ]
})
