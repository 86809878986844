<script setup lang="ts">
import { computed } from 'vue'
import { useI18nStore } from '@/@core/i18n/index'
import ReferencesListFilter from './ReferencesListFilter'

const ct = useI18nStore().createI18nContext('ct.tags.filters.references')
const ctGlobalFilters = useI18nStore().createI18nContext('ct.global.components.filters')

// eslint-disable-next-line no-undef
const modelValue = defineModel<{
  operator: string
}>()

const options = computed(() => {
  return [
    {
      label: ct.$t('all'),
      value: ReferencesListFilter.OPERATORS.all
    },
    {
      label: ct.$t('some'),
      value: ReferencesListFilter.OPERATORS.some
    },
    {
      label: ct.$t('none'),
      value: ReferencesListFilter.OPERATORS.none
    },
    {
      label: ct.$t('is-not-empty'),
      value: ReferencesListFilter.OPERATORS.exists
    },
    {
      label: ct.$t('is-empty'),
      value: ReferencesListFilter.OPERATORS.notExists
    }
  ]
})
</script>

<template>
  <div class="new-filters-dropdown__filter-body new-filters-dropdown__filter-body--scroll">
    <Row align-items="center">
      <Col>
        <Dropdown v-model="modelValue.operator" :options="options" size="small" />
      </Col>
    </Row>
  </div>
</template>

<style scoped></style>
