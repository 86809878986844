<script setup lang="ts">
import PrimeVueDropdown from 'primevue/dropdown'
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    size?: 'small' | 'large' | null
  }>(),
  {
    size: null
  }
)
const modelValue = ref()

const getDropdownClasses = computed(() => {
  return {
    [`p-dropdown-sm`]: props.size === 'small',
    [`p-dropdown-lg`]: props.size === 'large'
  }
})
</script>

<template>
  <PrimeVueDropdown v-model="modelValue" option-label="label" option-value="value" :input-class="getDropdownClasses" :auto-filter-focus="true">
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>

    <template #clearicon="scope">
      <GjIcon v-bind="scope" name="Close" class="p-icon p-dropdown-clear-icon" />
    </template>

    <template #dropdownicon="scope">
      <GjIcon v-bind="scope" name="ArrowDown" />
    </template>
  </PrimeVueDropdown>
</template>
