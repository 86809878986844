<script setup lang="ts">
import EventFilter from './EventFilter'
import { computed } from 'vue'
import { useI18nStore } from '@/@core/i18n/index'
import { CodexAutomationEventType } from '@/gql/graphql'
import Checkboxes from '@/modules/@global/components/Checkboxes/Checkboxes.vue'

const ct = useI18nStore().createI18nContext('ct.workflows.general.enums.eventTypes')
const ctGlobalFilters = useI18nStore().createI18nContext('ct.global.components.filters')

// eslint-disable-next-line no-undef
const modelValue = defineModel<{
	operator: string
	value: CodexAutomationEventType[]
}>()

const options = computed(() => [
	{
		label: ctGlobalFilters.$t('operators.include'),
		value: EventFilter.OPERATORS.include
	},
	{
		label: ctGlobalFilters.$t('operators.exclude'),
		value: EventFilter.OPERATORS.exclude
	}
])

const eventOptions = computed(() => {
	return [
		{
			value: CodexAutomationEventType.AssetUploaded,
			label: ct.$t(CodexAutomationEventType.AssetUploaded),
		},
		{
			value: CodexAutomationEventType.CommentAdded,
			label: ct.$t(CodexAutomationEventType.CommentAdded),
		},
		{
			value: CodexAutomationEventType.CommentReplyAdded,
			label: ct.$t(CodexAutomationEventType.CommentReplyAdded),
		},
		{
			value: CodexAutomationEventType.CommentResolved,
			label: ct.$t(CodexAutomationEventType.CommentResolved),
		},
		{
			value: CodexAutomationEventType.EntryCreated,
			label: ct.$t(CodexAutomationEventType.EntryCreated),
		},
		{
			value: CodexAutomationEventType.EntryDeleted,
			label: ct.$t(CodexAutomationEventType.EntryDeleted),
		},
		{
			value: CodexAutomationEventType.EntryUpdated,
			label: ct.$t(CodexAutomationEventType.EntryUpdated),
		},
		{
			value: CodexAutomationEventType.UserMentioned,
			label: ct.$t(CodexAutomationEventType.UserMentioned),
		}
	]
})
</script>

<template>
	<div class="new-filters-dropdown__filter-body new-filters-dropdown__filter-body--scroll">
		<Row align-items="center">
			<Col>
			<Dropdown v-model="modelValue.operator" :options="options" size="small" />
			</Col>
		</Row>

		<Row align-items="center">
			<Col>
			<Checkboxes v-model="modelValue.value" :options="eventOptions" />
			</Col>
		</Row>
	</div>
</template>