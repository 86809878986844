import * as yup from 'yup'
import type { FormField } from '@/modules/@global/components/DynamicForm/DynamicForm'
import { useI18nStore } from '@/@core/i18n'

/**
 * Model forms fields definition
 */
interface ModelFormsFields {
  name: FormField
  alias: FormField
  description: FormField
  iconId: FormField
}

/**
 * Model forms fields
 */
export const ModelFormsFields = (): ModelFormsFields => {
  const ct = useI18nStore().createI18nContext('ct.models.forms.fields')

  return {
    name: {
      type: 'Text',
      name: 'name',
      label: ct.$t('name.label'),
      rules: yup.string().required('Name is required')
    },
    alias: {
      type: 'Text',
      name: 'alias',
      label: ct.$t('alias.label'),
      rules: yup.string().required('Alias is required')
    },
    description: {
      type: 'TextArea',
      name: 'description',
      label: ct.$t('description.label'),
      rules: yup.string()
    },
    iconId: {
      type: 'IconField',
      name: 'iconId',
      label: ct.$t('iconId.label'),
      rules: yup.string().required('Icon is required')
    }
  }
}

/**
 * Model create form
 */
const CreateModelForm = (): FormField[] => {
  const fields = ModelFormsFields()

  return [
    {
      type: 'Row',
      children: [
        // Name
        {
          ...fields.name,
          layout: { col: 12 }
        },
        // Alias
        {
          ...fields.alias,
          layout: { col: 12 }
        },
        // Description
        {
          ...fields.description,
          layout: { col: 12 }
        },
        // Icon
        {
          ...fields.iconId,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

export { CreateModelForm }
