import { ref, onMounted, onBeforeUnmount } from 'vue'

export function useDisplay() {
  const isMobile = ref(false)
  const isTablet = ref(false)
  const isDesktop = ref(false)
  const currentBreakpoint = ref('')
  const updateDisplayStatus = () => {
    isMobile.value = window.matchMedia('(max-width: 767px)').matches
    isTablet.value = window.matchMedia('(min-width: 768px) and (max-width: 992px)').matches
    isDesktop.value = window.matchMedia('(min-width: 993px)').matches

    if (isMobile.value) {
      currentBreakpoint.value = 'mobile'
    } else if (isTablet.value) {
      currentBreakpoint.value = 'tablet'
    } else if (isDesktop.value) {
      currentBreakpoint.value = 'desktop'
    }
  }

  const mediaQueryListener = () => {
    updateDisplayStatus()
  }

  // Initial setup when the component is mounted
  onMounted(() => {
    updateDisplayStatus()
    window.addEventListener('resize', mediaQueryListener)
  })

  // Cleanup when the component is unmounted
  onBeforeUnmount(() => {
    window.removeEventListener('resize', mediaQueryListener)
  })

  return {
    mobile: isMobile,
    tablet: isTablet,
    desktop: isDesktop,
    currentBreakpoint
  }
}
