// import { CodexTagEntityType, CodexTagStatusCode } from '@/gql/graphql'
import type { FormField } from '@/modules/@global/components/DynamicForm/DynamicForm'
import * as yup from 'yup'
import { useI18nStore } from '@/@core/i18n'

/**
 * Tag forms fields definition
 */
interface TagFormsFields {
  tagValue: FormField
  description: FormField
  parentId: FormField
  // references: FormField
}

/**
 * Tag forms fields
 */
export const TagFormsFields = (): TagFormsFields => {
  const ct = useI18nStore().createI18nContext('ct.tags.forms.fields')

  return {
    tagValue: {
      type: 'Text',
      name: 'tagValue',
      label: ct.$t('name.label'),
      rules: yup.string().required('Name is required')
    },
    description: {
      type: 'Text',
      name: 'description',
      label: ct.$t('description.label')
    },
    parentId: {
      type: 'SelectParentId',
      name: 'parentId',
      label: ct.$t('parent-id.label')
    }
    // references: {
    //   type: 'ReferencesSelect',
    //   name: 'references',
    //   label: ct.$t('references.label')
    // }
  }
}

/**
 * Create tag form
 */
const CreateTagForm = (): FormField[] => {
  const fields = TagFormsFields()

  return [
    {
      type: 'Row',
      children: [
        // Name
        {
          ...fields.tagValue,
          layout: { col: 12 }
        },
        // Description
        {
          ...fields.description,
          layout: { col: 12 }
        },
        // Parent
        {
          ...fields.parentId,
          layout: { col: 12 }
        }
        // Referenced Entries
        // {
        //   ...fields.references,
        //   layout: { col: 12 }
        // }
      ]
    }
  ]
}

const UpdateTagForm = (): FormField[] => {
  const fields = TagFormsFields()

  return [
    {
      type: 'Row',
      children: [
        // Name
        {
          ...fields.tagValue,
          layout: { col: 12 }
        },
        // Description
        {
          ...fields.description,
          layout: { col: 12 }
        },
        // Parent
        {
          ...fields.parentId,
          layout: { col: 12 }
        },
        // Referenced Entries
        {
          ...fields.references,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

export { CreateTagForm, UpdateTagForm }
