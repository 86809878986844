<script setup lang="ts">
import { random } from 'lodash'

const labelWidth = random(15, 40)
</script>

<template>
  <div>
    <Skeleton :width="`${labelWidth}%`" height="17px" class="mb-1"></Skeleton>
    <Skeleton height="40px"></Skeleton>
  </div>
</template>

<style scoped></style>
