import { invert } from 'lodash'

export function enumMapping(_enum) {
  return {
    _enum,
    toString(value) {
      return invert(_enum)[value] || value
    },
    toNumber(value) {
      return _enum[value] || value
    }
  }
}
