import { graphql } from '@/gql/gql'

export const LIST_APIKEYS = graphql(/* GraphQL */ `
  query ListApiKeys(
    $limit: Int!
    $offset: Int!
    $where: CodexApiKeyFilter
    $order: CodexApiKeyOrder
  ) {
    data: apiKeyCollection(limit: $limit, offset: $offset, where: $where, order: $order) {
      total
      items {
        id
        name
        expiresAt
        status
        teams(limit: 100) {
          total
          items {
            id
            name
          }
        }
      }
    }
  }
`)

export const LIST_SELECTED_DROPDOWN_APIKEYS = graphql(/* GraphQL */ `
  query ListSelectedDropdownApiKeys($ids: [String!]) {
    existing: apiKeyCollection(limit: 1000, where: { id: { in: $ids } }) {
      total
      items {
        id
        name
      }
    }
  }
`)

export const LIST_DROPDOWN_APIKEYS = graphql(/* GraphQL */ `
  query ListDropdownApiKeys($limit: Int!, $offset: Int!, $where: CodexApiKeyFilter) {
    data: apiKeyCollection(limit: $limit, offset: $offset, where: $where) {
      total
      items {
        id
        name
      }
    }
  }
`)
