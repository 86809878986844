<script setup lang="ts">
import TextFilter from './TextFilter'
import { computed } from 'vue'
import { useI18nStore } from '@/@core/i18n'

const ct = useI18nStore().createI18nContext('ct.global.components.filters')

// eslint-disable-next-line no-undef
const modelValue = defineModel<{
  operator: string
  value: string
}>()

const showInput = computed(() => {
  return (
    modelValue.value?.operator !== TextFilter.OPERATORS.exists &&
    modelValue.value?.operator !== TextFilter.OPERATORS.notExists
  )
})
const options = computed(() => [
  {
    label: ct.$t('operators.is'),
    value: TextFilter.OPERATORS.equals
  },
  {
    label: ct.$t('operators.not'),
    value: TextFilter.OPERATORS.notEquals
  },
  {
    label: ct.$t('operators.contains'),
    value: TextFilter.OPERATORS.contains
  },
  {
    label: ct.$t('operators.not-contains'),
    value: TextFilter.OPERATORS.notContains
  },
  {
    label: ct.$t('operators.exists'),
    value: TextFilter.OPERATORS.exists
  },
  {
    label: ct.$t('operators.not-exists'),
    value: TextFilter.OPERATORS.notExists
  },
  {
    label: ct.$t('operators.starts-with'),
    value: TextFilter.OPERATORS.startsWith
  },
  {
    label: ct.$t('operators.ends-with'),
    value: TextFilter.OPERATORS.endsWith
  }
])
</script>

<template>
  <div class="new-filters-dropdown__filter-body">
    <Row>
      <Col>
        <Dropdown v-model="modelValue.operator" :options="options" size="small" />
      </Col>
    </Row>

    <Row v-if="showInput">
      <Col>
        <InputText size="small" v-model="modelValue.value" />
      </Col>
    </Row>
  </div>
</template>

<style scoped></style>
