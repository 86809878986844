import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=site', (items) => {
  return [
    ...items,
    {
      path: 'sections',
      name: 'sections',
      component: () => import('@/modules/sections/pages/SectionsListing.vue')
    }
  ]
})
