//oidc.ts
import { defineStore } from 'pinia'
import { piniaOidcCreateRouterMiddleware, piniaOidcCreateStoreModule } from './src'
import router from '@/@core/router'
import { defineComponent } from 'vue'
import oidcConfigs from './oidcConfigs'
import { CodexDebug, CodexHooks } from '@/@core'
import pinia from '@/@core/store'

/**
 * Pinia Store
 *
 * Note: Mostly got all the functionality from the old admin project from vue2. it was proven to work
 */
const useOidcStore = defineStore(
  piniaOidcCreateStoreModule(
    oidcConfigs,
    {},
    {
      userLoaded: (user) => {
        CodexDebug.authLog('OIDC user is loaded:', user)
      },

      userUnloaded: () => {
        CodexDebug.authLog('OIDC user is unloaded')
        void useOidcStore().clearStaleState()
      },

      accessTokenExpiring: () => {
        CodexDebug.authLog('Access token will expire')

        void useOidcStore().authenticateOidcSilent()
      },

      accessTokenExpired: () => {
        CodexDebug.authLog('Access token did expire')

        void useOidcStore().authenticateOidcSilent()
      },

      silentRenewError: () => {
        CodexDebug.authError('OIDC user is unloaded')

        void useOidcStore().clearStaleState()
        void useOidcStore().authenticateOidc()
      },

      userSignedOut: () => {
        CodexDebug.authLog('OIDC user is signed out')

        void useOidcStore().clearStaleState()
        void useOidcStore().authenticateOidc()
      },

      oidcError: (payload) => {
        // @ts-ignore
        CodexDebug.authError(`An error occurred at ${payload.context}:`, payload.error, payload)

        // [Auth Config Hook] On oidc error
        CodexHooks.applyActions('auth/oidcError', null, payload)

        /**
         * Sometimes the oidc client will throw an error that we can't handle
         * In this case, we want to clear the state and re-authenticate
         */
        const errorsToLogin = ['invalid_grant', 'No matching state found in storage']

        if (payload && payload.error && errorsToLogin.includes(payload.error)) {
          void useOidcStore().clearStaleState()
          void useOidcStore().authenticateOidc()
        }
      },

      automaticSilentRenewError: (payload) => {
        // @ts-ignore
        CodexDebug.authError('Automatic silent renew failed.', payload.error)

        void useOidcStore().clearStaleState()
        void useOidcStore().authenticateOidc()
      }
    }
  )
)

// Add the callback route
CodexHooks.addFilter('router/items?parent=null', (items) => {
  return [
    ...items,
    {
      path: '/callback',
      name: 'oidcCallback',
      component: async () => {
        return defineComponent({
          displayName: 'oidcCallback',
          setup() {
            useOidcStore()
              // @ts-ignore
              .oidcSignInCallback(router?.currentRoute?.fullPath)
              .then((redirectPath: string): void => {
                const user = useOidcStore().user
                // [Auth Config Hook] On user login
                CodexHooks.applyActions('auth/userLoggedIn', null, user)

                redirectPath = CodexHooks.applyFilters(
                  'auth/loginRedirectPath',
                  redirectPath,
                  null,
                  user
                )

                router.replace(redirectPath)
              })
              .catch((err) => {
                console.error(err)
              })
          },
          render() {
            return null
          }
        })
      }
    }
  ]
})

// Add the oidc middleware to the router
// @ts-ignore
router.beforeEach(piniaOidcCreateRouterMiddleware(useOidcStore(pinia)))

// @ts-ignore
window.useOidcStore = useOidcStore

export { useOidcStore }
