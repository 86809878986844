<script setup lang="ts">
import { ref } from 'vue'

const props = withDefaults(
  defineProps<{
    label?: string | null
    alertLabel?: string | null
    alertIcon?: string | null
    labelIcon?: string | null
    tooltip?: string | null
    helpText?: string | null
    direction?: 'horizontal' | 'vertical'
  }>(),
  {
    labelIcon: 'Info',
    alertLabel: null,
    alertIcon: '',
    label: null,
    tooltip: null,
    helpText: null,
    direction: 'vertical'
  }
)

const inputRef = ref(null)

const labelClicked = () => {
  const input =
    inputRef.value.getElementsByTagName('input')?.[0] ||
    inputRef.value.getElementsByClassName('p-component')?.[0]
  if (input) {
    input.focus()
    input.click()
  }
}
</script>

<template>
  <div :class="`input-base input-base--${direction}`">
    <div v-if="label" class="input-base__header" @click="labelClicked">
      <Text>{{ label }}</Text>
      <slot name="label-icon">
        <span v-tooltip.top="tooltip" class="label-icon-container" v-if="tooltip">
          <GjIcon size="18" name="Info" />
        </span>
      </slot>
    </div>
    <div class="input-base__body" ref="inputRef">
      <slot></slot>
    </div>
    <div class="input-base__help" v-if="helpText || $slots.help">
      <slot name="help">
        <InlineMessage :plain-text="true" :text="helpText" />
      </slot>
    </div>
    <div class="input-base__alert" v-if="alertLabel || $slots.alert">
      <slot name="alert">
        <InlineMessage :plain-text="true" :text="alertLabel" :icon="alertIcon" />
      </slot>
    </div>
  </div>
</template>

<style scoped></style>
