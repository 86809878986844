import { CodexHooks } from "@/@core";
import PresetGravitySelect from "./PresetGravitySelect.vue";
import PresetResizeSelect from "./PresetResizeSelect.vue";

CodexHooks.addFilter('dynamic-form/fields', (items) => {
	return [
    ...items,
    {
      type: 'preset-gravity-select',
      component: PresetGravitySelect
    },
    {
      type: 'preset-resize-select',
      component: PresetResizeSelect
    },
	]
})
