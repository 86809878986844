import CheckboxFilterComponent from './Checkbox.vue'
import type { Filter } from '@/modules/@global/components/Filters/Filters'
import { useI18nStore } from '@/@core/i18n'

export default class CheckboxFilter {
  static COMPONENT = CheckboxFilterComponent
  static OPERATORS: {
    include: string
    exclude: string
  } = {
    include: 'include',
    exclude: 'exclude'
  }

  static type: string = 'checkboxAlias'

  operator: string | null = null

  value: Array<string> | null = null

  constructor(
    {
      operator = CheckboxFilter.OPERATORS.include,
      value = []
    }: { operator: string; value?: Array<string> } = {
      operator: CheckboxFilter.OPERATORS.include
    }
  ) {
    if (operator === 'includeOneOf') operator = 'include' //to handle some cases from views on v3
    this.operator = operator
    this.value = value
  }
  set(
    {
      operator = CheckboxFilter.OPERATORS.include,
      value = []
    }: { operator: string; value?: Array<string> } = {
      operator: CheckboxFilter.OPERATORS.include
    }
  ) {
    this.operator = operator
    this.value = value
  }

  getCount() {
    return this.value?.length
  }

  getValueLabel(filter: Filter): string | null {
    let valueLabel = [...(this.value ?? [])]
    if (valueLabel?.length === 1) {
      const selectedOptions = { ...filter }.options?.filter((x) => x.value === valueLabel[0])
      if (selectedOptions?.length) valueLabel = selectedOptions[0]?.label
    }
    const ct = useI18nStore().createI18nContext('ct.global')
    switch (this.operator) {
      case CheckboxFilter.OPERATORS.include:
        return ct.$t('filters.checkbox.active-label.is', { value: valueLabel })
      case CheckboxFilter.OPERATORS.exclude:
        return ct.$t('filters.checkbox.active-label.is-not', { value: valueLabel })
      default:
        return null
    }
  }

  asQueryParam() {
    return {
      operator: this.operator,
      value: this.value
    }
  }

  asGraphQL() {
    switch (this.operator) {
      case CheckboxFilter.OPERATORS.include:
        return {
          in: this.value
        }
      case CheckboxFilter.OPERATORS.exclude:
        return {
          notIn: this.value
        }
      default:
        return null
    }
  }

  static validate({ operator, value }: { operator: string; value?: Array<string> }) {
    if (!operator) {
      return 'Operator is required'
    }

    if (value?.length === 0) {
      return 'Value is required'
    }

    return true
  }
  static fromQueryParam({ operator, value }: { operator: string; value?: Array<string> }) {
    return new CheckboxFilter({
      operator,
      value
    })
  }
}
