import {
  FIELD_TYPES,
  FIELD_VALUE_TYPES,
  WIDGET_GROUPS,
  FIELD_FILTER_OPERATORS,
  RANGE_OPERATORS
} from '@/modules/models/editor-ts/fields/constants'
import { CodexHooks } from '@/@core'

CodexHooks.addFilter('fields/types', (items) => {
  return [
    ...items,
    {
      pluginName: 'codex',
      displayName: 'Media',
      name: 'field_media_content',
      render: () => import('./Render.vue'),
      renderEditor: () => import('./Render.vue'),
      renderEditorPanel: [
        {
          icon: 'Settings',
          label: 'General',
          component: () => import('./General.vue'),
          isGeneral: true
        }
      ],
      renderEditorInitialPanel: [
        {
          icon: 'Settings',
          label: 'Initial',
          component: () => import('./Initial.vue')
        }
      ],
      group: WIDGET_GROUPS.BASIC,
      icon: () => import('./icon.svg'),

      type: FIELD_TYPES.MEDIA,
      valueType: FIELD_VALUE_TYPES.SINGLE,

      attrs: {
        defaultFolderId: {
          default: null
        },
        version: {
          default: null
        },
        validation: {
          default: {
            required: {
              value: false,
              errorMessage: 'Media field is required',
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: false,
                  field: '',
                  operator: FIELD_FILTER_OPERATORS.EXISTS,
                  value: ''
                }
              ]
            },
            readOnly: {
              value: false,
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: false,
                  field: '',
                  operator: FIELD_FILTER_OPERATORS.EXISTS,
                  value: ''
                }
              ]
            },
            requiredMetadata: {
              isEnabled: false,
              title: true,
              caption: true,
              alt: false,
              source: false,
              author: false,
              copyright: false,
              tags: false,
              errorMessage: 'Metadata is required'
            },
            numberOfAssets: {
              isEnabled: false,
              rangeOperator: RANGE_OPERATORS.BETWEEN,
              min: 2,
              max: 5,
              exactly: 0,
              errorMessage: 'Media should contains 2-5 files',
              errorTemplate: 'Media should contains {min}-{max}'
            },
            fileType: {
              isEnabled: true,
              image: true,
              files: false,
              video: false,
              playlists: false,
              audio: false,
              podcast: false,
              errorMessage: 'Unsupported type'
            },
            dimensions: {
              isEnabled: false,
              minWidth: 100,
              maxWidth: 1000,
              minHeight: 100,
              maxHeight: 1000,
              errorMessage: 'Media width should be 100-1000 (pixels) and height 100-1000 (pixels)',
              errorTemplate:
                'Media width should be {minWidth}-{maxWidth} (pixels) and height {minHeight}-{maxHeight} (pixels)'
            },
            requiredLabels: {
              isEnabled: false,
              allowedLabels: [],
              errorMessage: 'Assets should have at least one of selected labels'
            }
          }
        },
        appearance: {
          default: 1
        },
        showUploadNewAsset: {
          default: true
        },
        helpText: {
          default: ''
        },
        helpTextDisplay: {
          default: 'tooltip'
        },
        calculateMetrics: {
          default: false
        },
        configuration: {
          default: {
            searchable: true,
            filterable: true
          }
        },
        ratios: {
          default: []
        },
        hidden: {
          default: {
            value: false,
            conditionsEnabled: false,
            conditions: [
              {
                isSystem: false,
                field: '',
                operator: FIELD_FILTER_OPERATORS.EXISTS,
                value: ''
              }
            ]
          }
        }
      }
    }
  ]
})
