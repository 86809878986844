import dayjs from 'dayjs'

export function toLocale(number: number) {
  return number.toLocaleString()
}

export function bytesToSize(bytes: number, digits: number = 0) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  // eslint-disable-next-line radix
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  // eslint-disable-next-line no-restricted-properties
  return `${(bytes / Math.pow(1024, i)).toFixed(digits)} ${sizes[i]}`
}

// export function timeDuration(value) {
//   const duration = dayjs.duration(value, 'seconds')
//   const hours = `${duration.hours() < 10 ? '0' : ''}${duration.hours()}`
//   const minutes = `${duration.minutes() < 10 ? '0' : ''}${duration.minutes()}`
//   const seconds = `${duration.seconds() < 10 ? '0' : ''}${duration.seconds()}`
//   return duration.hours() > 0 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`
// }
