<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable'
import { inject } from 'vue'
import { CreateUserInvite } from '@/modules/users/forms/Users'
import { useI18nStore } from '@/@core/i18n'
import DialogContent from '@/modules/@global/components/DialogContent/DialogContent.vue'
import DynamicForm from '@/modules/@global/components/DynamicForm/DynamicForm.vue'
import { INVITE_USER } from '@/modules/users/queries/index.rest'
import type { Context } from '@/modules/@global/composables/useContext'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.users.popups.invite-user')

/**
 * Dialog
 */
const dialog = inject(DialogRefKey)

/**
 * Invite User
 */
const inviteUser = useMutation(INVITE_USER)

async function createInviteHandler(values) {
  dialog?.value?.close(values)
}

/**
 * Default values
 */
const defaultValues = {
  users: [
    {
      emails: [],
      teams: []
    }
  ]
}
</script>

<template>
  <DialogContent title="Invite user">
    <DynamicForm
      name="invite-user"
      :ref="(r:any) => context?.setRef('form', r)"
      :show-submit="false"
      :schema="CreateUserInvite()"
      :mutation="inviteUser"
      :data="defaultValues"
      :labels="{
        submit: ct.$t('submit')
      }"
      @submit="createInviteHandler"
    />
    <template #footer>
      <Button v-bind="context.getRef('form')?.props?.submit.value"></Button>
    </template>
  </DialogContent>
</template>
