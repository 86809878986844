import { CodexPluginStatus } from '@/gql/graphql'
import { merge } from 'lodash'
import { enumMapping } from '@/modules/@global/utils/EnumMapping'

/**
 * Plugin Name
 */
export const PLUGINS_NAME = 'plugins-listing'

/**
 * Plugin Enums
 */
export const PLUGIN_STATUS_MAPPING = enumMapping({
  [CodexPluginStatus.Active]: 1,
  [CodexPluginStatus.Pending]: 3
})

/**
 * Plugin Defaults
 */
export function pluginDefaults(plugin = {}) {
  return merge(
    {
      id: '',
      name: '',
      status: CodexPluginStatus.Pending,
    },
    plugin
  )
}
