<script setup lang="ts">
import DialogContent from '@/modules/@global/components/DialogContent/DialogContent.vue'
import { computed, inject } from 'vue'

/**
 * Dialog
 */
const dialog = inject('dialogRef')

const data = computed(() => dialog?.value?.data)

function handleConfirm() {
  dialog?.value?.close(true)
}
function handleCancel() {
  dialog?.value?.close(false)
}
</script>

<template>
  <DialogContent :title="data.title">
    <p class="confirm-popup__message">{{ data.description }}</p>

    <template #footer>
      <Button :label="data.cancel" severity="secondary" @click="handleCancel" />
      <Button :label="data.confirm" @click="handleConfirm" :severity="data.severity || 'danger'" />
    </template>
  </DialogContent>
</template>

<style scoped lang="scss"></style>
