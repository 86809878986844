<script setup lang="ts">
import { ref } from 'vue'

const colors = ref([
  '#36B37E',
  '#E34850',
  '#2291F2',
  '#B148E3',
  '#E3489C',
  '#FFAB00',
  '#2275F2',
  '#00E0FF',
  '#052D61',
  '#667C99'
])

const modelValue = defineModel<string>()
</script>

<template>
  <div class="color-field">
    <div v-for="color in colors" :key="color">
      <input :id="color" type="radio" name="color" :value="color" v-model="modelValue" />
      <label :for="color" class="color-field__color" :style="{ backgroundColor: color }">
        <GjIcon size="14" name="Check" />
      </label>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
