import { kebabCase } from 'lodash'
import type { RowProps } from './types'

export default function generateRowClasses(props: RowProps) {
  const classes: Record<string, boolean> = {
    grid: true
  }

  const availableProps: Array<keyof RowProps> = ['gap', 'justifyContent', 'alignItems']
  const flexProps: Array<string> = []

  availableProps.forEach((prop) => {
    const value: RowProps[keyof RowProps] = props[prop]
    if (!value) return

    if (typeof value === 'number' || typeof value === 'string') {
      const append = flexProps.includes(prop) ? 'flex-' : ''
      classes[`${append}${kebabCase(prop)}-${value}`] = true
    } else if (typeof value === 'object') {
      Object.entries(value).forEach(([bp, value]) => {
        const append = flexProps.includes(prop) ? 'flex-' : ''
        classes[`${bp}:${append}${kebabCase(prop)}-${value}`] = true
      })
    }
  })

  return classes
}
