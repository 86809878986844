import { CodexHooks } from '@/@core'
import type { Context } from '@/modules/@global/composables/useContext'
import { LOCALIZATIONS_NAME } from '../constants'

CodexHooks.addAction(`context/created?name=${LOCALIZATIONS_NAME}`, (context, contextObj) => {
  CodexHooks.addFilter(`table/variables?name=${LOCALIZATIONS_NAME}`, addFilterVariables, contextObj)
})

CodexHooks.addAction(`context/destroyed?name=${LOCALIZATIONS_NAME}`, (context, contextObj) => {
  CodexHooks.removeFilter(
    `table/variables?name=${LOCALIZATIONS_NAME}`,
    addFilterVariables,
    contextObj
  )
})
function addFilterVariables(variables: any, context: Context) {
  const search = context.getRef('search').search

  if (search) {
    variables.where = {
      ...variables.where,
      query: search.trim()
    }
  }

  return variables
}
