<script setup lang="ts">
let props = defineProps({
  field: {
    type: Object,
    required: true
  },
  component: {
    type: Object,
    default: null
  },
  mergedProps: {
    type: Object,
    default: () => ({})
  }
})

import { useField, useForm } from 'vee-validate'
import { inject } from 'vue'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const form = inject('dynamicForm') as ReturnType<typeof useForm>

const fieldNameAppend = inject('fieldNameAppend', '')

const { value, errorMessage, meta } = useField(
  () => `${fieldNameAppend}${props.field.name}`,
  props.field.rules
)
</script>

<template>
  <div class="dynamic-form__field">
    <InputBase :label="field.label" :required="field.required" :help-text="field.helpText" :tooltip="field.tooltip">
			<span v-if="!component">{{ $t(`Undefined field '${field.type}'`) }}</span>
			<component v-else :is="component?.component" :label="field.label" :placeholder="field.label" v-model="value"
				v-bind="mergedProps" :class="{
					'p-invalid': meta.touched && !meta.valid,
					'p-valid': meta.touched && meta.valid,
					[field.class]: field.class
				}" />

			<template v-if="errorMessage" #alert>
				<InlineMessage :plain-text="true" severity="error" :text="errorMessage" />
			</template>
		</InputBase>
	</div>
</template>

<style scoped></style>
