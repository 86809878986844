<script setup lang="ts">
import { useNavigation } from '@/@core/navigation'
import SidebarNavigation from './SidebarNavigation.vue'
import { useGlobalStore } from '@/modules/@global/store/global'

const store = useGlobalStore()

defineOptions({
  name: 'Sidebar'
})

const navigation = useNavigation()
</script>

<template>
  <div class="sidebar">
    <nav class="sidebar-navigation">
      <SidebarNavigation :navigation-items="navigation.navigationItems" />
    </nav>
  </div>
</template>

<style scoped></style>
