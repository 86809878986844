/**
 * PrimeVue Components
 */
import Button from '@/modules/primevue/components/Button/Button.vue'
import TieredMenu from '@/modules/primevue/components/TieredMenu/TieredMenu.vue'
import Checkbox from '@/modules/primevue/components/Checkbox/Checkbox.vue'
import Checkboxes from '@/modules/@global/components/Checkboxes/Checkboxes.vue'
import InputText from '@/modules/primevue/components/InputText/InputText.vue'
import InputGroup from '@/modules/primevue/components/InputGroup/InputGroup.vue'
import RadioButton from '@/modules/primevue/components/RadioButton/RadioButton.vue'
import RadioButtons from '@/modules/@global/components/RadioButtons/RadioButtons.vue'
import Dialog from '@/modules/primevue/components/Dialog/Dialog.vue'
import Sidebar from '@/modules/primevue/components/Sidebar/Sidebar.vue'
import Dropdown from '@/modules/primevue/components/Dropdown/Dropdown.vue'
import MultiSelect from '@/modules/primevue/components/MultiSelect/MultiSelect.vue'
import InputNumber from '@/modules/primevue/components/InputNumber/InputNumber.vue'
import Editor from '@/modules/primevue/components/Editor/Editor.vue'
import Badge from 'primevue/badge'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import OverlayPanel from 'primevue/overlaypanel'
import InlineMessage from '@/modules/primevue/components/InlineMessage/InlineMessage.vue'
import Skeleton from 'primevue/skeleton'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import TabMenu from 'primevue/tabmenu'
import Menu from 'primevue/menu'
import ColorPicker from '@/modules/primevue/components/ColorPicker/ColorPicker.vue'
import Image from 'primevue/image'
import ProgressSpinner from 'primevue/progressspinner'
import ProgressBar from 'primevue/progressbar'
import TextArea from '@/modules/primevue/components/TextArea/TextArea.vue'
import InputSwitch from '@/modules/primevue/components/InputSwitch/InputSwitch.vue'
import Tag from '@/modules/primevue/components/Tag/Tag.vue'
import Chip from '@/modules/primevue/components/Chip/Chip.vue'
import Chips from '@/modules/primevue/components/Chips/Chips.vue'
import DatePicker from '@/modules/primevue/components/DatePicker/DatePicker.vue'
import Card from '@/modules/primevue/components/Card/Card.vue'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import SplitButton from 'primevue/splitbutton'
import { FilterService } from 'primevue/api'

/**
 * PrimeVue Directives
 */
import Tooltip from 'primevue/tooltip'
import FocusTrap from 'primevue/focustrap'

/**
 * PrimeVue Services
 */
import DialogService from 'primevue/dialogservice'
import ToastService from 'primevue/toastservice'

export function installPrimeVueComponents(app: any) {
  /* eslint-disable vue/no-reserved-component-names */

  /**
   * PrimeVue Components
   */
  app.component('Button', Button)
  app.component('TieredMenu', TieredMenu)
  app.component('Badge', Badge)
  app.component('DataTable', DataTable)
  app.component('Column', Column)
  app.component('OverlayPanel', OverlayPanel)
  app.component('InputText', InputText)
  app.component('InputGroup', InputGroup)
  app.component('InputNumber', InputNumber)
  app.component('Checkbox', Checkbox)
  app.component('Checkboxes', Checkboxes)
  app.component('RadioButton', RadioButton)
  app.component('RadioButtons', RadioButtons)
  app.component('Dialog', Dialog)
  app.component('Sidebar', Sidebar)
  app.component('Dropdown', Dropdown)
  app.component('MultiSelect', MultiSelect)
  app.component('InlineMessage', InlineMessage)
  app.component('Skeleton', Skeleton)
  app.component('TabView', TabView)
  app.component('TabPanel', TabPanel)
  app.component('TabMenu', TabMenu)
  app.component('Menu', Menu)
  app.component('ColorPicker', ColorPicker)
  app.component('Image', Image)
  app.component('ProgressSpinner', ProgressSpinner)
  app.component('ProgressBar', ProgressBar)
  app.component('TextArea', TextArea)
  app.component('Editor', Editor)
  app.component('InputSwitch', InputSwitch)
  app.component('Tag', Tag)
  app.component('Chip', Chip)
  app.component('Chips', Chips)
  app.component('DatePicker', DatePicker)
  app.component('Card', Card)
  app.component('Accordion', Accordion)
  app.component('AccordionTab', AccordionTab)
  app.component('SplitButton', SplitButton)

  /**
   * PrimeVue Directives
   */
  app.directive('tooltip', Tooltip)
  app.directive('focustrap', FocusTrap)

  /**
   * PrimeVue Services
   */
  app.use(DialogService)
  app.use(ToastService)

  FilterService.register('true', (value: any, filter: any): boolean => {
    return true
  })
}
