import { CodexAssetStatus, CodexAssetType, type CodexAsset } from '@/gql/graphql'
import { enumMapping } from '../@global/utils/EnumMapping'

/**
 * Assets Name
 */
export const ASSETS_NAME = 'assets-listing'

/**
 * Assets Enums
 */

export const TYPE_MAPPING = enumMapping({
  [CodexAssetType.Undefined]: 0,
  [CodexAssetType.Image]: 1,
  [CodexAssetType.File]: 2,
  [CodexAssetType.Video]: 3,
  [CodexAssetType.VideoPlaylist]: 4,
  [CodexAssetType.Audio]: 5,
  [CodexAssetType.Podcast]: 6
})

export const FALLBACK_THUMBNAIL_MAPPING = Object.freeze({
  [CodexAssetType.File]: 'NoFileThumbnail.svg',
  [CodexAssetType.Video]: 'NoVideoThumbnail.svg',
  [CodexAssetType.VideoPlaylist]: 'NoPlaylistThumbnail.svg',
  [CodexAssetType.Audio]: 'NoAudioThumbnail.svg',
  [CodexAssetType.Podcast]: 'NoPodcastThumbnail.svg',
  [CodexAssetType.Image]: 'NoImageThumbnail.svg',
  [CodexAssetType.Undefined]: 'NoFileThumbnail.svg'
})

export const VP_INDICATOR = Object.freeze({
  [CodexAssetType.Video]: 'Play_fill',
  [CodexAssetType.VideoPlaylist]: 'Play_fill',
  [CodexAssetType.Audio]: 'IconparkMusicOne',
  [CodexAssetType.Podcast]: 'IconparkMicrophone'
})

export function downloadAsset(asset: CodexAsset) {
  const xhr = new XMLHttpRequest()
  xhr.onload = () => {
    const reader = new FileReader()
    reader.onloadend = () => {
      // Function to download sset with base64
      const link = document.createElement('a')
      link.setAttribute(
        'download',
        asset?.title ? `${asset.title}.${asset?.fileName.split('.').pop()}` : asset?.fileName
      )
      link.href = reader.result
      this.imageUrl = reader.result
      link.click()
    }
    reader.readAsDataURL(xhr.response)
  }

  try {
    let url = asset?.originalUrl
    if (!url) {
      const urlObj = new URL(asset?.url)
      urlObj.search = ''
      url = urlObj.toString()
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  } catch (e) {
    console.log(e)
  }
}
