import { useI18nStore } from '@/@core/i18n'
import type { FormField } from '@/modules/@global/components/DynamicForm/DynamicForm'
import * as yup from 'yup'

/**
 * Edit asset forms fields definition
 */
interface EditAssetFormFields {
  caption: FormField
  title: FormField
  alt: FormField
  author: FormField
  copyright: FormField
  source: FormField
  expiresAt: FormField
  labels: FormField
  // externalId: FormField
  // tags: FormField
  // focalPoint: FormField
  // attrs: FormField
}

/**
 * Edit asset forms fields
 */
export const EditAssetFormFields = (): EditAssetFormFields => {
  const ct = useI18nStore().createI18nContext('ct.assets.forms.fields')

  // Lables
  // name: {
  // 	type: 'Text',
  // 	name: 'name',
  // 	label: ct.$t('name.label'),
  // 	rules: yup.string().required('Name is required')
  // },
  return {
    labels: {
      type: 'Text',
      name: 'labels',
      label: ct.$t('labels.label')
    },
    title: {
      type: 'Text',
      name: 'title',
      label: ct.$t('title.label'),
      rules: yup.string()
    },
    caption: {
      type: 'TextArea',
      name: 'caption',
      label: ct.$t('caption.label')
    },
    alt: {
      type: 'TextArea',
      name: 'alt',
      label: ct.$t('alt.label')
    },
    source: {
      type: 'Text',
      name: 'source',
      label: ct.$t('source.label')
    },
    author: {
      type: 'Text',
      name: 'author',
      label: ct.$t('author.label')
    },
    copyright: {
      type: 'Text',
      name: 'copyright',
      label: ct.$t('copyright.label')
    },
    expiresAt: {
      type: 'DatePicker',
      name: 'expiresAt',
      label: ct.$t('expiresAt.label'),
      show: (values) => Object.keys({ ...values.hasExpiry }).length > 0,
      props: {
        minDate: new Date()
      }
    }
  }
}

/**
 * Create Edit asset form
 */
const CreateEditAssetForm = (): FormField[] => {
  const fields = EditAssetFormFields()

  return [
    {
      type: 'Row',
      children: [
        // Labels
        {
          ...fields.labels,
          layout: { col: 12 }
        },
        // Title
        {
          ...fields.title,
          layout: { col: 12 }
        },
        // Caption
        {
          ...fields.caption,
          layout: { col: 12 }
        },
        // Alt Text
        {
          ...fields.alt,
          layout: { col: 12 }
        },
        // Source
        {
          ...fields.source,
          layout: { col: 12 }
        },
        // Author Name
        {
          ...fields.author,
          layout: { col: 12 }
        },
        // Copyright
        {
          ...fields.copyright,
          layout: { col: 12 }
        },
        // // Tags
        // {
        //   ...fields.tags,
        //   layout: { col: 12 }
        // },
        // Expires at
        {
          ...fields.expiresAt,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

export { CreateEditAssetForm }
