<template>
  <div class="items">
    <Menu :model="pItems" ref="menu" autofocus />
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },

    command: {
      type: Function,
      required: true
    }
  },

  computed: {
    pItems() {
      return this.items.map((item) => ({
        ...item,
        command: (event) => this.command(item, event),
        items: item.items
          ? item.items.map((subItem) => ({
              ...subItem,
              command: (event) => this.command(subItem, event)
            }))
          : undefined
      }))
    }
  },

  methods: {
    onKeyDown({ event }) {
      console.log(event)
      // List from https://github.com/primefaces/primevue/blob/master/components/lib/menu/Menu.vue
      switch (event.code) {
        case 'ArrowDown':
        case 'ArrowUp':
        case 'Home':
        case 'End':
        case 'Enter':
        case 'Space':
        case 'Escape':
        case 'Tab':
          this.$refs.menu.onListKeyDown(event)
          return true
        default:
          return false
      }
    }
  }
}
</script>
