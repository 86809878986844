import gql from 'graphql-tag'

export const PUBLISHABLE_PRESET_INPUT = gql`
  fragment PublishablePresetInput on CodexPreset {
    alias
    captainImagePolicyId
    createdAt
    createdBy
    gravity
    height
    id
    name
    resize
    sites
    status
    storageId
    updatedAt
    updatedBy
    width
  }
`

export const READ_PRESET = gql`
  ${PUBLISHABLE_PRESET_INPUT}

  query ReadPreset($id: String!, $input: PublishablePresetInput!) {
    preset(id: $id, input: $input)
      @rest(type: "CodexPreset", path: "organizationId/presets/{args.id}", method: "GET") {
      ...PublishablePresetInput
    }
  }
`

export const CREATE_PRESET = gql`
  ${PUBLISHABLE_PRESET_INPUT}

  mutation CreatePreset($input: PublishablePresetInput!) {
    createPreset(input: $input)
      @rest(type: "CodexPreset", path: "organizationId/presets", method: "POST") {
      ...PublishablePresetInput
    }
  }
`

export const UPDATE_PRESET = gql`
  ${PUBLISHABLE_PRESET_INPUT}

  mutation UpdatePreset($input: PublishablePresetInput!) {
    updatePreset(input: $input)
      @rest(type: "CodexPreset", path: "organizationId/presets/{args.input.id}", method: "PUT") {
      ...PublishablePresetInput
    }
  }
`

export const DELETE_PRESET = gql`
  ${PUBLISHABLE_PRESET_INPUT}

  mutation DeletePreset($id: String!, $input: PublishablePresetInput!) {
    deletePreset(id: $id, input: $input)
      @rest(type: "CodexPreset", path: "organizationId/presets/{args.id}", method: "DELETE") {
      NoResponse
    }
  }
`
