import gql from 'graphql-tag'

/**
 * Fragment Definition
 */
export const TAG_PARENT = gql`
  fragment Tag on CodexTag {
    id
    tagAlias
    tagValue
    source
    parentId
    siteId
    createdBy {
      id
    }
  }
`

/**
 * Queries using the fragment
 */
export const LIST_DROPDOWN_TAGS = gql`
  query ListDropdownTags($where: CodexTagFilter, $limit: Int, $offset: Int, $order: CodexTagOrder) {
    data: tagCollection(where: $where, limit: $limit, offset: $offset, order: $order) {
      items {
        ...Tag
      }
      total
    }
  }
  ${TAG_PARENT}
`

export const LIST_SELECTED_DROPDOWN_TAGS = gql`
  query ListSelectedDropdownTags($ids: [String!]) {
    existing: tagCollection(where: { id: { in: $ids } }, limit: 100) {
      items {
        ...Tag
      }
      total
    }
  }
  ${TAG_PARENT}
`
