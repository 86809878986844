import { CodexHooks } from '@/@core'
import { useI18nStore } from '@/@core/i18n'
import { generateNavigationItem } from '@/@core/navigation'
import { useEntriesListingFunctions } from '@/modules/entries/composables/entriesListingComposable'

CodexHooks.addFilter('navigation/items?parent-static=site', (items) => {
  const ct = useI18nStore().createI18nContext('ct.entries.navigation')
  const { generateViewsNavigationItems } = useEntriesListingFunctions()
  const viewsNavigationItems = generateViewsNavigationItems()
  return [
    ...items,
    generateNavigationItem({
      name: 'entries',
      title: 'Entries',
      route: { path: '/empty' },
      children: [
        generateNavigationItem({
          name: 'all-entries',
          title: ct.$t('listing'),
          route: { name: 'entries', params: { viewId: 'all' } }
        }),
        generateNavigationItem({
          name: 'my-entries',
          title: ct.$t('my-listing'),
          route: { name: 'entries', params: { viewId: 'my' } }
        }),
        ...(viewsNavigationItems.groups.length || viewsNavigationItems.rootViews.length
          ? [
              generateNavigationItem({
                name: 'views',
                title: 'All Views',
                route: { path: '/empty' },
                children: [...viewsNavigationItems.groups, ...viewsNavigationItems.rootViews]
              })
            ]
          : [])
      ]
    })
  ]
})
