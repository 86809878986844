import { graphql } from '@/gql/gql'
import gql from 'graphql-tag'

export const TAG_LISTING_FRAGMENT = graphql(/* GraphQL */ `
  fragment TagListingFragment on CodexTag {
    __typename
    key: id
    id
    tagAlias
    tagValue
    source
    parentId
    siteId
    createdBy {
      id
    }
    children {
      total
    }
    references {
      total
    }
  }
`)

export const LIST_TAGS = graphql(/* GraphQL */ `
  query ListTag($limit: Int!, $offset: Int!, $siteId: String!, $order: CodexTagOrder) {
    data: tagCollection(
      limit: $limit
      offset: $offset
      where: { siteId: { eq: $siteId }, parentId: { exists: false } }
      order: $order
    ) {
      total
      items {
        ...TagListingFragment
      }
    }
  }
`)

export const LIST_TAGS_NESTED = graphql(/* GraphQL */ `
  query ListTagsNested(
    $limit: Int!
    $offset: Int!
    $siteId: String!
    $parentId: String
    $order: CodexTagOrder
  ) {
    data: tagCollection(
      limit: $limit
      offset: $offset
      where: { siteId: { eq: $siteId }, parentId: { eq: $parentId } }
      order: $order
    ) {
      total
      items {
        ...TagListingFragment
      }
    }
  }
`)

// export const TAG_DROPDOWN_FRAGMENT = graphql(/* GraphQL */ `
//   fragment TagDropdownFragment on CodexTag {
//     id
//     tagAlias
//     tagValue
//     source
//     parentId
//     siteId
//     createdBy {
//       id
//     }
//   }
// `)

export const TAG_PARENT = gql`
  fragment TagDropdownFragment on CodexTag {
    id
    tagAlias
    tagValue
    source
    parentId
    siteId
    createdBy {
      id
    }
  }
`

/**
 * Queries using the fragment
 */
export const DROPDOWN_TAGS = gql`
  ${TAG_PARENT}
  query tags($where: CodexTagFilter, $limit: Int, $offset: Int, $order: CodexTagOrder) {
    data: tagCollection(where: $where, limit: $limit, offset: $offset, order: $order) {
      items {
        ...TagDropdownFragment
        children {
          total
          items {
            ...TagDropdownFragment
          }
        }
      }
      total
    }
  }
`

export const SELECTED_DROPDOWN_TAGS = gql`
  ${TAG_PARENT}
  query currentTags($ids: [String!]) {
    data: tagCollection(where: { id: { in: $ids } }) {
      items {
        ...TagDropdownFragment
      }
    }
  }
`
