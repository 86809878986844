<script setup lang="ts">
import type { NavigationItem } from '@/@core/navigation/types'
import SidebarNavigation from './SidebarNavigation.vue'
import AppLink from '../AppLink.vue'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    item: NavigationItem
    indent: number
    depth: number
  }>(),
  {
    depth: 100
  }
)

let hasChildren = computed(() => {
  return props.item.children && props.item.children.length
})

let depthLimit = computed(() => {
  return props.depth - (props.indent + 1)
})
</script>

<template>
  <AppLink :to="props.item.route" :exact="true">{{ props.item.title }}</AppLink>
  <template v-if="hasChildren && depthLimit">
    <SidebarNavigation :navigation-items="item.children" :indent="props.indent + 1" />
  </template>
</template>

<style scoped></style>
