import gql from 'graphql-tag'

export const PUBLISHABLE_SECTION_INPUT = gql`
  fragment PublishableSectionInput on CodexSection {
    createdAt
    createdBy
    description
    externalId
    id
    media
    normalizedTitle
    parentId
    parentName
    path
    references
    siteId
    slug
    status
    title
    titleSlug
    updatedAt
    updatedBy
    url
  }
`

export const READ_SECTION = gql`
  ${PUBLISHABLE_SECTION_INPUT}

  query ReadSection($id: String!, $input: PublishableSectionInput!) {
    section(id: $id, input: $input)
      @rest(type: "CodexSection", path: "organizationId/sections/{args.id}", method: "GET") {
      ...PublishableSectionInput
    }
  }
`

export const CREATE_SECTION = gql`
  ${PUBLISHABLE_SECTION_INPUT}

  mutation CreateSection($input: PublishableSectionInput!) {
    createSection(input: $input)
      @rest(type: "CodexSection", path: "organizationId/sections", method: "POST") {
      ...PublishableSectionInput
    }
  }
`

export const UPDATE_SECTION = gql`
  ${PUBLISHABLE_SECTION_INPUT}

  mutation UpdateSection($input: PublishableSectionInput!) {
    updateSection(input: $input)
      @rest(type: "CodexSection", path: "organizationId/sections/{args.input.id}", method: "PUT") {
      ...PublishableSectionInput
    }
  }
`

export const DELETE_SECTION = gql`
  ${PUBLISHABLE_SECTION_INPUT}

  mutation DeleteSection($id: String!, $input: PublishableSectionInput!) {
    deleteSection(id: $id, input: $input)
      @rest(type: "CodexSection", path: "organizationId/sections/{args.id}", method: "DELETE") {
      NoResponse
    }
  }
`
