import { CodexHooks } from '@/@core'
import { isString } from 'lodash'
import { EVENT_MAPPING, STATUS_MAPPING } from '@/modules/workflows/constants'

CodexHooks.addFilter(
  'apollo/restFetchOptions?type=CodexAutomation',
  (options, context, uri, headers) => {
    if (isString(options.body)) {
      try {
        const bodyParsed = JSON.parse(options.body)

        if (bodyParsed.event) {
          bodyParsed.event = EVENT_MAPPING.toString(bodyParsed.event)
        }
        if (bodyParsed.status) {
          bodyParsed.status = STATUS_MAPPING.toString(bodyParsed.status)
        }

        options.body = JSON.stringify(bodyParsed)
      } catch (e) {}
    }

    return options
  }
)

CodexHooks.addFilter('apollo/responseTransformer?type=CodexAutomation', (response) => {
  if (response?.eventTypes) {
    response.eventTypes = EVENT_MAPPING.toString(response.eventTypes)
  }

  if (response?.status) {
    response.status = STATUS_MAPPING.toString(response.status)
  }

  return response
})
