import { graphql } from '@/gql'

export const SITE = graphql(/* GraphQL */ `
  query Site($id: String!) {
    site(id: $id) {
      id
      alias
      name
      logo
      description
      domain
      previewDomain
      attrs
    }
  }
`)

export const LIST_SITES = graphql(/* GraphQL */ `
  query ListSites($limit: Int!, $offset: Int!, $where: CodexSiteFilter, $order: CodexSiteOrder) {
    data: siteCollection(limit: $limit, offset: $offset, where: $where, order: $order) {
      total
      items {
        id
        name
        logo
      }
    }
  }
`)

export const LIST_SELECTED_DROPDOWN_SITES = graphql(/* GraphQL */ `
  query ListSelectedDropdownSites($ids: [String!]) {
    existing: siteCollection(limit: 1000, where: { id: { in: $ids } }) {
      total
      items {
        id
        name
      }
    }
  }
`)

export const LIST_DROPDOWN_SITES = graphql(/* GraphQL */ `
  query ListDropdownSites(
    $limit: Int!
    $offset: Int!
    $where: CodexSiteFilter
    $order: CodexSiteOrder
  ) {
    data: siteCollection(limit: $limit, offset: $offset, where: $where, order: $order) {
      total
      items {
        id
        name
      }
    }
  }
`)
