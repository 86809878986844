/* eslint-disable @typescript-eslint/no-unused-vars */

/** Test hooks **/
import { CodexDebug, CodexHooks } from '@/@core'

/** Auth hooks **/
CodexHooks.addAction('auth/userLoggedIn', (context, user) => {
  // CodexDebug.authInfo('userLoggedIn', user)
})

CodexHooks.addAction('auth/userLoggedOut', (context) => {
  // CodexDebug.authInfo('userLoggedOut')
})

CodexHooks.addAction('auth/oidcError', (context, payload) => {
  // CodexDebug.authInfo('oidcError', payload)
})

CodexHooks.addFilter('auth/setUserData', (user, context, oidcUser, decodedAccessToken) => {
  // CodexDebug.authInfo('setUserData', user)
  return user
})

CodexHooks.addFilter('auth/loginRedirectPath', (path, context) => {
  // CodexDebug.authInfo('loginRedirectPath', path)
  return path
})

/** I18n hooks **/
CodexHooks.addAction('i18n/setLocale', (context, locale) => {
  // CodexDebug.i18nInfo('setLocale', locale)
})

CodexHooks.addFilter('i18n/missing', (key, context, locale, instance, type) => {
  // CodexDebug.i18nInfo('missing', key, locale, instance, type)
  return key
})

CodexHooks.addFilter(
  'i18n/showMissingKey',
  (showMissingKey, context, key, locale, instance, type) => {
    // CodexDebug.i18nInfo('showMissingKey', showMissingKey, key, locale, instance, type)
    return showMissingKey
  }
)

/** Apollo hooks **/
// prettier-ignore
CodexHooks.addFilter('apollo/showError', (showError, context, requestContext, response, networkError, graphQLErrors) => {
  // CodexDebug.apolloInfo('showError', showError, requestContext, response, networkError, graphQLErrors)
  return showError
})

// prettier-ignore
CodexHooks.addAction('apollo/errorLink', (context, graphQLErrors, networkError, requestContext, response, operation) => {
  if( requestContext.showError ) {
    CodexDebug.apolloError('errorLink', graphQLErrors, networkError, requestContext, response, operation)
  }
  // CodexDebug.apolloInfo('errorLink', graphQLErrors, networkError, requestContext, response, operation)
})

CodexHooks.addFilter('apollo/restFetchUri', (uri, context, options, headers) => {
  // CodexDebug.apolloInfo('restFetchUri', uri, options)
  return uri
})

CodexHooks.addFilter('apollo/restFetchOptions', (options, context, uri, headers) => {
  // CodexDebug.apolloInfo('restFetchOptions', options, uri)
  return options
})

CodexHooks.addFilter('apollo/restFetchResponse', (response, context, uri, options) => {
  // CodexDebug.apolloInfo('restFetchResponse', response, uri, options)
  return response
})
