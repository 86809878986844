import { CodexHooks } from '@/@core'
import type { Context } from '@/modules/@global/composables/useContext'
import { AUTHORS_NAME } from '@/modules/authors/constants'

CodexHooks.addAction(`context/created?name=${AUTHORS_NAME}`, (context, contextObj) => {
  CodexHooks.addFilter(`table/variables?name=${AUTHORS_NAME}`, addFilterVariables, contextObj)
})

CodexHooks.addAction(`context/destroyed?name=${AUTHORS_NAME}`, (context, contextObj) => {
  CodexHooks.removeFilter(`table/variables?name=${AUTHORS_NAME}`, addFilterVariables, contextObj)
})

function addFilterVariables(variables: any, context: Context) {
  const search = context.getRef('search')?.search

  if (search) {
    variables.where = {
      ...variables.where,
      or: [
        { byline: { contains: search } },
        { firstName: { contains: search } },
        { lastName: { contains: search } }
      ]
    }
  }

  return variables
}
