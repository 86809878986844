<script setup lang="ts">
defineOptions({
	name: 'SidebarContent'
})

const props = withDefaults(defineProps<{
	title?: string,
	showHeader?: boolean
}>(), {
	showHeader: true
})
</script>

<template>
  <div v-if="showHeader" class="p-sidebar-content-header">
    <slot name="header">
      <div class="flex flex-column">
        <Text type="body-semi-bold-base">{{ props.title }}</Text>
      </div>
    </slot>
  </div>

	<div class="p-sidebar-content-body">
		<slot />
	</div>

	<div class="p-sidebar-content-footer">
		<slot name="footer" />
	</div>
</template>

<style scoped></style>
