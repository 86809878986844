/* eslint-disable vue/one-component-per-file */
import { CodexHooks } from '@/@core'
import { defineComponent, ref, isVNode } from 'vue'

export const LayoutElement = defineComponent({
  name: 'LayoutElement',
  props: {
    name: {
      type: String,
      required: true
    },
    main: {
      type: Boolean,
      default: false
    }
  },
  render() {
    return this.$slots.default?.()
  }
})

export const LayoutElements = defineComponent({
  name: 'LayoutElements',
  props: {
    name: {
      type: String,
      required: true
    },
    layout: {
      type: String,
      default: 'vertical'
    },
    items: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    flexDirection: {
      type: String,
      default: () => 'column'
    },
    alignItems: {
      type: String,
      default: () => ''
    }
  },
  setup() {
    const refreshKey = ref(0)

    CodexHooks.addAction('hooks/filter-added?name=layout/elements', () => {
      refreshKey.value++
    })

    CodexHooks.addAction('hooks/filter-removed?name=layout/elements', () => {
      refreshKey.value++
    })

    return {
      refreshKey
    }
  },
  render() {
    this.refreshKey

    const slotElements = (this.$slots.default?.() || []).filter(Boolean).map((item) => {
      return {
        name: item?.props?.name,
        main: item?.props?.main,
        element: item
      }
    })

    // Gather items from filter
    let items = CodexHooks.applyFilters(
      `layout/elements?name=${this.name}`,
      [...slotElements, ...this.items],
      null,
      ...this.data
    )

    items = items
      .filter((item: any) => {
        return isVNode(item) || item.element
      })
      .map((item: any, idx: number) => {
        let element = item
        let name = Math.random().toString(36).substring(7)

        if (!isVNode(item)) {
          if (item.element && item.element.constructor === Function) {
            try {
              element = item.element(...this.data)
            } catch (e) {
              element = <span></span>
            }
          } else {
            element = item.element
          }

          if (!isVNode(element)) {
            return null
          }

          if (item.name) {
            name = item.name
          }
        }

        return (
          <div
            class={{
              'layout-element': true,
              'layout-element-main': item.main
            }}
            key={name}
            data-slot-element-name={name}
            style={{
              width: this.layout === 'vertical' ? '100%' : 'auto',
              flex: item.main ? '1 1 auto' : null,
              display: 'flex',
              alignItems: this.$props.alignItems,
              flexDirection: this.$props.flexDirection,
              minHeight: item.main ? '0' : null,
              ...element?.props?.style
            }}
            v-bind="$attrs"
          >
            {element}
          </div>
        )
      })
      .filter(Boolean)

    return (
      <div
        class="layout-elements"
        data-slot-name={this.name}
        style={{
          display: 'flex',
          gap: '16px',
          flexDirection: this.layout === 'vertical' ? 'column' : 'row',
          alignItems: this.layout === 'vertical' ? 'flex-start' : 'center'
        }}
      >
        {items}
      </div>
    )
  }
})
