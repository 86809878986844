<script setup lang="ts">
import type { CodexFolder } from '@/gql/graphql'
import { computed, toRefs, inject } from 'vue'

import Breadcrumb from 'primevue/breadcrumb'
import type { TreeExpandedKeys } from 'primevue/tree'

const props = withDefaults(
  defineProps<{
    folder: CodexFolder | null
    showPath: boolean
  }>(),
  {
    folder: null,
    showPath: false
  }
)
let emit = defineEmits(['update:folderId'])

const rootFolder = {
  name: 'Root',
  id: null
}

const { folder, showPath } = toRefs(props)

const folderPath = computed(() => {
  if (showPath.value) return !!folder.value?.parent && generatePath(folder.value.path)
  return null
})

const type = inject('type')
const expandedKeys = inject('expandedKeys') as TreeExpandedKeys

const isTree = computed(() => type === 'tree')

const isOpen = computed(() => {
  return Object.keys(expandedKeys.value).includes(folder.value?.key)
})

// TODO: Change the 'object' type to a type or interface
function generatePath(path: any): object[] {
  return path.slice(0, path.length - 1)
}
</script>

<template>
  <div class="folder" :data-folder="folder?.id" :class="{ tree: isTree }" draggable>
    <img
      class="folder--icon"
      src="@/assets/icons/folder.svg"
      :height="isTree ? 24 : 32"
      :width="isTree ? 24 : 32"
    />
    <div class="folder--details">
      <Text clamp="1" class="folder--title">
        {{ folder?.name }}
      </Text>
      <div class="folder--path" v-if="!isTree">
        <Breadcrumb :home="rootFolder" :model="folderPath">
          <template #item="{ item }">
            <Text
              class="breadcrumb-folder text-primary font-semibold"
              :clamp="1"
              v-tooltip.top="item.name"
              @click.stop="emit('update:folderId', item.id)"
            >
              {{ item.name }}
            </Text>
          </template>

          <template #separator> / </template>
        </Breadcrumb>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.folder {
  padding: 8px 10px;
  gap: 8px;
  background-color: white;
  display: flex;
  flex-direction: row;

  &.active-folder {
    border: 2px solid #206ed5 !important;
    border-radius: inherit;
    background-color: rgba(29, 121, 242, 0.2);
  }

  &.tree {
    padding: 2px;
    width: 100%;
  }

  &--title,
  &--icon {
    cursor: pointer;
  }

  &--details {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .p-breadcrumb {
      border: none;
      padding: 0;
      background-color: transparent;

      .breadcrumb-folder {
        cursor: pointer;
      }
    }
  }
}
</style>
