import qs from 'qs'
import { merge, mergeWith, set, get, isArray } from 'lodash'

// import { apolloProvider } from '@/libs/vue-apollo'
// import store from '@/store'
// import gql from 'graphql-tag'
// import { transformEntriesGraphQL } from '@/codex-sdk/entries'

import { nextTick } from 'vue'
import { useDynamicFilters } from '@/modules/@global/store/dynamic-filters'

export interface Filter {
  type: string
  name?: string
  label?: string
  graphQLPath?: string | null
  alwaysShow?: boolean
  filters?: Filter[]
  hideDropdown?: boolean
  hideSearch?: boolean
  options?: any[]
}

export interface ActiveFilter {
  filter: Filter | null
  value: object | null
  location: string | null
}

export default class Filters {
  /**
   * The list of all filters including groups and dividers
   */
  filters: Filter[] = []

  /**
   * List of all active filters
   */
  activeFilters = {}

  /**
   * List of all active pending filters
   */
  activePendingFilters = {}

  /**
   * Cache filter values
   */
  cache: {} = {}

  /**
   * Filters ready
   */
  ready = false

  /**
   * Filters ready
   */
  skipFilters: string[] = []

  /**
   * @param {Array<Object>} filters Filters to set
   */
  constructor(filters: Filter[], skipFilters: string[] = []) {
    if (filters) {
      this.filters = filters
    }

    if (skipFilters) {
      this.skipFilters = skipFilters
    }
  }

  /**
   * Set cached item
   *
   * @param {Array|Object} key
   */
  setCache(value: { id: string } | {}[]) {
    if (!value) return

    if (isArray(value)) {
      value.forEach((v) => {
        // Vue.set(this.cache, v.id, v)
        this.cache[v.id] = v
      })
    } else {
      // Vue.set(this.cache, value.id, value)
      this.cache[value.id] = value
    }
  }

  /**
   * Load data for all active filters
   */
  async loadData() {
    let load = {}

    Object.keys(this.activeFilters).forEach((filterName) => {
      const activeFilter = this.activeFilters[filterName]

      if (activeFilter && typeof activeFilter.toLoad === 'function') {
        const filterLoad = activeFilter.toLoad(activeFilter)
        load = merge(load, filterLoad)
      }
    })

    // if (Object.keys(load).length !== 0) {
    //   const { data } = await apolloProvider.defaultClient.query({
    //     query: gql`
    //       query (
    //         $assets: [String!]
    //         $users: [String!]
    //         $sections: [String!]
    //         $authors: [String!]
    //         $folders: [String!]
    //         $entries: [String!]
    //         $labels: [String!]
    //       ) {
    //         assetCollection(where: { id: { in: $assets } }) {
    //           items {
    //             id
    //             title
    //             url(transformation: { height: 200, width: 200 })
    //           }
    //         }
    //         userCollection(where: { id: { in: $users } }) {
    //           items {
    //             id
    //             imageUrl
    //             email
    //             firstName
    //             lastName
    //           }
    //         }
    //         sectionCollection(where: { id: { in: $sections } }) {
    //           items {
    //             id
    //             title
    //             parentId
    //           }
    //         }
    //         authorCollection(where: { id: { in: $authors } }) {
    //           items {
    //             id
    //             firstName
    //             lastName
    //             byline
    //             email
    //           }
    //         }
    //         folderCollection(where: { id: { in: $folders } }) {
    //           items {
    //             id
    //             name
    //           }
    //         }
    //         entryCollection(where: { id: { in: $entries } }) {
    //           items {
    //             id
    //             system {
    //               title
    //               featuredMedia {
    //                 url
    //               }
    //               modelId
    //             }
    //           }
    //         }
    //         labelCollection(where: { id: { in: $labels } }) {
    //           items {
    //             id
    //             name
    //             color
    //           }
    //         }
    //       }
    //     `,
    //     variables: {
    //       assets: load.assets || ['n/a'],
    //       users: load.users || ['n/a'],
    //       sections: load.sections || ['n/a'],
    //       authors: load.authors || ['n/a'],
    //       folders: load.folders || ['n/a'],
    //       entries: load.entries || ['n/a'],
    //       labels: load.labels || ['n/a']
    //     }
    //   })
    //
    //   // Set requested assets to cache
    //   data.assetCollection.items.forEach((asset) => {
    //     if (this.cache[asset.id]) return
    //     Vue.set(this.cache, asset.id, asset)
    //   })
    //
    //   // Set requested users to cache
    //   data.userCollection.items.forEach((user) => {
    //     if (this.cache[user.id]) return
    //     Vue.set(this.cache, user.id, user)
    //   })
    //
    //   // Set requested sections to cache
    //   data.sectionCollection.items.forEach((section) => {
    //     if (this.cache[section.id]) return
    //     Vue.set(this.cache, section.id, section)
    //   })
    //
    //   // Set requested authors to cache
    //   data.authorCollection.items.forEach((author) => {
    //     if (this.cache[author.id]) return
    //     Vue.set(this.cache, author.id, author)
    //   })
    //
    //   // Set requested authors to cache
    //   data.folderCollection.items.forEach((folder) => {
    //     if (this.cache[folder.id]) return
    //     Vue.set(this.cache, folder.id, folder)
    //   })
    //
    //   // Set requested labels to cache
    //   data.labelCollection.items.forEach((label) => {
    //     if (this.cache[label.id]) return
    //     Vue.set(this.cache, label.id, label)
    //   })
    //
    //   // Set requested entries to cache
    //   // transformEntriesGraphQL(data.entryCollection.items)
    //   data.entryCollection.items.forEach((entry) => {
    //     if (this.cache[entry.id]) return
    //     Vue.set(this.cache, entry.id, entry)
    //   })
    // }

    // Set all models to cache
    // store.state.general.models.forEach((model) => {
    //   if (this.cache[model.id]) return
    //   Vue.set(this.cache, model.id, model)
    // })
  }

  /**
   * Load active filters from a query string
   *
   * @param {Object} query Query object from $route object (e.g. this.$route.query)
   */
  async loadFromQuery(query: string | object) {
    return new Promise((resolve) => {
      nextTick(() => {
        const queryFilters = qs.parse(query, { ignoreQueryPrefix: true })

        const { success } = this.setActiveFilters(queryFilters)

        if (Object.keys(queryFilters).length === 0) {
          this.ready = true
        }

        resolve(success)
      })
    })
  }

  /**
   * Returns a flat array of filters
   */
  get filtersFlat() {
    const filters = {}

    if (!this.filters) {
      return {}
    }

    this.filters.forEach((filter: any) => {
      if (filter.type === 'group') {
        filter.filters.forEach((f) => {
          filters[f.name] = f
        })
      } else if (filter.type !== 'divider') {
        filters[filter.name] = filter
      }
    })

    return filters
  }

  /**
   * @returns {String} Active filters as a query string
   */
  get asQueryParams() {
    const activeFilters = this.getActiveFilters()

    if (Object.keys(activeFilters).length === 0) {
      return ''
    }

    const queryParams = {}

    Object.keys(activeFilters).forEach((filterName) => {
      const activeFilter = activeFilters[filterName]
      const filter = this.getFilter(filterName)

      if (typeof activeFilter.asQueryParam === 'function') {
        const value = activeFilter.asQueryParam()

        if (!filter || value === null) return

        queryParams[filterName] = value
      } else {
        console.warn('Filter does not support asQueryParam', activeFilter)
      }
    })

    return qs.stringify(queryParams)
  }

  /**
   * @returns {String} Active filters as a query string
   */
  getQueryParamsObject() {
    const queryParams = this.asQueryParams

    if (typeof queryParams !== 'string') {
      return {}
    }

    return qs.parse(queryParams, { ignoreQueryPrefix: true, depth: 0 })
  }

  /**
   * @returns {String} Active filters as a query string
   */
  get asGraphQL() {
    const activeFilters = this.getActiveFilters()

    if (Object.keys(activeFilters).length === 0) {
      return {}
    }

    const queryParams = {}

    Object.keys(activeFilters).forEach((filterName) => {
      const activeFilter = activeFilters[filterName]
      const filter = this.getFilter(filterName)

      if (typeof activeFilter.asGraphQL === 'function') {
        const value = activeFilter.asGraphQL()

        if (!filter || value === null) return

        if (filter.graphQLPath) {
          set(
            queryParams,
            filter.graphQLPath,
            mergeWith(get(queryParams, filter.graphQLPath), value, (objValue, srcValue) => {
              if (Array.isArray(objValue)) {
                return objValue.concat(srcValue)
              }
              return undefined
            })
          )
        } else {
          queryParams[filterName] = value
        }
      } else {
        console.warn('Filter does not support asGraphQL', activeFilter)
      }
    })

    return queryParams
  }

  /**
   * @param {Array<Object>} filters Filters to set
   */
  setFilters(filters: Filter[]) {
    this.filters = filters

    // Remove any active filters that are not in the new filters
    // Object.keys(this.activeFilters).forEach((filterName) => {
    //   if (!this.filtersFlat[filterName]) {
    //     // console.log(380, filterName)
    //     // this.clearActiveFilter(filterName)
    //   }
    // })

    this.retryActivePendingFilters()
  }

  /**
   * @returns {Array<Object>} Returns an array of filters
   */
  getFilters() {
    return this.filters
  }

  /**
   * Return single filter
   */
  getFilter(filterName: string) {
    return this.filtersFlat[filterName]
  }

  /**
   * Finds and returns the filter object for the given filter name.
   *
   * @param {String} filterName Name of the filter
   * @returns {Object} Filter object
   */
  getFilterObject(filterName: string) {
    const filter = this.getFilter(filterName)
    const { filters } = useDynamicFilters()
    const availableFilter = filters.find((f): object => f.type === filter.type)
    if (!filter) {
      throw new Error(`Filter ${filterName} not found`)
    }

    if (!availableFilter) {
      throw new Error(`Filter type ${filter.type} not found`)
    }

    return availableFilter.component
  }

  /**
   * Returns the default value for a filter. Validations are not applied.
   *
   * @param {String} filterName Name of the filter
   */
  getFilterDefaultValue(filterName: string) {
    try {
      const FilterObject = this.getFilterObject(filterName)
      return new FilterObject()
    } catch (e) {
      return {}
    }
  }

  /**
   * @returns {Array<Object>} Returns an array of active filters
   */
  getActiveFilters() {
    return this.activeFilters
  }

  /**
   * @returns {Array<Object>} Returns an array of filters that are set to always show
   */
  getAlwaysShowFilters() {
    return Object.values(this.filtersFlat).filter((filter) => filter.alwaysShow)
  }

  /**
   * @param {String} filterName Name of the filter to return
   * @returns {Object} Returns the active filter value for the given filter name
   */
  getActiveFilter(filterName: string) {
    return this.activeFilters[filterName]
  }

  /**
   * Get active filter badge label
   *
   * @param {String} filterName Name of the filter to return
   */
  getActiveFilterBadgeLabel(filterName: string) {
    const filter = this.getFilter(filterName)
    const activeFilter = this.getActiveFilter(filterName)

    const dataObject = {
      cache: this.cache
    }

    return {
      value:
        activeFilter && typeof activeFilter.getValueLabel === 'function'
          ? activeFilter.getValueLabel(filter)
          : '',
      count:
        activeFilter && typeof activeFilter.getCount === 'function' ? activeFilter.getCount() : 1,
      label: filter ? filter.label : filterName
    }
  }

  /**
   * Set multiple active filters at once
   *
   * @param {Array<Object>} filters Filters to set
   */
  setActiveFilters(filters, successCallback, errorCallback) {
    const result = { fail: 0, success: 0 }

    if (!filters || Object.keys(filters).length == 0) return result

    Object.keys(filters).forEach((filterName) => {
      try {
        this.setActiveFilter(filterName, filters[filterName])
        result.success++

        if (successCallback) successCallback(filterName)
      } catch (e) {
        // console.error(e)
        result.fail++

        if (errorCallback) errorCallback(filterName)
      }
    })

    if (!result.fail) {
      this.ready = true
    }

    this.loadData()
    return result
  }

  /**
   * Sets the active filter for the given filter name, while also validating the value.
   *
   * @param {String} filterName Name of the filter to set
   * @param {Object} filterValue The value of the filter to set
   */
  setActiveFilter(filterName: string, filterValue: Filter) {
    if (this.skipFilters && this.skipFilters.includes(filterName)) {
      return
    }

    if (!this.filtersFlat[filterName] && !this.activePendingFilters[filterName]) {
      this.activePendingFilters[filterName] = filterValue

      return
    }

    const FilterObject = this.getFilterObject(filterName)

    if (FilterObject.validate) {
      const error = FilterObject.validate(filterValue)

      if (error !== true) {
        throw new Error(error)
      }
    }

    if (this.activeFilters[filterName]) {
      this.activeFilters[filterName].set(filterValue)
    } else {
      this.activeFilters[filterName] = new FilterObject(filterValue)
    }
  }

  /**
   * Retry pending filters
   */
  retryActivePendingFilters() {
    this.setActiveFilters(
      this.activePendingFilters,
      (filterName: string) => {
        delete this.activePendingFilters[filterName]
      },
      (filterName: string) => {
        console.warn('Failed to set pending filter', filterName)
      }
    )
  }

  /**
   * Clear active filters
   */
  clearActiveFilters() {
    this.activeFilters = {}
  }

  /**
   * Clears the active filter for the given filter name
   *
   * @param {String} filterName Name of the filter to clear
   */
  clearActiveFilter(filterName: string) {
    // Vue.delete(this.activeFilters, filterName)
    delete this.activeFilters[filterName]
  }

  /**
   * Is filer active
   *
   * @param {String} filterName Name of the filter to check if active
   */
  isFilterActive(filterName: string) {
    return !!this.activeFilters[filterName]
  }

  /**
   * @returns {Number} Return the number of active filters
   */
  getActiveFiltersCount() {
    return Object.keys(this.activeFilters).length
  }

  /**
   * @returns {Boolean} Returns true if there are active filters
   */
  hasActiveFilters() {
    return this.getActiveFiltersCount() > 0
  }
}
