import { CodexHooks } from '@/@core'
import CreateModelMapping from './CreateModelMapping.vue'
import UpdateModelMapping from './UpdateModelMapping.vue'

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  items = [
    ...items,

    /**
     * @codex
     *
     * Create Model Mapping
     *
     * @type {Sidebar}
     * @name create-model-mapping
     * @component CreateModelMapping
     *
     * @param {string} name
     *
     * @output {ModelMapping}
     */

    {
      type: 'Sidebar',
      name: 'create-model-mapping',
      component: CreateModelMapping
    },

    /**
     * Update Model Mapping
     *
     * @codex
     * @popup
     * @type {Sidebar} test description
     * @name update-model-mapping
     * @component UpdateModelMapping
     *
     * @param {string} id - Model Mapping ID
     *
     * @output {ModelMapping}
     */

    {
      type: 'Sidebar',
      name: 'update-model-mapping',
      component: UpdateModelMapping
    }
    // {
    //   type: 'Dialog',
    //   name: 'confirm-delete-preset',
    //   component: ConfirmDelete,
    //   data: {
    //     labels: {
    //       cancel: ct.$p('cancel'),
    //       confirm: ct.$p('confirm'),
    //       title: ct.$p('title'),
    //       message: ct.$p('message')
    //     }
    //   }
    // }
  ]

  return items
})
