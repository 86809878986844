<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable'
import { CREATE_APIKEY } from '@/modules/apikeys/queries/index.rest'
import { inject } from 'vue'
import { CreateApiKeyForm } from '@/modules/apikeys/forms/ApiKey'
import { useI18nStore } from '@/@core/i18n'
import { apikeyDefaults } from '@/modules/apikeys/constants'
import type { Context } from '@/modules/@global/composables/useContext'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.apikeys.popups.create-apikey')

/**
 * Dialog
 */
let dialog = inject(DialogRefKey)

/**
 * Create ApiKey
 */
const createApiKey = useMutation(CREATE_APIKEY, {
  refetchQueries: ['ListApiKeys']
})

async function createApiKeyHandler(values, response) {
  dialog?.value?.close(response)
}

/**
 * Default values
 */
const defaultValues = apikeyDefaults()
</script>

<template>
  <SidebarContent :title="'Create ApiKey'">
    <DynamicForm
      name="create-apikey"
      :ref="(r: any) => context?.setRef('form', r)"
      :show-submit="false"
      :schema="CreateApiKeyForm()"
      :mutation="createApiKey"
      :data="defaultValues"
      :labels="{
        submit: ct.$t('submit')
      }"
      @submit="createApiKeyHandler"
    ></DynamicForm>

    <template #footer>
      <Button v-bind="context.getRef('form')?.props?.submit.value"></Button>
    </template>
  </SidebarContent>
</template>

<style scoped></style>
