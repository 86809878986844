import { CodexHooks } from '@/@core'
import { generateNavigationItem } from '@/@core/navigation'
import type { NavigationItem } from '@/@core/navigation/types'
import { useOrganization } from '../store/organization'
import { useRoute } from '@/@core/router'

CodexHooks.addFilter('navigation/items?parent=organization-modules', addSitesToMenu)
CodexHooks.addFilter('navigation/items?parent=null', organizationNavigation, null, 8)

/**
 * Add sites to the menu
 */
function addSitesToMenu(items: NavigationItem[]) {
  const organization = useOrganization()

  if (organization.loading) {
    return [
      ...items,
      generateNavigationItem({
        title: 'Loading Sites...',
        route: { path: '/' },
        name: 'loading',
        group: 'sites'
      })
    ]
  }

  if (organization.loading || organization.error) return items

  const siteNavigationItems = organization.sites?.map((site: any) => {
    return generateNavigationItem({
      title: site?.name ? site?.name : '',
      route: {
        name: 'entries',
        params: {
          siteId: site?.id,
          organizationId: useOrganization().organizationId,
          viewId: 'all'
        }
      },
      name: `site-${site?.id}`,
      staticName: 'site',
      group: 'sites',
      children: []
    })
  })

  if (!siteNavigationItems) return items

  return [...items, ...siteNavigationItems]
}

/**
 * Organization level navigation
 * @param items
 */
function organizationNavigation(items: NavigationItem[]) {
  if (!useRoute()?.value?.params?.organizationId) return nonOrganizationLevelNavigation(items)

  return organizationLevelNavigation(items)
}

function nonOrganizationLevelNavigation(items: NavigationItem[]) {
  return [
    ...items,
    generateNavigationItem({
      title: 'Organizations',
      name: 'organizations',
      route: { name: 'organizations' }
    }),
    generateNavigationItem({
      title: 'Codex Dev',
      name: 'codex-dev',
      route: { name: 'sites', params: { organizationId: 'codex-dev' } }
    })
  ]
}

function organizationLevelNavigation(items: NavigationItem[]) {
  return [
    ...items,

    generateNavigationItem({
      title: 'Organizations',
      name: 'organizations',
      route: { name: 'organizations' }
    }),

    generateNavigationItem({
      title: 'Modules',
      name: 'organization-modules',
      type: 'modules',
      route: { path: '' },
      attrs: {
        groups: [{ key: 'top' }, { key: 'sites', title: 'Sites' }, { key: 'bottom' }]
      },
      children: [
        generateNavigationItem({
          name: 'home-module',
          title: 'Home',
          route: { name: 'sites', params: { organizationId: useOrganization().organizationId } },
          children: [
            generateNavigationItem({
              name: 'home',
              title: 'Home',
              route: { name: 'sites', params: { organizationId: useOrganization().organizationId } }
            })
          ]
        }),
        generateNavigationItem({
          name: 'assets-module',
          title: 'Assets',
          route: { name: 'assets', params: { organizationId: useOrganization().organizationId } },
          children: []
        }),
        generateNavigationItem({
          name: 'builder-module',
          title: 'Builder',
          route: { name: 'models', params: { organizationId: useOrganization().organizationId } },
          children: []
        }),
        generateNavigationItem({
          name: 'administration-module',
          title: 'Administration',
          route: { name: 'teams', params: { organizationId: useOrganization().organizationId } },
          children: []
        })
      ]
    })
  ]
}

