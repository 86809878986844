<script setup lang="ts">
// https://router.vuejs.org/guide/advanced/extending-router-link.html

import { type RouterLinkProps, useLink, useRouter } from 'vue-router'
import { computed, type ComputedRef, onErrorCaptured, watch, watchEffect } from 'vue'
import { CodexDebug } from '@/@core'
import { useRouterStore } from '@/@core/router'

defineOptions({
  name: 'AppLink',
  inheritAttrs: false
})

let emit = defineEmits(['click'])

const props = defineProps<RouterLinkProps>()

let routerStore = useRouterStore()

// If the route passed to RouterLink is not found, it will throw an error.
// And will probably break the whole app.
// Here we catch the error and return false to prevent the error from being thrown.
// let link = computed(() => {
//   routerStore.routerItems
//
//
// }) as ComputedRef<ReturnType<typeof useLink>>
// let link:any;
// if (props.to) {
//   try {
//     link = useLink(props)
//     CodexDebug.navigationSuccess('AppLink', props)
//   } catch (err) {
//     CodexDebug.navigationError('AppLink', err, props)
//   }
// }

// async function navigate(event: Event) {
//   event.preventDefault()
//
//   if (!link) return
//
//   try {
//     await link.navigate()
//     emit('click', event)
//   } catch (err) {
//     CodexDebug.navigationError('AppLink', err, props)
//   }
// }

const isExternalLink = computed(() => {
  try {
    return typeof props.to === 'string' && props.to.startsWith('http')
  } catch (err) {
    return false
  }
})

onErrorCaptured((err) => {
  CodexDebug.navigationError('AppLink', err, props)
  return false
})
</script>

<template>
<!--  <a href="#" v-if="!link" v-bind="$attrs">-->
<!--    <slot />-->
<!--  </a>-->

  <a v-if="isExternalLink" v-bind="$attrs" :href="(props.to as string)" target="_blank">
    <slot />
  </a>

  <router-link v-else :key="routerStore.refreshKey"  v-bind="$attrs" :to="props.to">
    <slot />
  </router-link>

<!--  <a-->
<!--    v-else-->
<!--    v-bind="$attrs"-->
<!--    :href="link.href.value"-->
<!--    @click="navigate"-->
<!--    :class="{-->
<!--      'router-link-active': link.isActive.value,-->
<!--      'router-link-exact-active': link.isExactActive.value-->
<!--    }"-->
<!--  >-->
<!--    <slot />-->
<!--  </a>-->
</template>
