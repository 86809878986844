<script setup lang="ts">
import { LayoutElements, LayoutElement } from './LayoutElements'
import { useGlobalStore } from '@/modules/@global/store/global'
import { useI18nStore } from '@/@core/i18n'
import { useDisplay } from '@/modules/@global/composables/useDisplay'

const display = useDisplay()
const store = useGlobalStore()
const i18nStore = useI18nStore()

const copyAccessToken = () => {
  console.log(window.localStorage.getItem('accessToken'))
  navigator.clipboard.writeText(window.localStorage.getItem('accessToken'))
}
</script>

<template>
  <div class="topbar">
    <div class="topbar__left">
      <AppLink :to="{name: 'organizations'}">
        <img
          alt="Codex logo"
          class="topbar__logo"
          src="@/assets/images/logo-white.svg"
          width="173"
          height="54"
        />
      </AppLink>
      <GjIcon
        @click="store.toggleMenu()"
        class="topbar__menu-icon"
        name="IconparkHamburgerButton"
      />
    </div>
    <div class="topbar__right desktop-only">
      <LayoutElements name="topbar" layout="horizontal">
        <LayoutElement name="search"><span @click="copyAccessToken">CAT</span></LayoutElement>
        <LayoutElement name="search">Search</LayoutElement>
        <LayoutElement name="notifications">Notifications</LayoutElement>
        <LayoutElement name="profile">Profile</LayoutElement>
        <LayoutElement name="language">
          <Dropdown
            v-model="i18nStore.locale"
            :options="i18nStore.locales"
            option-label=""
            option-value=""
            size="small"
          />
        </LayoutElement>
      </LayoutElements>
    </div>
  </div>
</template>

<style scoped></style>
