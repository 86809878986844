<script setup lang="ts">
import StatusFilter from './StatusFilter'
import { computed } from 'vue'
import { useI18nStore } from '@/@core/i18n/index'
import { CodexAutomationStatus } from "@/gql/graphql";
import Checkboxes from '@/modules/@global/components/Checkboxes/Checkboxes.vue'


const ct = useI18nStore().createI18nContext('ct.workflows.general.enums.status')
const ctGlobalFilters = useI18nStore().createI18nContext('ct.global.components.filters')

// eslint-disable-next-line no-undef
const modelValue = defineModel<{
	operator: string
	value: CodexAutomationStatus[]
}>()

const options = computed(() => [
	{
		label: ctGlobalFilters.$t('operators.include'),
		value: StatusFilter.OPERATORS.include
	},
	{
		label: ctGlobalFilters.$t('operators.exclude'),
		value: StatusFilter.OPERATORS.exclude
	},
])

const statusOptions = computed(() => {
	return [
		{
			value: CodexAutomationStatus.Active,
			label: ct.$t(CodexAutomationStatus.Active),
		},
		{
			value: CodexAutomationStatus.Inactive,
			label: ct.$t(CodexAutomationStatus.Inactive),
		},
	]
})
</script>

<template>
	<div class="new-filters-dropdown__filter-body new-filters-dropdown__filter-body--scroll">
		<Row align-items="center">
			<Col>
			<Dropdown v-model="modelValue.operator" :options="options" size="small" />
			</Col>
		</Row>

		<Row align-items="center">
			<Col>
			<Checkboxes v-model="modelValue.value" :options="statusOptions" />
			</Col>
		</Row>
	</div>
</template>