import { CodexUrlEntityType, CodexUrlStatusCode } from '@/gql/graphql'
import type { FormField } from '@/modules/@global/components/DynamicForm/DynamicForm'
import * as yup from 'yup'
import { useI18nStore } from '@/@core/i18n'
import { size } from 'lodash'
import { useSite } from '@/modules/sites/store/site'

/**
 * Url forms fields definition
 */
interface UrlFormsFields {
  url: FormField
  entityType: FormField
  redirectState: FormField
  entityId: FormField
  statusCode: FormField
}

/**
 * Url forms fields
 */
export const UrlFormsFields = (): UrlFormsFields => {
  const ct = useI18nStore().createI18nContext('ct.urls.forms.fields')

  return {
    url: {
      type: 'InputTextGroup',
      name: 'url',
      label: ct.$t('url.label'),
      rules: yup.string().required('URL is required'),
      props: {
        inputPrepend: `https://${useSite().result.site.domain}`
      }
    },
    entityType: {
      type: 'RadioButtons',
      name: 'entityType',
      label: ct.$t('entity-type.label'),
      class: 'entity-type-radiobuttons',
      props: {
        options: [
          {
            label: ct.$t('entity-type.options.entry'),
            value: CodexUrlEntityType.Entry,
            class: 'url-custom-radiobuttons',
            children: '<img src="https://codex.gjirafa.dev/img/Entry.e8283239.svg" alt="">',
            childrenClass: 'custom-url-radiobutton-img'
          },
          {
            label: ct.$t('entity-type.options.section'),
            value: CodexUrlEntityType.Section,
            class: 'url-custom-radiobuttons',
            children: '<img src="https://codex.gjirafa.dev/img/Section.531ee80d.svg" alt="">',
            childrenClass: 'custom-url-radiobutton-img'
          }
        ]
      }
    },
    redirectState: {
      type: 'InputSwitch',
      name: 'redirectState',
      label: 'Redirect',
      props: {
        value: CodexUrlEntityType.Redirect
      }
    },
    entityId: {
      type: 'RedirectDropdown',
      name: 'entityId',
      label: ct.$t('entity-id.label')
    },
    statusCode: {
      type: 'Select',
      name: 'statusCode',
      label: ct.$t('status-code.label'),
      show: (values) => values.redirectState,
      props: {
        options: [
          { label: ct.$t('status-code.options.permanent'), value: CodexUrlStatusCode.Permanent },
          { label: ct.$t('status-code.options.temporary'), value: CodexUrlStatusCode.Temporary },
          { label: ct.$t('status-code.options.gone'), value: CodexUrlStatusCode.Gone }
        ]
      }
    }
  }
}

/**
 * Create url form
 */
const CreateUrlForm = (): FormField[] => {
  const fields = UrlFormsFields()

  return [
    {
      type: 'Row',
      children: [
        // Url
        {
          ...fields.url,
          layout: { col: 12 }
        },
        // Entity type
        {
          ...fields.entityType,
          layout: { col: 12 }
        },
        // Redirect State
        {
          ...fields.redirectState,
          layout: { col: 12 }
        },
        // Entity id
        {
          ...fields.entityId,
          layout: { col: 12 }
        },

        // Status code
        {
          ...fields.statusCode,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

const UpdateUrlForm = (): FormField[] => {
  const fields = UrlFormsFields()

  return [
    {
      type: 'Row',
      children: [
        // Url
        {
          ...fields.url,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

export { CreateUrlForm, UpdateUrlForm }
