import { graphql } from '@/gql'

export const ORGANIZATIONS = graphql(/* GraphQL */ `
  query Organizations {
    organizationCollection {
      items {
        id
        alias
        name
        description
        logo
      }
    }
  }
`)

export const ORGANIZATION = graphql(/* GraphQL */ `
  query Organization {
    organization {
      id
      alias
      name
      logo
      languageCode
      storage {
        id
        cdnDomain
      }
      integrations {
        items {
          id
          alias
          attrs
          status
          createdAt
          updatedAt
        }
      }
      sites(limit: 1000) {
        items {
          id
          name
          alias
          description
          logo
          domain
          previewDomain
        }
      }
      models(limit: 1000) {
        items {
          id
        }
      }
      views(limit: 1000) {
        items {
          id
          name
          type
          filters
          searchTerm
          siteId
          path
          customizations
          teams {
            items {
              members {
                items {
                  id
                }
              }
            }
          }
        }
      }
      organizationPlugins {
        items {
          plugin {
            id
            name
            activeVersion {
              id
              status
              version
            }
          }
        }
      }
    }
  }
`)

export const LIST_MODELS = graphql(/* GraphQL */ `
  query AllModels {
    data: modelCollection(
      limit: 1000
      offset: 0
      where: { status: { in: [PUBLISHED] } }
      order: { createdAt: DESC }
    ) {
      items {
        id
        name
        alias
        iconId
        createdAt
        configurations {
          hideInCreateButton
          hideInCloneButton
        }
        fields {
          type
          valueType
          alias
          name
          configuration {
            filterable
            searchable
          }
          validation
        }
      }
    }
  }
`)
