<script setup lang="ts">
import { computed, inject, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { someParentHasClass } from '@/modules/@global/components/Filters/helpers'
import { createPopper } from '@popperjs/core'
import { throttle } from 'lodash'
import type { Filter } from '@/modules/@global/components/Filters/Filters'

defineOptions({
	name: 'ActiveFilters'
})
const editFilter = inject<Function>('editFilter')!
const activeFilter = inject<any>('activeFilter')
const filtersObject = inject<any>('filtersObject')

const notFittingFiltersPopper = ref(null)
const showNotFittingFiltersPopper = ref(false)
const filtersNotFitInContainer = ref([])
const resizeObserver = ref(null)
const refs = ref({})
const actionsContainerRef = ref(null)
const location = ref('active-filters-dropdown')
const activeFilters = computed(() => {
	// Get alwaysShow filters and put them first
	const alwaysShowFilters = filtersObject
		.getAlwaysShowFilters()
		.map((filter: Filter) => filter.name)
	let activeFilters = Object.keys(filtersObject.activeFilters)

	// Remove alwaysShow filters from activeFilters using .filter
	activeFilters = activeFilters.filter((filter) => !alwaysShowFilters.includes(filter))

	return activeFilters.concat(alwaysShowFilters)
})
const filtersContainerRef = ref()
const filtersSpacerRef = ref()
const notFittingFiltersButton = ref()
const notFittingFiltersDropdown = ref()

/**
 * Check document click to hide poppers
 */
const documentClick = (e: any) => {
	const filtersModalElement = someParentHasClass(filtersContainerRef, 'modal')
	const filtersSidebarElement = someParentHasClass(filtersContainerRef, 'b-sidebar')
	const targetModalElement = someParentHasClass(e.target, 'modal')
	const targetSidebarElement = someParentHasClass(e.target, 'b-sidebar')

	if (
		!someParentHasClass(e.target, 'new-filters-dropdown') &&
		!someParentHasClass(e.target, 'new-filters-dropdown__filter') &&
		(!targetModalElement || targetModalElement === filtersModalElement) &&
		(!targetSidebarElement || targetSidebarElement === filtersSidebarElement)
	) {
		showNotFittingFiltersPopper.value = false
	}
}

const toggleNotFittingFiltersPopper = () => {
	showNotFittingFiltersPopper.value = !showNotFittingFiltersPopper.value
	notFittingFiltersPopper.value.update()
}
/**
 * Check which filters don't fit in the container
 */
const checkForNotFittingFilters = () => {
	const filtersSpacer = filtersSpacerRef
	const filtersContainer = filtersContainerRef
	const actionsContainer = actionsContainerRef

	if (!filtersContainer.value) return

	filtersSpacer.value.style.display = 'block'

	try {
		const filtersContainerWidth = filtersContainer.value.offsetWidth
		const actionsContainerWidth = actionsContainer.value.offsetWidth + 30
		const notFitting = []
		let filtersWidth = actionsContainerWidth

		// Loop in reverse using for loop
		for (let i = activeFilters.value.length - 1; i >= 0; i--) {
			const filter = activeFilters.value[i]

			const filterEl = Array.isArray(refs.value[filter])
				? refs.value[filter][0]
				: refs.value[filter]

			if (!filterEl) return

			filtersWidth += filterEl.offsetWidth + 8

			if (filtersWidth > filtersContainerWidth) {
				notFitting.push(filter)
			}
		}

		filtersNotFitInContainer.value = notFitting
	} catch (e) {
		console.log(e)
	} finally {
		filtersSpacer.value.style.display = 'none'
	}
}

/**
 * Check if filter fits in the container
 */
const filterFitsInContainer = (filterName: string) => {
	return !filtersNotFitInContainer.value.includes(filterName)
}

/**
 * Check if filter is active
 */
const isActiveFilter = (filterName: string) => {
	return filtersObject.isFilterActive(filterName)
}

/**
 * Get filter
 */
const getFilter = (filterName: string) => {
	return filtersObject.getFilter(filterName)
}

/**
 * Get active filter badge label
 */
const getActiveFilterBadgeLabel = (filterName: string) => {
	return filtersObject.getActiveFilterBadgeLabel(filterName)
}

/**
 * Edit filter
 */
const editFilterin = (filterName: string, refName: string, placement = 'bottom-start') => {
	const filter = getFilter(filterName)

	if (!refName) {
		refName = filter.name
	}

	const filterEl = Array.isArray(refs.value[refName]) ? refs.value[refName][0] : refs.value[refName]
	editFilter(filterEl, filter, { placement }, location)
}

onMounted(() => {
	notFittingFiltersPopper.value = createPopper(
		notFittingFiltersButton.value,
		notFittingFiltersDropdown.value,
		{
			placement: 'bottom-start',
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: [0, 4]
					}
				},
				{
					name: 'preventOverflow',
					options: {
						boundary: 'viewport',
						mainAxis: false // true by default
					}
				}
			]
		}
	)

	checkForNotFittingFilters()

	const filtersContainer = refs.value.filtersContainer
	if (!filtersContainer) return

	resizeObserver.value = new ResizeObserver(
		throttle(() => {
			checkForNotFittingFilters()
			notFittingFiltersPopper.value.update()
		}, 100)
	)

	resizeObserver.value.observe(filtersContainer)
	resizeObserver.value.observe(refs.value.actionsContainer)
	window.addEventListener('resize', checkForNotFittingFilters)

	document.addEventListener('mousedown', documentClick)
})

onBeforeUnmount(() => {
	const filtersContainer = refs.value.filtersContainer
	if (!filtersContainer) return

	resizeObserver.value.unobserve(filtersContainer)
	window.removeEventListener('resize', checkForNotFittingFilters)
	document.removeEventListener('mousedown', documentClick)
})

watch(activeFilters, checkForNotFittingFilters)
</script>

<template>
	<div ref="filtersContainerRef" class="new-filters-dropdown__active-filters-outter">
		<div ref="filtersSpacerRef" class="new-filters-dropdown__active-filters-spacer" />

		<!-- Not fitting filters -->
		<div class="new-filters-dropdown__actions">
			<div ref="actionsContainerRef" class="new-filters-dropdown__actions-inner">
				<div v-show="filtersNotFitInContainer.length" ref="notFittingFiltersButton"
					class="new-filters-dropdown__toggle-not-fitting" :class="{
						'new-filters-dropdown__toggle-not-fitting--active': showNotFittingFiltersPopper
					}" @click.stop="toggleNotFittingFiltersPopper">
					{{ filtersNotFitInContainer.length }}+
				</div>
			</div>
		</div>

		<div v-show="filtersNotFitInContainer.length && showNotFittingFiltersPopper" ref="notFittingFiltersDropdown"
			class="new-filters-dropdown">
			<div class="new-filters-dropdown__body">
				<div class="new-filters-dropdown__items">
					<div v-for="filterName in filtersNotFitInContainer" :key="filterName" :ref="(el) => {
						refs[`${filterName}-not-fitting`] = el
					}
						" class="new-filters-dropdown__item" :class="{
						'new-filters-dropdown__item--active':
							activeFilter.filter?.name === filterName && activeFilter.location === location
					}" @click="editFilterin(filterName, `${filterName}-not-fitting`, 'left-start')">
						<!-- If the filter has more than one value, show the count -->
						<template v-if="getActiveFilterBadgeLabel(filterName).count > 1">
							<span class="new-filters-dropdown__active-filter-label">
								{{ getActiveFilterBadgeLabel(filterName).label }}:
							</span>

							<span class="new-filters-dropdown__active-filter-value">
								{{ getActiveFilterBadgeLabel(filterName).count }} values selected
							</span>
						</template>

						<!-- If the filter has only one value, show the value -->
						<template v-else>
							<span class="new-filters-dropdown__active-filter-label">
								{{ getActiveFilterBadgeLabel(filterName).label }}:
							</span>

							<span class="new-filters-dropdown__active-filter-value">
								{{ getActiveFilterBadgeLabel(filterName).value }}
							</span>
						</template>

						<!-- Caret -->
						<span class="new-filters-dropdown__active-filter-caret">
							<GjIcon name="ArrowDown" size="20" />
						</span>
					</div>
				</div>
			</div>
		</div>

		<div class="new-filters-dropdown__active-filters">
			<!-- Filter badges -->
			<div v-for="filterName in activeFilters" :key="filterName" :ref="(el) => {
						refs[`${filterName}`] = el
					}
						" class="new-filters-dropdown__active-filter" :class="{
						'new-filters-dropdown__active-filter--open':
							activeFilter.filter?.name === filterName && activeFilter.location === location,
						'new-filters-dropdown__active-filter--active': isActiveFilter(filterName),
						'new-filters-dropdown__active-filter--inactive': !isActiveFilter(filterName),
						'new-filters-dropdown__active-filter--not-fitting': !filterFitsInContainer(filterName)
					}" @click="editFilterin(filterName, filterName)">

				<!-- If filter is not active, only show the label -->
				<template v-if="!isActiveFilter(filterName)">
					<span class="new-filters-dropdown__active-filter-label">
						{{ getActiveFilterBadgeLabel(filterName).label }}
					</span>
				</template>

				<!-- If the filter has more than one value, show the count -->
				<template v-else-if="getActiveFilterBadgeLabel(filterName).count > 1">
					<span class="new-filters-dropdown__active-filter-value">
						{{ getActiveFilterBadgeLabel(filterName).label }} ({{
						getActiveFilterBadgeLabel(filterName).count
					}})
					</span>
				</template>

				<!-- If the filter has only one value, show the value -->
				<template v-else>
					<span class="new-filters-dropdown__active-filter-label">
						{{ getActiveFilterBadgeLabel(filterName).label }}:
					</span>

					<span class="new-filters-dropdown__active-filter-value">
						{{ getActiveFilterBadgeLabel(filterName).value }}
					</span>
				</template>

				<!-- Caret -->
				<span class="new-filters-dropdown__active-filter-caret">
					<GjIcon name="ArrowDown" size="20" />
				</span>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.new-filters-dropdown__actions {
	flex: 1;
	display: flex;
	justify-content: flex-end;
}

.new-filters-dropdown__active-filters-spacer {
	width: 1300px;
	display: none;
}

.new-filters-dropdown__active-filters-outter {
	display: flex;
	gap: 8px;
	min-width: 0;
}

.new-filters-dropdown__toggle-not-fitting {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 28px;
	padding: 0 8px;
	border: 1px solid #1d79f2;
	background: white;
	border-radius: 4px;

	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	color: #206ed4;

	cursor: pointer;

	&:hover,
	&.new-filters-dropdown__toggle-not-fitting--active {
		background: #ecf3ff;
	}
}

.new-filters-dropdown__active-filters {
	display: flex;
	justify-content: flex-end;
	gap: 8px;
	min-width: 0;
}

.new-filters-dropdown__active-filter {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 4px 4px 4px 8px;
	height: 28px;
	border: 1px solid #1d79f2;
	background: white;
	border-radius: 4px;

	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #667c99;

	cursor: pointer;

	&:hover,
	&.new-filters-dropdown__active-filter--open {
		background: #ecf3ff;
	}
}

.new-filters-dropdown__active-filter--inactive {
	background: #f4f6fa;
	color: #052d61;
	border: 1px solid #edeff3;

	&:hover,
	&.new-filters-dropdown__active-filter--open {
		background: #edeff3;
	}

	.new-filters-dropdown__active-filter-caret {
		color: #052d61;
	}
}

.new-filters-dropdown__active-filter--not-fitting {
	position: absolute;
	left: -9999px;
}

.new-filters-dropdown__active-filter-label {
	white-space: nowrap;
}

.new-filters-dropdown__active-filter-value {
	color: #206ed4;
	font-weight: bold;
	margin-left: 5px;
	text-transform: capitalize;
	flex-grow: 1;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.new-filters-dropdown .new-filters-dropdown__active-filter-value {
	max-width: unset;
	margin-right: 10px;
}

.new-filters-dropdown__active-filter-caret {
	color: #206ed4;

	svg {
		vertical-align: middle;
	}
}

.new-filters-dropdown .new-filters-dropdown__active-filter-caret {
	transform: rotate(-90deg);
}
</style>
