<script setup lang="ts">
import { inject } from 'vue'
import { useI18nStore } from '@/@core/i18n'
import { computed, ref } from 'vue'
import type { Context } from '@/modules/@global/composables/useContext'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.entries.popups.select-columns')

/**
 * Dialog
 */
let dialog: any = inject(DialogRefKey)

const columns = computed(() => {
  return dialog?.value?.data?.allColumns ?? []
})

const customization = ref(
  columns.value.filter((item: any) => !item.hidden).sort((a: any, b: any) => a.order - b.order)
)

const columnsMenuOptions = computed(() => {
  return columns.value
    .filter((option: any) => !!option.hidden)
    .map((item: any) => ({
      ...item,
      label: item.header,
      command: () => {
        item.hidden = false
        item.order = customization.value.length
        Object.assign(
          customization.value,
          columns.value
            .filter((item: any) => !item.hidden)
            .sort((a: any, b: any) => a.order - b.order)
        )
      }
    }))
})

const columnsMenu = ref()
function toggleColumnsMenu(event: MouseEvent) {
  columnsMenu.value.toggle(event)
}

async function submitHandler(save: any) {
  dialog?.value?.close(save ? customization.value.map((x: any) => x.field) : [])
}

function removeColumn(item: any) {
  item.hidden = true
  Object.assign(
    customization.value,
    columns.value.filter((item: any) => !item.hidden).sort((a: any, b: any) => a.order - b.order)
  )
}
</script>

<template>
  <SidebarContent :title="ct.$t('title')">
    <Row align-items="center">
      <Col>
        <Draggable :list="customization" :group="group" direction="vertical" :swap-threshold="0.5">
          <template #item="{ element }">
            <div
              class="customization-row handle"
              v-if="!element.hidden && element.field !== 'actions'"
            >
              <GjIcon name="Drag" size="24" />
              <Text is="label" :for="element.field" style="display: flex; gap: 4px">
                <Text is="span" :text="element.header" style="font-size: 18px" />
              </Text>
              <Text
                is="span"
                v-if="!element.disabled"
                style="
                  cursor: pointer;
                  margin-left: auto;
                  margin-top: auto;
                  display: flex;
                  margin-bottom: auto;
                "
              >
                <GjIcon name="Close" size="24" @click="removeColumn(element)" />
              </Text>
            </div>
          </template>
        </Draggable>
      </Col>
    </Row>
    <Row>
      <Col>
        <Button
          :disabled="!columnsMenuOptions.length"
          icon="Plus"
          severity="primary"
          :label="ct.$t('add-column')"
          @click="toggleColumnsMenu"
          aria-haspopup="true"
          aria-controls="overlay_tmenu"
        />
        <TieredMenu ref="columnsMenu" id="overlay_tmenu" :model="columnsMenuOptions" popup />
      </Col>
    </Row>
    <template #footer>
      <Button :label="ct.$t('discard')" icon="Close" @click="submitHandler(false)" text></Button>
      <Button :label="ct.$t('save')" icon="Save" @click="submitHandler(true)"></Button>
    </template>
  </SidebarContent>
</template>

<style scoped lang="scss">
.customization-row {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #5892e0;
  border-radius: 0.7rem;
  margin: 8px;
  padding: 8px;
  -webkit-box-shadow: -2px 0 12px #e0e5eb;
  box-shadow: -2px 0 12px #e0e5eb;
}
</style>
