<script setup lang="ts">
import PrimeInlineMessage from 'primevue/inlinemessage'
import Text from '@/modules/@global/components/Text/Text.vue'

const props = withDefaults(
  defineProps<{
    severity?: 'success' | 'info' | 'warn' | 'error' | ''
    text?: string | null
    plainText?: boolean
    title?: string | null
    icon?: string | null
    block?: boolean | null
  }>(),
  {
    severity: '',
    icon: '',
    plainText: false,
    text: '',
    block: false
  }
)

// write a function that returns the icon name based on the severity prop
const getIcon = (icon: string | null) => {
  if (icon) {
    return icon
  }
  switch (props.severity) {
    case 'success':
      return props.plainText ? 'Checkmark' : 'Checkmark_fill'
    case 'info':
      return props.plainText ? 'Info' : 'Info_fill'
    case 'warn':
      return props.plainText ? 'InfoAlt' : 'AlertTriangleFilled'
    case 'error':
      return props.plainText ? 'Warning' : 'InfoAlt_fill'
    default:
      return props.plainText ? 'Info' : 'Info'
  }
}
</script>

<template>
  <PrimeInlineMessage
    :class="{
      'p-inline-message--plain-text': plainText,
      'p-inline-message--block': block
    }"
    :severity="severity"
  >
    <template #icon>
      <span class="p-inline-message__icon">
        <GjIcon :name="getIcon(icon)" :size="plainText ? '16' : '24'" />
      </span>
    </template>
    <slot>
      <Text class="p-inline-message__title" v-if="title" type="body-medium-base">{{ title }}</Text>
      <Text class="p-inline-message__text" type="body-regular-small">{{ text }}</Text>
    </slot>
  </PrimeInlineMessage>
</template>
