import { CodexHooks } from '@/@core'
import SiteListFilter from './sites/SiteListFilter'

CodexHooks.addFilter('filters/types', (items) => {
	return [
		...items,
		{
      type: 'site-list',
      component: SiteListFilter
    }
	]
})