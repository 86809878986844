import { CodexHooks } from '@/@core'
import { isString } from 'lodash'
import { LABEL_ENTITY_MAPPING } from '@/modules/labels/constants'

CodexHooks.addFilter(
  'apollo/restFetchOptions?type=CodexLabel',
  (options, context, uri, headers) => {
    if (isString(options.body)) {
      try {
        const bodyParsed = JSON.parse(options.body)

        if (bodyParsed.entities) {
          bodyParsed.entities = bodyParsed.entities.map((e) => LABEL_ENTITY_MAPPING.toNumber(e))
        }
        options.body = JSON.stringify(bodyParsed)
      } catch (e) {
        // Do nothing
      }
    }

    return options
  }
)

CodexHooks.addFilter('apollo/responseTransformer?type=CodexLabel', (response) => {
  if (response?.entities) {
    response.entities = response.entities.map((e) => LABEL_ENTITY_MAPPING.toString(e))
  }
  return response
})
