import type { DebugStyleObject, DebugStyles, DebugTypes } from './types/index'

class CodexDebug {
  /** Debug Styles **/
  static debugStyles: DebugStyles = {
    log: { name: 'log', style: 'color: #666; font-weight: normal;' },
    info: { name: 'info', style: 'color: #147efb; font-weight: bold;' },
    warn: { name: 'warn', style: 'color: #f6a623; font-weight: bold;' },
    error: { name: 'error', style: 'color: #ef3b3b; font-weight: bold;' },
    success: {
      name: 'success',
      style:
        'color: #1db954; font-weight: bold; background-color: rgba(29, 185, 84, 0.1); padding: 2px 4px;'
    },
    debug: { name: 'debug', style: 'color: #9b51e0; font-weight: bold; font-style: italic;' },
    trace: { name: 'trace', style: 'color: #666; font-weight: normal; font-size: 0.9em;' }
  }

  /** Debug Mode **/
  static _debug: boolean = true

  /** Trace Mode **/
  static _trace: boolean = true

  /** Current Style **/
  static currentStyle: DebugStyleObject | null = null

  /** Always show errors */
  static alwaysShowErrors: boolean = true

  /** Log Types **/
  static TYPE: DebugTypes = {
    LOG: '📝.LOG',
    AUTH: '🔑.AUTH',
    I18N: '🌍.I18N',
    APOLLO: '🚀.APOLLO',
    NAVIGATION: '🧭.NAVIGATION',
    ROUTER: '🔀.ROUTER',
    HOOKS: '🎣.HOOKS',
    ABILITY: '🔓.ABILITY'
  }

  /** Enabled Log Types **/
  static enabledLogTypes = {
    [this.TYPE.LOG]: false,
    [this.TYPE.AUTH]: false,
    [this.TYPE.I18N]: false,
    [this.TYPE.APOLLO]: false,
    [this.TYPE.NAVIGATION]: false,
    [this.TYPE.ROUTER]: false,
    [this.TYPE.HOOKS]: false,
    [this.TYPE.ABILITY]: false
  }

  /** Enabled getters **/
  static get debugEnabled() {
    return this._debug
  }
  static get logEnabled() {
    return this.enabledLogTypes[this.TYPE.LOG]
  }
  static get authEnabled() {
    return this.enabledLogTypes[this.TYPE.AUTH]
  }
  static get i18nEnabled() {
    return this.enabledLogTypes[this.TYPE.I18N]
  }

  /** Log **/
  static log(...args: any[]) {
    this.styleLog(this.TYPE.LOG, this.debugStyles.log, ...args)
  }
  static info(...args: any[]) {
    this.styleLog(this.TYPE.LOG, this.debugStyles.info, ...args)
  }
  static warn(...args: any[]) {
    this.styleLog(this.TYPE.LOG, this.debugStyles.warn, ...args)
  }
  static error(...args: any[]) {
    this.styleLog(this.TYPE.LOG, this.debugStyles.error, ...args)
  }
  static success(...args: any[]) {
    this.styleLog(this.TYPE.LOG, this.debugStyles.success, ...args)
  }
  static debug(...args: any[]) {
    this.styleLog(this.TYPE.LOG, this.debugStyles.debug, ...args)
  }
  static trace(...args: any[]) {
    this.styleLog(this.TYPE.LOG, this.debugStyles.trace, ...args)
  }

  /** Auth **/
  static authLog(...args: any[]) {
    this.styleLog(this.TYPE.AUTH, this.debugStyles.log, ...args)
  }
  static authInfo(...args: any[]) {
    this.styleLog(this.TYPE.AUTH, this.debugStyles.info, ...args)
  }
  static authWarn(...args: any[]) {
    this.styleLog(this.TYPE.AUTH, this.debugStyles.warn, ...args)
  }
  static authError(...args: any[]) {
    this.styleLog(this.TYPE.AUTH, this.debugStyles.error, ...args)
  }
  static authSuccess(...args: any[]) {
    this.styleLog(this.TYPE.AUTH, this.debugStyles.success, ...args)
  }
  static authDebug(...args: any[]) {
    this.styleLog(this.TYPE.AUTH, this.debugStyles.debug, ...args)
  }
  static authTrace(...args: any[]) {
    this.styleLog(this.TYPE.AUTH, this.debugStyles.trace, ...args)
  }

  /** i18n **/
  static i18nLog(...args: any[]) {
    this.styleLog(this.TYPE.I18N, this.debugStyles.log, ...args)
  }
  static i18nInfo(...args: any[]) {
    this.styleLog(this.TYPE.I18N, this.debugStyles.info, ...args)
  }
  static i18nWarn(...args: any[]) {
    this.styleLog(this.TYPE.I18N, this.debugStyles.warn, ...args)
  }
  static i18nError(...args: any[]) {
    this.styleLog(this.TYPE.I18N, this.debugStyles.error, ...args)
  }
  static i18nSuccess(...args: any[]) {
    this.styleLog(this.TYPE.I18N, this.debugStyles.success, ...args)
  }
  static i18nDebug(...args: any[]) {
    this.styleLog(this.TYPE.I18N, this.debugStyles.debug, ...args)
  }
  static i18nTrace(...args: any[]) {
    this.styleLog(this.TYPE.I18N, this.debugStyles.trace, ...args)
  }

  /** Apollo **/
  static apolloLog(...args: any[]) {
    this.styleLog(this.TYPE.APOLLO, this.debugStyles.log, ...args)
  }
  static apolloInfo(...args: any[]) {
    this.styleLog(this.TYPE.APOLLO, this.debugStyles.info, ...args)
  }
  static apolloWarn(...args: any[]) {
    this.styleLog(this.TYPE.APOLLO, this.debugStyles.warn, ...args)
  }
  static apolloError(...args: any[]) {
    this.styleLog(this.TYPE.APOLLO, this.debugStyles.error, ...args)
  }
  static apolloSuccess(...args: any[]) {
    this.styleLog(this.TYPE.APOLLO, this.debugStyles.success, ...args)
  }
  static apolloDebug(...args: any[]) {
    this.styleLog(this.TYPE.APOLLO, this.debugStyles.debug, ...args)
  }
  static apolloTrace(...args: any[]) {
    this.styleLog(this.TYPE.APOLLO, this.debugStyles.trace, ...args)
  }

  /** Navigation **/
  static navigationLog(...args: any[]) {
    this.styleLog(this.TYPE.NAVIGATION, this.debugStyles.log, ...args)
  }
  static navigationInfo(...args: any[]) {
    this.styleLog(this.TYPE.NAVIGATION, this.debugStyles.info, ...args)
  }
  static navigationWarn(...args: any[]) {
    this.styleLog(this.TYPE.NAVIGATION, this.debugStyles.warn, ...args)
  }
  static navigationError(...args: any[]) {
    this.styleLog(this.TYPE.NAVIGATION, this.debugStyles.error, ...args)
  }
  static navigationSuccess(...args: any[]) {
    this.styleLog(this.TYPE.NAVIGATION, this.debugStyles.success, ...args)
  }
  static navigationDebug(...args: any[]) {
    this.styleLog(this.TYPE.NAVIGATION, this.debugStyles.debug, ...args)
  }
  static navigationTrace(...args: any[]) {
    this.styleLog(this.TYPE.NAVIGATION, this.debugStyles.trace, ...args)
  }

  /** Router **/
  static routerLog(...args: any[]) {
    this.styleLog(this.TYPE.ROUTER, this.debugStyles.log, ...args)
  }
  static routerInfo(...args: any[]) {
    this.styleLog(this.TYPE.ROUTER, this.debugStyles.info, ...args)
  }
  static routerWarn(...args: any[]) {
    this.styleLog(this.TYPE.ROUTER, this.debugStyles.warn, ...args)
  }
  static routerError(...args: any[]) {
    this.styleLog(this.TYPE.ROUTER, this.debugStyles.error, ...args)
  }
  static routerSuccess(...args: any[]) {
    this.styleLog(this.TYPE.ROUTER, this.debugStyles.success, ...args)
  }
  static routerDebug(...args: any[]) {
    this.styleLog(this.TYPE.ROUTER, this.debugStyles.debug, ...args)
  }
  static routerTrace(...args: any[]) {
    this.styleLog(this.TYPE.ROUTER, this.debugStyles.trace, ...args)
  }

  /** Hooks **/
  static hooksLog(...args: any[]) {
    this.styleLog(this.TYPE.HOOKS, this.debugStyles.log, ...args)
  }
  static hooksInfo(...args: any[]) {
    this.styleLog(this.TYPE.HOOKS, this.debugStyles.info, ...args)
  }
  static hooksWarn(...args: any[]) {
    this.styleLog(this.TYPE.HOOKS, this.debugStyles.warn, ...args)
  }
  static hooksError(...args: any[]) {
    this.styleLog(this.TYPE.HOOKS, this.debugStyles.error, ...args)
  }
  static hooksSuccess(...args: any[]) {
    this.styleLog(this.TYPE.HOOKS, this.debugStyles.success, ...args)
  }
  static hooksDebug(...args: any[]) {
    this.styleLog(this.TYPE.HOOKS, this.debugStyles.debug, ...args)
  }
  static hooksTrace(...args: any[]) {
    this.styleLog(this.TYPE.HOOKS, this.debugStyles.trace, ...args)
  }

  /** Ability **/
  static abilityLog(...args: any[]) {
    this.styleLog(this.TYPE.ABILITY, this.debugStyles.log, ...args)
  }
  static abilityInfo(...args: any[]) {
    this.styleLog(this.TYPE.ABILITY, this.debugStyles.info, ...args)
  }
  static abilityWarn(...args: any[]) {
    this.styleLog(this.TYPE.ABILITY, this.debugStyles.warn, ...args)
  }
  static abilityError(...args: any[]) {
    this.styleLog(this.TYPE.ABILITY, this.debugStyles.error, ...args)
  }
  static abilitySuccess(...args: any[]) {
    this.styleLog(this.TYPE.ABILITY, this.debugStyles.success, ...args)
  }
  static abilityDebug(...args: any[]) {
    this.styleLog(this.TYPE.ABILITY, this.debugStyles.debug, ...args)
  }
  static abilityTrace(...args: any[]) {
    this.styleLog(this.TYPE.ABILITY, this.debugStyles.trace, ...args)
  }

  /** Style Log **/
  static styleLog(type: string, style: DebugStyleObject | null, ...args: any[]) {
    if (!style) {
      style = this.debugStyles.log
    }

    this.currentStyle = style
    this._log(type, ...args)
    this.currentStyle = null
  }

  /** Log **/
  static _log(type: string, ...args: any[]) {
    type = type || this.TYPE.LOG

    if (
      (this._debug && (this.enabledLogTypes[type] || this.enabledLogTypes[type] === undefined)) ||
      (this.alwaysShowErrors && this.currentStyle === this.debugStyles.error)
    ) {
      // Style Log
      if (this.currentStyle) {
        const message = [`${this.currentStyle.name} | ${type} |`, ...args]
          .filter((arg) => typeof arg !== 'object')
          .map((arg) => String(arg))
          .join(' ')

        const styledMessage = `%c${message}`

        // Log the message with applied style, followed by any objects in the args
        if (!this._trace) {
          console.log(
            styledMessage,
            this.currentStyle.style,
            ...args.filter((arg) => typeof arg === 'object')
          )
        } else {
          console.groupCollapsed(
            styledMessage,
            this.currentStyle.style,
            ...args.filter((arg) => typeof arg === 'object')
          )

          console.trace()

          console.groupEnd()
        }

        return
      }

      // Unstyled Log
      if (!this._trace) {
        console.log(`${type} |`, ...args)
      } else {
        console.groupCollapsed(`${type} |`, ...args)

        console.trace()

        console.groupEnd()
      }
    }
  }
}

// @ts-ignore
window.CodexDebug = CodexDebug

export default CodexDebug
