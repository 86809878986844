import {
  WIDGET_GROUPS,
  FIELD_FILTER_OPERATORS,
  RANGE_OPERATORS
} from '@/modules/models/editor-ts/fields/constants'
import { fields } from '@/modules/@global/models/_exports'
import { CodexHooks } from '@/@core'

CodexHooks.addFilter('fields/types', (items) => {
  return [
    ...items,
    {
      pluginName: 'codex',
      displayName: 'Author',
      name: 'field_authors',
      render: () => import('./Render.vue'),
      renderEditor: () => import('./Render.vue'),
      renderEditorPanel: [
        {
          icon: 'Settings',
          label: 'General',
          component: () => import('./General.vue'),
          isGeneral: true
        },
        {
          icon: 'Brush',
          label: 'Appearance',
          component: () => import('./Appearance.vue')
        },
        {
          icon: 'SettingsPreferencesTools',
          label: 'Configurations',
          component: () => import('./Configuration.vue')
        }
      ],
      renderEditorInitialPanel: [
        {
          icon: 'Settings',
          label: 'Initial',
          component: () => import('./Initial.vue')
        }
      ],
      group: WIDGET_GROUPS.CODEX,
      icon: () => import('./icon.svg'),

      type: fields.type.AUTHOR,
      valueType: fields.valueType.SINGLE,

      attrs: {
        appearance: {
          default: 1
        },
        helpText: {
          default: ''
        },
        helpTextDisplay: {
          default: 'tooltip'
        },
        validation: {
          default: {
            required: {
              value: false,
              errorMessage: 'Author Field Required.',
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: false,
                  field: '',
                  operator: FIELD_FILTER_OPERATORS.EXISTS,
                  value: ''
                }
              ]
            },
            readOnly: {
              value: false,
              conditionsEnabled: false,
              conditions: [
                {
                  isSystem: false,
                  field: '',
                  operator: FIELD_FILTER_OPERATORS.EXISTS,
                  value: ''
                }
              ]
            },
            numberOfProperties: {
              isEnabled: false,
              rangeOperator: RANGE_OPERATORS.BETWEEN,
              min: 1,
              max: 2,
              exactly: 0,
              errorMessage: 'Number of Authors should contains 1-2',
              errorTemplate: 'Number of Authors should contains {min}-{max}'
            },
            invalidAuth: 'Invalid Author Input'
          }
        },
        configuration: {
          default: {
            searchable: true,
            filterable: true
          }
        },
        hidden: {
          default: {
            value: false,
            conditionsEnabled: false,
            conditions: [
              {
                isSystem: false,
                field: '',
                operator: FIELD_FILTER_OPERATORS.EXISTS,
                value: ''
              }
            ]
          }
        }
      }
    }
  ]
})
