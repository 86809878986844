<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable'
import { READ_AUTHOR, UPDATE_AUTHOR } from '@/modules/authors/queries/index.rest'
import { inject } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { cloneDeep } from 'lodash'
import { UpdateAuthorForm } from '@/modules/authors/forms/Authors'
import FormSkeleton from '@/modules/@global/components/Skeletons/FormSkeleton.vue'
import { useI18nStore } from '@/@core/i18n'
import type { Context } from '@/modules/@global/composables/useContext'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.authors.popups.update-author')

/**
 * Dialog
 */
let dialog = inject(DialogRefKey)

/**
 * Read author
 */
const { loading, result, error } = useQuery(
  READ_AUTHOR,
  () => ({
    id: dialog?.value?.data?.id
  }),
  {
    fetchPolicy: 'network-only'
  }
)

/**
 * Update author
 */
const updateAuthor = useMutation(UPDATE_AUTHOR)

async function updateAuthorHandler(values) {
  dialog?.value?.close(values)
}
</script>

<template>
  <SidebarContent :title="ct.$t('title')">
    <template v-if="loading">
      <FormSkeleton />
    </template>

    <template v-else-if="error">
      <InlineMessage severity="error" :text="error"></InlineMessage>
    </template>

    <template v-else>
      <DynamicForm
        name="update-author"
        :ref="(r:any) => context?.setRef('form', r)"
        :show-submit="false"
        :schema="UpdateAuthorForm()"
        :data="cloneDeep(result?.author)"
        :mutation="updateAuthor"
        :labels="{
          submit: ct.$t('submit')
        }"
        @submit="updateAuthorHandler"
      ></DynamicForm>
    </template>

    <template #footer>
      <Button
        v-if="!loading && !error"
        v-bind="context.getRef('form')?.props?.submit.value"
      ></Button>
    </template>
  </SidebarContent>
</template>
