import { ref, onUnmounted } from 'vue'
// import { updateAssetsFolder, updateFolder } from '@/codex-sdk/folders'

import { MOVE_ASSET } from '../queries/index.rest'
import { useMutation } from '@vue/apollo-composable'

export function useDragAndDrop() {
  const cursorPosition = ref<number | null>(null)
  const intervalId = ref<number | null>(null)
  const draggingAssets = ref(false)
  const dragOverElement = ref<HTMLElement | null>(null)
  const ghostEl = ref<HTMLElement | null>(null)
  const assetBeingEdited = ref(null)
  const folderBeingDragged = ref<string | null>(null)

  const moveAsset = useMutation(MOVE_ASSET, {
    refetchQueries: ['ListAssets']
  })

  function removeDragAsset() {
    clearInterval(intervalId.value!)
    intervalId.value = null
    draggingAssets.value = false
  }

  function checkScrollBoundaries() {
    const threshold = 200
    const maxSpeed = 100
    if (cursorPosition.value !== null) {
      if (cursorPosition.value <= threshold) {
        const mouseSpeed = (1 - cursorPosition.value / threshold) * maxSpeed
        window.scrollBy(0, -mouseSpeed)
      } else if (cursorPosition.value >= window.innerHeight - threshold) {
        const mouseSpeed = (1 - (window.innerHeight - cursorPosition.value) / threshold) * maxSpeed
        window.scrollBy(0, mouseSpeed)
      }
    }
  }

  function handleAssetDragstart(event: DragEvent, file: any, checked: boolean, selectedData: any) {
    if (selectedData?.show) return

    let dragging = []
    draggingAssets.value = true
    intervalId.value = window.setInterval(checkScrollBoundaries, 50)

    if (Object.keys(file).includes('parentId')) {
      dragging.push({
        type: 'folder',
        id: file.id,
        name: file.name,
        parentId: file.parentId
      })
      folderBeingDragged.value = file.id
    } else if (checked) {
      dragging = selectedData.items.map((asset: any) => ({
        type: 'asset',
        id: asset.id,
        name: asset.title || asset.fileName
      }))
    } else {
      dragging.push({ type: 'asset', id: file.id, name: file.title || file.fileName })
    }

    event.dataTransfer?.setData('text', JSON.stringify(dragging))
    dragAsset(event, dragging)
  }

  function dragAsset(event: DragEvent, dragging: any[]) {
    event.stopPropagation()
    const ghostElValue = document.createElement('p')
    ghostElValue.classList.add('dragging')
    const name = dragging[0]?.name
    if (dragging.length === 1 && name) {
      ghostElValue.textContent = `Dragging ${name}`
    } else {
      ghostElValue.textContent = `Dragging ${dragging.length} item${dragging.length > 1 ? 's' : ''}`
    }
    ghostEl.value = ghostElValue
    document.body.appendChild(ghostElValue)
    event.dataTransfer?.setDragImage(ghostElValue, 0, 0)
  }

  function _preventDefault(event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()
  }

  function getDragFolder(event: DragEvent) {
    let dropFolder = null
    try {
      dropFolder = event.target?.closest('[data-folder]')
    } catch (e) {
      console.error(e)
    }
    return dropFolder
  }

  function handleDragEnter(event: DragEvent, selectedData: any) {
    const dropFolder = getDragFolder(event)
    if (!dropFolder) {
      if (dragOverElement.value) {
        dragOverElement.value.classList.remove('active-folder')
        dragOverElement.value = null
      }
      return
    }

    if (dragOverElement.value && dragOverElement.value !== dropFolder) {
      dragOverElement.value.classList.remove('active-folder')
    }

    dragOverElement.value = dropFolder
    dropFolder.classList.add('active-folder')
  }

  function scrollWhenHover(e: MouseEvent) {
    cursorPosition.value = e.clientY
  }

  async function handleFolderDrop(event: DragEvent, selectedData: any) {
    const dropFolder = getDragFolder(event)
    if (!dropFolder) return

    event.preventDefault()
    dropFolder.classList.remove('active-folder')

    const dropFolderId = dropFolder.dataset.folder?.trim() || null
    const dragging = JSON.parse(event.dataTransfer?.getData('text') || '[]')

    // Move assets
    const assets = dragging.filter((a: any) => a.type === 'asset').map((a: any) => a.id)
    if (assets.length) {
      try {
        // await updateAssetsFolder(dropFolderId, assets)
        await moveAsset.mutate({
          input: {
            id: dropFolderId,
            assets: assets
          }
        })
        // Optionally, trigger a refetch or update UI
      } catch (e) {
        console.error('Error moving assets:', e)
      }
    }

    // Move folders
    const folders = dragging.filter((a: any) => a.type === 'folder' && a.id !== dropFolderId)
    for (const folder of folders) {
      if (folder.parentId !== dropFolderId) {
        try {
          // await updateFolder({ name: folder.name, id: folder.id, parentId: dropFolderId })
          // Optionally, trigger a refetch or update UI
        } catch (e) {
          console.error('Error moving folder:', e)
        }
      }
    }
  }

  onUnmounted(() => {
    removeDragAsset()
  })

  return {
    removeDragAsset,
    handleAssetDragstart,
    dragAsset,
    _preventDefault,
    getDragFolder,
    handleDragEnter,
    scrollWhenHover,
    handleFolderDrop
  }
}
