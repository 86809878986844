<script setup lang="ts">
import { formatDate, type DateFormatKeys } from '@/modules/@global/utils/formatDate'

const props = withDefaults(
	defineProps<{
		date: string | object
		format?: DateFormatKeys
		time?: boolean
	}>(),
	{
		format: 'Full',
		time: false
	}
)
</script>

<template>
	{{ props.date ? formatDate(props.date, props.format, props.time) : '' }}
</template>
