<script setup lang="ts">
import { computed } from 'vue'
import { GenerateUUID } from '@/modules/@global/utils/GenerateUUID'
const props = withDefaults(
  defineProps<{
    size?: 'small' | 'large'
    block?: boolean
    inputPrepend?: string
  }>(),
  {
    size: '',
    block: false,
    inputPrepend: ''
  }
)
const modelValue = defineModel()

const getInputTextClasses = computed(() => {
  return {
    [`p-inputtext-sm`]: props.size === 'small',
    [`p-inputtext-lg`]: props.size === 'large',
    [`p-inputtext-block`]: props.block
  }
})
const id = GenerateUUID.GENERAL()
</script>

<template>
  <div class="custom-input-group">
    <div class="prepend-item">
      <Text is="label" :for="id" :title="props.inputPrepend" :text="props.inputPrepend" />
    </div>
    <InputText :class="getInputTextClasses" v-model="modelValue" :id="id" />
  </div>
</template>

<style lang="scss">
.custom-input-group {
  display: flex;
  align-items: center;

  .p-inputtext {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .prepend-item {
    background-color: #efefef;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0.438rem 1rem;
    border-top: 1px solid #dedee2;
    border-left: 1px solid #dedee2;
    border-bottom: 1px solid #dedee2;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;

    .text {
      max-width: 200px;
      text-overflow: ellipsis;
      line-clamp: 1;
      overflow: hidden;
    }
  }
}
</style>
