import { merge } from 'lodash'

/**
 * Site Name
 */
export const SITES_NAME = 'sites-listing'

/**
 * Site defaults
 */
export function siteDefaults(site = {}) {
  return merge(
    {
      id: null,
      name: null,
      alias: null,
      domain: null,
      description: null,
      logo: null
    },
    site
  )
}
