<script setup lang="ts">
import PrimeVueTieredMenu from 'primevue/tieredmenu'
import { ref } from 'vue';

const el = ref();

function toggle(event: MouseEvent) {
	if (!el.value) return
	el.value.toggle(event);
}

defineExpose({
	toggle
});
</script>

<template>
  <PrimeVueTieredMenu v-bind="$attrs" ref="el">
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>

    <!-- Implement GjIcon -->
    <template #submenuicon="scope" v-if="$attrs.submenuicon">
      <GjIcon v-bind="scope" :name="$attrs.submenuicon" size="20" />
    </template>
    <template #itemicon="scope">
      <GjIcon v-if="scope.item.icon" v-bind="scope" :name="scope.item.icon" size="16" />
    </template>
  </PrimeVueTieredMenu>
</template>
