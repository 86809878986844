export function someParentHasClass(element: any, classname: string): any {

	if (
		element.className &&
		element.className.constructor === String &&
		element.className.split(' ').indexOf(classname) >= 0
	)
		return element
	return element.parentNode && someParentHasClass(element.parentNode, classname)
}
