import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=organization', (items) => {
  return [
    ...items,
    {
      path: 'design-system',
      name: 'design-system',
      component: () => import('@/modules/design-system/pages/DesignSystem.vue')
    }
  ]
})

CodexHooks.addFilter('router/items?parent=design-system', (items) => {
  return [
    ...items,
    {
      path: 'rich-content',
      name: 'ds-rich-content',
      component: () => import('@/modules/design-system/pages/RichContent.vue')
    },
    {
      path: 'alerts',
      name: 'ds-alerts',
      component: () => import('@/modules/design-system/pages/Alerts.vue')
    },
    {
      path: 'buttons',
      name: 'ds-buttons',
      component: () => import('@/modules/design-system/pages/Buttons.vue')
    },
    {
      path: 'chips-listing',
      name: 'ds-chips-listing',
      component: () => import('@/modules/design-system/pages/ChipsListing.vue')
    },
    {
      path: 'data-tables',
      name: 'ds-data-tables',
      component: () => import('@/modules/design-system/pages/DataTables.vue')
    },
    {
      path: 'dialogs',
      name: 'ds-dialogs',
      component: () => import('@/modules/design-system/pages/Dialogs.vue')
    },
    {
      path: 'dynamic-forms',
      name: 'ds-dynamic-forms',
      component: () => import('@/modules/design-system/pages/DynamicForms.vue')
    },
    {
      path: 'dynamic-selects',
      name: 'ds-dynamic-selects',
      component: () => import('@/modules/design-system/pages/DynamicSelects.vue')
    },
    {
      path: 'filters',
      name: 'ds-filters',
      component: () => import('@/modules/design-system/pages/Filters.vue')
    },
    {
      path: 'forms',
      name: 'ds-forms',
      component: () => import('@/modules/design-system/pages/Forms.vue')
    },
    {
      path: 'grid-system',
      name: 'ds-grid-system',
      component: () => import('@/modules/design-system/pages/GridSystem.vue')
    },
    {
      path: 'responsive',
      name: 'ds-responsive',
      component: () => import('@/modules/design-system/pages/Responsive.vue')
    },
    {
      path: 'tabs',
      name: 'ds-tabs',
      component: () => import('@/modules/design-system/pages/Tabs.vue')
    },
    {
      path: 'tags',
      name: 'ds-tags',
      component: () => import('@/modules/design-system/pages/Tags.vue')
    },
    {
      path: 'texts',
      name: 'ds-texts',
      component: () => import('@/modules/design-system/pages/Texts.vue')
    },
    {
      path: 'tiered-menus',
      name: 'ds-tiered-menus',
      component: () => import('@/modules/design-system/pages/TieredMenus.vue')
    }
  ]
})
