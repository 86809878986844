<script setup lang="ts">
import type { NavigationItem } from '@/@core/navigation/types'
import { resolveNavComponentType } from '../../utils/navigation'

defineOptions({
  name: 'SidebarNavigation'
})

const props = withDefaults(
  defineProps<{
    navigationItems?: NavigationItem[]
    indent?: number
    depth?: number
  }>(),
  {
    navigationItems: () => [],
    indent: 0,
    depth: 100
  }
)
</script>

<template>
  <ul
    class="sidebar-navigation-list"
    :class="{ 'sidebar-navigation-list--indent': props.indent }"
    :style="{ '--indent': props.indent }"
  >
    <li
      class="sidebar-navigation-item"
      v-for="item in props.navigationItems"
      :key="item.title"
      :data-type="item.type"
    >
      <component
        :is="resolveNavComponentType(item)"
        :item="item"
        :indent="props.indent"
        :depth="depth"
      />
    </li>
  </ul>
</template>

<style scoped></style>
