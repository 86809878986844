<script setup lang="ts">
import { CodexPresetResize } from '@/gql/graphql'
import { defineComponent, computed } from 'vue'
import { useI18nStore } from '@/@core/i18n'

defineComponent({
  name: 'PresetResizeSelect'
})

const ct = useI18nStore().createI18nContext('ct.presets.general.enums.resize')

const options = computed(() => {
  return [
    { label: ct.$t(CodexPresetResize.Fit), value: CodexPresetResize.Fit },
    { label: ct.$t(CodexPresetResize.Fill), value: CodexPresetResize.Fill },
    { label: ct.$t(CodexPresetResize.Auto), value: CodexPresetResize.Auto }
  ]
})
</script>

<template>
  <Dropdown :options="options"></Dropdown>
</template>
