<script setup lang="ts">
import { computed } from 'vue'
import { CodexLabelEntity } from '@/gql/graphql'
import { useI18nStore } from '@/@core/i18n'

const ct = useI18nStore().createI18nContext('ct.labels.general.enums.entities')
const entities = defineModel<string>('modelValue')

const options = computed(() => {
  return [
    { label: ct.$t(CodexLabelEntity.All), value: CodexLabelEntity.All },
    { label: ct.$t(CodexLabelEntity.Entry), value: CodexLabelEntity.Entry },
    { label: ct.$t(CodexLabelEntity.Asset), value: CodexLabelEntity.Asset }
  ]
})

const computedEntities = computed({
  get() {
    return entities.value
  },
  set(v: CodexLabelEntity[]) {
    if (v[v.length - 1] === CodexLabelEntity.All || v.length === 0) {
      entities.value = [CodexLabelEntity.All]
    } else {
      entities.value = v.filter((e) => e !== CodexLabelEntity.All)
    }
  }
})
</script>

<template>
  <MultiSelect
    v-model="computedEntities"
    :options="options"
    display="chip"
    :show-toggle-all="false"
  />
</template>

<style scoped lang="scss"></style>
