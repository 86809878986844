<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps(['style'])

const topStyle = computed(() => {
	return props.style?.top
})
const bottomStyle = computed(() => {
	return props.style?.bottom
})
const leftStyle = computed(() => {
	return props.style?.left
})
const rightStyle = computed(() => {
	return props.style?.right
})
const middleStyle = computed(() => {
	return props.style?.middle
})
</script>

<template>
	<div class="nine-point-wrapper">
		<div class="nine-point" style="z-index: 0">
			<div class="nine-point">
				<slot name="background" />
			</div>
			<div :style="middleStyle" class="point point-middle">
				<slot name="middle"></slot>
			</div>
			<div class="point point-top-left">
				<slot name="top-left"></slot>
			</div>
			<div :style="topStyle" class="point point-top">
				<slot name="top"></slot>
			</div>
			<div class="point point-top-right">
				<slot name="top-right"></slot>
			</div>
			<div :style="leftStyle" class="point point-left">
				<slot name="left"></slot>
			</div>
			<div :style="rightStyle" class="point point-right">
				<slot name="right"></slot>
			</div>
			<div class="point point-bottom-left">
				<slot name="bottom-left"></slot>
			</div>
			<div :style="bottomStyle" class="point point-bottom">
				<slot name="bottom"></slot>
			</div>
			<div class="point point-bottom-right">
				<slot name="bottom-right"></slot>
			</div>
		</div>
	</div>
</template>

<style scoped>
.nine-point-wrapper {
	position: absolute;
}

.nine-point {
	position: relative;
}

.nine-point-wrapper,
.nine-point {
	display: block;
	width: 100%;
	min-width: 100%;
	height: 100%;
	min-height: 100%;
}

.point {
	position: absolute;
	height: fit-content;
	width: fit-content;
}

.point-top-left {
	left: 0;
	top: 0;
}

.point-top {
	top: 0;
	left: 50%;
	transform: translate(-50%, 0);
}

.point-top-right {
	right: 0;
	top: 0;
}

.point-left {
	left: 0;
	top: 50%;
	transform: translate(0, -50%);
}

.point-middle {
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.point-right {
	right: 0;
	top: 50%;
	transform: translate(0, -50%);
}

.point-bottom-left {
	bottom: 0;
	left: 0;
}

.point-bottom {
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0);
}

.point-bottom-right {
	bottom: 0;
	right: 0;
}
</style>
