import { CodexHooks } from '@/@core'

import ApiKeysSelect from './ApiKeysSelect.vue'

CodexHooks.addFilter('dynamic-form/fields', (items) => {
  return [
    ...items,
    {
      type: 'ApiKeysSelect',
      component: ApiKeysSelect
    }
  ]
})
