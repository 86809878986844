import { CodexHooks } from '@/@core'
import PublishPopup from '@/modules/entries/components/EntryPublish/PublishPopup.vue'

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  return [
    ...items,
    {
      type: 'Dialog',
      name: 'entry-publish',
      component: PublishPopup
    }
  ]
})
