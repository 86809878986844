import gql from 'graphql-tag'

// ASSETS REST queries
export const EDIT_ASSET_INPUT = gql`
  fragment EditAssetInput on CodexAsset {
    alt
    attrs
    author
    caption
    compressedPath
    contentType
    copyright
    createdAt
    createdBy
    customParameters
    duration
    expiresAt
    externalId
    fileName
    focalPoint
    folderId
    height
    id
    ingestedAt
    labels
    path
    provider
    size
    source
    status
    storageId
    tags
    thumbnailTime
    title
    type
    updatedAt
    updatedBy
    versionId
    width
  }
`

export const READ_ASSET = gql`
  ${EDIT_ASSET_INPUT}

  query ReadAsset($id: String!) {
    asset(input: $input)
      @rest(type: "CodexAsset", path: "organizationId/assets/{args.input.id}", method: "GET") {
      ...EditAssetInput
    }
  }
`

export const UPDATE_ASSET = gql`
  ${EDIT_ASSET_INPUT}

  mutation UpdateAsset($input: EditAssetInput!) {
    updateAsset(id: $id, input: $input)
      @rest(type: "CodexAsset", path: "organizationId/assets/{args.id}", method: "PUT") {
      ...EditAssetInput
    }
  }
`

export const DELETE_ASSET = gql`
  ${EDIT_ASSET_INPUT}

  mutation DeleteAsset($id: String!) {
    deleteAsset(id: $id)
      @rest(type: "CodexAsset", path: "organizationId/assets/{args.id}", method: "DELETE") {
      NoResponse
    }
  }
`

export const MOVE_ASSET = gql`
  fragment MoveAssetInput on CodexAsset {
    id
    assets
  }

  mutation MoveAsset($input: MoveAssetInput!) {
    moveAsset(input: $input)
      @rest(type: "CodexAsset", path: "organizationId/assets/move", method: "PUT") {
      NoResponse
    }
  }
`
