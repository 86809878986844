<script setup lang="ts">
import { useI18nStore } from '@/@core/i18n'
import { type Context } from '@/modules/@global/composables/useContext'
import { computed, inject, ref, watch } from 'vue'
import { useOrganization } from '@/modules/organizations/store/organization'
import FieldMapper from '@/modules/models/components/mappings/components/FieldMapper.vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { CREATE_MODEL_MAPPING } from '@/modules/models/components/mappings/queries/index.rest'
import { READ_MODEL } from '@/modules/models/queries/index.rest'

const context: Context = inject('context') as Context
let dialog = inject('dialogRef')

const modelId = dialog?.value?.data?.modelId

const ct = useI18nStore().createI18nContext('ct.models.popups.create-model-mapping')
const { allModels } = useOrganization()

const modelOptions = computed(() => allModels.filter((m) => m.id !== modelId))

const mapping = ref({
  fromModel: modelId,
  toModel: null,
  fields: {},
  configurations: {
    isEnabled: false,
    folderId: null
  }
})

const currentModelResponse = ref(null)
currentModelResponse.value = useQuery(READ_MODEL, { id: modelId })
const currentModel = computed(() => currentModelResponse.value?.result?.model)

const toModel = computed({
  get() {
    if (!mapping.value.toModel) return null
    const currentModelResponse = ref(null)
    currentModelResponse.value = useQuery(
      READ_MODEL,
      computed(() => {
        return {
          id: mapping.value.toModel
        }
      })
    )
    return computed(() => currentModelResponse.value?.result?.model)?.value
  },
  set(v) {
    mapping.value.toModel = v.id
  }
})

watch(toModel, () => {
  mapping.value.fields = {}
})

const createModelMapping = useMutation(CREATE_MODEL_MAPPING)

const saveHandler = async () => {
  let results = {}

  try {
    results = await createModelMapping.mutate({
      input: mapping.value,
      modelId: modelId
    })
  } catch (error) {
    console.log(error)
  }
  dialog?.value?.close(results.data.createModelMapping)
}
</script>

<template>
  <SidebarContent :title="ct.$t('title')">
    <div>
      <Text type="body-regular-base" :text="ct.$t('model')" />
      <Dropdown
        v-model="toModel"
        :options="modelOptions"
        option-label="name"
        :option-value="(m) => m"
        filter
        reset-filter-on-hide
      >
        <template #value="{ value }">
          <template v-if="value">
            <GjIcon :name="value.iconId" size="18" />
            <Text is="span" :text="value.name" />
          </template>
        </template>
        <template #option="{ option }">
          <GjIcon :name="option.iconId" size="18" />
          <Text is="span" :text="option.name" />
        </template>
      </Dropdown>
    </div>

    <template v-if="mapping.toModel">
      <FieldMapper
        v-model:model-value="mapping.fields"
        :current-model="currentModel"
        :to-model="toModel"
      />

      <div style="display: flex; gap: 8px">
        <Checkbox v-model="mapping.configurations.isEnabled" binary input-id="folderId" />
        <Text is="label" for="folderId" style="display: flex; gap: 4px">
          <Text type="body-regular-base" :text="ct.$t('assets.label')" />
          <Text is="span" v-tooltip.top="ct.$t('assets.info')">
            <GjIcon name="Info" size="20" />
          </Text>
        </Text>
      </div>
    </template>

    <template #footer>
      <Button @click="saveHandler">
        {{ ct.$t('save') }}
      </Button>
    </template>
  </SidebarContent>
</template>

<style scoped></style>
