<script setup lang="ts">
const props = defineProps<{
  options: Array<{ label: string; value: string | number }>
}>()

const modelValue = defineModel<Array<String>>()
</script>

<template>
  <div>
    <div v-for="option in props.options" :key="option.value">
      <Checkbox
        :value="option.value"
        v-model="modelValue"
        type="checkbox"
        :input-id="option.value"
      />
      <Text is="label" :for="option.value">{{ option.label }}</Text>
    </div>
  </div>
</template>
