import gql from 'graphql-tag'

export const GET_PERMISSIONS = gql`
  query GetPermissions($id: String!) {
    getPermissions(id: $id)
      @rest(
        type: "CodexPermissions"
        path: "{args.id}/users/current-user/permissions"
        method: "GET"
      ) {
      id
      permissions
    }
  }
`
