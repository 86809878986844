<script setup lang="ts">
import { inject, ref } from 'vue'
import type { Filter } from '@/modules/@global/components/Filters/Filters'

defineOptions({
	name: 'FilterItems'
})

const props = defineProps<{ filters: any[] }>()

const filtersObject = inject<{ isFilterActive: Function }>('filtersObject')
const editFilter = inject<Function>('editFilter')!
const activeFilter = inject<{ filter: { name: string } }>('activeFilter')

const refs = ref<any>({})
const itemClick = (filter: Filter) => {
	const filterEl = Array.isArray(refs.value[filter.name])
		? refs.value[filter.name][0]
		: refs.value[filter.name]
	editFilter(filterEl, filter, {}, 'filters-dropdown')
}
</script>

<template>
	<div class="new-filters-dropdown__items">
		<template v-for="(filter, idx) in props.filters">
			<!-- Divider -->
			<hr v-if="filter.type === 'divider'" :key="`${filter.name}-${idx}`" class="new-filters-dropdown__divider" />

			<!-- Group -->
			<div v-else-if="filter.type === 'group'" :key="`${filter.name}-${idx}-group`" class="new-filters-dropdown__group">
				<div class="new-filters-dropdown__group-title">
					{{ filter.label }}
				</div>
				<FilterItems :filters="filter.filters" />
			</div>

			<!-- Filter -->
			<div v-else :key="`${filter.name}-${idx}-else`" :ref="(el) => (refs[filter.name] = el)"
				class="new-filters-dropdown__item" :class="{
			'new-filters-dropdown__item--active': filtersObject.isFilterActive(filter.name),
			'new-filters-dropdown__item--open': activeFilter.filter?.name === filter.name
		}" @click="itemClick(filter)">
				<!-- <component :is="filter.isModelField ? filter.type : filter.name">
					{{ filter.label }}
				</component> -->
				<p class="text text--body-regular-base">{{ filter.label }}</p>
			</div>
		</template>
	</div>
</template>

<style lang="scss">
.new-filters-dropdown__items {
	display: flex;
	flex-direction: column;
}

.new-filters-dropdown__items--margin {
	margin-top: 10px;
	margin-bottom: 10px;
}

.new-filters-dropdown__divider {
	margin: 8px 0;
}

.new-filters-dropdown__group {
	display: flex;
	flex-direction: column;
}

.new-filters-dropdown__group-title {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	text-transform: uppercase;
	color: #667c99;

	padding: 6px 16px;
}

.new-filters-dropdown__item {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #667c99;

	padding: 6px 16px;

	cursor: pointer;
	display: inline-flex;
	justify-content: space-between;

	&:hover {
		background: #f4f6fa;
	}
}

.new-filters-dropdown__item--active {
	color: #1a73e8;
	background: #f4f6fa;
}

.new-filters-dropdown__item--open {
	color: #1a73e8;
	background: #f4f6fa;
	font-weight: 500;
}
</style>
