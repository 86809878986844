<script setup lang="ts">
import { computed } from 'vue'
import PrimeVueInputText from 'primevue/inputtext'

const props = withDefaults(
	defineProps<{
		size?: 'small' | 'large'
		block?: boolean
	}>(),
	{
		size: '',
		block: false
	}
)

const getInputTextClasses = computed(() => {
	return {
		[`p-inputtext-sm`]: props.size === 'small',
		[`p-inputtext-lg`]: props.size === 'large',
		[`p-inputtext-block`]: props.block
	}
})
</script>

<template>
	<PrimeVueInputText :class="getInputTextClasses"></PrimeVueInputText>
</template>
