import gql from 'graphql-tag'

export const PUBLISHABLE_AUTHOR_INPUT = gql`
  fragment PublishableAuthorInput on CodexAuthor {
    id
    userId
    externalId
    firstName
    lastName
    byline
    url
    imageId
    status
    biography
    email
    website
    facebook
    instagram
    twitter
    linkedin
    telegram
    tiktok
  }
`

export const READ_AUTHOR = gql`
  ${PUBLISHABLE_AUTHOR_INPUT}

  query ReadAuthor($id: String!, $input: PublishableAuthorInput!) {
    author(id: $id, input: $input)
      @rest(type: "CodexAuthor", path: "organizationId/authors/{args.id}", method: "GET") {
      ...PublishableAuthorInput
    }
  }
`

export const CREATE_AUTHOR = gql`
  ${PUBLISHABLE_AUTHOR_INPUT}

  mutation CreateAuthor($input: PublishableAuthorInput!) {
    createAuthor(input: $input)
      @rest(type: "CodexAuthor", path: "organizationId/authors", method: "POST") {
      ...PublishableAuthorInput
    }
  }
`
export const UPDATE_AUTHOR = gql`
  ${PUBLISHABLE_AUTHOR_INPUT}

  mutation UpdateAuthor($input: PublishableAuthorInput!) {
    updateAuthor(input: $input)
      @rest(type: "CodexAuthor", path: "organizationId/authors/{args.input.id}", method: "PUT") {
      ...PublishableAuthorInput
    }
  }
`

export const DELETE_AUTHOR = gql`
  ${PUBLISHABLE_AUTHOR_INPUT}

  mutation DeleteAuthor($id: String!, $input: PublishableAuthorInput!) {
    deleteAuthor(id: $id, input: $input)
      @rest(type: "CodexAuthor", path: "organizationId/authors/{args.id}", method: "DELETE") {
      NoResponse
    }
  }
`
