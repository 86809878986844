<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable'
import { CREATE_PRESET } from '@/modules/presets/queries/index.rest'
import { inject } from 'vue'
import { CreatePresetForm } from '@/modules/presets/forms/Preset'
import { useI18nStore } from '@/@core/i18n'
import { presetDefaults } from '@/modules/presets/constants'
import type { Context } from '@/modules/@global/composables/useContext'
import { DialogRefKey } from '@/symbols'

/**
 * Context
 */
const context: Context = inject('context') as Context

/**
 * Translation Context
 */
const ct = useI18nStore().createI18nContext('ct.presets.popups.create-preset')

/**
 * Dialog
 */
const dialog = inject(DialogRefKey)

/**
 * Create preset
 */
const createPreset = useMutation(CREATE_PRESET, {
  refetchQueries: ['ListPresets']
})

async function createPresetHandler(values) {
  dialog?.value?.close(values)
}

/**
 * Default values
 */
const defaultValues = presetDefaults()
</script>

<template>
  <SidebarContent :title="ct.$t('title')">
    <DynamicForm
      name="create-preset"
      :ref="(r:any) => context?.setRef('form', r)"
      :show-submit="false"
      :schema="CreatePresetForm()"
      :mutation="createPreset"
      :data="defaultValues"
      :labels="{
        submit: ct.$t('submit')
      }"
      @submit="createPresetHandler"
    ></DynamicForm>

    <template #footer>
      <Button v-bind="context.getRef('form')?.props?.submit.value"></Button>
    </template>
  </SidebarContent>
</template>
