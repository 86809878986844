import { CodexHooks } from '@/@core'
import WorkflowStatusSelect from './WorkflowStatusSelect.vue'
import WorkflowsEventsSelect from './WorkflowsEventsSelect.vue'

CodexHooks.addFilter('dynamic-form/fields', (items) => {
  return [
    ...items,
    {
      type: 'workflows-status-select',
      component: WorkflowStatusSelect
    },
    {
      type: 'workflows-event-select',
      component: WorkflowsEventsSelect
    }
  ]
})
