import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=organization', (items) => {
  return [
    ...items,
    {
      path: 'assets',
      name: 'assets',
      component: () => import('@/modules/assets/pages/AssetsGrid.vue'),
      meta: {
        activeNavItem: 'assets'
      }
    }
  ]
})
