import { CodexHooks } from '@/@core'
import NumberFilter from '@/modules/@global/filters/number/NumberFilter'
import TextFilter from './text/TextFilter'
import CheckboxFilter from './checkbox/CheckboxFilter'
import DateTimeFilter from './datetime/DateTimeFilter'

CodexHooks.addFilter('filters/types', (items) => {
	return [
		...items,
		{
			type: 'number',
			component: NumberFilter
		},
		{
			type: 'text',
			component: TextFilter
		},
		{
			type: 'datetime',
			component: DateTimeFilter
		},
		{
			type: 'checkbox',
			component: CheckboxFilter
		}
	]
})
