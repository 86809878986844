import { CodexHooks } from '@/@core'
import ConfirmDelete from '@/modules/@global/popups/ConfirmDelete.vue'
import UploadProgress from '@/modules/@global/popups/UploadProgress.vue'
import ConfirmPopup from '@/modules/@global/popups/ConfirmPopup.vue'

/**
 * @popup confirm-delete
 * @title Confirm Delete
 * @defaultProps {
 *  "list": ['Item 1', 'Item 2', 'Item 3']
 *  }
 */
export interface ConfirmDeleteComponentPreview {
  props: {
    list: string[]
  }
}

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  items = [
    ...items,
    {
      type: 'Dialog',
      name: 'confirm-delete',
      component: ConfirmDelete
    },
    {
      type: 'Dialog',
      name: 'asset-upload-progress',
      component: UploadProgress
    },
    {
      type: 'Dialog',
      name: 'confirm-popup',
      component: ConfirmPopup
    }
  ]

  return items
})
