<script setup lang="ts">
import { useI18nStore } from '@/@core/i18n'
import { type Context } from '@/modules/@global/composables/useContext'
import { computed, inject, ref, watch } from 'vue'
import { useOrganization } from '@/modules/organizations/store/organization'
import FieldMapper from '@/modules/models/components/mappings/components/FieldMapper.vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  DELETE_MODEL_MAPPING,
  READ_MODEL_MAPPING,
  UPDATE_MODEL_MAPPING
} from '@/modules/models/components/mappings/queries/index.rest'
import { READ_MODEL } from '@/modules/models/queries/index.rest'
import { cloneDeep } from 'lodash'

const context: Context = inject('context') as Context
let dialog = inject('dialogRef')

const mappingId = dialog?.value?.data?.mappingId
const mapping = ref(null)

const modelId = dialog?.value?.data?.modelId
const model = ref(null)

const toModel = ref(null)

useQuery(READ_MODEL_MAPPING, { id: mappingId }, { fetchPolicy: 'no-cache' }).onResult((result) => {
  mapping.value = cloneDeep(result?.data?.modelMapping)
})

const ct = useI18nStore().createI18nContext('ct.models.popups.update-model-mapping')
const { allModels } = useOrganization()

const modelOptions = computed(() => allModels.filter((m) => m.id !== modelId))

useQuery(
  READ_MODEL,
  { id: modelId },
  {
    fetchPolicy: 'no-cache'
  }
).onResult((result) => {
  model.value = result?.data?.model
})

const toModelComputed = computed({
  get() {
    if (!mapping.value?.toModel) return null
    useQuery(
      READ_MODEL,
      computed(() => {
        return {
          id: mapping.value.toModel
        }
      })
    ).onResult((result) => {
      toModel.value = result?.data?.model
    })
    return toModel.value
  },
  set(v) {
    mapping.value.toModel = v.id
  }
})

watch(mapping?.value?.toModel, () => {
  mapping.value.fields = {}
})

const updateModelMapping = useMutation(UPDATE_MODEL_MAPPING)
const deleteModelMapping = useMutation(DELETE_MODEL_MAPPING)

const saveHandler = async () => {
  let results = {}

  try {
    results = await updateModelMapping.mutate({
      input: mapping.value,
      modelId: modelId
    })
  } catch (error) {
    console.log(error)
  }
  dialog?.value?.close(results.data.createModelMapping)
}

const deleteHandler = async () => {
  let results = {}

  try {
    results = await deleteModelMapping.mutate({
      input: mapping.value,
      modelId: modelId
    })
  } catch (error) {
    console.log(error)
  }
  dialog?.value?.close(results.data.createModelMapping)
}
</script>

<template>
  <SidebarContent :title="ct.$t('title')">
    <div>
      <Text type="body-regular-base" :text="ct.$t('model')" />

      <Dropdown
        :model-value="toModelComputed"
        :options="modelOptions"
        option-label="name"
        :option-value="(m) => m"
        filter
        reset-filter-on-hide
        disabled
      >
        <template #value="{ value }">
          <template v-if="value">
            <GjIcon :name="value.iconId" size="18" />
            <Text is="span" :text="value.name" />
          </template>
        </template>
        <template #option="{ option }">
          <GjIcon :name="option.iconId" size="18" />
          <Text is="span" :text="option.name" />
        </template>
      </Dropdown>
    </div>

    <template v-if="mapping?.toModel">
      <FieldMapper
        v-model:model-value="mapping.fields"
        :current-model="model"
        :to-model="toModel"
      />

      <div style="display: flex; gap: 8px">
        <Checkbox v-model="mapping.configurations.isEnabled" binary input-id="folderId" />
        <Text is="label" for="folderId" style="display: flex; gap: 4px">
          <Text type="body-regular-base" :text="ct.$t('assets.label')" />
          <Text is="span" v-tooltip.top="ct.$t('assets.info')">
            <GjIcon name="Info" size="20" />
          </Text>
        </Text>
      </div>
    </template>

    <template #footer>
      <div class="model-mapping-update__footer">
        <Button @click="deleteHandler" severity="danger"> {{ ct.$t('delete') }} </Button
        ><Button @click="saveHandler">
          {{ ct.$t('save') }}
        </Button>
      </div>
    </template>
  </SidebarContent>
</template>

<style lang="scss">
.model-mapping-update__footer {
  width: 100%;
  display: flex;
  justify-content: space-between !important;
}
</style>
