// Middleware to retry failed requests
import { RetryLink } from '@apollo/client/link/retry'

export default function createRetryLink() {
  return new RetryLink({
    attempts: {
      max: 2
    }
  })
}
