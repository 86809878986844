<script setup lang="ts">
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    chips: object[]
    limit?: number
    labelKey?: string
  }>(),
  {
    chips: () => [],
    labelKey: 'name'
  }
)

const menu = ref()
const toggle = (event) => {
  menu.value.toggle(event)
}

const list = computed(() => {
  if (props.limit) {
    return props.chips.slice(0, props.limit)
  } else {
    return props.chips
  }
})

const remainingList = computed(() => {
  if (props.limit && props.chips.length > props.limit) {
    return props.chips.slice(props.limit)
  } else {
    return []
  }
})
</script>

<template>
  <div class="chip-group">
    <Chip v-for="chip in list">{{ chip[labelKey] }}</Chip>
    <Chip v-if="remainingList.length > 0" @click="toggle" class="chip-group__more">
      <span>+{{ remainingList.length }}</span>
      <TieredMenu :model="remainingList" ref="menu" popup>
        <template #item="{ item }">
          <!-- p-menuitem-link is a class from PrimeVue -->
          <span class="p-menuitem-link"> {{ item[labelKey] }} </span>
        </template>
      </TieredMenu>
    </Chip>
  </div>
</template>

<style scoped></style>
