import { useI18nStore } from '@/@core/i18n'
import type { FormField } from '@/modules/@global/components/DynamicForm/DynamicForm'
import * as yup from 'yup'

/**
 * ApiKey forms fields definition
 */
interface ApiKeyFormFields {
  name: FormField
  teams: FormField,
	hasExpiry: FormField,
	expiresAt: FormField,
	apiKey: FormField
}

/**
 * ApiKey forms fields
 */
export const ApiKeyFormFields = (): ApiKeyFormFields => {
  const ct = useI18nStore().createI18nContext('ct.apikeys.forms.fields')

  return {
    name: {
      type: 'Text',
      name: 'name',
      label: ct.$t('name.label'),
      rules: yup.string().required('Name is required')
    },
    teams: {
      type: 'TeamsSelect',
      name: 'teams',
      label: ct.$t('teams.label')
    },
		hasExpiry: {
      type: 'Checkboxes',
      name: 'hasExpiry',
      label: '',
			props: {
				options: [
					{
						label: ct.$t('hasExpiry.label'),
						value: '1'
					}
				]
			}
    },
		expiresAt: {
      type: 'DatePicker',
      name: 'expiresAt',
      label: ct.$t('expiresAt.label'),
      show: (values) => Object.keys({ ...values.hasExpiry }).length > 0,
			props: {
				minDate: new Date()
			}
    },
		apiKey: {
			type: 'Text'
		}
  }
}

/**
 * Create apikey form
 */
const CreateApiKeyForm = (): FormField[] => {
  const fields = ApiKeyFormFields()

  return [
    {
      type: 'Row',
      children: [
        // Name
        {
          ...fields.name,
          layout: { col: 12 }
        },
        // Teams
        {
          ...fields.teams,
          layout: { col: 12 }
        },
        // Has expiry
        {
          ...fields.hasExpiry,
          layout: { col: 12 }
        },
        // Expires at
        {
          ...fields.expiresAt,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

export { CreateApiKeyForm }
