import { merge } from 'lodash'
import { CodexUrlEntityType, CodexUrlStatusCode } from '@/gql/graphql'
import { enumMapping } from '../@global/utils/EnumMapping'

/**
 * Url Name
 */
export const URLS_NAME = 'urls-listing'

export const URL_ENTITY_TYPE_MAPPING = enumMapping({
  [CodexUrlEntityType.Entry]: 1,
  [CodexUrlEntityType.Section]: 2,
  [CodexUrlEntityType.Author]: 3,
  [CodexUrlEntityType.Redirect]: 4
})

export const URL_REDIRECT_STATUS_CODE_MAPPING = enumMapping({
  [CodexUrlStatusCode.Permanent]: 301,
  [CodexUrlStatusCode.Temporary]: 302,
  [CodexUrlStatusCode.Gone]: 410
})

/**
 * Url Defaults
 */
export function urlDefaults(url = {}) {
  return merge(
    {
      entityType: CodexUrlEntityType.Entry,
      model: null,
      entityId: null,
      url: '/',
      statusCode: CodexUrlStatusCode.Permanent,
      redirectUrl: null,
      siteId: null
    },
    url
  )
}
