import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=organization', (items) => {
  return [
    ...items,
    {
      path: 'labels',
      name: 'labels',
      component: () => import('@/modules/labels/pages/LabelsListing.vue')
    }
  ]
})
