import { CodexHooks } from '@/@core'
import { isString } from 'lodash'
import { GRAVITY_MAPPING, RESIZE_MAPPING } from '@/modules/presets/constants'

CodexHooks.addFilter(
  'apollo/restFetchOptions?type=CodexPreset',
  (options, context, uri, headers) => {
    if (isString(options.body)) {
      try {
        const bodyParsed = JSON.parse(options.body)

        if (bodyParsed.gravity) {
          bodyParsed.gravity = GRAVITY_MAPPING.toNumber(bodyParsed.gravity)
        }
        if (bodyParsed.resize) {
          bodyParsed.resize = RESIZE_MAPPING.toNumber(bodyParsed.resize)
        }

        options.body = JSON.stringify(bodyParsed)
      } catch (e) {
        // Do nothing
      }
    }

    return options
  }
)

CodexHooks.addFilter('apollo/responseTransformer?type=CodexPreset', (response) => {
  if (response?.gravity) {
    response.gravity = GRAVITY_MAPPING.toString(response.gravity)
  }

  if (response?.resize) {
    response.resize = RESIZE_MAPPING.toString(response.resize)
  }

  return response
})
