import { CodexHooks } from '@/@core'
import SelectColumns from './SelectColumns.vue'
import ConfirmTakeoverLockPopup from '@/modules/entries/popups/ConfirmTakeoverLockPopup.vue'
import CreateView from '@/modules/entries/popups/CreateView.vue'
import UpdateView from '@/modules/entries/popups/UpdateView.vue'
import ConfirmDelete from '@/modules/@global/popups/ConfirmDelete.vue'
import { useI18nStore } from '@/@core/i18n'

CodexHooks.addFilter('popups/popups', (items: any[]) => {
  const ct = useI18nStore().createI18nContext('ct.views.popups.confirm-delete-view')
  return [
    ...items,
    {
      type: 'Sidebar',
      name: 'select-columns',
      component: SelectColumns
    },
    {
      type: 'Dialog',
      name: 'confirm-takeover-lock',
      component: ConfirmTakeoverLockPopup
    },
    {
      type: 'Sidebar',
      name: 'save-view',
      component: CreateView
    },
    {
      type: 'Sidebar',
      name: 'update-view',
      component: UpdateView
    },
    {
      type: 'Dialog',
      name: 'confirm-delete-view',
      component: ConfirmDelete,
      data: {
        labels: {
          cancel: ct.$p('cancel'),
          confirm: ct.$p('confirm'),
          title: ct.$p('title'),
          message: ct.$p('message')
        }
      }
    }
  ]
})
