import { graphql } from '@/gql'

// ASSETS graqhql queries
export const LIST_ASSETS = graphql(/* GraphQL */ `
  query ListAssets($limit: Int, $offset: Int, $where: CodexAssetFilter, $order: CodexAssetOrder) {
    data: assetCollection(limit: $limit, offset: $offset, where: $where, order: $order) {
      items {
        id
        folderId
        folder {
          path {
            id
            name
          }
        }
        type
        title
        contentType
        createdAt
        expiresAt
        updatedAt
        status
        size
        url(
          transformation: { width: 200, height: 200, quality: 60, resize: FILL, format: THUMBNAIL }
        )
        fileName
        caption
        alt
        source
        duration
        author
        copyright
        tags
        attrs
        width
        height
        focalPoint {
          x
          y
        }
        labels {
          id
          name
          color
        }
      }
      total
    }
  }
`)

export const GET_ASSET_BY_ID = graphql(/* GraphQL */ `
  query assetById($id: String!) {
    asset(id: $id) {
      id
      folderId
      title
      fileName
      width
      height
      tags
      source
      size
      url
      path
      type
      caption
      alt
      author
      copyright
      updatedAt
      expiresAt
      updatedBy {
        id
        email
        firstName
        lastName
        imageUrl
      }
      createdAt
      createdBy {
        id
        email
        firstName
        lastName
        imageUrl
      }
    }
  }
`)

// FOLDER graqhql queries
export const LIST_FOLDERS = graphql(/* GraphQL */ `
  query folderCollection($limit: Int, $parentId: CodexStringFilter) {
    data: folderCollection(limit: $limit, where: { parentId: $parentId }) {
      total
      items {
        id
        name
        path {
          name
          id
        }
        parentId
        parent {
          id
          name
        }
        children {
          total
          items {
            id
            name
            parentId
            parent {
              id
              name
            }
          }
        }
      }
    }
  }
`)

export const GET_FOLDER_BY_ID = graphql(/* GraphQL */ `
  query folder($id: String!) {
    data: folder(id: $id) {
      id
      name
      parentId
      createdAt
      path {
        name
        id
      }
      parentId
      parent {
        id
        name
      }
      children {
        total
        items {
          id
          name
          parentId
          parent {
            id
            name
          }
          children {
            total
            items {
              id
              name
              parentId
              parent {
                id
                name
              }
            }
          }
          path {
            name
            id
          }
        }
      }
    }
  }
`)
