import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=organization', (items) => {
	return [
		...items,
		{
			path: 'apikeys',
			name: 'apikeys',
			component: () => import('@/modules/apikeys/pages/ApiKeysListing.vue')
		}
	]
})