<script setup lang="ts">
const props = defineProps<{
  options: Array<{
    label: string
    value: string
    class?: string
    children?: string
    childrenClass?: string
  }>
}>()

const modelValue = defineModel<String>()

const selectOption = (option: String) => {
  modelValue.value = option
}
</script>

<template>
  <div>
    <div
      v-for="option in props.options"
      :key="option.value"
      :class="option.class"
      @click="selectOption(option.value)"
      class="radio-buttons"
    >
      <RadioButton
        :value="option.value"
        v-model="modelValue"
        type="radio"
        :input-id="option.value"
      />
      <div v-if="option.children" v-html="option.children" :class="option.childrenClass"></div>
      <Text is="label" :for="option.value">{{ option.label }}</Text>
    </div>
  </div>
</template>

<style scoped lang="scss">
.radio-buttons {
  cursor: pointer;

  .text {
    cursor: pointer;
  }
}
</style>
