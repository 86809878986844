<template>
  <template v-for="(instance, key) in instanceMap" :key="key">
    <DynamicDialogInstance
      :instance="instance"
      @hide="onDialogHide(instance)"
      @after-hide="onDialogAfterHide"
    >
      <component :is="instance.content" v-bind="instance.options.emits"></component>
    </DynamicDialogInstance>
  </template>
</template>

<script>
import BaseComponent from 'primevue/basecomponent'
import DynamicDialogEventBus from 'primevue/dynamicdialogeventbus'
import { UniqueComponentId } from 'primevue/utils'
import DynamicDialogInstance from './DynamicDialogInstance.vue'

export default {
  name: 'DynamicDialog',
  extends: BaseComponent,
  inheritAttrs: false,
  data() {
    return {
      instanceMap: {}
    }
  },
  openListener: null,
  closeListener: null,
  currentInstance: null,
  mounted() {
    this.openListener = ({ instance }) => {
      const key = `${UniqueComponentId()}_dynamic_dialog`

      instance.visible = true
      instance.key = key
      this.instanceMap[key] = instance
    }

    this.closeListener = ({ instance, params }) => {
      const key = instance.key
      const currentInstance = this.instanceMap[key]

      if (currentInstance) {
        currentInstance.visible = false
        currentInstance.options.onClose &&
          currentInstance.options.onClose({ data: params, type: 'config-close' })

        this.currentInstance = currentInstance
      }

      delete this.instanceMap[key]
    }

    DynamicDialogEventBus.on('open', this.openListener)
    DynamicDialogEventBus.on('close', this.closeListener)
  },
  beforeUnmount() {
    DynamicDialogEventBus.off('open', this.openListener)
    DynamicDialogEventBus.off('close', this.closeListener)
  },
  methods: {
    onDialogHide(instance) {
      !this.currentInstance &&
        instance.options.onClose &&
        instance.options.onClose({ type: 'dialog-close' })
    },
    onDialogAfterHide() {
      this.currentInstance && delete this.currentInstance
      this.currentInstance = null
    },
    getTemplateItems(template) {
      return Array.isArray(template) ? template : [template]
    }
  },
  components: {
    DynamicDialogInstance
  }
}
</script>
