import { CodexHooks } from '@/@core'
import { useOrganization } from '../store/organization'

CodexHooks.addFilter('ability/permissions', (permissions) => {
  const organization = useOrganization()

  if (organization.permissions) {
    permissions = {
      ...permissions,
      organization: organization.permissions
    }
  }

  return permissions
})
