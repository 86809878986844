import gql from 'graphql-tag'

export const MODEL_MAPPING_INPUT = gql`
  fragment CodexModelMappingInput on CodexModelMapping {
    fromModel
    toModel
    configurations
    createdAt
    createdBy
    fields
    id
    updatedAt
    updatedBy
  }
`

export const READ_MODEL_MAPPING = gql`
  ${MODEL_MAPPING_INPUT}

  query ReadModel($id: String!, $modelId: String!) {
    modelMapping(id: $id, modelId: $modelId)
      @rest(
        type: "CodexModelMappingInput"
        path: "codex-dev/models/{args.modelId}/mappings/{args.id}"
        method: "GET"
      ) {
      ...CodexModelMappingInput
    }
  }
`

export const CREATE_MODEL_MAPPING = gql`
  ${MODEL_MAPPING_INPUT}

  mutation CreateModel($input: CodexModelMappingInput!, $modelId: String!) {
    createModel(input: $input, modelId: $modelId)
      @rest(
        type: "CodexModelMapping"
        path: "codex-dev/models/{args.modelId}/mappings"
        method: "POST"
      ) {
      ...CodexModelMappingInput
    }
  }
`

export const UPDATE_MODEL_MAPPING = gql`
  ${MODEL_MAPPING_INPUT}

  mutation UpdateModel($input: CodexModelMappingInput!, $modelId: String!) {
    updateModel(input: $input, modelId: $modelId)
      @rest(
        type: "CodexModelMapping"
        path: "codex-dev/models/{args.modelId}/mappings/{args.input.id}"
        method: "PUT"
      ) {
      ...CodexModelMappingInput
    }
  }
`

export const DELETE_MODEL_MAPPING = gql`
  ${MODEL_MAPPING_INPUT}

  mutation DeleteModel($modelId: String!, $input: PublishableModelInput!) {
    deleteModel(modelId: $modelId, input: $input)
      @rest(
        type: "CodexModel"
        path: "codex-dev/models/{args.modelId}/mappings/{args.input.id}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`
