import { CodexHooks } from '@/@core'

CodexHooks.addFilter('router/items?parent=null', (items) => {
  return [
    ...items,
    {
      path: '/organizations',
      name: 'organizations',
      component: () => import('@/modules/organizations/pages/OrganizationsView.vue')
    },
    {
      path: '/:organizationId',
      name: 'organization',
      component: () => import('@/modules/organizations/pages/OrganizationView.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('@/modules/@core/pages/NotFound.vue')
    }
  ]
})
