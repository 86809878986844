import type { Context } from '@/modules/@global/composables/useContext'
import { CodexDebug } from '..'
import { ref, computed } from 'vue'
import type { Action, Filter, PriorityHandler } from './types/hooks'
import { SourceType } from './types/hooks'

class CodexHooks {
	private static actions: Map<string, PriorityHandler<Action>[]> = new Map()
	private static filters: Map<string, PriorityHandler<Filter>[]> = new Map()

	/**
   * Get whether the hook was registered from core / module / plugins
   */
  static parseHookSource(sourceLine?: string): object {
    if (!sourceLine) return {}
    const source: any = {}

    if (sourceLine?.includes('src/plugins')) {
      source.type = SourceType.Plugin

      const matches = sourceLine.match(/src\/plugins\/(.*?)\/(.*?)\//)
      if (matches) {
        source.plugin = matches[1]
        source.version = matches[2]
      }
    } else if (sourceLine?.includes('src/modules')) {
      source.type = SourceType.Module

      const matches = sourceLine.match(/src\/modules\/(.*?)\//)
      if (matches) {
        source.module = matches[1]
      }
    } else if (sourceLine?.includes('src/@core')) {
      source.type = SourceType.Core

      const matches = sourceLine.match(/src\/@core\/(.*?)\//)
      if (matches) {
        source.module = matches[1]
      }
    }

    return source
  }

  /**
   * Some Functions to help with debugging in the console
   *
	 * Get all actions and filters grouped by name
	 */
	static getActionsGroupedByName(name: string): any {
		const grouped: any = {}
		const entries: any = this.actions.entries()
		for (const [key, value] of entries) {
			const ns = this.namespace(key)
			if (grouped[ns] === undefined) grouped[ns] = {}
			grouped[ns][key] = value
		}
		if (name) return grouped[name]
		return grouped
	}

	static getFiltersGroupedByName(name: string): any {
		const grouped: any = {}
		const entries: any = this.filters.entries()
		for (const [key, value] of entries) {
			const ns = this.namespace(key)
			if (grouped[ns] === undefined) grouped[ns] = {}
			grouped[ns][key] = value
		}
		if (name) return grouped[name]
		return grouped
	}

  /**
   * Get all actions and filters grouped by source
   */
  static getActionsGroupedBySource(source: string, module?: string, plugin?: string): any {
    const grouped: any = {}
    const entries: any = this.actions.entries()
    for (const [key, value] of entries) {
      const actions = value.filter((a) => {
        const sourceData = a.source
        if (sourceData?.type === source) {
          if (module && sourceData.module !== module) return false
          if (plugin && sourceData.plugin !== plugin) return false
          return true
        }
        return false
      })

      if (actions.length) grouped[key] = actions
    }
    return grouped
  }

  static getFiltersGroupedBySource(source: string, module?: string, plugin?: string): any {
    const grouped: any = {}
    const entries: any = this.filters.entries()
    for (const [key, value] of entries) {
      const filters = value.filter((f) => {
        const sourceData = f.source
        if (sourceData?.type === source) {
          if (module && sourceData.module !== module) return false
          if (plugin && sourceData.plugin !== plugin) return false
          return true
        }
        return false
      })

      if (filters.length) grouped[key] = filters
    }
    return grouped
  }

	/**
	 * Reactive filter
	 */
	static reactiveFilter(filterName: string, value: any, context?: Context, ...args: any[]): any {
		const refreshKey = ref(0)

		// prettier-ignore
		CodexHooks.addAction(`hooks/filter-added?name=${CodexHooks.nameWithoutParams(filterName)}`, () => {
			refreshKey.value++
		})

		// prettier-ignore
		CodexHooks.addAction(`hooks/filter-removed?name=${CodexHooks.nameWithoutParams(filterName)}`, () => {
			refreshKey.value++
		})

		return computed(() => {
			refreshKey.value

			return CodexHooks.applyFilters(filterName, value, context, ...args)
		})
	}

	/**
	 * Action handling
	 */

	static addActionWithSource(
    source: string,
    actionName: string,
    handler: Action,
    context?: Context,
    priority: number = 10
  ): void {
    this.addAction(actionName, handler, context, priority, source)
  }

  static addAction(
    actionName: string,
    handler: Action,
    context?: Context,
    priority: number = 10,
    source?: string
  ): void {
    const actionSource = CodexHooks.parseHookSource(source)
    const actionKey = this.hookNameWithContext(actionName, context)
    const handlers = this.actions.get(actionKey) || []
    handlers.push({ handler, context, priority, source: actionSource })
		handlers.sort((a, b) => a.priority - b.priority)
		this.actions.set(actionKey, handlers)

		// [Codex Hooks Hook] ex: `hooks/action-added`
		CodexHooks.applyActions('hooks/action-added', null, actionName, handler, priority)

		// [Codex Hooks Hook] ex: `hooks/action-added?name=navigation/items?parent=null`
		CodexHooks.applyActions(`hooks/action-added?name=${actionName}`, null, handler, priority)

		// [Codex Hooks Hook] ex: `hooks/action-added?name=navigation/items`
		CodexHooks.applyActions(
			`hooks/action-added?name=${CodexHooks.nameWithoutParams(actionName)}`,
			null,
			handler,
			priority
		)
	}

	static removeAction(actionName: string, handler: Action, context?: Context): void {
		const actionKey = this.hookNameWithContext(actionName, context)
		const handlers = this.actions.get(actionKey)

		if (handlers) {
			const index = handlers.findIndex((h) => h.handler === handler)
			if (index !== -1) {
				handlers.splice(index, 1)

				// [Codex Hooks Hook] ex: `hooks/action-removed`
				CodexHooks.applyActions('hooks/action-removed', null, actionName, handler)

				// [Codex Hooks Hook] ex: `hooks/action-removed?name=navigation/items?parent=null`
				CodexHooks.applyActions(`hooks/action-removed?name=${actionName}`, null, handler)

				// [Codex Hooks Hook] ex: `hooks/action-removed?name=navigation/items`
				CodexHooks.applyActions(
					`hooks/action-removed?name=${CodexHooks.nameWithoutParams(actionName)}`,
					null,
					handler
				)
			}
		}
	}

	static applyActions(actionName: string, context?: Context | any, ...args: any[]): void {
		const actionKey = this.hookNameWithContext(actionName, context)

		const handlers = this.actions.get(actionKey)
		if (handlers) {
			handlers.forEach(({ handler, context }) => {
				handler(context, ...args)
			})
		}
	}

	static async applyActionsAwait(
		actionName: string,
		context?: Context | null,
		...args: any[]
	): Promise<void> {
		const actionKey = this.hookNameWithContext(actionName, context)

		const handlers = this.actions.get(actionKey)
		if (handlers) {
			await Promise.all(
				handlers.map(async ({ handler, context }) => {
					await handler(context, ...args)
				})
			)
		}
	}

	/**
   * Remove actions by source type
   */
  static removeActionsBySource(source: string): void {
    const actions = this.actions.entries()
    for (const [key, value] of actions) {
      const filtered = value.filter((a) => a?.source?.type === source)
      filtered.forEach((a) => this.removeAction(key, a.handler, a.context || undefined))
    }
  }

  /**
	 * Filter handling
	 */

	static addFilterWithSource(
    source: string,
    filterName: string,
    filter: Filter,
    context?: Context,
    priority: number = 10
  ): void {
    this.addFilter(filterName, filter, context, priority, source)
  }

  static addFilter(
    filterName: string,
    filter: Filter,
    context?: Context | null,
    priority: number = 10,
    source?: string
  ): void {
    const filterSource = CodexHooks.parseHookSource(source)
    const filterKey = this.hookNameWithContext(filterName, context)
    const filters = this.filters.get(filterKey) || []
    filters.push({ handler: filter, context, priority, source: filterSource })
		filters.sort((a, b) => a.priority - b.priority)
		this.filters.set(filterKey, filters)

		// [Codex Hooks Hook] ex: `hooks/filter-added`
		CodexHooks.applyActions('hooks/filter-added', null, filterName, filter, priority)

		// [Codex Hooks Hook] ex: `hooks/filter-added?name=navigation/items?parent=null`
		CodexHooks.applyActions(`hooks/filter-added?name=${filterName}`, null, filter, priority)

		// [Codex Hooks Hook] ex: `hooks/filter-added?name=navigation/items`
		CodexHooks.applyActions(
			`hooks/filter-added?name=${CodexHooks.nameWithoutParams(filterName)}`,
			null,
			filter,
			priority
		)
	}

	static removeFilter(filterName: string, filter: Filter, context?: Context): void {
		const filterKey = this.hookNameWithContext(filterName, context)
		const filters = this.filters.get(filterKey)
		if (filters) {
			const index = filters.findIndex((t) => t.handler === filter)
			if (index !== -1) {
				filters.splice(index, 1)

				// [Codex Hooks Hook] ex: `hooks/filter-removed`
				CodexHooks.applyActions('hooks/filter-removed', null, filterName, filter)

				// [Codex Hooks Hook] ex: `hooks/filter-removed?name=navigation/items?parent=null`
				CodexHooks.applyActions(`hooks/filter-removed?name=${filterName}`, null, filter)

				// [Codex Hooks Hook] ex: `hooks/filter-removed?name=navigation/items`
				CodexHooks.applyActions(
					`hooks/filter-removed?name=${CodexHooks.nameWithoutParams(filterName)}`,
					null,
					filter
				)

				// If empty, remove the filter
				if (filters.length === 0) {
					this.filters.delete(filterKey)
				}
			}
		}
	}

	static applyFilters(
		filterName: string,
		value: any,
		context?: Context | null,
		...args: any[]
	): any {
		const filterKey = this.hookNameWithContext(filterName, context)
		const filters = this.filters.get(filterKey)

		if (filters) {
			return filters.reduce((prevValue, { handler, context }) => {
				try {
					return handler(prevValue, context, ...args)
				} catch (e) {
					CodexDebug.hooksError(e, `Error while applying '${filterName}' filter`, handler)
					return prevValue
				}
			}, value)
		}
		return value
	}

	/**
   * Remove filters by source type
   */
  static removeFiltersBySource(source: string): void {
    const filters = this.filters.entries()
    for (const [key, value] of filters) {
      const filtered = value.filter((f) => f?.source?.type === source)
      filtered.forEach((f) => this.removeFilter(key, f.handler, f.context || undefined))
    }
  }

  /**
	 * Utils
	 */
	static nameWithoutParams(name: string): string {
		return name.split('?')[0]
	}

	/**
	 * Helper to get the namespace of a hook
	 */
	static namespace(name: string): any {
		return name.split('/')[0]
	}

	/**
	 * Helper to get the name of a hook
	 *
	 * `context` namespaced hooks cannot be contain a context
	 */
	static hookNameWithContext(name: string, context?: Context | null): string {
		if (this.namespace(name) === 'context') return name
		return context?.id ? `${name}|${context.id}` : name
	}
}

// @ts-ignore
window.CodexHooks = CodexHooks

export default CodexHooks
