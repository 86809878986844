import { CodexHooks } from '@/@core'
import {
  FIELD_FILTER_OPERATORS,
  FIELD_TYPES,
  FIELD_VALUE_TYPES,
  RANGE_OPERATORS
} from '@/modules/models/editor-ts/fields/constants'
import type {
  FieldAttrsType,
  FieldConditionalValidationType
} from '@/modules/@global/models/FieldTypes'
import { rangeValidation, type RangeValidationType } from '../../general/helpers'

const required = {
  name: 'required',
  type: FIELD_TYPES.SECTION,
  valueType: [FIELD_VALUE_TYPES.SINGLE, FIELD_VALUE_TYPES.LIST],
  component: () => import('@/modules/@global/models/field-validations/general/Required.vue'),
  attr: {
    value: false,
    errorMessage: 'Sections field is required.',
    conditionsEnabled: false,
    conditions: [
      {
        isSystem: false,
        field: '',
        operator: FIELD_FILTER_OPERATORS.EXISTS,
        value: ''
      }
    ]
  },
  validation: (value, attr: FieldConditionalValidationType, field: FieldAttrsType) => {
    return attr.value && !value
      ? { isValid: false, errorMessage: attr.errorMessage }
      : { isValid: true }
  }
}

const readOnly = {
  name: 'readOnly',
  type: FIELD_TYPES.SECTION,
  valueType: [FIELD_VALUE_TYPES.SINGLE, FIELD_VALUE_TYPES.LIST],
  component: () => import('@/modules/@global/models/field-validations/general/ReadOnly.vue'),
  attr: {
    value: false,
    conditionsEnabled: false,
    conditions: [
      {
        isSystem: false,
        field: '',
        operator: FIELD_FILTER_OPERATORS.EXISTS,
        value: ''
      }
    ]
  },
  validation: (value, attrs, field) => ({ isValid: true })
}

const numberOfSectionsGenerator = {
  name: 'numberOfSections',
  type: FIELD_TYPES.SECTION,
  valueType: [FIELD_VALUE_TYPES.LIST],
  component: () =>
    import('@/modules/@global/models/field-validations/fields/Sections/LimitSectionsNr.vue'),
  attr: {
    isEnabled: false,
    rangeOperator: RANGE_OPERATORS.BETWEEN,
    min: 1,
    max: 2,
    exactly: 0,
    errorTemplate: 'Number of Sections: {min}-{max}',
    errorMessage: 'Number of Sections: 1-2'
  },
  validation: (value: any, config: RangeValidationType, attrs: FieldAttrsType) => {
    return rangeValidation(value.length, config)
  }
}

const parentChildValidation = {
  name: 'parentChildValidation',
  type: FIELD_TYPES.SECTION,
  valueType: [FIELD_VALUE_TYPES.LIST],
  component: () => import('../Sections/RestrictParentChildSelection.vue'),
  attr: {
    restrictParentChildSelection: false,
    errorMessage: 'You cant select Sections which have parent or children selected'
  },
  validation: (value, attr, field: FieldAttrsType) => {
    // TODO: Handle when sections are added
    return { isValid: true }
  }
}

CodexHooks.addFilter('fields/validations', (items) => {
  return [...items, required, readOnly, numberOfSectionsGenerator, parentChildValidation]
})
