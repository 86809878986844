import { useDialog } from 'primevue/usedialog'
import { usePopupsStore } from '../store/popups'
import { inject } from 'vue'
import type { Context } from './useContext'

export default function usePopups(parentContext?: Context) {
  const dialog = useDialog()
  const popupsStore = usePopupsStore()

  type PropsType = {
    size: 'small' | 'medium' | 'large' | 'x-large'
    [key: string]: any
  }

  /**
   * Show a popup
   */
  const showPopup = (name: string, data: any = {}, props: PropsType = {}, context: any = {}): Promise<{ data?: any, type: string }> => {
    return new Promise((resolve, reject) => {
      const popup = popupsStore.popups.find((popup: any) => popup.name === name)

      if (!popup) {
        return
      }

      let defaultProps = {}
      if (popup.type === 'Sidebar') {
        const sizeClasses = {
          small: 'p-sidebar--sm',
          medium: 'p-sidebar--md',
          large: 'p-sidebar--lg',
          ['x-large']: 'p-sidebar--xl'
        }

        defaultProps = {
          // class: 'md:w-26rem w-12',
          class: `${popup.name} ${sizeClasses[popup.size] || 'p-sidebar--md'}`,
          position: 'right'
        }
      } else if (popup.type === 'Dialog') {
        const sizeClasses = {
          small: 'p-dialog--sm',
          medium: 'p-dialog--md',
          large: 'p-dialog--lg',
          ['x-large']: 'p-dialog--xl'
        }

        defaultProps = {
          modal: true,
          class: `${popup.name} ${sizeClasses[popup.size] || 'p-dialog--md'}`
        }
      }

      /**
       * If a context is not provided, try to inject it from the parent component
       */
      parentContext = parentContext || inject('context')

      const options = {
        parentContext: parentContext,
        context: {
          ...context
        },

        ...popup,
        data: {
          ...popup.data,
          ...data
        },
        props: {
          ...defaultProps,
          ...popup.props,
          ...props
        },
        onClose(returnData: any) {
          resolve(returnData)
        }
      }

      dialog.open(popup.component, options)
    })
  }

  return {
    showPopup
  }
}
