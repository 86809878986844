import { useI18nStore } from '@/@core/i18n'
import type { FormField } from '@/modules/@global/components/DynamicForm/DynamicForm'
import * as yup from 'yup'

/**
 * Team forms fields definition
 */
interface TeamFormFields {
  name: FormField
  description: FormField
  members: FormField
}

/**
 * Team forms fields
 */
export const TeamFormFields = (): TeamFormFields => {
  const ct = useI18nStore().createI18nContext('ct.teams.forms.fields')

  return {
    name: {
      type: 'Text',
      name: 'name',
      label: ct.$t('name.label'),
      rules: yup.string().required('Name is required')
    },
    description: {
      type: 'Text',
      name: 'description',
      label: ct.$t('description.label'),
      rules: yup.string().required('Description is required')
    },
    members: {
      type: 'UsersSelect',
      name: 'members',
      label: ct.$t('members.label')
    }
  }
}

/**
 * Create team form
 */
const CreateTeamForm = (): FormField[] => {
  const fields = TeamFormFields()

  return [
    {
      type: 'Row',
      children: [
        // Name
        {
          ...fields.name,
          layout: { col: 12 }
        },
        // Description
        {
          ...fields.description,
          layout: { col: 12 }
        },
        // Members
        {
          ...fields.members,
          layout: { col: 12 }
        }
      ]
    }
  ]
}

export { CreateTeamForm }
