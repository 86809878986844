<script setup lang="ts">
import DataView from 'primevue/dataview'
import EntryStatusTag from '@/modules/entries/components/EntryStatusTag.vue'
import type { CodexEntry } from '@/gql/graphql'
import { useI18nStore } from '@/@core/i18n'

const ct = useI18nStore().createI18nContext('ct.entries.components.entries-display')

const props = defineProps<{ entries: CodexEntry[] }>()

const quickEdit = () => {
  // TODO: Implement quick edit and refetch
}
</script>

<template>
  <div class="entries-display">
    <slot />

    <DataView :value="props.entries" class="entries-display-table">
      <template #list="{ data }">
        <div class="entries-display-table-row" @click="quickEdit(data)">
          <div class="entries-display-table-row-icon-container">
            <img
              :src="data.system?.featuredMedia?.url"
              v-if="data.system?.featuredMedia?.url"
              class="entries-display-table-row-icon-container__image"
            />
            <div v-else class="entries-display-table-row-icon-container__icon">
              <GjIcon name="Calendar" size="32" />
            </div>
          </div>
          <div style="flex-grow: 1">
            <Text
              is="p"
              type="body-regular-base"
              class="entries-display-table-row-icon-container__title"
              :text="data.system.title"
              v-tooltip.top="data.system.title"
              v-if="data.system.title"
              clamp="1"
            />

            <Text
              is="p"
              type="body-medium-base"
              class="entries-display-table-row-icon-container__title"
              :text="ct.$t('untitled')"
              v-else
              style="opacity: 0.6; font-style: italic"
            />
            <Text
              is="p"
              type="body-regular-small"
              :text="data.system?.model?.name || data.system?.modelAlias"
              clamp="1"
              class="entries-display-table-row-icon-container__model"
            />
          </div>

          <EntryStatusTag :status="data.system.status" rounded />
        </div>
      </template>
    </DataView>
  </div>
</template>

<style lang="scss">
.entries-display {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 300px;
  width: 100%;

  .entries-display-table {
    min-height: 172px;
    overflow-y: auto;

    .grid {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .p-dataview-content > .p-grid > div {
      border: none !important;
    }

    .p-dataview-header {
      padding: 0 !important;
      border: none !important;
    }

    .entries-display-table-row {
      width: 100%;
      display: flex;
      gap: 16px;
      align-items: center;
      padding: 6px 8px;
      background-color: var(--color-elevation-surface);
      border-radius: 4px;
    }

    .entries-display-table-row-icon-container {
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }

    .entries-display-table-row-icon-container__image {
      width: 40px;
      height: 32px;
    }

    .entries-display-table-row-icon-container__icon {
      width: 40px;
      height: 32px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .entries-display-table-row-icon-container__title {
      color: var(--color-text-main);
    }

    .entries-display-table-row-icon-container__model {
      color: var(--color-text-body);
    }
  }
}
</style>
