<script setup lang="ts">
import generateRowClasses from '@/modules/@global/utils/generateRowClasses'
import { type RowProps } from '@/modules/@global/utils/types'
import { computed } from 'vue'

const props = defineProps<RowProps>()

const rowClasses = computed(() => {
  return generateRowClasses(props)
})
</script>

<template>
  <div :class="rowClasses">
    <slot></slot>
  </div>
</template>
