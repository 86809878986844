import gql from 'graphql-tag'
import { useOidcStore } from '@/@core'
import { PUBLISHABLE_PRESET_INPUT } from '@/modules/presets/queries/index.rest'

export const PUBLISHABLE_ENTRY_INPUT = gql`
  fragment PublishableEntryInput on CodexEntry {
    id
    system {
      revisionId
      siteId
      modelId
      modelAlias
      externalId
      status
      ingestedAt
      createdAt
      updatedAt
      publishedAt
      firstPublishedAt
      unpublishedAt
      createdBy
      updatedBy
      versionId
      title
      slug
      featuredMedia
      labels
      metrics
      clonedFrom
      scheduledVersions
    }
    content
    attrs
    relations
  }
`

export const READ_ENTRY = gql`
  ${PUBLISHABLE_ENTRY_INPUT}

  query ReadEntry($siteId: String!, $modelAlias: String!, $entryId: String!) {
    entry(siteId: $siteId, modelAlias: $modelAlias, entryId: $entryId)
      @rest(
        type: "CodexEntry"
        path: "organizationId/entries/{args.entryId}?siteId={args.siteId}&model={args.modelAlias}"
        method: "GET"
      ) {
      ...PublishableEntryInput
    }
  }
`

export const CREATE_ENTRY = gql`
  ${PUBLISHABLE_ENTRY_INPUT}

  mutation CreateEntry($input: PublishableEntryInput!) {
    createEntry(input: $input)
      @rest(
        type: "CodexEntry"
        path: "organizationId/entries?siteId={args.input.system.siteId}&model={args.input.system.modelAlias}"
        method: "POST"
      ) {
      ...PublishableEntryInput
    }
  }
`

export const UPDATE_ENTRY = gql`
  ${PUBLISHABLE_ENTRY_INPUT}

  mutation UpdateEntry(
    $input: PublishableEntryInput!
    $isAutoSave: Boolean
    $publishReferences: Boolean
  ) {
    updateEntry(input: $input, isAutoSave: $isAutoSave, publishReferences: $publishReferences)
      @rest(
        type: "CodexEntry"
        path: "organizationId/entries/{args.input.id}?model={args.input.system.modelAlias}&siteId={args.input.system.siteId}&publishReferences={args.publishReferences}&isAutoSave={args.isAutoSave}"
        method: "PUT"
      ) {
      ...PublishableEntryInput
    }
  }
`

// TODO: Delete Entry
export const DELETE_ENTRY = gql`
  ${PUBLISHABLE_ENTRY_INPUT}

  mutation DeleteEntry($id: String!, $input: PublishableEntryInput!) {
    deleteEntry(id: $id, input: $input)
      @rest(type: "CodexEntry", path: "codex-dev/presets/{args.id}", method: "DELETE") {
      NoResponse
    }
  }
`

export const CREATE_SCHEDULED_ENTRY_VERSION = gql`
  fragment CodexScheduledVersion on CodexScheduledVersion {
    createdAt: DateTime
    createdBy: CodexUser
    entryId: String
    id: String
    publishScheduledDate: DateTime
    status: CodexScheduledVersionStatus
    unpublishScheduledDate: DateTime
    updatedAt: DateTime
    updatedBy: CodexUser
    versionId: String
  }
  fragment ScheduledEntryVersionInput on ScheduledEntryVersionInput {
    model: String
    siteId: String
    publishScheduledDate: String
    unpublishScheduledDate: String
    publishReferences: Boolean
  }

  mutation CreateScheduledEntry(
    $entryId: String!
    $versionId: String!
    $input: ScheduledEntryVersionInput
  ) {
    createScheduledEntry(entryId: $entryId, versionId: $versionId, input: $input)
      @rest(
        type: "CodexScheduledVersion"
        path: "organizationId/entries/{args.entryId}/versions/{args.versionId}/schedule"
        method: "PUT"
      ) {
      CodexScheduledVersion
    }
  }
`

export const DELETE_SCHEDULED_ENTRY_VERSION = gql`
  mutation DeleteScheduledEntry(
    $entryId: String!
    $versionId: String!
    $scheduledVersionId: String!
    $model: String!
    $siteId: String!
  ) {
    deleteScheduledEntry(
      entryId: $entryId
      versionId: $versionId
      scheduledVersionId: $scheduledVersionId
      model: $model
      siteId: $siteId
    )
      @rest(
        type: "CodexScheduledVersion"
        path: "organizationId/entries/{args.entryId}/versions/{args.versionId}/schedule?model={args.model}&siteId={args.siteId}&scheduledVersionId={args.scheduledVersionId}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`

export const LOCK_ENTRY = gql`
  mutation LockEntry(
    $siteId: String!
    $model: String!
    $entryId: String!
    $takeover: Boolean!
    $input: LockEntryInput!
  ) {
    entryLock(
      siteId: $siteId
      model: $model
      entryId: $entryId
      takeover: $takeover
      input: $input
    )
      @rest(
        type: "LockEntryInput"
        path: "organizationId/entries/{args.entryId}/lock?siteId={args.siteId}&model={args.model}&takeover={args.takeover}"
        method: "POST"
      ) {
      siteId
      model
      entryId
      lockedBy
      lockedAt
      releaseAt
      status
      id
      createdAt
      updatedAt
      createdBy
      updatedBy
    }
  }
`

export const CHECK_LOCK = gql`
  mutation CheckLock($siteId: String!, $model: String!, $entryId: String!) {
    checkLock(siteId: $siteId, model: $model, entryId: $entryId)
      @rest(
        type: "LockEntryInput"
        path: "organizationId/entries/{args.entryId}/lock?siteId={args.siteId}&model={args.model}&takeover={args.takeover}"
        method: "GET"
      ) {
      siteId
      model
      entryId
      lockedBy
      lockedAt
      releaseAt
      status
      id
      createdAt
      updatedAt
      createdBy
      updatedBy
      createdAt
    }
  }
`

export function deleteEntryLockFetch({
  organizationId = null,
  siteId = null,
  model = null,
  id = null
} = {}) {
  const token = useOidcStore().oidcAccessToken
  fetch(
    `https://api.codex.gjirafa.dev/v2/${organizationId}/entries/${id}/lock?model=${model}&siteId=${siteId}`,
    {
      method: 'DELETE',
      keepalive: true,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}

export const PUBLISHABLE_VIEW_INPUT = gql`
  fragment PublishableViewInput on CodexView {
    createdAt
    createdBy
    id
    name
    path
    searchTerm
    siteId
    teams
    type
    customizations
    filters
    updateAt
    updatedBy
  }
`

export const READ_VIEW = gql`
  ${PUBLISHABLE_VIEW_INPUT}

  query ReadView($id: String!, $input: PublishableViewInput!) {
    view(id: $id, input: $input)
      @rest(type: "CodexView", path: "organizationId/views/{args.id}", method: "GET") {
      ...PublishableViewInput
    }
  }
`

export const CREATE_VIEW = gql`
  ${PUBLISHABLE_VIEW_INPUT}

  mutation CreateView($input: PublishableViewInput!) {
    createView(input: $input)
      @rest(type: "CodexView", path: "organizationId/views", method: "POST") {
      ...PublishableViewInput
    }
  }
`

export const UPDATE_VIEW = gql`
  ${PUBLISHABLE_VIEW_INPUT}

  mutation UpdateView($input: PublishableViewInput!) {
    updateView(input: $input)
      @rest(type: "CodexView", path: "organizationId/views/{args.input.id}", method: "PUT") {
      ...PublishableViewInput
    }
  }
`

export const DELETE_VIEW = gql`
  ${PUBLISHABLE_VIEW_INPUT}

  mutation DeleteView($id: String!, $input: PublishableViewInput!) {
    deleteView(id: $id, input: $input)
      @rest(type: "CodexView", path: "organizationId/views/{args.id}", method: "DELETE") {
      NoResponse
    }
  }
`
