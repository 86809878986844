import { CodexHooks } from '@/@core'
import { generateNavigationItem } from '@/@core/navigation'
import { useI18nStore } from '@/@core/i18n'
import { useOrganization } from '@/modules/organizations/store/organization'
import Folders from '@/modules/assets/pages/folders/Folders.vue'

CodexHooks.addFilter('navigation/items?parent=assets-module', (items) => {
  const ct = useI18nStore().createI18nContext('ct.assets.navigation')

  return [
    ...items,
    // TODO: Folders,
    generateNavigationItem({
      name: 'folders',
      title: ct.$t('listing'),
      type: 'custom',
      component: Folders,
      route: { name: 'assets', params: { organizationId: useOrganization().organizationId } }
    })
  ]
})
